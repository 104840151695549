import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from '@mui/material';
import { ICost, TCostCreateRequest, TCostResponse } from '@types';
import { deleteCostAPI, saveCostAPI } from 'apis/cost';
import CostItemAccordion from 'components/costitem/CostItemAccordion';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import CostForm from './CostForm';

type Props = {
  data?: ICost;
};

CostAccordion.defaultProps = {
  data: undefined,
};

export default function CostAccordion({ data }: Props) {
  const queryClient = useQueryClient();

  const handleSaveCost = (request: TCostCreateRequest) => {
    saveCostMutation.mutate(request);
  };

  const handleDeleteCost = (code: string) => {
    deleteCostMutation.mutate(code);
  };

  const onSaveSuccess = (result: TCostResponse) => {
    if (result.success) {
      toast(`${result.data?.name} 저장 성공`, { type: 'success' });
      queryClient.invalidateQueries('cost');
    }
  };

  const onDeleteSuccess = (result: TCostResponse) => {
    if (result.success) {
      toast(`${result.data?.name} 삭제 성공`, { type: 'success' });
      queryClient.invalidateQueries('cost');
    }
  };

  const saveCostMutation = useMutation(saveCostAPI, {
    onSuccess: onSaveSuccess,
  });

  const deleteCostMutation = useMutation(deleteCostAPI, {
    onSuccess: onDeleteSuccess,
  });

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {data ? (
          <>
            <Typography sx={{ width: '66%', flexShrink: 0 }} fontWeight="bold">
              {data?.name} : {data?.code}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{`단위 : ${data?.unitCode.codeName}`}</Typography>
          </>
        ) : (
          <Typography>새로운 요금 생성</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Box component="div" sx={{ paddingX: 1 }}>
          <CostForm data={data} onSave={handleSaveCost} onDelete={handleDeleteCost} />
        </Box>
        <Box component="div" sx={{ paddingX: 2 }}>
          <Divider sx={{ marginY: 2 }} />
          <Typography fontWeight="bold" sx={{ mb: 1 }}>
            요금 아이템 목록
          </Typography>
          {data && (
            <>
              {data.items.map((item) => (
                <CostItemAccordion key={item.id} cost={data.code} data={item} />
              ))}
              <CostItemAccordion cost={data.code} />
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
