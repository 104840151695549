import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { SystemLayout } from 'layout/system';
import { Box } from '@mui/material';
import CodeTree from 'components/code/CodeTree';
import { useMutation, useQuery } from 'react-query';
import { createCodeAPI, findAllCodesAPI, modifyCodeAPI } from 'apis/code';
import { ICode, TCodeCreateResponse } from '@types';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { codeState } from 'states';
import { sortCode } from 'common/lib';
import CodeForm from 'components/code/CodeForm';
import useLoading from 'common/hooks/useLoading';

function CodePage() {
  // 코드 필드 상태 초기화
  const resetCode = (parent?: string) =>
    setSelectedCode({
      parent,
      id: undefined,
      children: undefined,
      code: undefined,
      codeName: undefined,
      description: undefined,
      orderNum: undefined,
      useYN: undefined,
      createdAt: undefined,
      updatedAt: undefined,
    });

  // Tree 선태 시, 코드 필드 세팅
  const onClickItem = (code: ICode) => {
    setSelectedCode(code);
  };

  // 코드 필드 데이터 변경 시, 세팅
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setSelectedCode((prev) => ({ ...prev, [name]: checked }));
    } else {
      setSelectedCode((prev) => ({ ...prev, [name]: value }));
    }
  };

  // 코드 저장, id값 유무에 따라 생성/수정
  const onSave = () => {
    if (selectedCode?.id) {
      modifyMutation.mutate(selectedCode);
    } else {
      createMutation.mutate(selectedCode);
    }
  };

  // 저장 성공 EventHandler
  const onSaveSuccess = (data: TCodeCreateResponse) => {
    if (data.success) {
      toast(`${data.data?.code} 저장 성공`, { type: 'success' });
      refetch();
    }
  };

  // 코드 생성 Mutation
  const createMutation = useMutation(createCodeAPI, {
    onSuccess: onSaveSuccess,
  });

  // 코드 수정 Mutation
  const modifyMutation = useMutation(modifyCodeAPI, {
    onSuccess: onSaveSuccess,
  });

  // 코드 조회
  const { data: codes, refetch, isFetching } = useQuery(['codes'], findAllCodesAPI);

  // 코드 재정렬
  const orderedData = useMemo(() => sortCode(codes?.data ?? []), [codes]);

  // 선택된 코드 상태
  const [selectedCode, setSelectedCode] = useState<Partial<ICode>>({});

  // Recoil 코드 세터
  const setCodes = useSetRecoilState(codeState);

  // 코드가 새롭게 바뀔 경우 RecoilState에 세팅
  useEffect(() => {
    setCodes(orderedData);
  }, [orderedData]);

  // 컴포넌트 최초 렌더링 시, 코드 필드 초기화
  useEffect(() => {
    resetCode();
  }, []);

  const changeLoading = useLoading({ trigger: false });

  useEffect(() => {
    changeLoading({ loading: createMutation.isLoading, message: '코드 저장중...' });
  }, [createMutation.isLoading]);

  useEffect(() => {
    changeLoading({ loading: modifyMutation.isLoading, message: '코드 저장중...' });
  }, [modifyMutation.isLoading]);

  useEffect(() => {
    changeLoading({ loading: isFetching, message: '코드 조회중...' });
  }, [isFetching]);

  return (
    <SystemLayout title="코드관리">
      <Box component="div" display="flex" flexDirection="row">
        <Box component="div" flex={2} p={1}>
          <CodeTree
            data={orderedData}
            onClickItem={onClickItem}
            onAddItem={resetCode}
            containerStyle={{ minHeight: '70vh', maxHeight: '70vh' }}
          />
        </Box>
        <Box component="div" flex={1} p={1}>
          <CodeForm
            data={selectedCode}
            onSave={onSave}
            onChange={onChange}
            containerStyle={{ minHeight: '70vh', maxHeight: '70vh' }}
          />
        </Box>
      </Box>
    </SystemLayout>
  );
}

export default CodePage;
