import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GA_ID } from '@const';
import ReactGA from 'react-ga';

export default function useReactGA() {
  const location = useLocation();
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (GA_ID !== undefined) {
      ReactGA.initialize(GA_ID);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
}
