import React, { useMemo } from 'react';
import BaseTree, { RenderBaseTree } from 'components/common/BaseTree';
import { SxProps, Theme } from '@mui/material';
import { IGroup } from '@types';
import { flatten } from 'common/lib';
import _ from 'lodash';

interface Props {
  data?: IGroup[];
  containerStyle?: SxProps<Theme>;
  showAddBtn?: boolean;
  showCheckBtn?: boolean;
  onClickItem?(group: IGroup): void;
  onChecked?(group: IGroup, checked: boolean): void;
  onAddItem?(parent: string): void;
}

GroupTree.defaultProps = {
  data: [],
  showAddBtn: true,
  showCheckBtn: false,
  onClickItem: undefined,
  onAddItem: undefined,
  onChecked: undefined,
  containerStyle: {},
};

export default function GroupTree({
  containerStyle,
  showAddBtn,
  showCheckBtn,
  onClickItem,
  onAddItem,
  onChecked,
  data,
}: Props) {
  const createTree = (group: IGroup): RenderBaseTree => {
    return {
      id: group.id.toString(),
      name: `${group.groupCode} | ${group.groupName} | ${group.groupType.name} ${
        group.groupDetailType ? `| ${group.groupDetailType.name}` : ''
      }`,
      children: group.children?.map((sub) => createTree(sub)),
    };
  };

  const onClick = (tree: RenderBaseTree) => {
    if (typeof onClickItem === 'function') {
      const selected = flatDatas.filter((code) => code.id.toString() === tree.id);

      if (selected && selected.length > 0) {
        onClickItem(selected[0]);
      }
    }
  };

  const onClickAdd = (parentId: string) => {
    if (typeof onAddItem === 'function') {
      const selected = flatDatas.filter((code) => code.id.toString() === parentId);

      if (selected && selected.length > 0) {
        onAddItem(selected[0].groupCode);
      }
    }
  };

  const handleChecked = (nodeId: string, checked: boolean) => {
    if (typeof onChecked === 'function') {
      const selected = _.find(flatDatas, (group) => group.id.toString() === nodeId);

      if (selected) {
        onChecked(selected, checked);
      }
    }
  };

  const flatDatas = useMemo(() => flatten(data ?? []) as IGroup[], [data]);

  const treeData: RenderBaseTree[] = useMemo(() => {
    return (data ?? []).map((tree) => createTree(tree));
  }, [data]);

  return (
    <BaseTree
      showAddBtn={showAddBtn}
      showCheckBtn={showCheckBtn}
      data={treeData}
      onClickItem={onClick}
      onClickAdd={onClickAdd}
      onChecked={handleChecked}
      containerStyle={containerStyle}
    />
  );
}
