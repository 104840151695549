import React, { ChangeEvent, ReactElement } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormLabel } from '@mui/material';

type Props = {
  label?: string | ReactElement;
  title?: string;
  name: string;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
};

BaseCheckbox.defaultProps = {
  label: '',
  title: undefined,
  defaultChecked: false,
  checked: false,
  onChange: undefined,
};

export default function BaseCheckbox(props: Props) {
  const { title, label, ...others } = props;

  return (
    <FormControl>
      {title && <FormLabel>{title}</FormLabel>}
      <FormControlLabel label={label} control={<Checkbox {...others} />} />
    </FormControl>
  );
}
