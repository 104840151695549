import React, { useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IEmissionItemHist, TEmissionItemHistListResponse } from '@types';
import BaseTablePagination from 'components/common/BaseTablePagination';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { findALlEmissionItemHistAPI } from 'apis/emission';
import { useTheme } from '@mui/material';

interface Column {
  id: keyof IEmissionItemHistGridData;
  label: string;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
}

export interface IEmissionItemHistGridData {
  idx: number;
  code: string;
  name: string;
  parent: string;
  unit: string;
  value?: number;
  orderNum: number;
  message?: string;
  createdAt?: string;
  createBy?: string;
}

function createData(data: IEmissionItemHist): IEmissionItemHistGridData {
  return {
    idx: data.idx,
    code: data.history.code || '',
    name: data.history.name || '',
    parent: data.history.parent || '',
    unit: data.history.unit || '',
    value: data.history.value,
    orderNum: data.history.orderNum,
    message: data.message,
    createdAt: data.createdAt,
    createBy: data.createBy,
  };
}

const columns: readonly Column[] = [
  { id: 'idx', label: '인덱스' },
  { id: 'createdAt', label: '수정일시' },
  { id: 'message', label: '내용' },
  { id: 'createBy', label: '작업자' },
  { id: 'parent', label: '부모코드' },
  { id: 'code', label: '코드' },
  { id: 'name', label: '아이템명' },
  { id: 'unit', label: '단위' },
  { id: 'value', label: '배출계수' },
  { id: 'orderNum', label: '순서' },
];

type Props = {
  emissionItemId?: number;
};

EmissionItemHistGrid.defaultProps = {
  emissionItemId: undefined,
};

export default function EmissionItemHistGrid({ emissionItemId }: Props) {
  const theme = useTheme();
  const [state, setState] = useState<{ page: number; rowsPerPage: number }>({
    page: 0,
    rowsPerPage: 100,
  });

  const { data } = useQuery(['emissionItemHist', emissionItemId], () =>
    findALlEmissionItemHistAPI(emissionItemId || 0),
  );

  const rows = useMemo(
    () =>
      data?.data
        ?.slice(state.page * state.rowsPerPage, (state.page + 1) * state.rowsPerPage)
        .map((hist) => createData(hist)),
    [state, data],
  );

  const renderCell = (row: any, column: Column) => {
    const value = row[column.id];
    return value;
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 500, minHeight: 500 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    backgroundColor: theme.palette.mode === 'light' ? '#f7fafc !important' : '#0f1018 !important',
                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.idx}>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.id} align={column.align} sx={{ paddingY: 0 }}>
                        {renderCell(row, column)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <BaseTablePagination
        totalCount={data?.data?.length || 0}
        rowsPerPage={state.rowsPerPage}
        page={state.page}
        onPageChange={(page) => setState((prev) => ({ ...prev, page }))}
        onRowsPerPageChange={(rowsPerPage) => setState((prev) => ({ ...prev, rowsPerPage }))}
      />
    </Paper>
  );
}
