import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, SelectChangeEvent, TextField } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { roleState, flatSiteState } from 'states';
import { IUser, TUserCreateRequest } from '@types';
import RoleRadioGroup from 'components/role/RoleRadioGroup';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import SiteSelectBox from 'components/site/SiteSelectBox';

interface Props extends IBaseModalProps {
  onSave?(data: TUserCreateRequest): void;
  isMyModify?: boolean;
  data?: IUser | null;
}

UserSaveModal.defaultProps = {
  onSave: undefined,
  isMyModify: false,
  data: {},
};

export default function UserSaveModal({ open, data, onSave, onClose, isMyModify }: Props) {
  const init = () => {
    setState({
      id: undefined,
      userId: undefined,
      password: undefined,
      name: undefined,
      dept: undefined,
      site: undefined,
      role: undefined,
      useYN: undefined,
    });
  };

  const onConfirm = () => {
    if (typeof onSave === 'function') {
      onSave(state);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;

    if (type === 'checkbox') {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const roles = useRecoilValue(roleState);

  const flatSite = useRecoilValue(flatSiteState);

  const [state, setState] = useState<TUserCreateRequest>({
    id: undefined,
    userId: undefined,
    password: undefined,
    name: undefined,
    dept: undefined,
    site: undefined,
    role: undefined,
    useYN: undefined,
  });

  useEffect(() => {
    if (data) {
      setState({ ...data, site: data.site?.siteCode });
    } else {
      init();
    }
  }, [data]);

  return (
    <BaseModal
      open={open}
      onConfirm={onConfirm}
      onClose={onClose}
      title="유저 저장"
      confirmTitle="저장"
      cancleTitle="취소"
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        sx={{ width: 500, height: 400 }}
      >
        <TextField name="id" sx={{ display: 'none' }} type="hidden" value={state.id} />
        <Box component="div" mt={2} />
        <TextField
          required
          name="userId"
          variant="standard"
          label="유저 아이디"
          value={state.userId}
          onChange={onChange}
        />
        <Box component="div" mt={1} />
        <TextField
          name="password"
          variant="standard"
          label="비밀번호"
          type="password"
          required={!data?.id}
          value={state.password}
          onChange={onChange}
        />
        <Box component="div" mt={1} />
        <TextField required name="name" variant="standard" label="유저 이름" value={state.name} onChange={onChange} />
        <Box component="div" mt={1} />
        <TextField name="dept" variant="standard" label="부서명" value={state.dept} onChange={onChange} />
        <Box component="div" mt={1} />
        {!isMyModify && (
          <Box component="div">
            <SiteSelectBox
              name="site"
              data={flatSite}
              value={state.site}
              defaultValue={data?.site?.siteCode}
              onChange={onSelectChange}
              useYN
            />
          </Box>
        )}
        {!isMyModify && (
          <Box component="div" mt={2}>
            <RoleRadioGroup
              showLabel
              name="role"
              defaultValue={data?.role}
              value={state.role}
              onChange={onChange}
              data={roles}
            />
          </Box>
        )}
        {!isMyModify && (
          <FormControl>
            <FormLabel>사용여부</FormLabel>
            <FormControlLabel
              label="사용여부"
              control={<Checkbox name="useYN" defaultChecked={data?.useYN} checked={state.useYN} onChange={onChange} />}
            />
          </FormControl>
        )}
      </Box>
    </BaseModal>
  );
}
