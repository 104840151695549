import React, { ChangeEvent, useEffect, useState } from 'react';
import { IEmissionItem, TEmissionItemCreateRequest } from '@types';
import { Box, Button, Card, Grid, SelectChangeEvent, SxProps, TextField, Theme, Typography } from '@mui/material';
import useEmissionItemHistModal from 'common/hooks/useEmissionItemHistModal';
import EmissionItemTypeSelect from './EmissionItemTypeSelect';

type Props = {
  containerSx?: SxProps<Theme>;
  data?: IEmissionItem;
  onSave?(data: TEmissionItemCreateRequest): void;
  onDelete?(code: string): void;
};

EmissionItemForm.defaultProps = {
  containerSx: undefined,
  data: undefined,
  onSave: undefined,
  onDelete: undefined,
};

export default function EmissionItemForm({ data, onSave, onDelete, containerSx }: Props) {
  const [state, setState] = useState<TEmissionItemCreateRequest>({});
  const { modal, openModal } = useEmissionItemHistModal({});

  const handleSave = () => {
    if (typeof onSave === 'function') {
      onSave(state);
    }
  };

  const handleDelete = () => {
    if (typeof onDelete === 'function' && data?.code && window.confirm(`${data.code}를 삭제하시겠습니까?`)) {
      onDelete(data.code);
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setState({ ...data });
  }, [data]);

  return (
    <Card variant="outlined" sx={{ p: 2, display: 'flex', ...containerSx }}>
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            fullWidth
            name="parent"
            label="부모코드"
            variant="standard"
            onChange={onChangeInput}
            InputLabelProps={{ shrink: true }}
            defaultValue={data?.parent || ''}
            value={state.parent || ''}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            required
            fullWidth
            name="code"
            label="코드"
            variant="standard"
            onChange={onChangeInput}
            InputLabelProps={{ shrink: true }}
            defaultValue={data?.code || ''}
            value={state.code || ''}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            required
            fullWidth
            name="name"
            label="아이템명"
            variant="standard"
            onChange={onChangeInput}
            InputLabelProps={{ shrink: true }}
            defaultValue={data?.name || ''}
            value={state.name || ''}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <EmissionItemTypeSelect
            required
            fullWidth
            name="type"
            label="아이템유형"
            variant="standard"
            onChange={onChangeSelect}
            defaultValue={data?.type || ''}
            value={state.type || ''}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            fullWidth
            name="unit"
            label="단위"
            variant="standard"
            onChange={onChangeInput}
            InputLabelProps={{ shrink: true }}
            defaultValue={data?.unit || ''}
            value={state.unit || ''}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            fullWidth
            name="value"
            label="배출계수"
            variant="standard"
            onChange={onChangeInput}
            InputLabelProps={{ shrink: true }}
            defaultValue={data?.value || ''}
            value={state.value || ''}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            fullWidth
            name="orderNum"
            label="순서"
            variant="standard"
            onChange={onChangeInput}
            InputLabelProps={{ shrink: true }}
            defaultValue={data?.orderNum || ''}
            value={state.orderNum || ''}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button fullWidth variant="contained" color="info" disabled={!data?.id} onClick={() => openModal(data?.id)}>
            <Typography>이력조회</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button fullWidth variant="contained" onClick={handleSave}>
            <Typography>저장</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button
            fullWidth
            variant="contained"
            color="error"
            disabled={!data?.id || data.children.length > 0}
            onClick={handleDelete}
          >
            <Typography>삭제</Typography>
          </Button>
        </Grid>
      </Grid>
      {modal}
    </Card>
  );
}
