import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';
import React, { useMemo } from 'react';

type Props = BaseSelectBoxProps;

export default function EmissionItemTypeSelect({ defaultValue, value, ...others }: Props) {
  const items = useMemo(
    (): SelectItemProps[] => [
      { id: 'EMISSION_SECTION', name: '배출부문' },
      { id: 'EMISSION_ITEM', name: '배출항목' },
      { id: 'EMISSION_FACTOR', name: '배출계수' },
    ],
    [defaultValue, value],
  );

  return <BaseSelectBox {...others} items={items} value={value} defaultValue={defaultValue} />;
}
