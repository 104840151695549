import React, { useEffect } from 'react';
import { SystemLayout } from 'layout/system';
import { TTaskSearchField } from '@types';
import { useQuery } from 'react-query';
import { findPageTaskAPI } from 'apis/task';
import TaskDataGrid, { ITaskGridData } from 'components/task/TaskDataGrid';
import TaskSearchField from 'components/task/TaskSearchField';
import { Box, Button, Typography } from '@mui/material';
import _ from 'lodash';
import usePageInfo from 'common/hooks/usePageInfo';
import useTaskModal from 'common/hooks/useTaskModal';
import useLoading from 'common/hooks/useLoading';

export function TaskPage() {
  const handleClickRow = (selected: ITaskGridData) => {
    const filtered = changeSelectedTask(selected.id);

    if (filtered) {
      taskModify(filtered);
    }
  };

  const changeSelectedTask = (id: number) => {
    const filtered = _.find(data?.data?.content, (task) => task.id === id);

    if (filtered) {
      setTask(filtered);
    }

    return filtered;
  };

  const onChangeCallback = () => {
    refetch();
  };
  const { changePage, changeRowsPerPage, changeSearch, changeSort, pageInfo } = usePageInfo<TTaskSearchField>({
    defaultPage: 0,
    defaultSize: 10,
    onChangeCallback,
  });
  const loading = useLoading({ trigger: false });
  const { task, modal, setTask, taskCreate, taskModify } = useTaskModal({ onReload: onChangeCallback });
  const { data, refetch, isFetching } = useQuery(['task', pageInfo], () => findPageTaskAPI(pageInfo));

  useEffect(() => {
    if (task) {
      changeSelectedTask(task.id);
    }
  }, [data]);

  useEffect(() => {
    loading({ loading: isFetching, message: '작업 조회중...' });
  }, [isFetching]);

  return (
    <SystemLayout title="작업관리">
      <TaskSearchField onChange={changeSearch} />
      <Box component="div" sx={{ p: 1 }}>
        <TaskDataGrid
          data={data}
          page={pageInfo.page}
          rowPerPage={pageInfo.size}
          onClick={handleClickRow}
          onChangeSort={changeSort}
          onChangePage={changePage}
          onChangeRowPerPage={changeRowsPerPage}
        />
        <Box component="div" display="flex" justifyContent="flex-end" sx={{ mt: 1 }}>
          <Button variant="contained" onClick={taskCreate}>
            <Typography>작업생성</Typography>
          </Button>
        </Box>
      </Box>
      {modal}
    </SystemLayout>
  );
}
