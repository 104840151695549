import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { INodeData, TNodeCreateRequest, TNodeType } from '@types';
import { Data10AggType } from 'common/hooks/useTagChartData10';
import { DateSearchProps } from 'components/common/DateSearchBox';
import React, { ReactElement, useMemo } from 'react';
import { Handle, Position } from 'react-flow-renderer';

export type DateType = 'year' | 'month' | 'day' | 'realtime';

export type NodeSearchFormType = {
  dateSearch?: DateSearchProps;
  aggFunc?: Data10AggType;
};

export type NodeDataProps = INodeData & {
  isEditMode?: boolean;
  onClick?(data: TNodeCreateRequest): void;
  onEdit?(data: TNodeCreateRequest): void;
  onDelete?(id: string): void;
  search?: NodeSearchFormType;
};

export type BaseNodeTypeProps = {
  id: string;
  type: TNodeType;
  data?: NodeDataProps;
  xPos?: number;
  yPos?: number;
  children?: ReactElement | ReactElement[];
};

BaseNodeType.defaultProps = {
  data: {},
  children: undefined,
  xPos: 0,
  yPos: 0,
};

export default function BaseNodeType({ id, data, type, xPos, yPos, children }: BaseNodeTypeProps) {
  // 노드 수정 버튼 클릭 이벤트
  const handleEdit = () => {
    if (typeof data?.onEdit === 'function') {
      data?.onEdit({
        id,
        data,
        type,
        position: { x: xPos || 0, y: yPos || 0 },
      });
    }
  };

  // 노드 삭제 버튼 클릭 이벤트
  const handleDelete = () => {
    if (typeof data?.onDelete === 'function') {
      if (window.confirm('노드를 삭제하시겠습니까?')) {
        data?.onDelete(id);
      }
    }
  };

  const renderHandle = useMemo(() => {
    switch (type) {
      case 'tagNodeInput':
      case 'groupNodeInput':
        return (
          <>
            <Handle type="source" id="left" position={Position.Left} style={{ backgroundColor: 'blue' }} />
            <Handle type="source" id="right" position={Position.Right} style={{ backgroundColor: 'blue' }} />
            <Handle type="source" id="top" position={Position.Top} style={{ backgroundColor: 'blue' }} />
            <Handle type="source" id="bottom" position={Position.Bottom} style={{ backgroundColor: 'blue' }} />
          </>
        );
      case 'tagNodeDefault':
      case 'groupNodeDefault':
        return (
          <>
            <Handle type="source" id="left" position={Position.Left} style={{ top: '60%', backgroundColor: 'blue' }} />
            <Handle type="target" id="left" position={Position.Left} style={{ top: '40%', backgroundColor: 'red' }} />

            <Handle
              type="source"
              id="right"
              position={Position.Right}
              style={{ top: '60%', backgroundColor: 'blue' }}
            />
            <Handle type="target" id="right" position={Position.Right} style={{ top: '40%', backgroundColor: 'red' }} />

            <Handle type="source" id="top" position={Position.Top} style={{ left: '55%', backgroundColor: 'blue' }} />
            <Handle type="target" id="top" position={Position.Top} style={{ left: '45%', backgroundColor: 'red' }} />

            <Handle
              type="source"
              id="bottom"
              position={Position.Bottom}
              style={{ left: '55%', backgroundColor: 'blue' }}
            />
            <Handle
              type="target"
              id="bottom"
              position={Position.Bottom}
              style={{ left: '45%', backgroundColor: 'red' }}
            />
          </>
        );
      case 'tagNodeOutput':
      case 'groupNodeOutput':
        return (
          <>
            <Handle type="target" id="left" position={Position.Left} style={{ backgroundColor: 'red' }} />
            <Handle type="target" id="right" position={Position.Right} style={{ backgroundColor: 'red' }} />
            <Handle type="target" id="top" position={Position.Top} style={{ backgroundColor: 'red' }} />
            <Handle type="target" id="bottom" position={Position.Bottom} style={{ backgroundColor: 'red' }} />
          </>
        );

      default:
        return null;
    }
  }, [type]);

  const renderControlBox = useMemo(
    () =>
      data?.isEditMode && (
        <Box component="div" display="flex" flexDirection="row" justifyContent="flex-end">
          <IconButton size="small" onClick={handleEdit}>
            <Edit />
          </IconButton>
          <IconButton size="small" onClick={handleDelete}>
            <Delete />
          </IconButton>
        </Box>
      ),
    [data],
  );

  return (
    <>
      {renderControlBox}
      <Box component="div">
        {children}
        {renderHandle}
      </Box>
    </>
  );
}
