import { Add, ArrowDownward, ArrowUpward, ModeEdit } from '@mui/icons-material';
import { Box, Card, Divider, IconButton, Typography } from '@mui/material';
import { TGroupFieldCreateRequest } from '@types';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { flatCodeState } from 'states';

type Props = {
  data?: TGroupFieldCreateRequest[];
  label?: string;
  onAddClick?(): void;
  onModifyClick?(data: TGroupFieldCreateRequest): void;
  onOrderChange?(data: TGroupFieldCreateRequest): void;
};

GroupFieldList.defaultProps = {
  label: '그룹필드',
  data: [],
  onAddClick: undefined,
  onModifyClick: undefined,
  onOrderChange: undefined,
};

export default function GroupFieldList({ onModifyClick, onAddClick, onOrderChange, label, data }: Props) {
  const flatCodes = useRecoilValue(flatCodeState);

  const handleAddClick = () => {
    if (typeof onAddClick === 'function') {
      onAddClick();
    }
  };

  const handleModifyClick = (selected: TGroupFieldCreateRequest) => {
    if (typeof onModifyClick === 'function') {
      onModifyClick(selected);
    }
  };

  const handleOrderChange = (data: TGroupFieldCreateRequest, move: number) => {
    if (typeof onOrderChange === 'function') {
      onOrderChange({ ...data, orderNum: (data.orderNum || 0) + move });
    }
  };

  const renderListItem = (field: TGroupFieldCreateRequest) => {
    const selectedType = _.find(flatCodes, (code) => code.code === field.fieldType);
    const seletedCode = _.find(flatCodes, (code) => code.code === field.code);

    if (!selectedType) return undefined;
    if (!seletedCode) return undefined;

    return (
      <Box component="div" key={`${field.code}`}>
        <Typography variant="caption" color="#666">
          {field.code}
        </Typography>
        <Box component="div" display="flex" alignItems="center" sx={{ mt: -1 }}>
          <Box component="div" flex={1}>
            <Typography variant="body2">{`${field.isRequired ? '*' : ''} ${selectedType.codeName} : ${
              seletedCode.codeName
            } : ${field.orderNum}`}</Typography>
          </Box>
          <Box component="div">
            <IconButton size="small" onClick={() => handleOrderChange(field, -1)}>
              <ArrowUpward />
            </IconButton>
            <IconButton size="small" onClick={() => handleOrderChange(field, 1)}>
              <ArrowDownward />
            </IconButton>

            <IconButton size="small" onClick={() => handleModifyClick(field)}>
              <ModeEdit />
            </IconButton>
          </Box>
        </Box>
        <Divider />
      </Box>
    );
  };

  const renderDatas = useMemo(
    () => data?.sort((a, b) => (a.orderNum ?? 0) - (b.orderNum ?? 0)).map((field) => renderListItem(field)),
    [flatCodes, data],
  );

  return (
    <Box component="div">
      <Box component="div">
        <Typography variant="caption" color="#666">
          {label}
        </Typography>
        <IconButton size="small" onClick={handleAddClick}>
          <Add />
        </IconButton>
      </Box>
      <Card variant="outlined" sx={{ p: 1 }}>
        {renderDatas}
      </Card>
    </Box>
  );
}
