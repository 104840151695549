import { TextField, TextFieldProps } from '@mui/material';
import React, { useState } from 'react';
import { SwatchesPicker, ColorResult } from 'react-color';
import { BaseModal, IBaseModalProps } from './BaseModal';

type Props = Omit<IBaseModalProps, 'onConfirm'> & {
  onConfirm?(color?: string): void;
};

ColorPickerModal.defaultProps = {
  onConfirm: undefined,
};

export default function ColorPickerModal({ onConfirm, ...others }: Props) {
  const [color, setColor] = useState<string | undefined>(undefined);

  const handleChange = (color: ColorResult) => {
    const { hex } = color;

    setColor((prev) => {
      if (prev === hex) return undefined;
      return hex;
    });
  };

  const handleConfirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm(color);
    }
  };

  return (
    <BaseModal {...others} onConfirm={handleConfirm}>
      <SwatchesPicker color={color} onChange={(color) => handleChange(color)} />
    </BaseModal>
  );
}
