import { AddAlarm, Alarm, Cancel, Search } from '@mui/icons-material';
import { Box, Chip, IconButton, TextField, Typography } from '@mui/material';
import { IAlarm, IGroupField, ITag, TGroupFieldDataCreateRequest, ILocation } from '@types';
import { findAlarmPageAPI } from 'apis/alarm';
import TagAlarmSelectModal from 'components/alarm/TagAlarmSelectModal';
import LocationInput from 'components/googlemap/LocationInput';
import TagCodeSaveModal from 'components/tag/TagCodeSaveModal';
import TagMutlipleSearchField from 'components/tag/TagMultipleSearchField';
import TagSearchModal from 'components/tag/TagSearchModal';
import ThreeModelSelectInput from 'components/threemodel/ThreeModelSelectInput';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

type Props = {
  field: IGroupField;
  data?: TGroupFieldDataCreateRequest;
  onChange?(data: TGroupFieldDataCreateRequest): void;
};

GroupFieldDataInput.defaultProps = {
  data: undefined,
  onChange: undefined,
};

export default function GroupFieldDataInput({ data, field, onChange }: Props) {
  const [state, setState] = useState<TGroupFieldDataCreateRequest>({
    ...data,
    field: data ? data.field : field.id,
  });

  const { data: alarm } = useQuery(
    ['findAlarmById', state.alarm],
    () => findAlarmPageAPI({ size: 2, page: 0, search: { id: state.alarm } }),
    { enabled: Boolean(state.alarm) },
  );

  const selectedAlarm = useMemo(() => {
    if (alarm) {
      return alarm.data?.content?.at(0);
    }

    return undefined;
  }, [alarm]);

  const [tagSearchModalOpen, setTagSearchModalOpen] = useState<boolean>(false);
  const [alarmSearchModalOpen, setAlarmSearchModalOpen] = useState<boolean>(false);
  const [tagSaveModalOpen, setTagSaveModalOpen] = useState<boolean>(false);
  const [threeModelOpen, setThreeModelOpen] = useState<boolean>(false);

  // 데이터 변경 이벤트
  const onChangeData = (data: string | undefined) => {
    const newState = { ...state, data };
    setState(newState);

    if (typeof onChange === 'function') {
      onChange(newState);
    }
  };

  // 알람 변경 이벤트
  const onChangeAlarm = (alarmId: number | undefined) => {
    const newState = { ...state, alarm: alarmId };
    setState(newState);

    if (typeof onChange === 'function') {
      onChange(newState);
    }
  };

  // Input데이터 변경 이벤트
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeData(value);
  };

  const onChangeModel = (id: string | undefined) => {
    onChangeData(id);
  };

  // Tag 검색 모달창 열기
  const handleTagSearchModalOpen = () => {
    setTagSearchModalOpen(true);
  };

  // Tag 검색 모달창 닫기
  const handleTagSearchModalClose = () => {
    setTagSearchModalOpen(false);
  };

  // Alarm 검색 모달창 열기
  const handleAlarmSearchModalOpen = () => {
    setAlarmSearchModalOpen(true);
  };

  // Alarm 검색 모달창 닫기
  const handleAlarmSearchModalClose = () => {
    setAlarmSearchModalOpen(false);
  };

  // Tag Save 모달창 열기
  const handleTagSaveModalOpen = () => {
    setTagSaveModalOpen(true);
  };

  // Tag Save 모달창 닫기
  const handleTagSaveModalClose = () => {
    setTagSaveModalOpen(false);
  };

  // Tag 입력 취소
  const handleCancleData = () => {
    onChangeData('');
  };

  // Tag 선택 이벤트
  const handleAddTag = (tags: ITag[]) => {
    if (tags.length > 0) {
      onChangeData(tags[0].tagCode);
    }

    handleTagSearchModalClose();
  };

  // 알람 선택 이벤트
  const handleAddAlarm = (alarm: IAlarm) => {
    onChangeAlarm(alarm.id);
    handleAlarmSearchModalClose();
  };

  // 알람 삭제 이벤트
  const handleDeleteAlarm = () => {
    onChangeAlarm(undefined);
  };

  // Location 변경 이벤트
  const handleChangeLocation = (location: ILocation) => {
    if (location) {
      onChangeData(JSON.stringify(location));
    }
  };

  const renderLocationInput = useCallback(() => {
    const location = JSON.parse(state.data ?? '{}') as ILocation;

    if (location) {
      return <LocationInput onChange={handleChangeLocation} defaultValue={location} />;
    }

    return <LocationInput onChange={handleChangeLocation} />;
  }, [state.data]);

  const renderTagMultiInput = useCallback(() => {
    let defaultTags: string[] = [];

    if (state.data) {
      defaultTags = state.data?.split(',') ?? [];
    }

    return (
      <Box component="div">
        <Typography variant="caption">{field.name}</Typography>
        <TagMutlipleSearchField defaultTagCodes={defaultTags} onChange={(tags) => onChangeData(tags.join(','))} />
      </Box>
    );
  }, [state.data]);

  const renderField = useMemo(() => {
    switch (field.fieldType.code) {
      case 'FIELD_TEXT':
        return (
          <TextField
            variant="standard"
            fullWidth
            required={field.isRequired}
            name={field.name}
            label={field.name}
            onChange={onChangeInput}
            value={state.data}
          />
        );
      case 'FIELD_NUMBER':
        return (
          <TextField
            variant="standard"
            fullWidth
            required={field.isRequired}
            name={field.name}
            label={field.name}
            onChange={onChangeInput}
            type="number"
            value={state.data}
          />
        );
      case 'FIELD_DATE':
        return (
          <TextField
            variant="standard"
            fullWidth
            required={field.isRequired}
            name={field.name}
            label={field.name}
            onChange={onChangeInput}
            type="date"
            value={state.data}
            InputLabelProps={{ shrink: true }}
          />
        );
      case 'FIELD_TAG':
        return (
          <Box component="div" display="flex" alignContent="center" alignItems="center">
            <TextField
              sx={{ flex: 1 }}
              required={field.isRequired}
              name={field.name}
              label={field.name}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
              onClick={() => {
                if (state.data) {
                  handleTagSaveModalOpen();
                }
              }}
              variant="standard"
              value={state.data}
            />
            <IconButton
              size="small"
              disabled={!state.data || Boolean(state.alarm)}
              onClick={handleAlarmSearchModalOpen}
            >
              <AddAlarm />
            </IconButton>
            {state.data ? (
              <IconButton size="small" onClick={handleCancleData}>
                <Cancel />
              </IconButton>
            ) : (
              <IconButton size="small" onClick={handleTagSearchModalOpen}>
                <Search />
              </IconButton>
            )}
          </Box>
        );
      case 'FIELD_3D':
        return (
          <ThreeModelSelectInput
            name={field.name}
            label={field.name}
            required={field.isRequired}
            defaultValue={state.data}
            onChange={onChangeModel}
          />
        );
      case 'FIELD_GPS':
        return renderLocationInput();
      case 'FIELD_TAG_LIST':
        return renderTagMultiInput();
      default:
        return <TextField variant="standard" fullWidth />;
    }
  }, [state, field, threeModelOpen, renderLocationInput, renderTagMultiInput]);

  return (
    <>
      <Box component="div" sx={{ mt: 1 }} />
      {renderField}
      {selectedAlarm && (
        <Chip
          sx={{ mt: 1 }}
          icon={<Alarm />}
          label={selectedAlarm.alarmName}
          size="small"
          onDelete={handleDeleteAlarm}
        />
      )}
      {field.fieldType.code === 'FIELD_TAG' && (
        <>
          <TagAlarmSelectModal
            tag={data?.data}
            open={alarmSearchModalOpen}
            onClose={handleAlarmSearchModalClose}
            onSelect={handleAddAlarm}
          />
          <TagSearchModal
            singleTag
            open={tagSearchModalOpen}
            onClose={handleTagSearchModalClose}
            onConfirm={handleAddTag}
          />
          {state.data && (
            <TagCodeSaveModal open={tagSaveModalOpen} tagCode={state.data} onClose={handleTagSaveModalClose} />
          )}
        </>
      )}
    </>
  );
}
