import { ENDPOINT } from '@const';
import {
  TEmissionItemCreateRequest,
  TEmissionItemHistListResponse,
  TEmissionItemListResponse,
  TEmissionItemResponse,
} from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const createEmissionItemAPI = async (request: TEmissionItemCreateRequest): Promise<TEmissionItemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.emission.createEmissionItem,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('배출아이템 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyEmissionItemAPI = async (request: TEmissionItemCreateRequest): Promise<TEmissionItemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.emission.modifyEmissionItem,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('배출아이템 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteEmissionItemAPI = async (code: string): Promise<TEmissionItemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.emission.deleteEmissionItem,
      ...createAuth(),
      url: ENDPOINT.emission.deleteEmissionItem.url.replace('{code}', code),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('배출아이템 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const findAllEmissionItemAPI = async (): Promise<TEmissionItemListResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.emission.findAllEmissionItem,
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('배출아이템 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const findALlEmissionItemHistAPI = async (itemId: number): Promise<TEmissionItemHistListResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.emission.findAllEmissionItemHist,
      ...createAuth(),
      url: ENDPOINT.emission.findAllEmissionItemHist.url.replace('{id}', itemId.toString()),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('배출아이템 이력 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};
