import React from 'react';
import { Box, Typography } from '@mui/material';
import { ILoginRequest } from '@types';
import LoginBox from 'components/login/LoginBox';
import styled from 'styled-components';

type Props = {
  logoImage?: string;
  subLogoImage?: string;
  backgroundImage?: string;
  onLogin?(data: ILoginRequest): Promise<boolean>;
  copyright?: string;
};

LoginLayout.defaultProps = {
  logoImage: undefined,
  subLogoImage: undefined,
  backgroundImage: undefined,
  onLogin: undefined,
  copyright: 'Copyright ⓒ 에스이피협동조합 All rights reserved',
};

function LoginLayout({ copyright, logoImage, subLogoImage, backgroundImage, onLogin }: Props) {
  return (
    <PageBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ backgroundColor: '#111' }}
    >
      <LoginBox onLogin={onLogin} logoImage={logoImage} subLogoImage={subLogoImage} />
      <Box component="div" sx={{ zIndex: 2, position: 'absolute', bottom: 10 }}>
        <Typography color="#fff" sx={{ opacity: 0.5 }}>
          {copyright}
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          zIndex: 1,
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundColor: 'rgba(0,0,0, 0.3)',
        }}
      />
      <Box
        component="div"
        sx={{
          zIndex: 0,
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundColor: 'rgba(0,0,0, 0.5)',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          filter: 'blur(8px)',
        }}
      />
    </PageBox>
  );
}

const PageBox = styled(Box)`
  width: 100vw;
  height: 100vh;
`;

export default LoginLayout;
