import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { TGroupFieldCreateRequest } from '@types';
import CodeSelectBox from 'components/code/CodeSelectBox';
import BaseCheckbox from 'components/common/BaseCheckbox';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import SynonymInput from 'components/voice/SynonymInput';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { flatCodeState } from 'states';

interface Props extends IBaseModalProps {
  data?: TGroupFieldCreateRequest | null;
  onSave?(data: TGroupFieldCreateRequest): void;
  onDeleteGroupField?(data: TGroupFieldCreateRequest): void;
}

GroupFieldSaveModal.defaultProps = {
  data: null,
  onSave: undefined,
  onDeleteGroupField: undefined,
};

export default function GroupFieldSaveModal({ open, onClose, onSave, onDeleteGroupField, data }: Props) {
  const flatCodes = useRecoilValue(flatCodeState);
  const [state, setState] = useState<TGroupFieldCreateRequest>({});

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setState((prev) => ({ ...prev, [name]: checked }));
  };

  const onChangeCode = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    const selectedCode = _.find(flatCodes, (code) => code.code === value);

    if (selectedCode) {
      setState((prev) => ({ ...prev, [name]: selectedCode.code }));
    }
  };

  const validate = (data: TGroupFieldCreateRequest): boolean => {
    if (!data.code || !data.code) {
      toast('필수값을 입력해주세요.', { type: 'error' });
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (validate(state) && typeof onSave === 'function') {
      onSave(state);
    }
  };

  const handleDelete = () => {
    if (typeof onDeleteGroupField === 'function') {
      onDeleteGroupField(state);
    }
  };

  const handleSynonymChange = (synonym: string[]) => {
    setState((prev) => ({ ...prev, synonym }));
  };

  useEffect(() => {
    if (data) {
      setState({ ...data });
    } else {
      setState({ orderNum: 1 });
    }
  }, [data]);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      onConfirm={handleSave}
      title="그룹필드 저장"
      onDelete={data?.code ? handleDelete : undefined}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ width: 500, height: 300 }}
      >
        <CodeSelectBox
          required
          name="fieldType"
          label="필드유형"
          parentId="FIELD_TYPE"
          data={flatCodes}
          onChange={onChangeCode}
          defaultValue={data?.fieldType}
          value={state?.fieldType}
        />
        <CodeSelectBox
          required
          name="code"
          label="필드코드"
          parentId="FIELD_CODE"
          data={flatCodes}
          onChange={onChangeCode}
          defaultValue={data?.code}
          value={state?.code}
        />
        <SynonymInput defaultValue={state.synonym} onChange={handleSynonymChange} />
        <TextField
          name="orderNum"
          label="순서"
          variant="standard"
          type="number"
          onChange={onChange}
          defaultValue={data?.orderNum}
          value={state?.orderNum}
        />
        <BaseCheckbox
          name="isRequired"
          label="필수값"
          defaultChecked={data?.isRequired ?? false}
          checked={state?.isRequired}
          onChange={onChangeCheck}
        />
      </Box>
    </BaseModal>
  );
}
