import { Box, Card, SxProps, Theme, Typography } from '@mui/material';
import useLastTagData from 'common/hooks/useLastTagData';
import { DateSearchProps } from 'components/common/DateSearchBox';
import React, { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import GuageChart, { TGaugeLevel } from './GaugeChart';

type CaptionType = 'dtt' | 'name' | string;

type Props = {
  dateProps: DateSearchProps;
  isIncrease?: boolean;
  tags: string[];
  cardSx?: SxProps<Theme>;
  levels?: TGaugeLevel[];
  captionContent?: CaptionType[];
};

TagDataGaugeChartCard.defaultProps = {
  isIncrease: false,
  cardSx: {},
  levels: [],
  captionContent: [],
};

export default function TagDataGaugeChartCard({ cardSx, dateProps, tags, isIncrease, levels, captionContent }: Props) {
  const datasets = useLastTagData({
    dateProps,
    tagTypes: tags?.map((tag) => ({ tag, aggFunc: 'mean' })) ?? [],
    isIncrease,
  });
  const data = useMemo(() => datasets?.map((dt) => dt.data.at(0)?.value), [datasets]);
  const unit = useMemo(() => datasets?.map((dt) => dt.tag.tagUnit.codeName).at(0), [datasets]);
  const name = useMemo(() => datasets?.map((dt) => dt.tag.tagName).at(0), [datasets]);
  const dtt = useMemo(() => datasets?.map((dt) => dt.data.at(0)?.dtt).at(0), [datasets]);

  const ErrorComponent = useMemo(() => <div>대시보드 아이템을 구성하는데 오류가 발생하였습니다.</div>, []);

  const caption = useMemo(() => {
    const contents: CaptionType[] = [];

    captionContent?.forEach((cc) => {
      switch (cc) {
        case 'dtt':
          contents.push(`${dtt}`);
          break;
        case 'name':
          contents.push(`${name}`);
          break;
        default:
          contents.push(`${cc}`);
          break;
      }
    });

    if (contents.length > 0) {
      return (
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: -1, marginBottom: -1 }}
        >
          <Typography variant="caption" fontSize={10} textAlign="center">{`${contents.join(' ')}`}</Typography>
        </Box>
      );
    }

    return null;
  }, [captionContent, name, dtt]);

  return (
    <Card sx={{ ...cardSx }}>
      <Box component="div" display="flex" sx={{ width: '100%', height: caption === null ? '100%' : '90%' }}>
        <ErrorBoundary fallbackRender={() => ErrorComponent}>
          <GuageChart levels={levels || []} data={data?.at(0)} unit={unit} valueFormat=",.2f" />
        </ErrorBoundary>
      </Box>
      {caption}
    </Card>
  );
}
