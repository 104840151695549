import { Mic } from '@mui/icons-material';
import { Fab } from '@mui/material';
import React, { useState } from 'react';
import VoiceChat from './VoiceChat';

export default function VoiceFabButton() {
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <Fab color="primary" sx={{ position: 'fixed', right: 15, bottom: 15 }} onClick={handleModalOpen}>
        <Mic />
      </Fab>
      <VoiceChat open={modalOpen} onClose={handleModalClose} />
    </>
  );
}
