import { Delete } from '@mui/icons-material';
import { Box, Button, Card, IconButton, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import BaseCheckbox from './BaseCheckbox';

export type ListItemCardProps = {
  id: string;
  title?: string;
  checked?: boolean;
  onDelete?(id: string): void;
  onSelect?(id: string): void;
  onCheck?(id: string, checked: boolean): void;
};

ListItemCard.defaultProps = {
  title: undefined,
  checked: false,
  onDelete: undefined,
  onSelect: undefined,
  onCheck: undefined,
};

export default function ListItemCard({ id, title, checked, onCheck, onDelete, onSelect }: ListItemCardProps) {
  const onClickTitle = () => {
    if (typeof onSelect === 'function') {
      onSelect(id);
    }
  };

  const onClickDelete = () => {
    if (typeof onDelete === 'function') {
      onDelete(id);
    }
  };

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (typeof onCheck === 'function') {
      onCheck(id, checked);
    }
  };

  return (
    <Card sx={{ p: 0.5, pl: 1, pr: 1, m: 1, display: 'flex', alignItems: 'center' }}>
      {onCheck && (
        <Box component="div">
          <BaseCheckbox label="" name="checked" checked={checked} onChange={onCheckboxChange} />
        </Box>
      )}
      <Box component="div" flex={1}>
        {onSelect ? (
          <Button onClick={onClickTitle}>
            <Typography>{title}</Typography>
          </Button>
        ) : (
          <Typography variant="body1">{title}</Typography>
        )}
      </Box>
      {onDelete && (
        <Box component="div">
          <IconButton color="error" onClick={onClickDelete}>
            <Delete />
          </IconButton>
        </Box>
      )}
    </Card>
  );
}
