import React, { ChangeEvent, useState, useDeferredValue, useEffect } from 'react';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { GpsFixed, LocationOn } from '@mui/icons-material';
import { ILocation } from '@types';
import useBaseModalControl from 'common/hooks/useBaseModalControl';
import LocationSelectModal from './LocationSelectModal';

type Props = {
  onChange?(location: ILocation): void;
  defaultValue?: ILocation;
};

LocationInput.defaultProps = {
  onChange: undefined,
  defaultValue: undefined,
};

export default function LocationInput({ onChange, defaultValue }: Props) {
  const [state, setState] = useState<ILocation>(defaultValue ?? {});

  const { onOpen, ...modalControl } = useBaseModalControl();

  // 현재 위치 조회
  const getCurrentLocation = () => {
    window.navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      setState(() => {
        const newLocation: ILocation = {
          lat: latitude,
          lng: longitude,
        };

        handleLocationChanged(newLocation);

        return newLocation;
      });
    });
  };

  // 위-경도 변경 이벤트
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const parsedValue = parseFloat(value ?? 0);

    setState((prev) => {
      const newLocation = {
        ...prev,
        [name]: parsedValue,
      };

      handleLocationChanged(newLocation);

      return newLocation;
    });
  };

  // Location 변경 적용 이벤트
  const handleChangeLocation = (location: ILocation) => {
    setState(() => {
      handleLocationChanged(location);
      return location;
    });

    modalControl.onClose();
  };

  // Location 변경 후, 이벤트 전파
  const handleLocationChanged = (location: ILocation) => {
    if (typeof onChange === 'function') {
      onChange(location);
    }
  };

  return (
    <Box component="div" display="flex" alignItems="center">
      <TextField
        name="lat"
        label="위도(lat)"
        variant="standard"
        type="number"
        fullWidth
        InputLabelProps={{ shrink: true }}
        sx={{ marginRight: 1 }}
        defaultValue={defaultValue?.lat ?? ''}
        value={state.lat}
        onChange={handleChangeInput}
      />
      <TextField
        name="lng"
        label="경도(lng)"
        variant="standard"
        type="number"
        fullWidth
        InputLabelProps={{ shrink: true }}
        defaultValue={defaultValue?.lng ?? ''}
        value={state.lng}
        onChange={handleChangeInput}
      />
      <Tooltip title="현재위치">
        <IconButton onClick={getCurrentLocation}>
          <GpsFixed />
        </IconButton>
      </Tooltip>

      <Tooltip title="지도에서 찾기">
        <IconButton onClick={onOpen}>
          <LocationOn />
        </IconButton>
      </Tooltip>

      <LocationSelectModal {...modalControl} onConfirm={handleChangeLocation} />
    </Box>
  );
}
