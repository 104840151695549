import { findAllVRTourAPI } from 'apis/vrtour';
import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

type Props = BaseSelectBoxProps;

export default function VRTourSelectBox(props: Props) {
  const { data } = useQuery('vrtours', findAllVRTourAPI);

  const items: SelectItemProps[] = useMemo(
    (): SelectItemProps[] => (data?.data ? data.data.map((dt) => ({ id: dt.id.toString(), name: dt.title })) : []),
    [data],
  );

  return <BaseSelectBox items={items} {...props} />;
}
