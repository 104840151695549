/* eslint-disable no-param-reassign */
import { MpSdk } from '@matterport/webcomponent';
import { Box, Button, Typography } from '@mui/material';
import { IMatterport, IMatterportItem, INodeTagData, IThreeModel, TMatterportItemCreateRequest } from '@types';
import { findAllMatterportItemsAPI, saveMatterportItemAPI } from 'apis/matterport';
import useBaseModalControl from 'common/hooks/useBaseModalControl';
import { createTagPosition, getFileURL, filterGroupFieldData } from 'common/lib';
import _ from 'lodash';
import React, { useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { findData1H } from 'apis/data';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { flatGroupState, threeModelState } from 'states';
import { Euler } from 'three';
import MatterportItemSaveModal from './MatterportItemSaveModal';
import MatterportTagNavigation from '../matterport/MatterportTagNavigation';

type Props = {
  data: IMatterport;
  hideBack?: boolean;
  readonly?: boolean;
};

type MatterportTagAttach = {
  data: IMatterportItem;
  tagId: string;
  sandboxId: string;
  msg: MpSdk.Tag.IMessenger;
};

type TRANSFORM_TYPE = undefined | 'TRANSLATE' | 'SCALE' | 'ROTATE';

MatterportItemBox.defaultProps = {
  readonly: false,
  hideBack: false,
};

export default function MatterportItemBox({ data, hideBack, readonly }: Props) {
  const groups = useRecoilValue(flatGroupState);
  const threeModels = useRecoilValue(threeModelState);
  const navigate = useNavigate();
  // SDK
  const [sdk, setSdk] = useState<MpSdk | undefined>(undefined);
  // 현재 선택된 좌표를 알기 위한 Pointer
  const [pointer, setPointer] = useState<MpSdk.Pointer.Intersection | undefined>(undefined);
  // Pointer 상태가 너무 자주변경될걸 우려해, DefferedValue로 Wrapping
  const defferedPointer = useDeferredValue(pointer);
  // SDK를통해 Attach 한 Matterport Tag 리스트
  const [matterportTags, setMatterportTags] = useState<MatterportTagAttach[]>([]);
  // 저장된 Matterport Item Data 조회
  const { data: items, refetch } = useQuery(['findAllMatterportItems', data.idx], () =>
    findAllMatterportItemsAPI(data.idx),
  );
  // 3D 모델 제어를 위한 Node 관리 State
  const [sceneNodes, setSceneNodes] = useState<{
    lights?: MpSdk.Scene.INode;
    models?: MpSdk.Scene.INode[];
    transform?: MpSdk.Scene.INode;
    translateComp?: MpSdk.Scene.IComponent;
    rotateComp?: MpSdk.Scene.IComponent;
    scaleComp?: MpSdk.Scene.IComponent;
  }>({});
  // 3D 모델 변형을 위한 State
  const [transformState, setTransformState] = useState<{
    type: TRANSFORM_TYPE;
    selectedNode: MpSdk.Scene.INode | undefined;
    selectedItem: IMatterportItem | undefined;
  }>({
    type: undefined,
    selectedNode: undefined,
    selectedItem: undefined,
  });

  // 메타포트 아이템 저장 Modal 제어 hook
  const { onOpen, onClose, open } = useBaseModalControl();
  // Modal용 Item
  const [modalItem, setModalItem] = useState<TMatterportItemCreateRequest | undefined>(undefined);

  const getSceneObject = async () => {
    if (!sdk) return undefined;

    const [sceneObject] = await sdk.Scene.createObjects(1);
    return sceneObject;
  };

  // SDK 초기화
  const init = useCallback(() => {
    if (!sdk) {
      const viewer = document.querySelector('matterport-viewer');
      // SDK 가져오기
      const getSdk = async (evt: any) => {
        const eventSdk = evt.detail.mpSdk as MpSdk;

        await eventSdk.Scene.configure((renderer, three) => {
          renderer.physicallyCorrectLights = true;
          renderer.outputEncoding = three.sRGBEncoding;
          renderer.shadowMap.enabled = true;
          renderer.shadowMap.type = three.PCFSoftShadowMap;
        });

        const defaultTags = await eventSdk.Mattertag.getData();

        // 기존 태그들 삭제
        if (defaultTags && data.defaultTagHide) {
          await Promise.all(defaultTags.map((tag) => eventSdk.Mattertag.remove(tag.sid)));
        }

        setSdk(eventSdk);

        viewer?.removeEventListener('mpSdkConnected', getSdk);
      };

      viewer?.addEventListener('mpSdkConnected', getSdk);
    }
  }, [data, sdk]);

  // 태그 생성 이벤트 => 생성하고자하는 위치의 x,y,z 값을 가지고 저장 Modal을 띄움
  const createTag = useCallback(async () => {
    if (sdk && defferedPointer) {
      const defaultData = createTagPosition(defferedPointer);

      if (!defaultData) return;

      setModalItem({ ...defaultData, matterport: data.idx });

      onOpen();
    }
  }, [sdk, defferedPointer]);

  const getThreeModel = (item: IMatterportItem): IThreeModel | undefined => {
    const threeId = item.data?.threeModel;

    if (!threeId) return undefined;

    return _.find(threeModels, (model) => model.id === threeId);
  };

  // INodeTag 데이터를 통해 차트 데이터 생성
  const getTagsChartData = async (tags: INodeTagData[] | undefined) => {
    if (tags === undefined) return [];

    const datas = await Promise.all(tags.map((data) => findData1H({ tag: data.tag })) ?? []);

    const seriesData = datas.flatMap((data) =>
      data.data?.datasets.flatMap((dt) => {
        const series = dt.data.map((_dt) => ({ y: _dt.value, x: moment(_dt.dtt).valueOf() }));

        return {
          series,
          tag: dt.tag,
        };
      }),
    );

    return seriesData;
  };

  const getGroupTagChartData = async (groupCode?: string | undefined) => {
    const group = _.find(groups, (gr) => gr.groupCode === groupCode);

    if (!group) return [];

    const tags = filterGroupFieldData(group, 'FIELD_TAG');

    const datas = await Promise.all(
      tags.filter((data) => data.data).map((data) => findData1H({ tag: data?.data ?? '' })) ?? [],
    );

    const seriesData = datas.flatMap((data) =>
      data.data?.datasets.flatMap((dt) => {
        const series = dt.data.map((_dt) => ({ y: _dt.value, x: moment(_dt.dtt).valueOf() }));

        return {
          series,
          tag: dt.tag,
        };
      }),
    );

    return seriesData;
  };

  // ThreeJS Node 선택 이벤트
  const handleSelectThreeNode = (selectedNode: MpSdk.Scene.INode, selectedItem: IMatterportItem, tagId: string) => {
    setTransformState((prev) => {
      if (prev.type === undefined) {
        sdk?.Tag.dock(tagId);

        return {
          ...prev,
          selectedNode: undefined,
          selectedItem: undefined,
        };
      }

      const objNode = selectedNode as any;

      if (prev.selectedNode && objNode?.obj3D) {
        const { position, rotation, scale } = objNode.obj3D;

        saveMutation.mutate({
          ...prev.selectedItem,
          anchorPosition: position,
          data: {
            ...prev.selectedItem?.data,
            threeData: {
              rotate: {
                x: rotation.x,
                y: rotation.y,
                z: rotation.z,
              },
              scale: {
                x: scale.x,
                y: scale.y,
                z: scale.z,
              },
            },
          },
        });
      }

      return {
        ...prev,
        selectedNode: prev.selectedNode ? undefined : selectedNode,
        selectedItem: prev.selectedItem ? undefined : selectedItem,
      };
    });
  };

  // Three Node 변형 이벤트
  const handleTransform = useCallback(() => {
    if (
      sceneNodes.transform &&
      sceneNodes.translateComp &&
      sceneNodes.rotateComp &&
      sceneNodes.scaleComp &&
      transformState.type
    ) {
      const { translateComp, rotateComp, scaleComp } = sceneNodes;

      let targetComp: MpSdk.Scene.IComponent | undefined;

      switch (transformState.type) {
        case 'TRANSLATE':
          targetComp = translateComp;
          break;
        case 'ROTATE':
          targetComp = rotateComp;
          break;
        case 'SCALE':
          targetComp = scaleComp;
          break;
        default:
          break;
      }

      if (targetComp && targetComp.inputs) {
        if (transformState.selectedNode) {
          targetComp.inputs.selection = transformState.selectedNode;
          targetComp.inputs.visible = true;
          sdk?.Pointer.setVisible(false);
        } else {
          targetComp.inputs.selection = null;
          targetComp.inputs.visible = false;
          sdk?.Pointer.setVisible(true);
        }
      }
    }
  }, [sceneNodes, transformState]);

  // 메타포트 아이템 -> SDK 등록
  const attachTags = async (datas: IMatterportItem[]) => {
    if (!sdk) return;

    // 기존 등록된 태그들 제거
    await detechAllTag();

    // modelNode 생성
    const sceneObject = await getSceneObject();

    const models: MpSdk.Scene.INode[] = [];

    let lights: MpSdk.Scene.INode | undefined;
    let transform: MpSdk.Scene.INode | undefined;
    let translateComp: MpSdk.Scene.IComponent | undefined;
    let rotateComp: MpSdk.Scene.IComponent | undefined;
    let scaleComp: MpSdk.Scene.IComponent | undefined;

    // 빛생성
    if (sceneObject) {
      lights = sceneObject.addNode();
      lights.addComponent('mp.lights');
      lights.start();
    }

    // 3D모델 위치/회전/scale 변경 노드
    if (sceneObject) {
      transform = sceneObject.addNode();

      translateComp = transform.addComponent('mp.transformControls', {
        selection: null,
        mode: 'translate',
        visible: false,
      });

      rotateComp = transform.addComponent('mp.transformControls', {
        selection: null,
        mode: 'rotate',
        visible: false,
      });

      scaleComp = transform.addComponent('mp.transformControls', {
        selection: null,
        mode: 'scale',
        visible: false,
      });

      transform.start();
    }

    const tags = (
      await Promise.all(
        datas.map(async (data) => {
          const tagId = (await sdk.Tag.add(data)).at(0);

          return {
            data,
            tagId,
          };
        }),
      )
    ).filter((data) => data.tagId !== undefined);

    // Tag -> Sandbox를 추가하여 Attach
    const attachResult = await Promise.all(
      tags.map(async (tag) => {
        const { data, tagId } = tag;

        // eslint-disable-next-line import/no-webpack-loader-syntax, global-require, @typescript-eslint/no-var-requires
        const htmlStr = require('raw-loader!../../resource/html/matterportTag.html').default as string;
        // 랜덤 아이디 생성
        const rand = uuid();
        // Sandbox 생성
        const [sandboxId, msg] = await sdk.Tag.registerSandbox(htmlStr.replace('#MARKER_ID#', rand));
        // Sandbox와 초기화 통신
        msg.on(`init_${rand}`, () => {
          msg.send(`init_${rand}`, data);
        });

        // Sandbox와 데이터 통신
        // Sandbox -> data 요청 -> 플랫폼에서 데이터 조회 -> Sandbox로 데이터 전달
        msg.on(`data_${rand}`, async (_data: IMatterportItem | undefined) => {
          if (_data?.data?.type === 'Tags') {
            const data = await getTagsChartData(_data?.data?.tags);
            msg.send(`data_${rand}`, data);
          } else {
            const data = await getGroupTagChartData(_data?.data?.groupCode);
            msg.send(`data_${rand}`, data);
          }
        });

        // 태그에 Sandbox를 붙여 메타포트 Tag 등록
        await sdk.Tag.attach(tagId as string, sandboxId);

        const threeModel = getThreeModel(data);

        if (threeModel && sceneObject) {
          const modelURL = getFileURL(threeModel.file);
          await sdk.Tag.editOpacity(tagId as string, 0);

          const modelNode = sceneObject.addNode();
          const modelScale = threeModel.scale * 0.06;

          const gltfComponent = modelNode.addComponent('mp.gltfLoader', {
            url: modelURL,
            visible: true,
            colliderEnabled: true,
          });

          modelNode.scale.set(modelScale, modelScale, modelScale);
          modelNode.position.set(data.anchorPosition.x, data.anchorPosition.y, data.anchorPosition.z);

          if (data.data?.threeData?.scale) {
            modelNode.scale.set(data.data.threeData.scale.x, data.data.threeData.scale.y, data.data.threeData.scale.z);
          }

          const objNode = modelNode as any;

          if (objNode.obj3D && data.data?.threeData?.rotate) {
            objNode.obj3D.setRotationFromEuler(
              new Euler(data.data.threeData.rotate.x, data.data.threeData.rotate.y, data.data.threeData.rotate.z),
            );
          }

          gltfComponent.spyOnEvent({
            eventType: 'INTERACTION.CLICK',
            onEvent(_: any) {
              handleSelectThreeNode(modelNode, data, tagId as string);
            },
          });

          modelNode.start();
          models.push(modelNode);
        }

        return {
          data,
          tagId,
          sandboxId,
          msg,
        } as MatterportTagAttach;
      }),
    );

    setSceneNodes((prev) => ({ ...prev, lights, models, transform, translateComp, rotateComp, scaleComp }));
    setMatterportTags(attachResult);
  };

  // 현재 메타포트 Scene에 등록된 모든 태그를 제거
  const detechAllTag = async () => {
    if (!sdk) return;

    await Promise.all(
      matterportTags.map(async (tag) => {
        await sdk.Tag.detach(tag.tagId, tag.sandboxId);
        await sdk.Tag.remove(tag.tagId);
      }),
    );

    sceneNodes.lights?.stop();
    sceneNodes.transform?.stop();

    if (sceneNodes.models) {
      sceneNodes.models.forEach((model) => model.stop());
    }

    setMatterportTags([]);
  };

  // 메타포트 아이템 저장 성공
  const handleSaveSuccess = () => {
    onClose();
    refetch();
  };

  // 우클릭 이벤트
  const handleRightClick = useCallback(() => {
    if (readonly) return;

    if (defferedPointer) {
      createTag();
    }
  }, [defferedPointer, readonly]);

  // 이전 화면 으로 이동
  const moveToPrev = () => {
    navigate(-1);
  };

  // Navigation Location 버튼 클릭 이벤트
  const handleClickLocation = async (data: IMatterportItem) => {
    const selected = getMatterportTagByItem(data);

    if (!selected) return;
    if (!sdk) return;

    await sdk.Tag.dock(selected.tagId);
  };

  // Navigation의 Info 버튼 클릭
  const handleClickinfo = (data: IMatterportItem) => {
    setModalItem(data);
    onOpen();
  };

  // MattrerportItem을 통해 Matterport 조회ㅅ
  const getMatterportTagByItem = (item: IMatterportItem) => {
    return _.find(matterportTags, (tag) => tag.data.idx === item.idx);
  };

  // 메타포트 sdk 초기화
  useEffect(() => {
    init();
  }, [init]);

  // 포인터 인터렉션 SDK 저장
  useEffect(() => {
    if (sdk) {
      sdk.Pointer.intersection.subscribe((data) => {
        setPointer({ ...data });
      });
    }
  }, [sdk]);

  // 메타포트 아이템이 변경되면 메타포트 Tag에 Attach 함
  useEffect(() => {
    if (sdk && items?.data) {
      attachTags(items.data);
    }
  }, [sdk, items]);

  // 컴포넌트 언마운트 시, 새로고침 진행
  // 새로고침하지않으면, 다시 메타포트 조회시 조회되지않음.
  useEffect(() => {
    return () => {
      navigate(0);
    };
  }, [navigate]);

  useEffect(() => {
    handleTransform();
  }, [handleTransform]);

  const saveMutation = useMutation(saveMatterportItemAPI, {
    onSuccess() {
      handleSaveSuccess();
    },
  });

  const renerPrevButton = useMemo(() => {
    return (
      !hideBack && (
        <Box component="div">
          <Button onClick={moveToPrev}>
            <Typography>이전으로</Typography>
          </Button>
        </Box>
      )
    );
  }, [hideBack]);

  const renderTransformButtons = useMemo(
    () =>
      !readonly && (
        <Box component="div" display="flex" flex={1} justifyContent="flex-end">
          <Button
            disabled={transformState.type === undefined}
            onClick={() => setTransformState((prev) => ({ ...prev, type: undefined }))}
          >
            <Typography>선택모드</Typography>
          </Button>
          <Button
            disabled={transformState.type === 'TRANSLATE'}
            onClick={() => setTransformState((prev) => ({ ...prev, type: 'TRANSLATE' }))}
          >
            <Typography>이동모드</Typography>
          </Button>
          <Button
            disabled={transformState.type === 'ROTATE'}
            onClick={() => setTransformState((prev) => ({ ...prev, type: 'ROTATE' }))}
          >
            <Typography>회전모드</Typography>
          </Button>
          <Button
            disabled={transformState.type === 'SCALE'}
            onClick={() => setTransformState((prev) => ({ ...prev, type: 'SCALE' }))}
          >
            <Typography>스케일모드</Typography>
          </Button>
        </Box>
      ),
    [readonly, transformState.type],
  );

  return (
    <Box id="matterport" component="div" sx={{ width: '100%', height: '100%' }}>
      <Box component="div" display="flex">
        {renerPrevButton}
        {renderTransformButtons}
      </Box>

      <Box component="div" sx={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}>
        <MatterportTagNavigation
          datas={items?.data ?? []}
          onClickLocation={handleClickLocation}
          onClickInfo={handleClickinfo}
        />
        <matterport-viewer
          onContextMenu={handleRightClick}
          style={{ width: '100%', height: '100%', position: 'relative' }}
          m={data.spaceId}
          newtags="1"
          application-key={data.sdkKey}
        />
      </Box>
      <MatterportItemSaveModal data={modalItem} open={open} onClose={onClose} onSaveSuccess={handleSaveSuccess} />
    </Box>
  );
}
