import { ITaskHist, TTaskHistCreateRequest, TTaskHistResponse } from '@types';
import { createTaskHistAPI, deleteTaskHistAPI, modifyTaskHistAPI } from 'apis/taskhist';
import TaskHistSaveModal from 'components/taskhist/TaskHistSaveModal';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import useLoading from './useLoading';

type Props = {
  siteCode?: string;
  taskId?: number;
  onReload?(): void;
};

export const useTaskHistModal = ({ onReload, siteCode, taskId }: Props) => {
  const reload = () => {
    if (typeof onReload === 'function') {
      onReload();
    }
  };

  const taskModify = (hist: ITaskHist) => {
    setTaskHist(hist);
    handleTaskHistModalOpen();
  };

  const taskCreate = () => {
    setTaskHist(undefined);
    handleTaskHistModalOpen();
  };

  const handleTaskHistModalOpen = () => {
    setOpen(true);
  };

  const handleTaskHistModalClose = () => {
    setOpen(false);
    setTaskHist(undefined);
  };

  const handleOnHistSave = (request: TTaskHistCreateRequest) => {
    if (request.id && request.id > 0) {
      modifyHistMutation.mutate(request);
    } else {
      createHistMutation.mutate(request);
    }
  };

  const handleOnHistDelete = (id: number) => {
    deleteHistMutation.mutate(id);
  };

  const onHistSaveSucess = (data: TTaskHistResponse) => {
    if (data.success) {
      toast('작업이력 저장 성공', { type: 'success' });
      handleTaskHistModalClose();
      reload();
    }
  };

  const onHistDeleteSucess = (data: TTaskHistResponse) => {
    if (data.success) {
      toast('작업이력 삭제 성공', { type: 'success' });
      handleTaskHistModalClose();
      reload();
    }
  };

  const [taskhist, setTaskHist] = useState<ITaskHist | undefined>();
  const [open, setOpen] = useState<boolean>(false);
  const loading = useLoading({ trigger: false });
  const createHistMutation = useMutation(createTaskHistAPI, { onSuccess: onHistSaveSucess });
  const modifyHistMutation = useMutation(modifyTaskHistAPI, { onSuccess: onHistSaveSucess });
  const deleteHistMutation = useMutation(deleteTaskHistAPI, { onSuccess: onHistDeleteSucess });

  const modal = useMemo(
    () => (
      <TaskHistSaveModal
        open={open}
        site={siteCode}
        taskId={taskId}
        data={taskhist}
        onClose={handleTaskHistModalClose}
        onSaveTaskHist={handleOnHistSave}
        onDeleteTaskHist={handleOnHistDelete}
      />
    ),
    [taskhist, siteCode, taskId, open],
  );

  useEffect(() => {
    loading({ loading: createHistMutation.isLoading, message: '작업이력 저장중...' });
  }, [createHistMutation.isLoading]);

  useEffect(() => {
    loading({ loading: modifyHistMutation.isLoading, message: '작업이력 저장중...' });
  }, [modifyHistMutation.isLoading]);

  useEffect(() => {
    loading({ loading: deleteHistMutation.isLoading, message: '작업이력 삭제중...' });
  }, [deleteHistMutation.isLoading]);

  return {
    taskModify,
    taskCreate,
    modal,
  };
};
