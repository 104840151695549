import { Grid } from '@mui/material';
import { DateSearchProps } from 'components/common/DateSearchBox';
import CostTagData10Card, { CostTagData10CardProps } from 'components/data/CostTagData10Card';
import React, { useMemo, useState } from 'react';
import { AggUnitType } from './useTagChartData10';

type Props = {
  defaultValue?: CostTagData10CardProps[];
};

export const useChart10CostCard = ({ defaultValue }: Props) => {
  const [chartCards, setChartCards] = useState<CostTagData10CardProps[]>(defaultValue ?? []);

  const addCard = (newCard: CostTagData10CardProps[]) => {
    if (newCard.length > 0) {
      setChartCards((prev) => [...prev, ...newCard]);
    }
  };

  const getCardData = () => {
    return chartCards;
  };

  // Chart Card 삭제
  const handleDeleteChartCard = (id: string) => {
    setChartCards((prev) => prev.filter((p) => p.id !== id));
  };
  // Chart Card Label 변경
  const handleChartCardTitleChange = (id: string, changeTitle: string) => {
    setChartCards((prev) => prev.map((pcard) => (pcard.id === id ? { ...pcard, label: changeTitle } : pcard)));
  };
  // Chart Card 날짜 변경
  const handleChartCardDateChange = (id: string, changeDate: DateSearchProps) => {
    setChartCards((prev) => prev.map((pcard) => (pcard.id === id ? { ...pcard, dateProps: changeDate } : pcard)));
  };
  // 차트 데이터 취합 단위 변경
  const handleChartCardAggUnitTypeChange = (id: string, aggUnit: AggUnitType) => {
    setChartCards((prev) => prev.map((pcard) => (pcard.id === id ? { ...pcard, aggUnit } : pcard)));
  };

  const memoedChartCard = useMemo(
    () => (
      <Grid container spacing={1} sx={{ p: 2 }}>
        {chartCards.map((card) => (
          <CostTagData10Card
            {...card}
            key={card.id}
            onDelete={handleDeleteChartCard}
            onTitleChange={handleChartCardTitleChange}
            onDatePropsChange={handleChartCardDateChange}
            onAggUnitTypeChange={handleChartCardAggUnitTypeChange}
          />
        ))}
      </Grid>
    ),
    [chartCards],
  );

  return { cards: memoedChartCard, addCard, getCardData };
};
