import { SelectChangeEvent } from '@mui/material';
import { TMatterportItemType } from '@types';
import BaseSelectBox, { BaseSelectBoxProps } from 'components/common/BaseSelectBox';
import React, { useMemo } from 'react';

type MatterportItemTypeSelectProp = {
  id: TMatterportItemType;
  name: string;
};

type Props = BaseSelectBoxProps & {
  onSelectType?(name: string, value: TMatterportItemType | undefined): void;
};

MatterportItemTypeSelectBox.defaultProps = {
  onSelectType: undefined,
};

export default function MatterportItemTypeSelectBox({ onSelectType, ...others }: Props) {
  const items = useMemo(() => {
    const types: MatterportItemTypeSelectProp[] = [
      {
        id: 'Tags',
        name: '태그데이터',
      },
      {
        id: 'Group',
        name: '그룹데이터',
      },
    ];

    return types;
  }, []);

  const handleChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;

    if (typeof onSelectType === 'function') {
      onSelectType(name ?? '', value as TMatterportItemType | undefined);
    }
  };

  return <BaseSelectBox {...others} items={items} onChange={handleChange} />;
}
