import { ENDPOINT } from '@const';
import { TProductManageCreateRequest, TProductManageListResponse, TProductManageResponse } from '@types';
import axios from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const saveProductManageAPI = async (request: TProductManageCreateRequest): Promise<TProductManageResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.productManage.saveProductManage,
      ...createAuth(),
      data: request,
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('제품 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export type ProductManageSearchProps = {
  productId?: number;
  siteCode?: string;
};

export const findAllProductManageAPI = async ({
  siteCode,
  productId,
}: ProductManageSearchProps): Promise<TProductManageListResponse> => {
  if (!siteCode || !productId) return { data: [], success: true };

  const result = await axios
    .request({
      ...ENDPOINT.productManage.findAllProductManageBySiteAndProductId,
      ...createAuth(),
      url: ENDPOINT.productManage.findAllProductManageBySiteAndProductId.url
        .replace('{siteCode}', siteCode)
        .replace('{productId}', productId.toString()),
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('제품 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};

type DeleteProductManageAPIProps = {
  siteCode: string;
  id: number;
};

export const deleteProductManageAPI = async (request: DeleteProductManageAPIProps): Promise<TProductManageResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.productManage.deleteProductManage,
      ...createAuth(),
      url: ENDPOINT.productManage.deleteProductManage.url
        .replace('{siteCode}', request.siteCode)
        .replace('{id}', request.id.toString()),
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('제품 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};
