import React, { useCallback, useEffect, useMemo } from 'react';
import { findDataAPI, findPrevDayDataAPI, findPrevMonthDataAPI, findPrevYearDataAPI } from 'apis/data';
import { ChartData } from 'chart.js';
import { useQuery } from 'react-query';
import { CHART_COLORS, CHART_COLORS_BG } from '@const';
import { DateSearchProps } from 'components/common/DateSearchBox';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { ChartType, IData1, TData1ChartResponse } from '@types';
import { getDateRange, getPrevDateRange, getPrevNameByDateProp } from 'common/lib';
import { useTheme } from '@mui/material';
import useLoading from './useLoading';

export type Data10AggType = 'mean' | 'min' | 'max' | 'sum' | 'count';
export type AggUnitType = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';

type Props = {
  type?: ChartType;
  tags?: string[] | null | undefined;
  dataType?: Data10AggType;
  dateProps?: DateSearchProps;
  isIncrease?: boolean;
  colors?: string[];
  bgColors?: string[];
  fill?: boolean;
  showLoading?: boolean;
  showAnnotation?: boolean;
  aggUnit?: AggUnitType;
  aggVal?: number;
  xAxis?: string;
  stackId?: string;
  xFormatter?(xValue: string): string;
  yFormatter?(xValue: string, yValue: number): number;
  isPrev?: boolean;
  showDataLabel?: boolean;
};

const useTagChartData10 = ({
  tags,
  type = 'line',
  showLoading = false,
  colors,
  bgColors,
  fill,
  dateProps = undefined,
  dataType = 'mean',
  showAnnotation = false,
  isIncrease = false,
  aggUnit,
  aggVal,
  xAxis = 'x',
  stackId = undefined,
  isPrev = false,
  xFormatter = undefined,
  yFormatter = undefined,
  showDataLabel = false,
}: Props) => {
  const theme = useTheme();

  const themeColors = useMemo(() => {
    if (theme.palette.mode === 'dark') {
      return {
        textColor: '#eee',
        labelBackgroundColor: isPrev ? 'rgb(50, 50, 50)' : 'black',
      };
    }

    return {
      textColor: '#222',
      labelBackgroundColor: isPrev ? 'rgb(200,200,200)' : 'rgb(250,250,250)',
    };
  }, [theme.palette.mode, isPrev]);

  const createNullFetch = (): Promise<TData1ChartResponse> =>
    new Promise((resolve, reject) => {
      resolve({
        success: true,
      });
    });

  const onFetchData = (): Promise<TData1ChartResponse> => {
    if (!dateProps) return createNullFetch();

    if (tags && tags.length > 0) {
      if (dateProps.type === 'prevDay') {
        return findPrevDayDataAPI(tags.at(0) || '', dataType, isIncrease);
      }
      if (dateProps.type === 'prevMonth') {
        return findPrevMonthDataAPI(tags.at(0) || '', dataType, isIncrease);
      }
      if (dateProps.type === 'prevYear') {
        return findPrevYearDataAPI(tags.at(0) || '', dataType, isIncrease);
      }
    }

    if (isPrev) {
      return findDataAPI({
        tagTypes: tags?.map((tag) => ({ tag, aggFunc: dataType })) || [],
        isIncrease,
        ...getPrevDateRange(dateProps),
      });
    }

    return findDataAPI({
      tagTypes: tags?.map((tag) => ({ tag, aggFunc: dataType })) || [],
      isIncrease,
      ...getDateRange(dateProps),
    });
  };

  const { data: dataList, isFetching } = useQuery(
    ['data10', tags, dateProps, dataType, isIncrease, aggUnit, aggVal, isPrev ? 'prev-search' : ''],
    onFetchData,
    {
      enabled: Boolean(tags),
      refetchInterval: 60 * 1000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );

  const chartType = useMemo(() => {
    if (type === 'stackedBar') return 'bar';

    return type;
  }, [type]);

  const createData = useCallback(
    (data: IData1[]) => {
      if (typeof xFormatter === 'function' || typeof yFormatter === 'function') {
        return data.map((dt) => ({
          originX: dt.dtt,
          x: typeof xFormatter === 'function' ? xFormatter(dt.dtt) : dt.dtt,
          y: typeof yFormatter === 'function' ? yFormatter(dt.dtt, dt.value) : dt.value,
          isPrev,
        }));
      }
      return data.map((dt) => ({
        x: dt.dtt,
        y: dt.value,
        isPrev,
      }));
    },
    [yFormatter, xFormatter, isPrev],
  );

  const chartData = useMemo((): ChartData<'line' | 'bar' | 'scatter', any> => {
    const tempData = dataList?.data?.datasets ?? [];

    const lineColor = colors ?? CHART_COLORS;
    const bgColor = bgColors ?? CHART_COLORS_BG;

    // if (isPrev) {
    //   lineColor = lineColor.slice().reverse();
    //   bgColor = bgColor.slice().reverse();
    // }

    if (tempData.length < 1) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const datasets: ChartData<'line' | 'bar' | 'scatter', any> = {
      datasets: tempData.map((data, index) => ({
        id: `${data.tag.tagCode}-${isPrev ? 'prev' : ''}`,
        fill: isPrev ? false : fill,
        type: isPrev ? 'line' : chartType,
        label: `${isPrev ? getPrevNameByDateProp(dateProps, '', '-') : ''}${data.tag.tagName}(${
          data.tag.tagUnit.codeName
        })`,
        data: createData(data.data),
        borderColor: lineColor.at(index),
        backgroundColor: bgColor.at(index),
        pointBackgroundColor: bgColor.at(index),
        pointHoverRadius: 5,
        pointRadius: 2,
        borderRadius: 5,
        maxBarThickness: 100,
        xAxisID: xAxis,
        datalabels: {
          formatter: (value, context) => {
            const fixedValue = Number(value.y.toFixed(2)).toLocaleString();
            return `${fixedValue} ${data.tag.tagUnit.codeName}`;
          },
          backgroundColor: themeColors.labelBackgroundColor,
          borderRadius: 5,
          color: themeColors.textColor,
          display: showDataLabel,
          font: {
            weight: 'bold',
            size: 9,
          },
          padding: 3,
          anchor: 'center',
          align: 'center',
        },
      })),
    };

    return datasets;
  }, [dataList, dataType, chartType, colors, bgColors, isPrev]);

  const chartAnnotation: AnnotationOptions[] = useMemo(() => {
    const tempData = dataList?.data?.datasets ?? [];
    if (!showAnnotation) return [];
    if (tempData.length < 1) {
      return [];
    }

    return tempData
      .map((data) =>
        (data.tag.alarms ?? [])
          .map((alarm) => {
            const annotation: AnnotationOptions[] = [];

            if (alarm.min) {
              annotation.push({
                type: 'line',
                yMin: alarm.min,
                yMax: alarm.min,
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1,
              });
            }

            if (alarm.max) {
              annotation.push({
                type: 'line',
                yMin: alarm.max,
                yMax: alarm.max,
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1,
              });
            }

            return annotation;
          })
          .flat(),
      )
      .flat();
  }, [showAnnotation, dataList]);

  useLoading({ loading: isFetching, message: '데이터 로딩중', trigger: showLoading });

  return { data: chartData, annotation: chartAnnotation, dataList, isFetching };
};

export default useTagChartData10;
