import EmissionItemHistModal from 'components/emissionitem/EmissionItemHistModal';
import React, { useEffect, useMemo, useState } from 'react';

type Props = {
  emissionItemId?: number;
};

const useEmissionItemHistModal = ({ emissionItemId }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>(undefined);

  const handleOpen = (id: number | undefined = undefined) => {
    setSelectedItemId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedItemId(undefined);
    setOpen(false);
  };

  const modal = useMemo(
    () => <EmissionItemHistModal open={open} emissionItemId={selectedItemId} onClose={handleClose} />,
    [selectedItemId, open],
  );

  useEffect(() => {
    setSelectedItemId(emissionItemId);
  }, [emissionItemId]);

  return {
    modal,
    openModal: handleOpen,
  };
};

export default useEmissionItemHistModal;
