import { IFile } from '@types';
import { getFileURL } from 'common/lib';

export const downloadFile = (file: IFile) => {
  const url = getFileURL(file);

  if (url) {
    fetch(url, { method: 'GET' })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = file.filename;

        document.body.appendChild(a);

        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
      });
  }
};
