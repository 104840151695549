import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { IDashboard, TDashboardCreateRequest } from '@types';
import BaseCheckbox from 'components/common/BaseCheckbox';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import RoleMultiSelectBox from 'components/role/RoleMultiSelectBox';
import SiteSelectBox from 'components/site/SiteSelectBox';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatSiteState } from 'states';

type Props = Omit<IBaseModalProps, 'onConfirm' | 'onDelete'> & {
  data?: IDashboard | null;
  onConfirm?(request: TDashboardCreateRequest): void;
  onDelete?(id: number): void;
};

DashboardSaveModal.defaultProps = {
  data: undefined,
  onConfirm: undefined,
  onDelete: undefined,
};

export default function DashboardSaveModal({ data, onConfirm, onDelete, open, ...others }: Props) {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    switch (type) {
      case 'checkbox':
        setState((prev) => ({ ...prev, [name]: checked }));
        break;
      default:
        setState((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const onSelectChange = (e: SelectChangeEvent) => {
    const { value: site } = e.target;
    setState((prev) => ({ ...prev, site }));
  };

  const onAccessRoleChange = (name: string, roleCodes: string[]) => {
    setState((prev) => ({ ...prev, [name]: roleCodes }));
  };

  const handleConfirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm(state);
    }
  };

  const handleDelete = () => {
    if (typeof onDelete === 'function' && state.id) {
      if (window.confirm(`${state.name}를 삭제하시겠습니까?`)) {
        onDelete(state.id);
      }
    }
  };

  const [state, setState] = useState<TDashboardCreateRequest>({});
  const flatSite = useRecoilValue(flatSiteState);

  useEffect(() => {
    if (data) {
      setState({
        ...data,
      });
    }
  }, [data]);

  useEffect(() => {
    if (!open) {
      setState({});
    }
  }, [open]);

  return (
    <BaseModal title="대시보드 저장" {...others} open={open} onConfirm={handleConfirm} onDelete={handleDelete}>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        sx={{ width: 400, height: 300 }}
      >
        <TextField label="이름" variant="standard" fullWidth name="name" value={state.name} onChange={onInputChange} />
        <SiteSelectBox defaultValue={data?.site} data={flatSite} onChange={onSelectChange} value={state.site} />
        <RoleMultiSelectBox
          name="accessRoles"
          defaultValue={data?.accessRoles}
          label="조회 권한 설정"
          containerSx={{ mt: 1, mb: 1 }}
          onSelectChange={onAccessRoleChange}
        />
        <TextField
          label="순서"
          variant="standard"
          fullWidth
          name="orderNum"
          value={state.orderNum}
          type="number"
          onChange={onInputChange}
        />
        <BaseCheckbox
          label="사용여부"
          name="useYN"
          defaultChecked={data?.useYN}
          checked={state.useYN}
          onChange={onInputChange}
        />
      </Box>
    </BaseModal>
  );
}
