import { ENDPOINT } from '@const';
import { TTaskCreateRequest, TTaskPageRequest, TTaskPageResponse, TTaskResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const createTaskAPI = async (request: TTaskCreateRequest): Promise<TTaskResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.task.createTask,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('작업 저장 에러');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyTaskAPI = async (request: TTaskCreateRequest): Promise<TTaskResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.task.modifyTask,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('작업 저장 에러');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteTaskAPI = async (id: number): Promise<TTaskResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.task.deleteTask,
      ...createAuth(),
      url: ENDPOINT.task.deleteTask.url.replace('{id}', id.toString()),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('작업 삭제 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const findPageTaskAPI = async (request: TTaskPageRequest): Promise<TTaskPageResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.task.findPageTask,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('작업 조회 에러');
  }

  toastErrorMessage(result.data);

  return result.data;
};
