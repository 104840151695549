import React, { useEffect, useState } from 'react';
import { SystemLayout } from 'layout/system';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import GroupTree from 'components/group/GroupTree';
import { useRecoilState } from 'recoil';
import { groupState } from 'states';
import GroupForm from 'components/group/GroupForm';
import { IGroup, TGroupCreateRequest, TGroupResponse } from '@types';
import { useMutation, useQuery } from 'react-query';
import { createGroupAPI, deleteGroupAPI, findGroupsAPI, modifyGroupAPI } from 'apis/group';
import useLoading from 'common/hooks/useLoading';
import _ from 'lodash';

export default function GroupPage() {
  // 그룹 선택 이벤트 헨들러
  const handleSelectGroup = (group: IGroup) => {
    setSelectedGroup(group);
  };

  // 추가 버튼 클릭 이벤트 헨들러
  const handleAddClick = (parent: string) => {
    setSelectedGroup({ parent });
  };

  // 저장버튼 클릭 이벤트 헨들러
  const handleSaveClick = (request: TGroupCreateRequest) => {
    if ((request.id ?? 0) > 0) {
      modifyMutation.mutate(request);
    } else {
      createMutation.mutate(request);
    }
  };

  // 삭제 버튼 클릭
  const handleDeleteClick = (groupCode: string) => {
    deleteMutation.mutate(groupCode);
  };

  // 저장성공 이벤트 헨들러
  const onSaveSuccess = (data: TGroupResponse) => {
    if (data.success) {
      toast(`${data.data?.groupCode} 저장 성공`, { type: 'success' });
      refetchGroup();
      if (data.data) {
        handleSelectGroup(data.data);
      }
    }
  };

  // 삭제성공 이벤트 헨들러
  const onDeleteSuccess = (data: TGroupResponse) => {
    if (data.success) {
      toast(`${data.data?.groupCode} 삭제 성공`, { type: 'success' });
      refetchGroup();
    }
  };

  // 그룹 생성 Mutation
  const createMutation = useMutation(createGroupAPI, {
    onSuccess: onSaveSuccess,
  });

  // 그룹 수정 Mutation
  const modifyMutation = useMutation(modifyGroupAPI, {
    onSuccess: onSaveSuccess,
  });

  // 그룹 삭제 Mutation
  const deleteMutation = useMutation(deleteGroupAPI, {
    onSuccess: onDeleteSuccess,
  });

  // groups refetch
  const { data, refetch: refetchGroup, isFetching } = useQuery(['groups'], findGroupsAPI, { enabled: false });

  // 그룹목록 Recoil State
  const [groups, setGroups] = useRecoilState(groupState);

  // 선택된 그룹 정보
  const [selectedGroup, setSelectedGroup] = useState<Partial<IGroup>>();

  const changeLoading = useLoading({ trigger: false });

  useEffect(() => {
    changeLoading({ message: '그룹 저장중...', loading: createMutation.isLoading });
  }, [createMutation.isLoading]);

  useEffect(() => {
    changeLoading({ message: '그룹 저장중...', loading: modifyMutation.isLoading });
  }, [modifyMutation.isLoading]);

  useEffect(() => {
    changeLoading({ message: '그룹 조회중...', loading: isFetching });
  }, [isFetching]);

  useEffect(() => {
    refetchGroup();
  }, []);

  useEffect(() => {
    if (data?.data) {
      setGroups(data.data);
    }
    if (selectedGroup) {
      const newSelectedGroup = _.find(data?.data, (dt) => dt.groupCode === selectedGroup?.groupCode);
      if (newSelectedGroup) {
        setSelectedGroup(newSelectedGroup);
      }
    }
  }, [data]);

  return (
    <SystemLayout title="그룹관리">
      <Box component="div" display="flex" flexDirection="row" flexWrap="wrap">
        <Box component="div" flex={3} p={1}>
          <GroupTree
            containerStyle={{ minHeight: '80vh', minWidth: 200, maxHeight: '80vh' }}
            data={groups}
            onClickItem={handleSelectGroup}
            onAddItem={handleAddClick}
          />
        </Box>
        <Box component="div" flex={2} p={1}>
          <GroupForm
            containerStyle={{ minHeight: '80vh', maxHeight: '80vh', minWidth: 200, overflowY: 'auto' }}
            data={selectedGroup}
            onSave={handleSaveClick}
            onDelete={handleDeleteClick}
          />
        </Box>
      </Box>
    </SystemLayout>
  );
}
