import { ENDPOINT } from '@const';
import { TAlarmCreateRequest, TAlarmPageRequest, TAlarmPageResponse, TAlarmResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const findAlarmPageAPI = async (request: TAlarmPageRequest): Promise<TAlarmPageResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.alarm.findPages, ...createAuth(), data: request })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('알람 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const createAlarmAPI = async (request: TAlarmCreateRequest): Promise<TAlarmResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.alarm.createAlarm, ...createAuth(), data: request })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('알람 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyAlarmAPI = async (request: TAlarmCreateRequest): Promise<TAlarmResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.alarm.modifyAlarm, ...createAuth(), data: request })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('알람 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteAlarmAPI = async (id: number): Promise<TAlarmResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.alarm.deleteAlarm,
      url: ENDPOINT.alarm.deleteAlarm.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('알람 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};
