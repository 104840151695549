import { ENDPOINT } from '@const';
import { TVRTourCreateRequest, TVRTourListResponse, TVRTourResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, createFormData, toastErrorMessage } from 'common/lib';

export const createVRTourAPI = async (request: TVRTourCreateRequest): Promise<TVRTourResponse> => {
  const frm = createFormData(request);

  const result = await axios
    .request({
      ...ENDPOINT.vrtour.createVRTour,
      headers: {
        ...createAuth().headers,
        'Content-Type': 'multipart/form-data',
      },
      data: frm,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('VR투어 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyVRTourAPI = async (request: TVRTourCreateRequest): Promise<TVRTourResponse> => {
  const frm = createFormData(request);

  const result = await axios
    .request({
      ...ENDPOINT.vrtour.modifyVRTour,
      headers: {
        ...createAuth().headers,
        'Content-Type': 'multipart/form-data',
      },
      data: frm,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('VR투어 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteVRTourAPI = async (id: number): Promise<TVRTourResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.vrtour.deleteVRTour,
      url: ENDPOINT.vrtour.deleteVRTour.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('VR 투어 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const findAllVRTourAPI = async (): Promise<TVRTourListResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.vrtour.findAllVRTours,
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('VR투어 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const findVRTourAPI = async (id: number): Promise<TVRTourResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.vrtour.findById,
      url: ENDPOINT.vrtour.findById.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('VR투어 조회 실패');

  return result.data;
};
