import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';
import React, { useMemo } from 'react';

type Props = BaseSelectBoxProps;

export default function TagDataTypeSelectBox({ ...others }: Props) {
  const items = useMemo(
    (): SelectItemProps[] => [
      { id: 'NUMBER', name: '숫자' },
      { id: 'STRING', name: '문자열' },
    ],
    [],
  );
  return <BaseSelectBox label="데이터 유형" items={items} {...others} />;
}
