import React, { useMemo } from 'react';
import { Box, Button, Card, Chip, Divider, TextField, Typography } from '@mui/material';
import { IAlarmHist } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import moment from 'moment';
import useTagRTChartData from 'common/hooks/useTagRTChartData';
import DataLineChart from 'components/data/DataLineChart';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { TaskHistCard } from 'components/task/TaskHistCard';
import { useTaskHistModal } from 'common/hooks/useTaskHistModal';

interface Props extends IBaseModalProps {
  onReload?(): void;
  onDeleteAlarmHist?(id: number): void;
  onCreateAlarmTask?(id: number, name: string, desc: string, site: string): void;
  data?: IAlarmHist | null;
}

AlarmHistSaveModal.defaultProps = {
  onReload: undefined,
  onDeleteAlarmHist: undefined,
  onCreateAlarmTask: undefined,
  data: {},
};

export default function AlarmHistSaveModal({
  open,
  data,
  onClose,
  onCreateAlarmTask,
  onDeleteAlarmHist,
  onReload,
}: Props) {
  const handleDelete = () => {
    if (data && typeof onDeleteAlarmHist === 'function') {
      if (window.confirm('알람 이력을 삭제하시겠습니까?')) {
        onDeleteAlarmHist(data.id);
      }
    }
  };

  const dataProps = useMemo(() => {
    if (!data) return undefined;

    return {
      tag: data?.alarm?.tag?.tagCode,
      date: {
        start: moment(data.dtt).add(-15, 'minute'),
        end: moment(data.dtt).add(15, 'minute'),
      },
    };
  }, [data]);

  const handleAlarkTaskCreateClick = () => {
    if (typeof onCreateAlarmTask === 'function' && data) {
      onCreateAlarmTask(
        data.id,
        `${data?.alarm?.alarmName} 작업`,
        `${data.dtt} 발생한 ${data?.alarm?.alarmName} 알람 작업`,
        data?.alarm?.site.siteCode,
      );
    }
  };

  const { modal, taskCreate, taskModify } = useTaskHistModal({
    onReload,
    siteCode: data?.task?.site?.siteCode,
    taskId: data?.task?.id,
  });

  const { data: chartData, annotation: chartAnnotation } = useTagRTChartData({
    tags: dataProps?.tag,
    date: dataProps?.date,
  });

  const annotation: AnnotationOptions[] = useMemo(
    () => [
      {
        type: 'line',
        xMin: data?.dtt,
        xMax: data?.dtt,
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 3,
      },
      ...chartAnnotation,
    ],
    [chartAnnotation, data],
  );

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="알람 이력"
      confirmTitle=""
      cancleTitle="취소"
      onDelete={data ? handleDelete : undefined}
    >
      <Box component="div" sx={{ width: 500, height: 600 }}>
        <TextField fullWidth name="id" sx={{ display: 'none' }} type="hidden" value={data?.id} />
        <TextField
          fullWidth
          name="alarmName"
          variant="standard"
          label="알람명"
          value={data?.alarm?.alarmName}
          InputProps={{ readOnly: true }}
          sx={{ mt: 1 }}
        />
        <TextField
          fullWidth
          name="min"
          variant="standard"
          label="최소값"
          type="number"
          value={data?.min}
          InputProps={{ readOnly: true }}
          sx={{ mt: 1 }}
        />
        <TextField
          fullWidth
          name="max"
          variant="standard"
          label="최대값"
          type="number"
          value={data?.max}
          InputProps={{ readOnly: true }}
          sx={{ mt: 1 }}
        />
        <TextField
          fullWidth
          name="value"
          variant="standard"
          label="발생값"
          type="number"
          value={data?.value}
          InputProps={{ readOnly: true }}
          sx={{ mt: 1 }}
        />
        <TextField
          fullWidth
          name="dtt"
          variant="standard"
          label="발생일시"
          value={data?.dtt}
          InputProps={{ readOnly: true }}
          sx={{ mt: 1 }}
        />
        <TextField
          fullWidth
          name="tag"
          variant="standard"
          label="태그명"
          value={data?.alarm?.tag?.tagName}
          InputProps={{ readOnly: true }}
          sx={{ mt: 1 }}
        />
        <TextField
          fullWidth
          name="site"
          variant="standard"
          label="사이트"
          value={data?.alarm?.site?.siteName}
          InputProps={{ readOnly: true }}
          sx={{ mt: 1 }}
        />
        {data?.task ? (
          <TaskHistCard
            onClickAdd={taskCreate}
            onClickModify={taskModify}
            containerSx={{ mt: 2 }}
            cardSx={{ maxHeight: 120, overflowY: 'auto' }}
            data={data?.task?.histories}
          />
        ) : (
          <Button fullWidth onClick={handleAlarkTaskCreateClick}>
            <Typography>알람 작업 생성</Typography>
          </Button>
        )}
        <Box component="div" sx={{ mt: 1 }} />
        <Card sx={{ p: 1, height: 120 }}>
          <DataLineChart
            label="알람발생차트"
            labelSx={{ mb: -2 }}
            dataset={chartData}
            annotation={annotation}
            showY
            showX
            height={120}
          />
        </Card>
      </Box>
      {modal}
    </BaseModal>
  );
}
