import { Add } from '@mui/icons-material';
import { Box, Chip, IconButton } from '@mui/material';
import { INodeSystem } from '@types';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import NodeSystemSelectBox from './NodeSystemSelectBox';

type Props = {
  name: string;
  data?: INodeSystem[];
  defalutValues?: number[];
  onChange?(name: string, ids: number[]): void;
  label?: string;
};

NodeSystemMultiSelectBox.defaultProps = {
  data: [],
  defalutValues: [],
  label: undefined,
  onChange: undefined,
};

export default function NodeSystemMultiSelectBox({ name, label, data, defalutValues, onChange }: Props) {
  const [selectedNode, setSelectedNode] = useState<INodeSystem | null>(null);
  const [nodeIds, setNodeIds] = useState<number[]>(defalutValues || []);

  const nodes = useMemo(() => data?.filter((node) => nodeIds.includes(node.id)), [data, nodeIds]);

  const handleAddNode = () => {
    if (selectedNode) {
      setNodeIds((prev) => {
        const newState = _.uniqBy([...prev, selectedNode.id], (p) => p);
        handleChangeEvent(newState);

        return newState;
      });
      setSelectedNode(null);
    }
  };

  const handleDeleteNode = (id: number) => {
    setNodeIds((prev) => {
      const newState = prev.filter((p) => p !== id);
      handleChangeEvent(newState);
      return newState;
    });
  };

  const handleChangeEvent = (nodeIds: number[]) => {
    if (typeof onChange === 'function') {
      onChange(name, nodeIds);
    }
  };

  const renderChips = useMemo(
    () =>
      nodes?.map((node) => (
        <Chip
          key={node.id}
          label={`${node.site} | ${node.name}`}
          sx={{ marginX: 0.5 }}
          onDelete={() => handleDeleteNode(node.id)}
        />
      )),
    [nodes],
  );

  useEffect(() => {
    if (defalutValues) {
      setNodeIds(defalutValues);
    }
  }, [defalutValues]);

  return (
    <Box component="div" display="flex" flexDirection="column">
      <Box component="div" display="flex" flexDirection="row" alignItems="center" sx={{ width: '100%' }}>
        <NodeSystemSelectBox
          label={label}
          data={data}
          onChangeNodeSystem={setSelectedNode}
          value={selectedNode?.id.toString() || ''}
          fullWidth
        />
        <IconButton size="small" disabled={!selectedNode} onClick={handleAddNode}>
          <Add />
        </IconButton>
      </Box>
      <Box component="div" sx={{ mt: 1 }}>
        {renderChips}
      </Box>
    </Box>
  );
}
