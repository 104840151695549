import React from 'react';
import { ICostItemTime, TCostItemTimeCreateRequest, TCostItemTimeResponse } from '@types';
import { deleteCostItemTimeAPI, saveCostItemTimeAPI } from 'apis/cost';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Box, SxProps, Theme } from '@mui/material';
import CostItemTimeForm from './CostItemTimeForm';

type Props = {
  month: number;
  data?: ICostItemTime;
  sx?: SxProps<Theme>;
};

CostItemTimeBox.defaultProps = {
  data: undefined,
  sx: undefined,
};

export default function CostItemTimeBox({ month, data, sx }: Props) {
  const queryClient = useQueryClient();

  const handleSave = (request: TCostItemTimeCreateRequest) => {
    saveMutation.mutate(request);
  };

  const handleDelete = (id: number) => {
    deleteMutation.mutate(id);
  };

  const onSaveSuccess = (result: TCostItemTimeResponse) => {
    if (result.success) {
      toast(`${result.data?.name} 저장 성공`, { type: 'success' });
      queryClient.invalidateQueries('cost');
    }
  };
  const onDeleteSuccess = (result: TCostItemTimeResponse) => {
    if (result.success) {
      toast(`${result.data?.name} 삭제 성공`, { type: 'success' });
      queryClient.invalidateQueries('cost');
    }
  };

  const saveMutation = useMutation(saveCostItemTimeAPI, {
    onSuccess: onSaveSuccess,
  });

  const deleteMutation = useMutation(deleteCostItemTimeAPI, {
    onSuccess: onDeleteSuccess,
  });

  return (
    <Box component="div" sx={sx}>
      <CostItemTimeForm month={month} data={data} onSave={handleSave} onDelete={handleDelete} />
    </Box>
  );
}
