import React, { ChangeEvent, useMemo, useState } from 'react';
import { Button, Card, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { DataLayout } from 'layout/data';
import TagMutlipleSearchField from 'components/tag/TagMultipleSearchField';
import DateSearchBox, { DateSearchProps } from 'components/common/DateSearchBox';
import ProductSelecBox from 'components/product/ProductSelectBox';
import SiteSelectBox from 'components/site/SiteSelectBox';
import { useRecoilValue } from 'recoil';
import { flatSiteState } from 'states';
import BaseCheckbox from 'components/common/BaseCheckbox';
import CostSelectBox from 'components/cost/CostSelectBox';
import { IProduct } from '@types';
import { useChart10ProductCard } from 'common/hooks/useChart10ProductCard';
import uuid from 'react-uuid';
import AggTypeSelectBox from 'components/data/AggTypeSelectBox';
import { Data10AggType } from 'common/hooks/useTagChartData10';

export default function DataProductPage() {
  const flatSites = useRecoilValue(flatSiteState);

  const [state, setState] = useState<{
    site?: string;
    product?: number;
    cost?: string;
    dateProps: DateSearchProps;
    aggType: Data10AggType;
    isIncrease: boolean;
    tags: string[];
  }>({
    site: undefined,
    product: undefined,
    cost: undefined,
    isIncrease: false,
    aggType: 'mean',
    dateProps: {
      type: 'currentYear',
    },
    tags: [],
  });

  const onChangeSelect = (e: SelectChangeEvent) => {
    const { value, name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeProduct = (product: IProduct | undefined) => {
    setState((prev) => ({ ...prev, product: product?.id }));
  };

  const onChangeDateProps = (dateProps: DateSearchProps) => {
    setState((prev) => ({ ...prev, dateProps }));
  };

  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setState((prev) => ({ ...prev, [name]: checked }));
  };

  const onChangeTags = (tags: string[]) => {
    setState((prev) => ({ ...prev, tags }));
  };

  const handleAddCard = () => {
    addCard([
      {
        id: uuid(),
        ...state,
        height: 250,
        chartWidth: '100%',
        chartHeight: '100%',
        xs: 12,
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
      },
    ]);
  };

  const { cards, addCard } = useChart10ProductCard({});

  const renderSearchForm = useMemo(
    () => (
      <Card variant="outlined" sx={{ p: 2 }}>
        <SiteSelectBox name="site" data={flatSites} onChange={onChangeSelect} />
        <ProductSelecBox site={state.site} sx={{ mt: 1 }} onSelectProduct={onChangeProduct} />
        <TagMutlipleSearchField sx={{ mt: 1 }} onChange={onChangeTags} />
        <CostSelectBox
          name="cost"
          label="비용 선택"
          defaultValue=""
          showEmpty
          sx={{ mt: 1 }}
          onChange={onChangeSelect}
          value={state.cost}
        />
        <DateSearchBox {...state.dateProps} menu={['currentYear', 'year']} fullWidth onChange={onChangeDateProps} />
        <AggTypeSelectBox name="aggType" value={state.aggType} onChange={onChangeSelect} />
        <BaseCheckbox name="isIncrease" label="적산값 처리" checked={state.isIncrease} onChange={onChangeCheckbox} />
        <Button fullWidth variant="contained" sx={{ marginTop: 1 }} onClick={handleAddCard}>
          <Typography>검색</Typography>
        </Button>
      </Card>
    ),
    [flatSites, state],
  );

  return (
    <DataLayout title="제품 데이터 조회">
      {renderSearchForm}
      <Grid container>{cards}</Grid>
    </DataLayout>
  );
}
