import { IPageRequest, ISort } from '@types';
import React, { useEffect, useState } from 'react';

type Props<T> = {
  defaultPage?: number;
  defaultSize?: number;
  defaultSearch?: T;
  onChangeCallback?(): void;
};

export default function usePageInfo<T>({
  onChangeCallback,
  defaultPage = 0,
  defaultSize = 10,
  defaultSearch,
}: Props<T>) {
  const changeSearch = (search: T) => {
    setPageInfo((prev) => ({ ...prev, search }));
    runCallback();
  };

  const changeSort = (sort: ISort[]) => {
    setPageInfo((prev) => ({ ...prev, sort }));
    runCallback();
  };

  const changePage = (page: number) => {
    setPageInfo((prev) => ({ ...prev, page }));
    runCallback();
  };

  const changeRowsPerPage = (size: number) => {
    setPageInfo((prev) => ({ ...prev, size }));
    runCallback();
  };

  const runCallback = () => {
    if (typeof onChangeCallback === 'function') {
      onChangeCallback();
    }
  };

  const [pageInfo, setPageInfo] = useState<IPageRequest<T>>({
    page: defaultPage,
    size: defaultSize,
    search: defaultSearch,
  });

  return {
    pageInfo,
    changeSearch,
    changeSort,
    changePage,
    changeRowsPerPage,
  };
}
