import { ENDPOINT } from '@const';
import { TDashboardCreateRequest, TDashboardListResponse, TDashboardResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const createDashboardAPI = async (request: TDashboardCreateRequest): Promise<TDashboardResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.dashboard.createDashboard,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('대시보드 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyDashboardAPI = async (request: TDashboardCreateRequest): Promise<TDashboardResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.dashboard.modifyDashboard,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('대시보드 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteDashboardAPI = async (id: number): Promise<TDashboardResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.dashboard.deleteDashboard,
      url: ENDPOINT.dashboard.deleteDashboard.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('대시보드 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const duplicateDashboardAPI = async (id: number): Promise<TDashboardResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.dashboard.duplicateDashboard,
      url: ENDPOINT.dashboard.duplicateDashboard.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('대시보드 복제 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const findAllDashboardAPI = async (): Promise<TDashboardListResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.dashboard.findAllDashboard,
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('대시보드 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};
