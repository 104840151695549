import { ENDPOINT } from '@const';
import {
  IResponse,
  TDashboardItemCreateRequest,
  TDashboardItemModifyListRequest,
  TDashboardItemResponse,
} from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

// 대시보드 아이템 생성
export const createDashboardItemAPI = async (request: TDashboardItemCreateRequest): Promise<TDashboardItemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.dashboardItem.createDashboardItem,
      ...createAuth(),
      data: { ...request, data: JSON.stringify(request.data) },
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('대시보드 아이템 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 대시보드 아이템 수정
export const modifyDashboardItemAPI = async (request: TDashboardItemCreateRequest): Promise<TDashboardItemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.dashboardItem.modifyDashboardItem,
      ...createAuth(),
      data: { ...request, data: JSON.stringify(request.data) },
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('대시보드 아이템 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyDashboardItemListAPI = async (request: TDashboardItemModifyListRequest): Promise<IResponse<any>> => {
  const result = await axios
    .request({
      ...ENDPOINT.dashboardItem.modifyDashboardItemList,
      ...createAuth(),
      data: { items: request.items.map((item) => ({ ...item, data: JSON.stringify(item.data) })) },
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('대시보드 아이템 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 대시보드 아이템 삭제
export const deleteDashboardItemAPI = async (id: number): Promise<TDashboardItemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.dashboardItem.deleteDashboardItem,
      ...createAuth(),
      url: ENDPOINT.dashboardItem.deleteDashboardItem.url.replace('{id}', id.toString()),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('대시보드 아이템 삭제 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const findDashboardItemAPI = async (id: string): Promise<TDashboardItemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.dashboardItem.findDashboardItem,
      ...createAuth(),
      url: ENDPOINT.dashboardItem.findDashboardItem.url.replace('{id}', id.toString()),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('대시보드 아이템 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};
