import { Add, Edit } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { INodeSystem } from '@types';
import { findAllNodeSystemAPI } from 'apis/nodesystem';
import useNodeSystemModal from 'common/hooks/useNodeSystemModal';
import useReactFlow from 'common/hooks/useReactFlow';
import NodeSystemSelectBox from 'components/nodesystem/NodeSystemSelectBox';
import { SystemLayout } from 'layout/system';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { nodeSystemState } from 'states';

export default function NodePage() {
  const setNodeState = useSetRecoilState(nodeSystemState);
  const [selectedNodeSystem, setSelectedNodeSystem] = useState<INodeSystem | null>();
  const { data, refetch, remove } = useQuery(['allNodeSystems'], findAllNodeSystemAPI);

  const reload = useCallback(() => {
    remove();
    refetch();
  }, [remove, refetch]);

  // 데이터 reload 시, selectedNodeSystem 재지정
  const reloadSelectedNode = useCallback(() => {
    if (selectedNodeSystem && data?.data) {
      const reloadedSelectedNodeSystem = _.find(data.data, (dt) => dt.id === selectedNodeSystem.id);
      if (reloadedSelectedNodeSystem) {
        setSelectedNodeSystem(reloadedSelectedNodeSystem);
      }
    }
  }, [data, selectedNodeSystem]);

  const { modal, openModal } = useNodeSystemModal({
    reload,
    onDeleted: () => setSelectedNodeSystem(undefined),
  });
  const { reactFlow } = useReactFlow({
    nodeSystem: selectedNodeSystem,
    reload,
    isEditMode: true,
    cardSx: { height: '100%' },
    sx: { height: '70vh' },
  });

  useEffect(() => {
    reloadSelectedNode();
  }, [reloadSelectedNode]);

  useEffect(() => {
    if (data && data.data) {
      setNodeState(data.data);
    }
  }, [data]);

  return (
    <SystemLayout title="계통관리">
      <Box component="div" display="flex" alignItems="center">
        <NodeSystemSelectBox data={data?.data} onChangeNodeSystem={setSelectedNodeSystem} />
        <IconButton onClick={() => openModal(null)}>
          <Add />
        </IconButton>
        {selectedNodeSystem && (
          <IconButton onClick={() => openModal(selectedNodeSystem)}>
            <Edit />
          </IconButton>
        )}
      </Box>
      <Box component="div" mt={2}>
        {reactFlow}
      </Box>
      {modal}
    </SystemLayout>
  );
}
