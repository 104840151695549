import { Box } from '@mui/material';
import { IGroup } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { filterGroupFieldData } from 'common/lib';
import GroupFieldDataRenderBox from './GroupFieldDataRenderBox';

type Props = IBaseModalProps & {
  group?: IGroup;
};

GroupInfoModal.defaultProps = {
  group: undefined,
};

export default function GroupInfoModal({ group, ...others }: Props) {
  const renderThreeField = useMemo(() => {
    const threeField = filterGroupFieldData(group, 'FIELD_3D');

    return threeField.map((gfd) => <GroupFieldDataRenderBox key={gfd.field.id} data={gfd} containerSx={{ mt: 1 }} />);
  }, [group]);

  const renderGroupFieldDatas = useMemo(
    () =>
      _.sortBy(group?.groupFieldDatas, (gfd) => gfd.field.orderNum).map((gfd) => (
        <GroupFieldDataRenderBox exceptCodes={['FIELD_3D']} key={gfd.field.id} data={gfd} containerSx={{ mt: 1 }} />
      )),
    [group],
  );

  return (
    <BaseModal {...others} title={group?.groupName} disableConfirm cancleTitle="닫기">
      <Box component="div" sx={{ width: 600, height: 500 }}>
        {renderThreeField}
        {renderGroupFieldDatas}
      </Box>
    </BaseModal>
  );
}
