import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Box, Button, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { flatSiteState } from 'states';
import { IAlarm, ITag, TAlarmCreateRequest } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import BaseCheckbox from 'components/common/BaseCheckbox';
import SiteSelectBox from 'components/site/SiteSelectBox';
import TagSearchModal from 'components/tag/TagSearchModal';
import TagListItemCard from 'components/tag/TagListItemCard';

interface Props extends IBaseModalProps {
  onSave?(data: TAlarmCreateRequest): void;
  onDeleteAlarm?(id: number): void;
  data?: IAlarm | null;
  defaultTag?: ITag;
}

AlarmSaveModal.defaultProps = {
  onSave: undefined,
  onDeleteAlarm: undefined,
  data: {},
  defaultTag: undefined,
};

export default function AlarmSaveModal({ open, data, defaultTag, onSave, onDeleteAlarm, onClose }: Props) {
  const init = () => {
    setState({
      id: undefined,
      alarmName: undefined,
      min: undefined,
      max: undefined,
      site: undefined,
      alertMin: undefined,
      alertMax: undefined,
      tag: defaultTag?.tagCode || undefined,
      useYN: undefined,
    });
    setSelectedTag(defaultTag);
  };

  const onConfirm = () => {
    if (typeof onSave === 'function') {
      onSave(state);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    if (type === 'checkbox') {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleTagModalOpen = () => {
    setTagModalOpen(true);
  };

  const handleTagModalClose = () => {
    setTagModalOpen(false);
  };

  const handleTagAdd = (tag: ITag[]) => {
    if (tag.length > 0) {
      setSelectedTag(tag[0]);
      setState((prev) => ({ ...prev, tag: tag[0].tagCode }));
    }

    handleTagModalClose();
  };

  const handleTagDelete = () => {
    setSelectedTag(undefined);
    setState((prev) => ({ ...prev, tag: undefined }));
  };

  const handleAlarmDelete = () => {
    if (typeof onDeleteAlarm === 'function') {
      if (window.confirm(`${state.alarmName}를 삭제하시겠습니까?`)) {
        onDeleteAlarm(state.id || 0);
      }
    }
  };

  const flatSite = useRecoilValue(flatSiteState);
  const [tagModalOpen, setTagModalOpen] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<ITag | undefined>(undefined);
  const [state, setState] = useState<TAlarmCreateRequest>({
    id: undefined,
    alarmName: undefined,
    min: undefined,
    max: undefined,
    alertMin: undefined,
    alertMax: undefined,
    tag: undefined,
    site: undefined,
    useYN: undefined,
  });

  useEffect(() => {
    if (data) {
      setState({
        ...data,
        site: data.site.siteCode,
        tag: data.tag.tagCode,
      });
      if (data.tag) {
        setSelectedTag(data.tag);
      }
    } else {
      init();
    }
  }, [data]);

  return (
    <BaseModal
      open={open}
      onConfirm={onConfirm}
      onClose={onClose}
      onDelete={(state.id && state.id > 0 && handleAlarmDelete) || undefined}
      title="알람 저장"
      confirmTitle="저장"
      cancleTitle="취소"
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ width: 500, height: 450 }}
      >
        <TextField name="id" sx={{ display: 'none' }} type="hidden" value={state.id} />
        <TextField
          required
          name="alarmName"
          variant="standard"
          label="알람명"
          value={state.alarmName}
          onChange={onChange}
        />
        <TextField
          name="min"
          variant="standard"
          label="알람-최소값"
          type="number"
          value={state.min}
          onChange={onChange}
        />
        <TextField
          name="max"
          variant="standard"
          label="알람-최대값"
          type="number"
          value={state.max}
          onChange={onChange}
        />
        <TextField
          name="alertMin"
          variant="standard"
          label="경보-최소값"
          type="number"
          value={state.alertMin}
          onChange={onChange}
        />
        <TextField
          name="alertMax"
          variant="standard"
          label="경보-최대값"
          type="number"
          value={state.alertMax}
          onChange={onChange}
        />
        <SiteSelectBox
          required
          label="사이트"
          name="site"
          defaultValue={data?.site.siteCode}
          data={flatSite}
          onChange={onSelectChange}
        />
        <Box component="div">
          <Button fullWidth variant="contained" onClick={handleTagModalOpen}>
            <Typography>태그조회</Typography>
          </Button>
          {selectedTag && <TagListItemCard data={selectedTag} onDelete={handleTagDelete} />}
        </Box>
        <Box component="div">
          <BaseCheckbox
            label="사용여부"
            name="useYN"
            checked={state.useYN}
            defaultChecked={data?.useYN}
            onChange={onChange}
          />
        </Box>
      </Box>
      <TagSearchModal singleTag open={tagModalOpen} onClose={handleTagModalClose} onConfirm={handleTagAdd} />
    </BaseModal>
  );
}
