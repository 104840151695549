import { Fullscreen } from '@mui/icons-material';
import { Box, IconButton, SelectChangeEvent } from '@mui/material';
import { IDashboard } from '@types';
import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';
import _ from 'lodash';
import React, { useMemo } from 'react';

type Props = BaseSelectBoxProps & {
  data?: IDashboard[];
  onSelect?(dashboard: IDashboard | null): void;
  useYN?: boolean;
  showFullScreenBtn?: boolean;
};

DashboardSelectBox.defaultProps = {
  data: undefined,
  onSelect: undefined,
  showFullScreenBtn: false,
  useYN: false,
};

export default function DashboardSelectBox({ data, onSelect, showFullScreenBtn, useYN, ...others }: Props) {
  const items = useMemo(
    (): SelectItemProps[] =>
      data
        ? _.sortBy(data, (dt) => dt.orderNum)
            .filter((dt) => (useYN ? dt.useYN : true))
            .map((dt) => ({ id: dt.id.toString(), name: dt.name }))
        : [],
    [useYN, data],
  );

  const handleChange = (e: SelectChangeEvent) => {
    const { value: id } = e.target;

    const selectedItem = _.find(data, (dt) => dt.id.toString() === id);

    if (typeof onSelect === 'function') {
      onSelect(selectedItem ?? null);
    }
  };

  const onClickFullScreen = () => {
    window.open(`/dashboard/fullscreen`, undefined, 'fullscreen=yes');
  };

  return (
    <Box component="div" display="flex" alignItems="center" sx={{ width: '100%' }}>
      <BaseSelectBox label="대시보드 유형" {...others} items={items} onChange={handleChange} />
      {showFullScreenBtn && (
        <IconButton onClick={onClickFullScreen}>
          <Fullscreen />
        </IconButton>
      )}
    </Box>
  );
}
