import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import DashboardSelectBox from 'components/dashboard/DashboardSelectBox';
import { useRecoilValue } from 'recoil';
import { dashboardState } from 'states';
import { IDashboard } from '@types';
import useNewDashboard from 'common/hooks/useNewDashboard';
import VoiceFabButton from 'components/voice/VoiceFabButton';

type Props = {
  isFullscreen?: boolean;
};

MainPage.defaultProps = {
  isFullscreen: false,
};

function MainPage({ isFullscreen }: Props) {
  const dashboards = useRecoilValue(dashboardState);
  const [dashboard, setDashboard] = useState<IDashboard | null>(null);
  const { grid } = useNewDashboard({ dashboard: dashboard || undefined, showControls: false });

  useEffect(() => {
    if (dashboards && dashboard === null) {
      setDashboard(dashboards.at(0) || null);
    }
  }, [dashboards]);

  return (
    <Box component="div" sx={{ p: 3 }}>
      <DashboardSelectBox
        data={dashboards}
        defaultValue=""
        value={dashboard?.id.toString() || '  '}
        onSelect={setDashboard}
        showFullScreenBtn={!isFullscreen}
        useYN
      />
      {grid}
      <VoiceFabButton />
    </Box>
  );
}

export default MainPage;
