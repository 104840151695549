import { CHART_COLORS_BG } from '@const';
import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { ICostItemMonth } from '@types';
import React, { useMemo } from 'react';

type Props = {
  data: ICostItemMonth[];
  containerSx?: SxProps<Theme>;
};

CostItemMonthLineBar.defaultProps = {
  containerSx: undefined,
};

export default function CostItemMonthLineBar({ data, containerSx }: Props) {
  const renderMemo = useMemo(
    () => (
      <Grid container sx={containerSx}>
        {data
          .slice()
          .sort((prev, current) => prev.startMonth - current.startMonth)
          .map((month, index) => (
            <Grid
              key={month.id}
              item
              xs={false}
              sm={false}
              md={month.endMonth - month.startMonth + 1}
              sx={{ height: 20, backgroundColor: CHART_COLORS_BG[index] }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Typography
                fontWeight="bold"
                variant="caption"
              >{`${month.name}(${month.startMonth}~${month.endMonth})`}</Typography>
            </Grid>
          ))}
      </Grid>
    ),
    [data, containerSx],
  );

  return renderMemo;
}
