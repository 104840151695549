import { Box } from '@mui/material';
import { findDashboardItemAPI } from 'apis/dashboarditem';
import NodeSystemToggleCard from 'components/dashboard/NodeSystemToggleCard';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

export default function NodeFullScreenPage() {
  const { itemId } = useParams();
  const { data } = useQuery(['findDashboardItem', itemId], () => findDashboardItemAPI(itemId || ''));

  return (
    <Box component="div" display="flex" sx={{ width: '100%', height: '100%' }}>
      {data?.data && (
        <NodeSystemToggleCard showFullScreenBtn={false} sx={{ width: '100%', height: '100%' }} item={data.data} />
      )}
    </Box>
  );
}
