import { Check, Commit } from '@mui/icons-material';
import { Box, Button, Card, Chip, FormLabel, IconButton, SxProps, TextField, Theme, Typography } from '@mui/material';
import { ITag } from '@types';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import TagSearchModal from './TagSearchModal';

type Props = {
  isSingleTag?: boolean;
  onChange?(tagCodes: string[]): void;
  onChangeTags?(tags: ITag[]): void;
  defaultTagCodes?: string[];
  sx?: SxProps<Theme>;
};

TagMutlipleSearchField.defaultProps = {
  isSingleTag: false,
  onChange: undefined,
  onChangeTags: undefined,
  defaultTagCodes: undefined,
  sx: undefined,
};

export default function TagMutlipleSearchField({ isSingleTag, onChange, onChangeTags, sx, defaultTagCodes }: Props) {
  // 태그 검색창 오픈 이벤트
  const handleOpenTagSearchModal = () => {
    setOpenTagSearchModal(true);
  };
  // 태그 검색창 닫기 이벤트
  const handleCloseTagSearchModal = () => {
    setOpenTagSearchModal(false);
  };

  const [openTagSearchModal, setOpenTagSearchModal] = useState<boolean>(false);
  const [input, setInput] = useState<string | undefined>();
  const [chips, setChips] = useState<string[]>([]);

  const [isInputMode, setIsInputMode] = useState<boolean>(false);

  const handleConfirmInput = () => {
    if (input && typeof onChange === 'function') {
      onChange(input.split(','));

      setInput(undefined);
    }
  };

  const handleConfirm = (tags: ITag[]) => {
    handleCloseTagSearchModal();

    // 기존 태그코드 + 새 태그코드가 되도록 수정
    const tagCodes = tags.map((tag) => tag.tagCode);
    const newChips = _.uniqBy([...tagCodes, ...chips], (tag) => tag);

    setChips(newChips);

    if (typeof onChange === 'function') {
      onChange(newChips);
    }

    if (typeof onChangeTags === 'function') {
      onChangeTags(tags);
    }
  };

  // 태그 Chip 삭제
  const handleDeleteTag = (tagCode: string) => {
    const newChips = chips.filter((ptag) => ptag !== tagCode);
    setChips(newChips);

    if (typeof onChange === 'function') {
      onChange(newChips);
    }
  };

  // Save Tag Chips
  const renderTagChips = useMemo(
    () => chips.map((tag) => <Chip key={tag} sx={{ m: 0.5 }} label={tag} onDelete={() => handleDeleteTag(tag)} />),
    [chips],
  );

  useEffect(() => {
    if (defaultTagCodes) {
      setChips(defaultTagCodes);
    }
  }, [defaultTagCodes]);

  return (
    <Card sx={{ p: 1, ...sx }}>
      <Box component="div" display="flex">
        <FormLabel>태그 목록</FormLabel>
        <Box component="div" display="flex" flex={1} sx={{ justifyContent: 'flex-end' }}>
          <Button size="small" onClick={() => setIsInputMode((prev) => !prev)}>
            <Typography fontSize={14}>{isInputMode ? '검색입력' : '직접입력'}</Typography>
          </Button>
        </Box>
      </Box>
      <Box component="div" sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {renderTagChips}
      </Box>
      {isInputMode ? (
        <Box component="div" display="flex">
          <TextField variant="standard" fullWidth onChange={(e) => setInput(e.target.value)} />
          <IconButton onClick={handleConfirmInput}>
            <Check />
          </IconButton>
        </Box>
      ) : (
        <Button fullWidth variant="contained" color="info" onClick={handleOpenTagSearchModal}>
          <Typography>태그 검색</Typography>
        </Button>
      )}
      <TagSearchModal
        singleTag={isSingleTag}
        open={openTagSearchModal}
        onClose={handleCloseTagSearchModal}
        onConfirm={handleConfirm}
      />
    </Card>
  );
}
