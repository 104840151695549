import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { ITask, IUser, TTaskCreateRequest } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import { TaskHistCard } from 'components/task/TaskHistCard';
import { UserAutoCompleteInput } from 'components/user/UserAutoCompleteInput';
import { useQuery } from 'react-query';
import { findUsersAPI } from 'apis/user';
import SiteSelectBox from 'components/site/SiteSelectBox';
import { useRecoilValue } from 'recoil';
import { flatSiteState } from 'states';
import { useTaskHistModal } from 'common/hooks/useTaskHistModal';

interface Props extends IBaseModalProps {
  onSaveTask?(data: TTaskCreateRequest): void;
  onDeleteTask?(id: number): void;
  onReload?(): void;
  data?: ITask | null;
}

TaskSaveModal.defaultProps = {
  onSaveTask: undefined,
  onDeleteTask: undefined,
  onReload: undefined,
  data: {},
};

export default function TaskSaveModal({ open, data, onClose, onSaveTask, onDeleteTask, onReload }: Props) {
  const handleDelete = () => {
    if (data && typeof onDeleteTask === 'function') {
      if (window.confirm('작업을 삭제하시겠습니까?')) {
        onDeleteTask(data.id);
      }
    }
  };

  const handleSave = () => {
    if (typeof onSaveTask === 'function') {
      onSaveTask(state);
    }
  };

  const handleManagerChange = (changed: IUser) => {
    setState((prev) => ({ ...prev, manager: changed.userId }));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const { modal, taskCreate, taskModify } = useTaskHistModal({
    onReload,
    siteCode: data?.site?.siteCode,
    taskId: data?.id,
  });
  const [state, setState] = useState<TTaskCreateRequest>({});
  const flatSites = useRecoilValue(flatSiteState);

  const { data: users } = useQuery(
    ['task_users', state.site],
    () => findUsersAPI({ page: 0, size: 999, search: { site: state.site, useYN: true } }),
    {
      enabled: Boolean(state.site),
    },
  );

  useEffect(() => {
    setState({ id: undefined, ...data, site: data?.site?.siteCode, manager: data?.manager?.userId });
  }, [data]);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="작업 관리"
      confirmTitle="저장"
      cancleTitle="취소"
      onConfirm={handleSave}
      onDelete={(data?.id ?? 0) > 0 ? handleDelete : undefined}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ width: 500, height: data && data.id ? 600 : 250 }}
      >
        <TextField name="id" sx={{ display: 'none' }} type="hidden" value={state.id} />
        <TextField
          name="taskName"
          variant="standard"
          label="작업명"
          onChange={handleInputChange}
          value={state.taskName}
          required
        />
        <TextField
          name="taskDesc"
          variant="standard"
          label="작업설명"
          onChange={handleInputChange}
          value={state.taskDesc}
        />
        <SiteSelectBox
          name="site"
          data={flatSites}
          defaultValue={data?.site?.siteCode}
          value={state.site}
          onChange={handleSelectChange}
          fullWidth
          required
          useYN
        />
        <UserAutoCompleteInput
          required
          defaultUser={data?.manager}
          label="담당자"
          data={users}
          onChange={handleManagerChange}
        />
        {state.createdAt && (
          <TextField variant="standard" label="생성일시" value={state.createdAt} InputProps={{ readOnly: true }} />
        )}
        {state.updatedAt && (
          <TextField variant="standard" label="수정일시" value={state.updatedAt} InputProps={{ readOnly: true }} />
        )}
        {data?.histories && (
          <TaskHistCard
            containerSx={{ mt: 2 }}
            cardSx={{ minHeight: 200, maxHeight: 200, overflowY: 'auto' }}
            data={data?.histories}
            onClickAdd={taskCreate}
            onClickModify={taskModify}
          />
        )}
      </Box>
      {modal}
    </BaseModal>
  );
}
