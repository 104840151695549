import { Box, Card, Typography, useTheme } from '@mui/material';
import { getFileURL } from 'common/lib';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { matterportState } from 'states';
import _ from 'lodash';

type Props = {
  data?: number;
};

MatterportCard.defaultProps = {
  data: undefined,
};

export default function MatterportCard({ data }: Props) {
  const matterports = useRecoilValue(matterportState);
  const matterport = useMemo(() => _.find(matterports, (_data) => _data.idx === data), [matterports]);

  const navigate = useNavigate();

  const moveTo = () => {
    const url = `/matterport/fullscreen/${matterport?.idx}`;
    window.open(url, undefined, 'fullscreen=yes');
  };

  return matterport ? (
    <Card
      onClick={moveTo}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${getFileURL(matterport.thumbnailImage)})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Box
        component="div"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          height: 50,
          alignItems: 'center',
        }}
      >
        <Typography fontWeight="bold" color="#eee">
          {matterport.name}
        </Typography>
      </Box>
    </Card>
  ) : (
    <div />
  );
}
