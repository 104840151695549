import React, { useEffect, useMemo, useState } from 'react';
import { Add, ContentCopy, Edit } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import {
  IDashboard,
  IDashboardItem,
  TDashboardCreateRequest,
  TDashboardItemResponse,
  TDashboardResponse,
} from '@types';
import {
  createDashboardAPI,
  deleteDashboardAPI,
  duplicateDashboardAPI,
  findAllDashboardAPI,
  modifyDashboardAPI,
} from 'apis/dashboard';
import DashboardSaveModal from 'components/dashboard/DashboardSaveModal';
import DashboardSelectBox from 'components/dashboard/DashboardSelectBox';
import { SystemLayout } from 'layout/system';
import _ from 'lodash';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { dashboardState } from 'states';
import useDashboardItemModal from 'common/hooks/useDashboardItemModal';
import { deleteDashboardItemAPI, modifyDashboardItemListAPI } from 'apis/dashboarditem';
import useNewDashboard from 'common/hooks/useNewDashboard';

export default function DashboardPage() {
  const handleSelectDashboard = (selected: IDashboard | null) => {
    setSelectedId(selected?.id || undefined);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalSelect(undefined);
  };

  // 대시보드 수정 버튼 클릭
  const handleEditClick = () => {
    setModalSelect(selectedDashobard || undefined);
    handleModalOpen();
  };

  // 대시보드 저장
  const handleDashboardSave = (request: TDashboardCreateRequest) => {
    if (request.id && request.id > 0) {
      modifyMutation.mutate(request);
    } else {
      createMutation.mutate(request);
    }
  };

  // 대시보드 삭제
  const handleDashboardDelete = (id: number) => {
    deleteDashboardMutation.mutate(id);
  };

  // 대시보드 복제
  const handleDashboardDupl = (id: number) => {
    if (window.confirm('대시보드를 복제하시겠습니까?')) {
      duplicateMutation.mutate(id);
    }
  };

  const onSaveSuccess = (result: TDashboardResponse) => {
    if (result && result.success) {
      toast('대시보드 저장 성공', { type: 'success' });
      handleModalClose();
      refetch();
    }
  };

  const onDeleteItemSuccess = (result: TDashboardItemResponse) => {
    if (result && result.success) {
      toast('대시보드 아이템 삭제 성공', { type: 'success' });
      refetch();
    }
  };

  const onDeleteDashboardSucess = (result: TDashboardResponse) => {
    if (result && result.success) {
      toast('대시보드 삭제 성공', { type: 'success' });
      handleModalClose();
      refetch();
    }
  };

  const handleDeleteItem = (item: IDashboardItem) => {
    deleteItemMutation.mutate(item.id);
  };

  const handleSaveItems = (items: IDashboardItem[]) => {
    modifyItemListMutation.mutate({ items });
  };

  const [dashboard, setDashboard] = useRecoilState(dashboardState);
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const [modalSelect, setModalSelect] = useState<IDashboard | undefined>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const selectedDashobard = useMemo(() => {
    const finded = _.find(dashboard, (item) => item.id === selectedId);
    return finded;
  }, [dashboard, selectedId]);

  const { data, refetch } = useQuery(['dashboards'], findAllDashboardAPI);

  const {
    modal: dashboardItemModal,
    handleOpen: openItemModal,
    handleEdit: editItemModal,
  } = useDashboardItemModal({
    dashboard: selectedDashobard ?? undefined,
    reload: refetch,
  });
  const { grid } = useNewDashboard({
    dashboard: selectedDashobard ?? undefined,
    showControls: true,
    onEditClick: editItemModal,
    onDeleteClick: handleDeleteItem,
    onSaveClick: handleSaveItems,
  });

  const createMutation = useMutation(createDashboardAPI, {
    onSuccess: onSaveSuccess,
  });
  const modifyMutation = useMutation(modifyDashboardAPI, {
    onSuccess: onSaveSuccess,
  });
  const duplicateMutation = useMutation(duplicateDashboardAPI, {
    onSuccess: onSaveSuccess,
  });
  const modifyItemListMutation = useMutation(modifyDashboardItemListAPI, {
    onSuccess: onSaveSuccess,
  });
  const deleteItemMutation = useMutation(deleteDashboardItemAPI, {
    onSuccess: onDeleteItemSuccess,
  });
  const deleteDashboardMutation = useMutation(deleteDashboardAPI, {
    onSuccess: onDeleteDashboardSucess,
  });

  useEffect(() => {
    setDashboard(data?.data ?? []);
  }, [data]);

  return (
    <SystemLayout title="대시보드 관리 ">
      <Box component="div">
        <Box component="div" display="flex" alignItems="center">
          <DashboardSelectBox fullWidth data={dashboard} onSelect={handleSelectDashboard} />
          <IconButton onClick={handleModalOpen}>
            <Add />
          </IconButton>
          {selectedDashobard && (
            <IconButton onClick={handleEditClick}>
              <Edit />
            </IconButton>
          )}
          {selectedDashobard && (
            <IconButton onClick={() => handleDashboardDupl(selectedDashobard.id)}>
              <ContentCopy />
            </IconButton>
          )}
        </Box>
        <Box component="div" sx={{ mt: 1 }}>
          <Button fullWidth variant="contained" onClick={openItemModal} disabled={!selectedDashobard}>
            아이템 추가
          </Button>
        </Box>
      </Box>
      <DashboardSaveModal
        data={modalSelect}
        open={modalOpen}
        onClose={handleModalClose}
        onConfirm={handleDashboardSave}
        onDelete={handleDashboardDelete}
      />
      {dashboardItemModal}
      {grid}
    </SystemLayout>
  );
}
