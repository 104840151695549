import React, { useCallback, useMemo } from 'react';
import useTagChartData10, { AggUnitType } from 'common/hooks/useTagChartData10';
import { useQuery } from 'react-query';
import { findAllProductManageAPI } from 'apis/productManage';
import useProductChartData10 from 'common/hooks/useProductChartData10';
import { DateSearchProps } from 'components/common/DateSearchBox';
import moment from 'moment';
import _ from 'lodash';
import useTagChartCostData10 from 'common/hooks/useTagChartCostData10';
import Data10ChartCard, { Data10ChartCardProps } from './Data10ChartCard';

export type ProductTagData10ChartCardProps = Data10ChartCardProps & {
  site?: string;
  product?: number;
  cost?: string;
  showPrev?: boolean;
};

ProductTagData10ChartCard.defaultProps = {
  site: undefined,
  product: undefined,
  cost: undefined,
  showPrev: false,
};

export default function ProductTagData10ChartCard({
  product,
  site,
  cost,
  showPrev,
  ...props
}: ProductTagData10ChartCardProps) {
  const { lineColors, bgColors, tags, dateProps, aggType, chartType, fill, isIncrease } = props;

  const { data: productData, isFetched } = useQuery(['findProductManage', product, site], () =>
    findAllProductManageAPI({ productId: product, siteCode: site }),
  );

  // 태그값 조회 시, 비용조회인지 검증, 생산량데이터가 조회되었는지 검증
  const validSearchTags = useMemo(() => {
    if (!isFetched) return [];
    if (cost) return [];
    return tags;
  }, [tags, cost, isFetched]);

  // 비용 태그값 조회 시, 비용조회인지 검증, 생산량데이터가 조회되었는지 검증
  const validCostTags = useMemo(() => {
    if (!isFetched) return [];
    if (!cost) return [];
    return tags;
  }, [tags, cost, isFetched]);

  // 제품 데이터 조회
  const getProductedValue = useCallback(
    (dateStr: string, chartValue: number) => {
      const date = moment(dateStr);

      const currentYearProduct = _.find(productData?.data, (pm) => pm.year === date.year());

      if (!currentYearProduct) return chartValue;

      const currentProduct = _.find(currentYearProduct.data, (pmd) => {
        const pmdDate = moment(pmd.dt);
        return pmdDate.month() === date.month();
      })?.value;

      if (!currentProduct) return chartValue;
      if (currentProduct < 1) return chartValue;

      return chartValue / currentProduct;
    },
    [productData],
  );

  // dateProps를 통해 연도 조회
  const getYear = (dateProps: DateSearchProps): number => {
    switch (dateProps.type) {
      case 'currentYear':
        return moment().year();
      case 'year':
        return dateProps.start?.year() || moment().year();
      default:
        return moment().year();
    }
  };

  // 날짜 포맷 수정
  const dttFormatter = (dtt: string): string => {
    const currentDate = moment(dtt);

    switch (dateProps.type) {
      case 'currentYear':
      case 'year':
        return currentDate.set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD');
      default:
        break;
    }

    return dtt;
  };

  const prevDttFormatter = (prevDtt: string): string => {
    const prevMoment = moment(prevDtt);

    switch (dateProps.type) {
      case 'currentYear':
      case 'year':
        return prevMoment
          .add(1, 'year')
          .set('day', 0)
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD');
      default:
        break;
    }

    return prevMoment.add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
  };

  const { data: productChartData } = useProductChartData10({
    search: {
      productId: product,
      siteCode: site,
    },
    fill,
    bgColors,
    colors: lineColors,
    type: 'line',
    yAxis: 'productY',
    stackId: 'x1',
    xFormatter: dttFormatter,
    year: getYear(dateProps),
    showDataLabel: true,
  });

  const { data: chartData } = useTagChartData10({
    dateProps,
    fill,
    tags: validSearchTags,
    bgColors,
    colors: lineColors,
    dataType: aggType,
    stackId: 'x1',
    type: 'stackedBar',
    xFormatter: dttFormatter,
    yFormatter: getProductedValue,
    isIncrease,
    showDataLabel: true,
  });

  // const { data: prevChartData } = useTagChartData10({
  //   dateProps,
  //   fill,
  //   tags: validSearchTags,
  //   isPrev: true,
  //   bgColors,
  //   colors: lineColors,
  //   dataType: aggType,
  //   stackId: 'x2',
  //   type: 'stackedBar',
  //   xFormatter: prevDttFormatter,
  //   yFormatter: getProductedValue,
  //   isIncrease,
  //   showDataLabel: true,
  // });

  const { data: costData } = useTagChartCostData10({
    cost,
    dateProps,
    fill,
    tags: validCostTags,
    bgColors,
    colors: lineColors,
    dataType: aggType,
    type: 'stackedBar',
    xFormatter: dttFormatter,
    yFormatter: getProductedValue,
  });

  const dateAggUnit = useMemo((): AggUnitType => {
    switch (dateProps.type) {
      case 'currentDay':
      case 'currentDayASC':
      case 'currentDayDESC':
      case 'day':
        return 'minute';
      case 'currentMonth':
      case 'currentMonthASC':
      case 'currentMonthDESC':
      case 'month':
        return 'day';
      case 'currentYear':
      case 'currentYearASC':
      case 'currentYearDESC':
      case 'year':
        return 'month';
      default:
        break;
    }

    return 'minute';
  }, [dateProps]);

  return (
    <Data10ChartCard
      {...props}
      chartType="stackedBar"
      scales={{
        wonY: {
          position: 'left',
          grid: {
            display: false,
          },
          ticks: {
            stepSize: 10,
          },
          display: !!cost,
        },
        productY: {
          position: 'right',
          grid: {
            display: false,
          },
          ticks: {
            stepSize: 10,
          },
          min: 0,
          display: true,
        },
      }}
      dataset={{
        datasets: [...chartData.datasets, ...costData.datasets, ...productChartData.datasets],
      }}
      dateProps={{ ...dateProps, menu: ['currentYear', 'year'] }}
      aggUnit={dateAggUnit}
    />
  );
}
