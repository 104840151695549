import { findLastDataAPI, findTopNDataAPI } from 'apis/data';
import { DateSearchProps } from 'components/common/DateSearchBox';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getDateRange } from 'common/lib';

export type TagType = {
  tag: string;
  aggFunc: string;
};

type Props = {
  tagTypes?: TagType[];
  dateProps?: DateSearchProps;
  isIncrease?: boolean;
  refetchOnFocus?: boolean;
  isPrev?: boolean;
};

const useLastTagData = ({ tagTypes, dateProps, isIncrease, refetchOnFocus = true, isPrev = false }: Props) => {
  const fetch = () => {
    let start = moment();
    let end = moment();
    let desc = false;

    switch (dateProps?.type) {
      case 'CUSTOM':
        return findLastDataAPI({
          ...getDateRange(dateProps),
          isIncrease: isIncrease ?? false,
          tagTypes: tagTypes ?? [],
        });
      case 'dayLast':
      case 'monthLast':
      case 'yearLast':
        switch (dateProps?.type) {
          case 'dayLast':
            start = start.set('hour', 0).set('minute', 0).set('second', 0);
            if (isPrev) {
              start = start.add(-1, 'day');
              end = end.add(-1, 'day').set('hour', 23).set('minute', 59).set('second', 59);
            }
            break;
          case 'monthLast':
            start = start.set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
            if (isPrev) {
              start = start.add(-1, 'month');
              end = end.add(-1, 'month');
              end = end.set('date', end.daysInMonth()).set('hour', 23).set('minute', 59).set('second', 59);
            }
            break;
          case 'yearLast':
            start = start.set('month', 0).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
            if (isPrev) {
              start = start.add(-1, 'year');
              end = end.add(-1, 'year');
              end = end.set('month', 11);
              end = end.set('date', end.daysInMonth()).set('hour', 23).set('minute', 59).set('second', 59);
            }
            break;
          default:
            start = start.set('hour', 0).set('minute', 0).set('second', 0);
        }

        return findLastDataAPI({
          start: start.format('YYYY-MM-DD HH:mm:ss'),
          end: end.format('YYYY-MM-DD HH:mm:ss'),
          tagTypes: tagTypes || [],
          isIncrease,
        });
      case 'currentDayASC':
      case 'currentDayDESC':
      case 'currentMonthASC':
      case 'currentMonthDESC':
      case 'currentYearASC':
      case 'currentYearDESC':
        switch (dateProps?.type) {
          case 'currentDayASC':
            start = start.set('hour', 0).set('minute', 0).set('second', 0);
            desc = false;
            if (isPrev) {
              start = start.add(-1, 'day');
              end = end.add(-1, 'day').set('hour', 23).set('minute', 59).set('second', 59);
            }
            break;
          case 'currentDayDESC':
            start = start.set('hour', 0).set('minute', 0).set('second', 0);
            desc = true;
            if (isPrev) {
              start = start.add(-1, 'day');
              end = end.add(-1, 'day').set('hour', 23).set('minute', 59).set('second', 59);
            }
            break;
          case 'currentMonthASC':
            start = start.set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
            desc = false;
            if (isPrev) {
              start = start.add(-1, 'month');
              end = end.add(-1, 'month');
              end = end.set('date', end.daysInMonth()).set('hour', 23).set('minute', 59).set('second', 59);
            }
            break;
          case 'currentMonthDESC':
            start = start.set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
            desc = true;
            if (isPrev) {
              start = start.add(-1, 'month');
              end = end.add(-1, 'month');
              end = end.set('date', end.daysInMonth()).set('hour', 23).set('minute', 59).set('second', 59);
            }
            break;
          case 'currentYearASC':
            start = start.set('month', 0).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
            desc = false;
            if (isPrev) {
              start = start.add(-1, 'year');
              end = end.add(-1, 'year');
              end = end.set('month', 11);
              end = end.set('date', end.daysInMonth()).set('hour', 23).set('minute', 59).set('second', 59);
            }
            break;
          case 'currentYearDESC':
            start = start.set('month', 0).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
            desc = true;
            if (isPrev) {
              start = start.add(-1, 'year');
              end = end.add(-1, 'year');
              end = end.set('month', 11);
              end = end.set('date', end.daysInMonth()).set('hour', 23).set('minute', 59).set('second', 59);
            }
            break;
          default:
            start = start.set('hour', 0).set('minute', 0).set('second', 0);
        }

        return findTopNDataAPI({
          start: start.format('YYYY-MM-DD HH:mm:ss'),
          end: end.format('YYYY-MM-DD HH:mm:ss'),
          tagTypes: tagTypes || [],
          desc,
          n: 1,
          isIncrease,
        });

      default:
        break;
    }

    return findLastDataAPI({
      start: start.format('YYYY-MM-DD HH:mm:ss'),
      end: end.format('YYYY-MM-DD HH:mm:ss'),
      tagTypes: tagTypes || [],
      isIncrease,
    });
  };

  const { data: dataList } = useQuery(['lastData', tagTypes, dateProps, isIncrease, isPrev], fetch, {
    enabled: Boolean(tagTypes),
    refetchInterval: 15 * 1000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: refetchOnFocus,
  });

  return dataList?.data?.datasets;
};

export default useLastTagData;
