import { Box, Button, Grid, Typography } from '@mui/material';
import { IMatterport } from '@types';
import useBaseModalControl from 'common/hooks/useBaseModalControl';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { matterportState } from 'states';
import MatterportBox from './MatterportBox';
import MatterportSaveModal from './MatterportSaveModal';

export default function MatterportBoxList() {
  const { open, onClose, onOpen } = useBaseModalControl();
  const matterports = useRecoilValue(matterportState);

  const [state, setState] = useState<{
    site?: string;
  }>({});

  const [selectedItem, setSelectdedItem] = useState<IMatterport | undefined>(undefined);

  const handleAdd = () => {
    setSelectdedItem(undefined);
    onOpen();
  };

  const handleEdit = (data: IMatterport) => {
    setSelectdedItem(data);
    onOpen();
  };

  const handleClose = () => {
    setSelectdedItem(undefined);
    onClose();
  };

  const handleSave = () => {
    handleClose();
  };

  const handleDelete = () => {
    handleClose();
  };

  return (
    <Box component="div">
      <Button fullWidth variant="contained" onClick={handleAdd}>
        <Typography fontWeight="bold">메타포트 추가</Typography>
      </Button>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {matterports.map((data) => (
          <MatterportBox key={data.idx} data={data} onClickEdit={handleEdit} clickLink={`/matterport/${data.idx}`} />
        ))}
      </Grid>
      <MatterportSaveModal
        open={open}
        data={selectedItem}
        onClose={handleClose}
        onSaveSuccess={handleSave}
        onDeleteSuccess={handleDelete}
      />
    </Box>
  );
}
