import { Box } from '@mui/material';
import { TAlarmHistSearchField } from '@types';
import { findAlarmHistPageAPI } from 'apis/alarmhist';
import useAlarmHistModal from 'common/hooks/useAlarmHistModal';
import useLoading from 'common/hooks/useLoading';
import usePageInfo from 'common/hooks/usePageInfo';
import useTaskModal from 'common/hooks/useTaskModal';
import AlarmHistDataGrid, { IAlarmHistGridData } from 'components/alarmhist/AlarmHistDataGrid';
import AlarmHistSearchField from 'components/alarmhist/AlarmHistSearchField';
import { SystemLayout } from 'layout/system';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { loginState } from 'states';

export default function AlarmHistPage() {
  const handleOnClick = (selected: IAlarmHistGridData) => {
    const item = changeSelected(selected.id);

    if (item) {
      setAlarmHist(item);
      openAlarmHistModal();
    }
  };

  const changeSelected = (id: number) => {
    const selectedItem = _.find(data?.data?.content, (hist) => hist.id === id);
    return selectedItem;
  };

  const changeSelectTask = (taskId: number) => {
    const selectedItem = _.find(data?.data?.content, (hist) => hist.task?.id === taskId);

    if (selectedItem && selectedItem.task) {
      setTask(selectedItem.task);
    }
  };

  const handleOnReload = () => {
    refetch();
  };

  const user = useRecoilValue(loginState);
  const { pageInfo, changePage, changeRowsPerPage, changeSearch, changeSort } = usePageInfo<TAlarmHistSearchField>({
    defaultSize: 10,
    defaultPage: 0,
    onChangeCallback: handleOnReload,
  });
  const {
    createMutation: createTaskMutation,
    modal,
    taskModify,
    task,
    setTask,
  } = useTaskModal({ onReload: handleOnReload });

  const {
    modal: alarmHistModal,
    modalOpen: openAlarmHistModal,
    alarmHist,
    setAlarmHist,
  } = useAlarmHistModal({ userId: user?.userId, createTaskMutation, onReload: handleOnReload });

  const { data, isFetching, refetch } = useQuery(['alarmhists', pageInfo], () => findAlarmHistPageAPI(pageInfo));
  const loading = useLoading({ trigger: false });

  useEffect(() => {
    loading({ loading: isFetching, message: '알람이력 조회중...' });
  }, [isFetching]);

  useEffect(() => {
    if (task && data) {
      changeSelectTask(task.id);
    }
  }, [data, task]);

  useEffect(() => {
    if (alarmHist && data) {
      const item = changeSelected(alarmHist.id);

      if (item) {
        setAlarmHist(item);
      }
    }
  }, [data, alarmHist]);

  return (
    <SystemLayout title="알람이력 관리">
      <Box component="div">
        <AlarmHistSearchField onChange={changeSearch} refetch={refetch} />
        <AlarmHistDataGrid
          data={data}
          page={pageInfo.page}
          rowPerPage={pageInfo.size}
          onClick={handleOnClick}
          onClickTask={taskModify}
          onChangePage={changePage}
          onChangeRowPerPage={changeRowsPerPage}
          onChangeSort={changeSort}
        />
      </Box>
      {alarmHistModal}
      {modal}
    </SystemLayout>
  );
}
