import { ENDPOINT } from '@const';
import {
  ILoginRequest,
  TLoginResponse,
  TPageUserRequest,
  TPageUserResponse,
  TUserCreateRequest,
  TUserCreateResponse,
  TUserModifyResponse,
} from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

/**
 * 사용자 로그인 API
 * @param request 사용자 로그인 요청 데이터
 * @returns 로그인 사용자 정보
 */
const loginAPI = async (request: ILoginRequest): Promise<TLoginResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.user.login,
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('서버에러발생');
  }

  toastErrorMessage(result.data);

  return result.data;
};

/**
 * 사용자 생성 API
 * @param request 사용자 생성 요청 데이터
 * @returns 생성된 사용자 정보
 */
const registerAPI = async (request: TUserCreateRequest): Promise<TUserCreateResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.user.register, ...createAuth(), data: request })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('유저 생성 실패');
  }
  toastErrorMessage(result.data);

  return result.data;
};

/**
 * 사용자 수정 API
 * @param request 사용자 수정 요청
 * @returns 수정된 사용자 정보
 */
const userModifyAPI = async (request: TUserCreateRequest): Promise<TUserModifyResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.user.modify,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('유저 수정 실패');
  }
  toastErrorMessage(result.data);

  return result.data;
};

/**
 * 토큰 재발급 및 검증 API
 * @param token 토큰값
 * @returns 재발급된 사용자 정보
 */
const refreshAPI = async (token: string): Promise<TLoginResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.user.refresh,
      headers: {
        Authorization: token,
      },
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('기존 토큰 검증 실패');
  }

  return result.data;
};

/**
 * 사용자 페이지 조회 API
 * @param request 사용자 페이지 요청 정보
 * @returns 조회된 사용자 페이지 정보
 */
const findUsersAPI = async (request: TPageUserRequest): Promise<TPageUserResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.user.findUsers,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('유저 조회 실패');
  }
  toastErrorMessage(result.data);

  return result.data;
};

export { loginAPI, refreshAPI, findUsersAPI, registerAPI, userModifyAPI };
