import { INode, INodeSystem, TNodeCreateRequest, TNodeResponse } from '@types';
import { createNodeAPI, modifyNodeAPI } from 'apis/node';
import NodeSaveModal from 'components/node/NodeSaveModal';
import { NodeSearchFormType } from 'components/reactflow/BaseNodeType';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

type Props = {
  nodeSystem?: INodeSystem | null;
  readonly?: boolean;
  reload?(): void;
  nodeSearchForm?: NodeSearchFormType;
};

export default function useNodeModal({ nodeSystem, readonly, reload, nodeSearchForm }: Props) {
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setState(null);
  }, []);

  const openModal = (data: INode | null) => {
    setState(data);
    handleOpen();
  };

  const onReload = () => {
    if (typeof reload === 'function') {
      reload();
    }
  };

  const handleSave = (request: TNodeCreateRequest) => {
    if (request.id && request.id.length > 0) {
      modifyMutation.mutate(request);
    } else {
      createMutation.mutate(request);
    }
  };

  const onSaveSuccess = (data: TNodeResponse) => {
    if (data && data.success) {
      toast('노드 저장 성공', { type: 'success' });
      handleClose();
    }

    onReload();
  };

  const [state, setState] = useState<INode | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const createMutation = useMutation(createNodeAPI, {
    onSuccess: onSaveSuccess,
  });

  const modifyMutation = useMutation(modifyNodeAPI, {
    onSuccess: onSaveSuccess,
  });

  const modal = useMemo(
    () => (
      <NodeSaveModal
        open={open}
        data={state}
        readonly={readonly}
        onClose={handleClose}
        nodeSystem={nodeSystem}
        onSaveNode={handleSave}
        nodeSearchForm={nodeSearchForm}
      />
    ),
    [open, state, nodeSystem, handleClose, handleOpen],
  );

  return {
    modal,
    openModal,
    node: state,
    setNode: setState,
  };
}
