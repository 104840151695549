import { Card, SxProps, Theme } from '@mui/material';
import { PieType } from '@types';
import useTopNTagData from 'common/hooks/useTopNTagData';
import { DateSearchProps } from 'components/common/DateSearchBox';
import React, { useMemo } from 'react';
import PieChart from './PieChart';

type Props = {
  type?: PieType;
  name: string;
  dateProps: DateSearchProps;
  isIncrease?: boolean;
  tags: string[];
  cardSx?: SxProps<Theme>;
  topn?: number;
};

TopNDataPolarChartCard.defaultProps = {
  type: 'pie',
  isIncrease: false,
  cardSx: {},
  topn: 5,
};

export default function TopNDataPolarChartCard({ type, name, topn, cardSx, dateProps, tags, isIncrease }: Props) {
  const datasets = useTopNTagData({
    dateProps,
    tagTypes: tags.map((tag) => ({ tag, aggFunc: 'mean' })),
    isIncrease,
    topn: topn || 5,
  });
  const labels = useMemo(() => datasets?.map((dt) => `${dt.tag.tagName} ${dt.tag.tagUnit.codeName}`), [datasets]);
  const data = useMemo(() => datasets?.map((dt) => dt.data.at(0)?.value.toFixed(2)), [datasets]);

  return (
    <Card sx={cardSx}>
      <PieChart type={type} labels={labels || []} data={data || []} />
    </Card>
  );
}
