import { Add } from '@mui/icons-material';
import { Box, Chip, IconButton, TextField } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';

type Props = {
  name?: string;
  label?: string;
  defaultValue?: string[];
  onChange?(synonym: string[]): void;
};

SynonymInput.defaultProps = {
  name: 'synonym',
  label: '음성 동의어',
  defaultValue: [],
  onChange: undefined,
};

export default function SynonymInput({ name, label, defaultValue, onChange }: Props) {
  const [synonymList, setSynonymList] = useState<string[]>([]);
  const [synonym, setSynonym] = useState<string>('');

  const handleChange = (changedSynonym: string[]) => {
    if (typeof onChange === 'function') {
      onChange(changedSynonym);
    }
  };

  // 동의어 입력 이벤트
  const handleInputSynonym = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSynonym(value);
  };

  // 동의어 추가
  const handleAddSynonym = () => {
    if (synonym) {
      setSynonymList((prev) => {
        const newSynonym = [...prev, synonym];
        handleChange(newSynonym);
        return newSynonym;
      });
      setSynonym('');
    }
  };

  // 동의어 삭제
  const handleDeleteSynonym = (deleteSynonym: string) => {
    setSynonymList((prev) => {
      const newSynonym = prev.filter((psynonym) => psynonym !== deleteSynonym) || [];
      handleChange(newSynonym);
      return newSynonym;
    });
  };

  useEffect(() => {
    if (defaultValue) {
      setSynonymList(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Box component="div" display="flex" flexDirection="column">
      <Box component="div" display="flex" flexDirection="row">
        {synonymList.map((synonym) => (
          <Chip sx={{ m: 1 }} key={synonym} label={synonym} onDelete={() => handleDeleteSynonym(synonym)} />
        ))}
      </Box>
      <Box component="div" display="flex" flexDirection="row" alignItems="center">
        <TextField
          fullWidth
          name={name}
          label={label}
          variant="standard"
          InputLabelProps={{ shrink: true }}
          onChange={handleInputSynonym}
          value={synonym}
        />
        <IconButton onClick={handleAddSynonym}>
          <Add />
        </IconButton>
      </Box>
    </Box>
  );
}
