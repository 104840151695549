import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, TextField } from '@mui/material';
import { IRole, TRoleCreateRequest } from '@types';
import BaseAccordion from 'components/common/BaseAccordion';

type AuthState = {
  checked: boolean;
  id: string;
};

type Props = {
  role: IRole;
  title?: string;
  onSaveClick?(role: TRoleCreateRequest): void;
  onDeleteClick?(id: number): void;
  auths?: string[];
};

RoleAccordion.defaultProps = {
  title: undefined,
  onSaveClick: undefined,
  onDeleteClick: undefined,
  auths: [],
};

export default function RoleAccordion({ role, auths, title, onSaveClick, onDeleteClick }: Props) {
  const refresh = () => {
    loadRoleAuthSetting();
    initState();
  };

  const onAuthChange = (code: string, checked: boolean) => {
    setAuthStates((prev) => prev.map((auth) => ({ ...auth, checked: auth.id === code ? checked : auth.checked })));
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onSaveButtonClick = () => {
    if (typeof onSaveClick === 'function') {
      onSaveClick(state);
    }
  };

  const onDeleteButtonClick = () => {
    if (typeof onDeleteClick === 'function' && state.id) {
      if (window.confirm(`${state.roleCode}를 삭제하시겠습니까?`)) {
        onDeleteClick(state.id);
      }
    }
  };

  const saveButton = <Button onClick={onSaveButtonClick}>저장</Button>;
  const deleteButton = (
    <Button onClick={onDeleteButtonClick} color="error" disabled={!role?.id}>
      삭제
    </Button>
  );
  const refreshButton = (
    <Button color="info" onClick={refresh}>
      새로고침
    </Button>
  );

  const roleAuthes = useMemo(() => role.authorities?.map((auth) => auth.author), [role]);
  const [authStates, setAuthStates] = useState<AuthState[]>([]);
  const [state, setState] = useState<TRoleCreateRequest>({});

  const initState = useCallback(() => {
    if (role) {
      setState((prev) => ({ ...prev, roleCode: role.roleCode, id: role.id }));
    } else {
      setState((prev) => ({ ...prev, roleCode: undefined, id: undefined }));
    }
  }, [role]);

  const initAuth = useCallback(() => {
    setAuthStates((auths ?? []).map((auth) => ({ id: auth, checked: false })));
  }, [auths]);

  const loadRoleAuthSetting = useCallback(() => {
    if (roleAuthes) {
      setAuthStates((prev) => prev.map((auth) => ({ ...auth, checked: roleAuthes.includes(auth.id) })));
    }
  }, [initAuth, roleAuthes]);

  const renderForm = useMemo(() => {
    return (
      <>
        <TextField name="id" type="hidden" sx={{ display: 'none' }} value={state.id} />
        <Box component="div" mb={2}>
          <TextField
            name="roleCode"
            label="Role Name"
            variant="standard"
            defaultValue={role.roleCode}
            value={state.roleCode}
            onChange={onChange}
          />
        </Box>
        <FormControl>
          <FormLabel>Authorities</FormLabel>
          <Box component="div">
            {authStates?.map((auth) => (
              <FormControlLabel
                key={auth.id}
                label={auth.id}
                checked={auth.checked}
                onChange={(e, checked) => {
                  onAuthChange(auth.id, checked);
                }}
                control={<Checkbox />}
              />
            ))}
          </Box>
        </FormControl>
      </>
    );
  }, [state, authStates]);

  useEffect(() => {
    initAuth();
  }, [initAuth]);

  useEffect(() => {
    loadRoleAuthSetting();
  }, [loadRoleAuthSetting]);

  useEffect(() => {
    initState();
  }, [initState]);

  useEffect(() => {
    setState((prev) => ({ ...prev, authorities: authStates.filter((auth) => auth.checked).map((auth) => auth.id) }));
  }, [authStates]);

  return (
    <BaseAccordion
      title={title !== undefined ? title : role.roleCode}
      actions={[refreshButton, deleteButton, saveButton]}
    >
      {renderForm}
    </BaseAccordion>
  );
}
