import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { flatSiteState, flatCodeState } from 'states';
import { IEmissionItem, ITag, TTagCreateRequest } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import SiteSelectBox from 'components/site/SiteSelectBox';
import CodeSelectBox from 'components/code/CodeSelectBox';
import BaseCheckbox from 'components/common/BaseCheckbox';
import TagTypeSelectBox from 'components/tag/TagTypeSelectBox';
import MultiTagData1ChartCard from 'components/data/MultiTagData1ChartCard';
import EmissionItemSelectModal from 'components/emissionitem/EmissionItemSelectModal';
import TagDataTypeSelectBox from './TagDataTypeSelectBox';
import TagSearchModal from './TagSearchModal';

interface Props extends IBaseModalProps {
  onSave?(data: TTagCreateRequest): void;
  data?: ITag | null;
}

TagSaveModal.defaultProps = {
  onSave: undefined,
  data: {},
};

export default function TagSaveModal({ open, data, onSave, onClose }: Props) {
  const init = () => {
    setState({
      id: undefined,
      tagCode: undefined,
      tagName: undefined,
      tagDesc: undefined,
      site: undefined,
      tagType: undefined,
      tagUnit: undefined,
      useYN: undefined,
      tagDetailType: undefined,
      expression: undefined,
    });
  };

  const [tagSearchModalOpen, setTagSearchModalOpen] = useState<boolean>(false);
  const [emissionSearchModalOpen, setEmissionSearchModalOpen] = useState<boolean>(false);

  const handleOpenTagSearchModal = () => {
    setTagSearchModalOpen(true);
  };

  const handleCloseTagSearchModal = () => {
    setTagSearchModalOpen(false);
  };

  const handleConfirmTagSearch = (tags: ITag[]) => {
    const tagStr = tags.map((tag) => `#${tag.tagCode}#`).join(' ');

    setState((prev) => ({ ...prev, expression: `${prev.expression || ''} ${tagStr}` }));
    handleCloseTagSearchModal();
  };

  const handleOpenEmissionSearchModal = () => {
    setEmissionSearchModalOpen(true);
  };

  const handleCloseEmissionSearchMOdal = () => {
    setEmissionSearchModalOpen(false);
  };

  const handleConfirmEmissionSearch = (emissions: IEmissionItem[]) => {
    const emissionStr = emissions.map((emission) => `@${emission.code}@`).join(' ');

    setState((prev) => ({ ...prev, expression: `${prev.expression || ''} ${emissionStr}` }));
    handleCloseEmissionSearchMOdal();
  };

  const onConfirm = () => {
    if (typeof onSave === 'function') {
      onSave(state);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    if (type === 'checkbox') {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const flatSite = useRecoilValue(flatSiteState);
  const flatCode = useRecoilValue(flatCodeState);

  const [state, setState] = useState<TTagCreateRequest>({
    id: undefined,
    tagCode: undefined,
    tagName: undefined,
    tagDesc: undefined,
    site: undefined,
    tagType: undefined,
    tagUnit: undefined,
    useYN: undefined,
    tagDetailType: undefined,
    expression: undefined,
  });

  useEffect(() => {
    if (data) {
      setState({
        ...data,
        site: data.site.siteCode,
        tagUnit: data.tagUnit.code,
        tagDetailType: data.tagDetailType?.code,
      });
    } else {
      init();
    }
  }, [data]);

  return (
    <BaseModal
      open={open}
      onConfirm={onConfirm}
      onClose={onClose}
      title="태그 저장"
      confirmTitle="저장"
      cancleTitle="취소"
    >
      <Box component="div" sx={{ width: 500, height: 600 }}>
        <TextField name="id" sx={{ display: 'none' }} type="hidden" value={state.id} />
        <TextField
          required
          fullWidth
          name="tagCode"
          variant="standard"
          label="태그 코드"
          value={state.tagCode}
          onChange={onChange}
        />
        <TextField
          required
          fullWidth
          name="tagName"
          variant="standard"
          label="태그 명"
          value={state.tagName}
          onChange={onChange}
        />
        <TextField
          name="tagDesc"
          variant="standard"
          label="태그 설명"
          value={state.tagDesc}
          onChange={onChange}
          fullWidth
        />
        <Box component="div">
          <TagTypeSelectBox
            required
            fullWidth
            name="tagType"
            value={state.tagType}
            defaultValue={data?.tagType}
            onChange={onSelectChange}
          />
        </Box>
        <Box component="div">
          <TagDataTypeSelectBox
            name="dataType"
            fullWidth
            value={state.dataType}
            defaultValue={data?.dataType}
            onChange={onSelectChange}
          />
        </Box>
        <Box component="div">
          <SiteSelectBox
            required
            name="site"
            data={flatSite}
            value={state.site}
            defaultValue={data?.site?.siteCode}
            onChange={onSelectChange}
            useYN
          />
        </Box>
        <Box component="div">
          <CodeSelectBox
            required
            name="tagUnit"
            label="태그 단위"
            defaultValue={data?.tagUnit?.code}
            data={flatCode}
            parentId="TAG_UNIT"
            onChange={onSelectChange}
          />
        </Box>
        <Box component="div">
          <CodeSelectBox
            required
            name="tagDetailType"
            label="태그 세부  타입"
            defaultValue={data?.tagDetailType?.code}
            data={flatCode}
            parentId="TAG_DETAIL_TYPE"
            onChange={onSelectChange}
          />
        </Box>
        {state.tagType === 'VIRTUAL' && (
          <Box component="div" sx={{ mt: 1, mb: 1 }}>
            <Box component="div">
              <Button onClick={handleOpenTagSearchModal}>
                <Typography>태그 조회</Typography>
              </Button>
              <Button onClick={handleOpenEmissionSearchModal}>
                <Typography>배출계수 조회</Typography>
              </Button>
            </Box>
            <Box component="div">
              <TextField
                name="expression"
                fullWidth
                label="산술식"
                variant="standard"
                multiline
                value={state.expression}
                onChange={onChange}
              />
            </Box>
            <Box component="div" sx={{ mt: 1 }}>
              <TextField
                name="priority"
                fullWidth
                label="우선순위"
                variant="standard"
                value={state.priority}
                onChange={onChange}
              />
            </Box>
          </Box>
        )}
        <Box component="div">
          <BaseCheckbox
            label="사용여부"
            name="useYN"
            checked={state.useYN}
            defaultChecked={data?.useYN}
            onChange={onChange}
          />
        </Box>
        <MultiTagData1ChartCard
          id={`${data?.id}_chartcard`}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          height={120}
          chartWidth="100%"
          chartHeight="100%"
          duration="10m"
          showDeleteBtn={false}
          tagStr={[data?.tagCode || '']}
        />
      </Box>
      <TagSearchModal
        open={tagSearchModalOpen}
        onClose={handleCloseTagSearchModal}
        onConfirm={handleConfirmTagSearch}
      />
      <EmissionItemSelectModal
        open={emissionSearchModalOpen}
        onClose={handleCloseEmissionSearchMOdal}
        onConfirm={handleConfirmEmissionSearch}
        singleSelect
      />
    </BaseModal>
  );
}
