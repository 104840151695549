import { Box, Fab, Grid } from '@mui/material';
import MultiTagData1ChartCard, { MultiTagData1ChartCardProps } from 'components/data/MultiTagData1ChartCard';
import React, { useEffect, useMemo, useState } from 'react';
import { DataLayout } from 'layout/data';
import { Save } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { customConfigState } from 'states';
import { toast } from 'react-toastify';
import { Data1MDuration } from 'common/hooks/useTagRTChartData';
import Data1SearchBox from 'components/data/Data1SearchBox';

export default function Data1MTagPage() {
  // Chart Card Title Change
  const handleChartTitleChange = (id: string, title: string) => {
    setChartCards((prev) => prev.map((p) => (p.id === id ? { ...p, title } : p)));
  };

  // 차트 카드 추가
  const handleAddChart = (chartProps: MultiTagData1ChartCardProps[]) => {
    setChartCards((prev) => [...prev, ...chartProps]);
  };

  // Chart Card 삭제
  const handleDeleteChartCard = (id: string) => {
    setChartCards((prev) => prev.filter((p) => p.id !== id));
  };
  // ChartCard Duration 변경 이벤트
  const handleChartCardDurationChange = (id: string, duration: Data1MDuration) => {
    setChartCards((prev) => prev.map((pcard) => (pcard.id === id ? { ...pcard, duration } : pcard)));
  };
  // 설정값 저장
  const handleSaveConfig = () => {
    setCustomConfig((prev) => ({ ...prev, rtdataTagConfig: chartCards }));
    toast('차트 설정 저장 완료', { type: 'success' });
  };

  const [chartCards, setChartCards] = useState<MultiTagData1ChartCardProps[]>([]);
  // CustomConfig Recoil State
  const [customConfig, setCustomConfig] = useRecoilState(customConfigState);

  const memoedChartCards = useMemo(
    () => (
      <Grid container spacing={1} sx={{ p: 2 }}>
        {chartCards.map((card) => (
          <MultiTagData1ChartCard
            key={card.id}
            chartHeight={180}
            height={180}
            chartWidth="100%"
            onTitleChange={handleChartTitleChange}
            onDelete={handleDeleteChartCard}
            onDurationChange={handleChartCardDurationChange}
            {...card}
          />
        ))}
      </Grid>
    ),
    [chartCards],
  );

  // Custom Config이 존재하면 Setting
  useEffect(() => {
    if (customConfig.rtdataTagConfig) {
      setChartCards(customConfig.rtdataTagConfig);
    }
  }, [customConfig.rtdataTagConfig]);

  return (
    <DataLayout title="태그별 실시간 조회">
      <Box component="div">
        <Data1SearchBox onAddChartCards={handleAddChart} />
        {memoedChartCards}
      </Box>
      <Fab sx={{ position: 'fixed', right: 10, bottom: 10 }} color="success" size="small" onClick={handleSaveConfig}>
        <Save />
      </Fab>
    </DataLayout>
  );
}
