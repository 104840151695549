import { Box, TextField, TextFieldProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ColorPickerModal from './ColorPickerModal';

type Props = Omit<TextFieldProps, 'onChange'> & {
  onChange?(color?: string): void;
};

ColorPickerInput.defaultProps = {
  onChange: undefined,
};

export default function ColorPickerInput({ defaultValue, name, onChange, ...others }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [color, setColor] = useState<string | undefined>(defaultValue as string | undefined);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = (changedColor?: string) => {
    setColor(changedColor);
    handleClose();

    if (typeof onChange === 'function') {
      onChange(changedColor);
    }
  };

  return (
    <Box
      component="div"
      display="flex"
      width="100%"
      justifyItems="center"
      alignItems="center"
      sx={{ position: 'relative' }}
    >
      <TextField {...others} InputProps={{ readOnly: true }} onClick={handleOpen} value={color || ''} />
      <Box
        component="div"
        sx={{
          width: 15,
          height: 15,
          backgroundColor: color,
          borderRadius: 10,
          position: 'absolute',
          right: 10,
          bottom: 10,
        }}
      />
      <ColorPickerModal
        title="색상선택"
        confirmTitle="선택"
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </Box>
  );
}
