import React, { ChangeEvent, useEffect, useState } from 'react';
import { SystemLayout } from 'layout/system';
import { Box } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { ISite, TSiteCreateResponse } from '@types';
import { toast } from 'react-toastify';
import SiteTree from 'components/site/SiteTree';
import { createSiteAPI, findAllSitesAPI, modifySiteAPI } from 'apis/site';
import SiteForm from 'components/site/SiteForm';
import useLoading from 'common/hooks/useLoading';
import { useSetRecoilState } from 'recoil';
import { siteState } from 'states';

function SitePage() {
  // 필드 상태 초기화
  const reset = (parent?: string) =>
    setSelectedSite({
      parent,
      id: undefined,
      siteCode: undefined,
      siteName: undefined,
      children: undefined,
      useYN: undefined,
      createdAt: undefined,
      updatedAt: undefined,
    });

  // Tree 선태 시, 코드 필드 세팅
  const onClickItem = (site: ISite) => {
    setSelectedSite(site);
  };

  // 코드 필드 데이터 변경 시, 세팅
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setSelectedSite((prev) => ({ ...prev, [name]: checked }));
    } else {
      setSelectedSite((prev) => ({ ...prev, [name]: value }));
    }
  };

  // 코드 저장, id값 유무에 따라 생성/수정
  const onSave = () => {
    if (selectedSite?.id) {
      modifyMutation.mutate(selectedSite);
    } else {
      createMutation.mutate(selectedSite);
    }
  };

  // 저장 성공 EventHandler
  const onSaveSuccess = (data: TSiteCreateResponse) => {
    if (data.success) {
      toast(`${data.data?.siteCode} 저장 성공`, { type: 'success' });
      refetch();
    }
  };

  // 코드 생성 Mutation
  const createMutation = useMutation(createSiteAPI, {
    onSuccess: onSaveSuccess,
  });

  // 코드 수정 Mutation
  const modifyMutation = useMutation(modifySiteAPI, {
    onSuccess: onSaveSuccess,
  });

  // Site 조회
  const { data: sites, refetch, isFetching } = useQuery(['sites'], findAllSitesAPI);

  const setRecoilSite = useSetRecoilState(siteState);

  // 선택된 코드 상태
  const [selectedSite, setSelectedSite] = useState<Partial<ISite>>({});

  // 로딩 상태 변경
  const changeLoading = useLoading({ trigger: false });

  useEffect(() => {
    changeLoading({ loading: createMutation.isLoading, message: '사이트 저장중...' });
  }, [createMutation.isLoading]);

  useEffect(() => {
    changeLoading({ loading: modifyMutation.isLoading, message: '사이트 저장중...' });
  }, [modifyMutation.isLoading]);

  useEffect(() => {
    changeLoading({ loading: isFetching, message: '사이트 조회중...' });
  }, [isFetching]);

  useEffect(() => {
    if (sites?.data) {
      setRecoilSite(sites.data);
    }
  }, [sites]);

  // 컴포넌트 최초 렌더링 시, 필드 초기화
  useEffect(() => {
    reset();
  }, []);

  return (
    <SystemLayout title="사이트관리">
      <Box component="div" display="flex" flexDirection="row">
        <Box component="div" flex={2} p={1}>
          <SiteTree
            containerStyle={{ minHeight: '70vh', maxHeight: '70vh' }}
            data={sites?.data}
            onClickItem={onClickItem}
            onAddItem={reset}
          />
        </Box>
        <Box component="div" flex={1} p={1}>
          <SiteForm
            data={selectedSite}
            onSave={onSave}
            onChange={onChange}
            containerStyle={{ minHeight: '70vh', maxHeight: '70vh' }}
          />
        </Box>
      </Box>
    </SystemLayout>
  );
}

export default SitePage;
