import React, { useMemo } from 'react';
import { ICostItemMonth, TCostItemMonthCreateRequest, TCostItemMonthResponse } from '@types';
import { deleteCostItemMonthAPI, saveCostItemMonthAPI } from 'apis/cost';
import BaseAccordion from 'components/common/BaseAccordion';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Box, Divider, Typography } from '@mui/material';
import CostItemTimeBox from 'components/costitemtime/CostItemTimeBox';
import CostItemMonthForm from './CostItemMonthForm';

type Props = {
  item: number;
  data?: ICostItemMonth;
};

CostItemMonthAccordion.defaultProps = {
  data: undefined,
};

export default function CostItemMonthAccordion({ item, data }: Props) {
  const queryClient = useQueryClient();

  const handleSave = (request: TCostItemMonthCreateRequest) => {
    saveMutation.mutate(request);
  };

  const handleDelete = (id: number) => {
    deleteMutation.mutate(id);
  };

  const onSaveSuccess = (result: TCostItemMonthResponse) => {
    if (result.success) {
      toast(`${result.data?.name} 저장 성공`, { type: 'success' });
      queryClient.invalidateQueries('cost');
    }
  };
  const onDeleteSuccess = (result: TCostItemMonthResponse) => {
    if (result.success) {
      toast(`${result.data?.name} 삭제 성공`, { type: 'success' });
      queryClient.invalidateQueries('cost');
    }
  };

  const saveMutation = useMutation(saveCostItemMonthAPI, {
    onSuccess: onSaveSuccess,
  });

  const deleteMutation = useMutation(deleteCostItemMonthAPI, {
    onSuccess: onDeleteSuccess,
  });

  const renderTimes = useMemo(
    () =>
      data ? (
        <Box component="div" sx={{ p: 1 }}>
          <Divider sx={{ marginY: 1 }} />
          <Typography>부하시간별 요금</Typography>
          {data.times.map((time) => (
            <CostItemTimeBox key={time.id} sx={{ marginTop: 1 }} month={data.id} data={time} />
          ))}
          <CostItemTimeBox sx={{ marginTop: 1 }} month={data.id} />
        </Box>
      ) : (
        <div />
      ),
    [data],
  );

  return (
    <BaseAccordion
      variant="elevation"
      title={data ? `${data.name} (${data.startMonth}월 ~ ${data.endMonth}월)` : '새 계절 생성'}
    >
      <CostItemMonthForm item={item} data={data} onSave={handleSave} onDelete={handleDelete} />

      {renderTimes}
    </BaseAccordion>
  );
}
