import React, { useMemo } from 'react';
import { findCostDataAPI } from 'apis/data';
import { ChartData } from 'chart.js';
import { useQuery } from 'react-query';
import { CHART_COLORS, CHART_COLORS_BG } from '@const';
import { DateSearchProps } from 'components/common/DateSearchBox';
import { ChartType, IData1, TData1ChartResponse } from '@types';
import { getDateRange, getPrevDateRange } from 'common/lib';
import { useRecoilValue } from 'recoil';
import { costState } from 'states';
import _ from 'lodash';
import { useTheme } from '@mui/material';
import useLoading from './useLoading';

export type Data10AggType = 'mean' | 'min' | 'max' | 'sum' | 'count';
export type AggUnitType = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';

type Props = {
  type?: ChartType;
  tags?: string[] | null | undefined;
  dataType?: Data10AggType;
  dateProps?: DateSearchProps;
  colors?: string[];
  bgColors?: string[];
  fill?: boolean;
  showLoading?: boolean;
  showAnnotation?: boolean;
  aggUnit?: AggUnitType;
  cost?: string;
  isPrev?: boolean;
  stackId?: string;
  xFormatter?(dtt: string): string;
  yFormatter?(xValue: string, yValue: number): number;
};

const useTagChartCostData10 = ({
  tags,
  type = 'line',
  showLoading = false,
  colors,
  bgColors,
  fill,
  dateProps = undefined,
  dataType = 'mean',
  aggUnit,
  cost = undefined,
  isPrev = false,
  stackId = 'x',
  xFormatter = undefined,
  yFormatter = undefined,
}: Props) => {
  const theme = useTheme();
  const costs = useRecoilValue(costState);
  const selectedCost = useMemo(() => _.find(costs, (co) => co.code === cost), [cost, costs]);

  const themeColors = useMemo(() => {
    if (theme.palette.mode === 'dark') {
      return {
        textColor: '#eee',
        labelBackgroundColor: isPrev ? 'rgb(50, 50, 50)' : 'black',
      };
    }

    return {
      textColor: '#222',
      labelBackgroundColor: isPrev ? 'rgb(200,200,200)' : 'rgb(250,250,250)',
    };
  }, [theme.palette.mode, isPrev]);

  const createNullFetch = (): Promise<TData1ChartResponse> =>
    new Promise((resolve, reject) => {
      resolve({
        success: true,
      });
    });

  const onFetchData = (): Promise<TData1ChartResponse> => {
    if (!dateProps) return createNullFetch();
    if (!cost) return createNullFetch();

    if (isPrev) {
      return findCostDataAPI({
        tagTypes: tags?.map((tag) => ({ tag, aggFunc: dataType })) || [],
        isIncrease: true,
        cost,
        ...getPrevDateRange(dateProps),
      });
    }

    return findCostDataAPI({
      tagTypes: tags?.map((tag) => ({ tag, aggFunc: dataType })) || [],
      isIncrease: true,
      cost,
      ...getDateRange(dateProps),
    });
  };

  const { data: dataList, isFetching } = useQuery(
    ['costData10', tags, dateProps, dataType, aggUnit, isPrev, cost],
    onFetchData,
    {
      enabled: Boolean(tags),
      refetchInterval: 60 * 1000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );

  const chartType = useMemo(() => {
    if (type === 'stackedBar') return 'bar';

    return type;
  }, [type]);

  const createData = (data: IData1[]) => {
    if (typeof xFormatter === 'function' || typeof yFormatter === 'function') {
      return data.map((dt) => ({
        originX: dt.dtt,
        x: typeof xFormatter === 'function' ? xFormatter(dt.dtt) : dt.dtt,
        y: typeof yFormatter === 'function' ? yFormatter(dt.dtt, dt.value) : dt.value,
        isPrev,
      }));
    }
    return data.map((dt) => ({
      x: dt.dtt,
      y: dt.value,
    }));
  };

  const chartData = useMemo((): ChartData<'line' | 'bar' | 'scatter', any> => {
    const tempData = dataList?.data?.datasets ?? [];

    if (tempData.length < 1) {
      return {
        labels: [],
        datasets: [],
      };
    }

    let lineColor = colors ?? CHART_COLORS;
    let bgColor = bgColors ?? CHART_COLORS_BG;

    if (isPrev) {
      lineColor = lineColor.slice().reverse();
      bgColor = bgColor.slice().reverse();
    }

    const tagIndex: { [key: string]: number } = tempData
      .map((data) => data.tag.tagCode)
      .reduce((prev, current, index) => ({ [current]: index, ...prev }), {});

    const datasets: ChartData<'line' | 'bar' | 'scatter', any> = {
      datasets: tempData.map((data, index) => ({
        fill,
        type: data.type === 'DATA' ? 'bar' : 'line',
        label: `${data.tag.tagName}${data.type === 'WON' ? `(${selectedCost?.name})` : ''}`,
        data: createData(data.data),
        borderColor: lineColor.at(tagIndex[data.tag.tagCode]),
        backgroundColor: bgColor.at(tagIndex[data.tag.tagCode]),
        barThickness: 'flex',
        maxBarThickness: 200,
        yAxisID: data.type === 'DATA' ? 'y' : 'wonY',
        stack: stackId,
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 10,
        datalabels: {
          formatter: (value, context) => {
            const fixedValue = Number(value.y.toFixed(0)).toLocaleString();
            return `${fixedValue}${data.type === 'WON' ? ' 원' : ` ${data.tag.tagUnit.codeName}`}`;
          },
          backgroundColor: data.type === 'WON' ? themeColors.labelBackgroundColor : 'rgba(255, 255, 255, 0)',
          borderRadius: 5,
          color: themeColors.textColor,
          display: data.type === 'WON',
          font: {
            weight: 'bold',
            size: 9,
          },
          padding: 3,
          anchor: 'end',
          align: 'end',
        },
      })),
    };

    return datasets;
  }, [dataList, dataType, colors, chartType, selectedCost, themeColors]);
  useLoading({ loading: isFetching, message: '데이터 로딩중', trigger: showLoading });

  return { data: chartData, dataList, isFetching };
};

export default useTagChartCostData10;
