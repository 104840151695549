import { TLoading } from '@types';
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { loadingState } from 'states';

type Props = TLoading & {
  trigger?: boolean;
};

const useLoading = (props: Props) => {
  const setLoading = useSetRecoilState(loadingState);

  const changeLoading = (data: TLoading) => {
    const { loading, message } = data;
    setLoading({ loading, message });
  };

  useEffect(() => {
    const { trigger } = props;
    if (trigger) {
      changeLoading({ ...props });
    }
  }, [props]);

  useEffect(() => {
    return () => {
      changeLoading({ loading: false, message: '' });
    };
  }, []);

  return changeLoading;
};

export default useLoading;
