import React, { useMemo } from 'react';
import BaseTree, { RenderBaseTree } from 'components/common/BaseTree';
import { SxProps, Theme } from '@mui/material';
import { ISite } from '@types';
import { flatten } from 'common/lib';

interface Props {
  data?: ISite[];
  containerStyle?: SxProps<Theme>;
  showAddBtn?: boolean;
  onClickItem?(csite: ISite): void;
  onAddItem?(parent: string): void;
}

CodeTree.defaultProps = {
  data: [],
  showAddBtn: true,
  onClickItem: undefined,
  onAddItem: undefined,
  containerStyle: {},
};

export default function CodeTree({ containerStyle, showAddBtn, onClickItem, onAddItem, data }: Props) {
  const createTree = (site: ISite): RenderBaseTree => {
    return {
      id: site.id.toString(),
      name: `${site.siteCode} | ${site.siteName} | ${site.useYN ? 'Y' : 'F'}`,
      children: site.children.map((sub) => createTree(sub)),
    };
  };

  const onClick = (tree: RenderBaseTree) => {
    if (typeof onClickItem === 'function') {
      const selected = flatDatas.filter((code) => code.id.toString() === tree.id);

      if (selected && selected.length > 0) {
        onClickItem(selected[0]);
      }
    }
  };

  const onClickAdd = (parentId: string) => {
    if (typeof onAddItem === 'function') {
      const selected = flatDatas.filter((code) => code.id.toString() === parentId);

      if (selected && selected.length > 0) {
        onAddItem(selected[0].siteCode);
      }
    }
  };

  const flatDatas = useMemo(() => flatten(data ?? []) as ISite[], [data]);

  const treeData: RenderBaseTree[] = useMemo(() => {
    return (data ?? []).map((tree) => createTree(tree));
  }, [data]);

  return (
    <BaseTree
      showAddBtn={showAddBtn}
      data={treeData}
      onClickItem={onClick}
      onClickAdd={onClickAdd}
      containerStyle={containerStyle}
    />
  );
}
