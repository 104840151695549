import { ITag } from '@types';
import ListItemCard, { ListItemCardProps } from 'components/common/ListItemCard';
import React from 'react';

type Props = Omit<ListItemCardProps, 'id'> & {
  data: ITag;
};

export default function TagListItemCard({ data, ...others }: Props) {
  return (
    <ListItemCard
      {...others}
      id={data.tagCode}
      title={`${data.tagCode} | ${data.tagUnit.codeName} | ${data.tagName} | ${data.site.siteName} | ${
        data.useYN ? 'Y' : 'F'
      }`}
    />
  );
}
