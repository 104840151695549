import { SelectChangeEvent } from '@mui/material';
import { IThreeCanvas } from '@types';
import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';
import _ from 'lodash';
import React, { useMemo } from 'react';

type Props = BaseSelectBoxProps & {
  data?: IThreeCanvas[];
  onSelectThreeCanvas?(name: string | undefined, selected: IThreeCanvas | undefined): void;
};

ThreeCanvasSelectBox.defaultProps = {
  data: [],
  onSelectThreeCanvas: undefined,
};

export default function ThreeCanvasSelectBox({ data = [], onSelectThreeCanvas, ...others }: Props) {
  const items: SelectItemProps[] = useMemo(
    () => data.map((dt) => ({ id: dt.id.toString(), name: `${dt.site} | ${dt.name}` })),
    [data],
  );

  const handleSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    const id = Number(value);

    const selected = _.find(data, (dt) => dt.id === id);

    if (typeof onSelectThreeCanvas === 'function') {
      onSelectThreeCanvas(name, selected);
    }
  };

  return <BaseSelectBox showEmpty items={items} onChange={handleSelect} {...others} />;
}
