import React, { useEffect, useState } from 'react';
import { Box, Card, Typography, Button } from '@mui/material';
import { IAlarm, TAlarmCreateRequest, TAlarmResponse, TAlarmSearchField } from '@types';
import { createAlarmAPI, deleteAlarmAPI, findAlarmPageAPI, modifyAlarmAPI } from 'apis/alarm';
import usePageInfo from 'common/hooks/usePageInfo';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import BaseTablePagination from 'components/common/BaseTablePagination';
import { useMutation, useQuery } from 'react-query';
import ListItemCard from 'components/common/ListItemCard';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { findTagByCodeAPI } from 'apis/tag';
import AlarmSaveModal from './AlarmSaveModal';

type Props = IBaseModalProps & {
  tag?: string;
  onSelect?(alarm: IAlarm): void;
};

TagAlarmSelectModal.defaultProps = {
  tag: undefined,
  onSelect: undefined,
};

export default function TagAlarmSelectModal({ tag, onSelect, ...others }: Props) {
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);
  const [selectedAlarm, setSelectedAlarm] = useState<IAlarm | undefined>(undefined);
  const [modifyAlarm, setModifyAlarm] = useState<IAlarm | undefined>(undefined);

  const { changePage, changeRowsPerPage, pageInfo } = usePageInfo<TAlarmSearchField>({
    defaultPage: 0,
    defaultSize: 25,
    defaultSearch: { tagCode: tag },
  });
  const { data, refetch } = useQuery(['findAlarm', pageInfo], () => findAlarmPageAPI(pageInfo));
  const { data: tagDatas } = useQuery(['findTagByCode', tag], () => findTagByCodeAPI([tag || '']), {
    enabled: Boolean(tag),
  });

  const onChecked = (id: string, checked: boolean) => {
    if (checked) {
      const selected = _.find(data?.data?.content, (alarm) => alarm.id.toString() === id);
      setSelectedAlarm(selected);
    } else {
      setSelectedAlarm(undefined);
    }
  };

  const handleConfirm = () => {
    if (typeof onSelect === 'function' && selectedAlarm) {
      onSelect(selectedAlarm);
    }
  };

  const handleSelectModifyAlarm = (alarm: IAlarm) => {
    setModifyAlarm(alarm);
    handleModalOpen();
  };

  const handleModalOpen = () => {
    setSaveModalOpen(true);
  };

  const handleModalClose = () => {
    setSaveModalOpen(false);
    setModifyAlarm(undefined);
  };

  const handleSave = (request: TAlarmCreateRequest) => {
    if (request.id && request.id > 0) {
      modifyMutation.mutate(request);
    } else {
      createMutation.mutate(request);
    }
  };

  const handleDelete = (deleteId: number) => {
    if (window.confirm('알람을 삭제하시겠습니까?')) {
      deleteMutation.mutate(deleteId);
    }
  };

  const onSuccess = (result: TAlarmResponse) => {
    if (result.success) {
      toast('알람 저장성공', { type: 'success' });
      refetch();
      handleModalClose();
    }
  };

  const onDeleteSuccess = (result: TAlarmResponse) => {
    if (result.success) {
      toast('알람 삭제성공', { type: 'success' });
      refetch();
    }
  };

  const createMutation = useMutation(createAlarmAPI, {
    onSuccess,
  });

  const modifyMutation = useMutation(modifyAlarmAPI, {
    onSuccess,
  });

  const deleteMutation = useMutation(deleteAlarmAPI, {
    onSuccess: onDeleteSuccess,
  });

  return (
    <BaseModal
      title="태그 알람 선택"
      disableConfirm={!selectedAlarm}
      confirmTitle="선택"
      onConfirm={handleConfirm}
      {...others}
    >
      <Box component="div">
        <Card variant="outlined" sx={{ minWidth: 500, minHeight: 400, maxHeight: 400, p: 1, overflowY: 'auto' }}>
          {data?.data?.content?.map((alarm) => (
            <ListItemCard
              checked={alarm.id === selectedAlarm?.id}
              onSelect={() => handleSelectModifyAlarm(alarm)}
              onCheck={onChecked}
              onDelete={() => handleDelete(alarm.id)}
              id={alarm.id.toString()}
              key={alarm.id}
              title={`${alarm.alarmName} | ${alarm.site.siteName}`}
            />
          ))}
        </Card>
        <BaseTablePagination
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
          page={pageInfo.page}
          rowsPerPage={pageInfo.size}
          totalCount={data?.data?.totalElements || 0}
        />
      </Box>
      <Box component="div" display="flex" justifyContent="flex-end">
        <Button onClick={handleModalOpen}>
          <Typography>알람 생성</Typography>
        </Button>
      </Box>
      <AlarmSaveModal
        onSave={handleSave}
        data={modifyAlarm || null}
        defaultTag={tagDatas?.data?.at(0)}
        open={saveModalOpen}
        onClose={handleModalClose}
      />
    </BaseModal>
  );
}
