import { Box, Button, SelectChangeEvent, Typography } from '@mui/material';
import DateSearchBox, { DateSearchProps } from 'components/common/DateSearchBox';
import GroupCodeSelectBox from 'components/group/GroupCodeSelectBox';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatGroupState } from 'states';

type Props = {
  onSearch?(search: State): void;
  defaultGroup?: string;
  defaultDate?: DateSearchProps;
};

type State = {
  dateProps?: DateSearchProps;
  groupCode?: string;
};

MesSearchBox.defaultProps = {
  onSearch: undefined,
  defaultGroup: undefined,
  defaultDate: undefined,
};

export default function MesSearchBox({ onSearch, defaultGroup, defaultDate }: Props) {
  const handleChangeGroup = (e: SelectChangeEvent) => {
    const { value: code } = e.target;
    const changedGroup = _.find(mesGroup, (group) => group.groupCode === code);
    setSearch((prev) => ({ ...prev, groupCode: changedGroup?.groupCode }));
  };

  const handleChangeDate = (dateProps: DateSearchProps) => {
    setSearch((prev) => handleChange({ ...prev, dateProps }));
  };

  const handleChange = (current: State): State => {
    return current;
  };

  const handleSearch = () => {
    if (typeof onSearch === 'function') {
      onSearch(search);
    }
  };

  const groups = useRecoilValue(flatGroupState);

  const mesGroup = useMemo(
    () =>
      groups.filter(
        (group) => group.groupType.code === 'GROUP_TYPE_MES_PRODUCT' && group.groupDetailType?.code === undefined,
      ),
    [groups],
  );

  const [search, setSearch] = useState<State>({
    dateProps: defaultDate ?? { type: 'currentDay' },
    groupCode: defaultGroup,
  });

  return (
    <Box component="div">
      <GroupCodeSelectBox
        label="MES 제품그룹 선택"
        data={mesGroup}
        defaultValue={mesGroup.at(0)?.groupCode || ''}
        value={search.groupCode}
        showEmpty
        onChange={handleChangeGroup}
      />
      <Box component="div" display="flex" alignItems="center">
        <DateSearchBox {...search.dateProps} onChange={handleChangeDate} menu={['day', 'currentDay']} />
      </Box>

      <Button fullWidth variant="contained" onClick={handleSearch}>
        <Typography>조회</Typography>
      </Button>
    </Box>
  );
}
