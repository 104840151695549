/* eslint-disable */
import { DataArray, Mic, Stop } from '@mui/icons-material';
import { Card, Dialog, DialogProps, Fab, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import io from 'socket.io-client';
import moment from 'moment';
import { WEBSOCKET_URL } from '@const';
import MesSearchModal from 'components/mes/MesSearchModal';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import VoiceChatGroupTagModal from './VoiceChatGroupTagModal.';

type NLP_INTENT = '품질데이터_조회' | '태그_조회';

export type NLPResponse = {
  nlp?: {
    intent: NLP_INTENT;
    entities: { [key: string]: string }[];
    text: string;
  };
  RecogText: string;
  RecogTime: number;
  Confidence: number;
  words: { word: string }[];
};
type Props = IBaseModalProps;

export type ChatProps = {
  type: 'message' | 'audio';
  sendBy: 'me' | 'server';
  message: any | undefined;
  date: string;
  nlp?: NLPResponse;
};

const DOWNSAMPLING_WORKER = 'js/downsampling_worker.js';

let mediaStream: any = undefined;
let mediaStreamSource: any = undefined;
let processor: any = undefined;
let audioContext: AudioContext | undefined = undefined;

let analyser: AnalyserNode | undefined = undefined;

export default function VoiceChat({ open, ...others }: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const socket = useMemo(() => io(`${WEBSOCKET_URL}`, { autoConnect: false }), []);

  const [socketConnected, setSocketConnected] = useState<boolean>(false);

  const [state, setState] = useState<{
    connected: boolean;
    recording: boolean;
    recognitionOutput: any[];
  }>({
    connected: false,
    recording: false,
    recognitionOutput: [],
  });

  const [modalState, setModalState] = useState<{
    mes: boolean;
    tag: boolean;
  }>({ mes: false, tag: false });

  const renderAudioVisual = () => {
    const ctx = canvasRef?.current?.getContext('2d');
    if (analyser && ctx) {
      analyser.fftSize = 256;

      let bufferLength = analyser.frequencyBinCount;
      let dataArray = new Uint8Array(bufferLength);
      const WIDTH = canvasRef.current?.width ?? 0;
      const HEIGHT = canvasRef.current?.height ?? 0;

      const barWidth = (WIDTH / bufferLength) * 2.5;
      let barHeight = 0;
      let x = 0;

      const renderFrame = () => {
        requestAnimationFrame(renderFrame);

        x = 0;

        analyser?.getByteFrequencyData(dataArray);

        if (dataArray) {
          ctx.clearRect(0, 0, WIDTH, HEIGHT);

          for (let i = 0; i < bufferLength; i++) {
            barHeight = dataArray[i];

            let r = barHeight + 25 * (i / bufferLength);
            let g = 250 * (i / bufferLength);
            let b = 50;

            ctx.fillStyle = 'rgba(' + r + ',' + g + ',' + b + ', 0.5)';
            ctx.fillRect(x, HEIGHT - barHeight, barWidth, barHeight);

            x += barWidth + 1;
          }
        }
      };
      renderFrame();
    }
  };

  const createAudioProcessor = (audioContext: AudioContext, audioSource: MediaElementAudioSourceNode | undefined) => {
    const processor: any = audioContext.createScriptProcessor(4096, 1, 1);
    const sampleRate = audioSource?.context.sampleRate;

    const downsampler = new Worker(DOWNSAMPLING_WORKER);
    downsampler.postMessage({ command: 'init', inputSampleRate: sampleRate });
    downsampler.onmessage = (e) => {
      if (socket.connected) {
        socket.emit('stream-data', e.data.buffer);
      }
    };

    processor.onaudioprocess = (event: any) => {
      const data = event.inputBuffer.getChannelData(0);
      downsampler.postMessage({ command: 'process', inputFrame: data });
    };

    processor.shutdown = () => {
      processor.disconnect();
      processor.onaudioprocess = null;
    };

    processor.connect(audioContext.destination);

    return processor;
  };

  const socketClear = () => {
    socket.off('connect');

    socket.off('recognize');

    socket.off('disconnect');

    socket.disconnect();
  };

  const socketInit = () => {
    socket.on('connect', () => {
      console.log('connect');
      setState((prev) => ({ ...prev, connected: true }));
      setSocketConnected(true);
    });

    socket.on('recognize', (results: any) => {
      console.log('recognized:', results);

      if (results.nlp && results.nlp.length > 0) {
        const nlpResult: NLPResponse = {
          ...results,
          nlp: JSON.parse(results.nlp),
        };

        handleRecordData('server', 'message', nlpResult?.nlp?.text, nlpResult);
      } else {
        const originText = results.text as string;
        const nlpResult: NLPResponse = {
          ...results,
          nlp: {
            text: originText.replaceAll(' 조회', ''),
            intent: results.text.includes('품질') ? '품질데이터_조회' : '태그_조회',
          },
        };

        handleRecordData('server', 'message', originText, nlpResult);
      }
    });

    socket.on('disconnect', (results: any) => {
      console.log('disconnect');
      setSocketConnected(false);
    });

    if (!socket.connected) {
      socket.connect();
    }
  };

  const startRecording = () => {
    if (!state.recording) {
      setState((prev) => ({
        ...prev,
        recording: true,
      }));

      startMicrophone();
    }
  };

  const startMicrophone = () => {
    const success = (stream: any) => {
      audioContext = new AudioContext();

      console.log('started recording');

      if (socket.connected) {
        socket.emit('stream-start');
      }

      mediaStream = stream;
      mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
      processor = createAudioProcessor(audioContext, mediaStreamSource);
      mediaStreamSource.connect(processor);

      // Analyser Connect
      analyser = audioContext.createAnalyser();
      mediaStreamSource?.connect(analyser);
      // analyser.connect(audioContext.destination);
    };

    const fail = (e: any) => {
      console.error('recording failure', e);
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          video: false,
          audio: true,
        })
        .then(success)
        .then(renderAudioVisual)
        .catch(fail);
    }
  };

  const stopMicrophone = () => {
    if (mediaStream) {
      mediaStream.getTracks()[0].stop();
    }

    if (mediaStreamSource) {
      mediaStreamSource.disconnect();
    }

    if (analyser) {
      analyser.disconnect();
    }

    if (processor) {
      processor.shutdown();
    }

    if (audioContext) {
      audioContext.close();
    }
  };

  const stopRecording = () => {
    console.log('stop recording');
    if (state.recording) {
      if (socket.connected) {
        console.log('stream-reset');
        socket.emit('stream-reset');
      }

      setState((prev) => ({
        ...prev,
        recording: false,
      }));

      stopMicrophone();
    }
  };

  const [chats, setChats] = useState<ChatProps[]>([]);

  const handleRecordData = (
    sendBy: 'me' | 'server',
    type: 'audio' | 'message',
    message: string | undefined,
    nlp: NLPResponse | undefined,
  ) => {
    if (!message || !nlp) return;

    setChats((prev) => [
      ...prev,
      {
        sendBy,
        type,
        message,
        date: moment().format('YYYY-MM-DD HH:mm:ss'),
        nlp,
      },
    ]);
  };

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const chatMessage = (data: ChatProps) => {
    return (
      <Box
        component="div"
        key={data.date}
        sx={{
          m: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: data.sendBy === 'me' ? 'flex-end' : 'flex-start',
          alignContent: 'center',
        }}
      >
        <Card sx={{ p: 2 }}>
          {data.type === 'audio' ? <Typography>{data.message}</Typography> : <Typography>{data.message}</Typography>}
        </Card>
        <Typography variant="caption">{data.date}</Typography>
      </Box>
    );
  };

  const intentAction = useCallback(() => {
    const lastChat = chats.at(-1);
    if (lastChat) {
      switch (lastChat.nlp?.nlp?.intent) {
        case '태그_조회':
          console.log('태그조회');
          setModalState((prev) => ({ tag: true, mes: false }));
          stopRecording();
          break;
        case '품질데이터_조회':
          console.log('품질 데이터 조회');
          setModalState((prev) => ({ tag: false, mes: true }));
          stopRecording();
          break;
        default:
          console.log('default');
          setModalState((prev) => ({ tag: true, mes: false }));
          break;
      }
    }
  }, [chats]);

  const connectionStateCircle = useMemo(() => {
    if (socketConnected) {
      return (
        <Box
          component="div"
          sx={{
            width: 15,
            height: 15,
            borderRadius: 25,
            backgroundColor: 'green',
            position: 'absolute',
            right: 10,
            top: 10,
          }}
        />
      );
    }
    return (
      <Box
        component="div"
        sx={{
          width: 15,
          height: 15,
          borderRadius: 25,
          backgroundColor: 'red',
          position: 'absolute',
          right: 10,
          top: 10,
        }}
      />
    );
  }, [socketConnected]);

  useEffect(() => {
    if (open) {
      socketInit();
    } else {
      stopRecording();
      socketClear();
    }
  }, [open]);

  useEffect(() => {
    intentAction();
  }, [intentAction]);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  return (
    <>
      <BaseModal open={open ?? false} title="" confirmTitle="" cancleTitle="" {...others}>
        <Box display="flex" flexDirection="column" component="div" sx={{ width: 500, height: 800 }}>
          {connectionStateCircle}
          <Box ref={chatBoxRef} component="div" flex={1} sx={{ p: 1, pb: 5, overflowY: 'auto', zIndex: 99 }}>
            {chats.map((chat) => chatMessage(chat))}
          </Box>
          <Box component="div" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            {state.recording ? (
              <Fab sx={{ mb: 1 }} size="large" color="warning" onClick={stopRecording}>
                <Stop />
              </Fab>
            ) : (
              <Fab sx={{ mb: 1 }} size="large" color="primary" onClick={startRecording}>
                <Mic />
              </Fab>
            )}
          </Box>
          <canvas
            style={{
              width: '100%',
              height: 100,
              position: 'absolute',
              left: 0,
              bottom: 0,
              backgroundColor: 'transparent',
              zIndex: 98,
            }}
            ref={canvasRef}
          />
        </Box>
      </BaseModal>
      <MesSearchModal open={modalState.mes} onClose={() => setModalState((prev) => ({ ...prev, mes: false }))} />

      <VoiceChatGroupTagModal
        open={modalState.tag}
        voice={chats.at(-1)?.nlp}
        onClose={() => setModalState((prev) => ({ ...prev, tag: false }))}
      />
    </>
  );
}
