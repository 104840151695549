import React, { MouseEvent, ReactElement, useState, useMemo, useEffect } from 'react';
import {
  Box,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Badge,
  Button,
  Card,
  Switch,
  Stack,
  useTheme,
} from '@mui/material';
import {
  Notifications,
  AccountCircle,
  LightModeRounded,
  DarkModeRounded,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { useSpring, animated } from 'react-spring';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { colorModeState, dashboardState, loginState } from 'states';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGES } from '@const';
import { useCookies } from 'react-cookie';
import { useMutation, useQuery } from 'react-query';
import { findAlarmHistPageAPI } from 'apis/alarmhist';
import Moment from 'react-moment';
import { IUser, TLoginResponse, TUserCreateRequest, TUserCreateResponse } from '@types';
import { refreshAPI, userModifyAPI } from 'apis/user';
import { toast, ToastContainer } from 'react-toastify';
import useLoading from 'common/hooks/useLoading';
import Snowfall from 'react-snowfall';
import useDateNow from '../common/hooks/useDateNow';
import SideTreeMenu, { RenderSideTree } from './SideTreeMenu';
import AlarmHistNoti from './alarmhist/AlarmHistNoti';
import SideTreeIconMenu from './SideTreeIconMenu';
import UserSaveModal from './user/UserSaveModal';

type Props = {
  children?: ReactElement | ReactElement[];
};

Nav.defaultProps = {
  children: undefined,
};

function Nav({ children }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [user, setUser] = useRecoilState(loginState);
  const [colorMode, setColorMode] = useRecoilState(colorModeState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [alarmAnchorEl, setAlarmAnchorEl] = useState<null | HTMLElement>(null);
  // Side Menu FullSize <-> Mini Toggle state
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const toggleStyle = useSpring({ width: toggleMenu ? 180 : 80 });
  const theme = useTheme();
  const timeNow = useDateNow();

  // 로그아웃 시, 대시보드 초기화를 위해 사용
  const setDashboards = useSetRecoilState(dashboardState);

  // 로그인 리프레쉬
  const onLoginSuccess = (data: TLoginResponse) => {
    if (data.success && data.data) {
      setUser(data.data);
      setCookie('token', data.data.token, { path: '/' });
    }
  };

  // 토큰 새로고침
  useQuery('refresh', () => refreshAPI(cookies.token), {
    refetchInterval: 3 * 60 * 1000 * 60,
    refetchIntervalInBackground: true,
    onSuccess: onLoginSuccess,
    refetchOnWindowFocus: false,
  });

  const moveTo = (url?: string) => {
    if (url) {
      navigate(url);
    }
  };

  const onLogout = () => {
    removeCookie('token');
    setDashboards([]);
    setUser(undefined);
    moveTo('/');
  };

  // 내 정보 수정
  const [selectedUser, setSelectedUser] = useState<IUser | undefined>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onOpenModifiyModal = () => {
    setModalOpen(true);
    setSelectedUser(user);
  };

  const onCloseModifiyModal = () => {
    setModalOpen(false);
    setSelectedUser(undefined);
  };

  const onSaveSuccess = (data: TUserCreateResponse) => {
    if (data.success) {
      toast.success(`${data.data?.userId} 유저 저장 완료`, {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
      });
      onCloseModifiyModal();
      setModalOpen(false);
    } else {
      toast.error(data.errMessage, {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
      });
    }
  };

  const userModifyMutation = useMutation(userModifyAPI, {
    onSuccess: onSaveSuccess,
  });

  const changeLoading = useLoading({ trigger: false });

  useEffect(() => {
    changeLoading({ loading: userModifyMutation.isLoading, message: '사용자 저장중...' });
  }, [userModifyMutation.isLoading]);

  const userSave = (request: TUserCreateRequest) => {
    if (request.id) {
      userModifyMutation.mutate(request);
    }
  };

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAlarmMenu = (event: MouseEvent<HTMLElement>) => {
    setAlarmAnchorEl(event.currentTarget);
  };

  const handleAlarmClose = () => {
    setAlarmAnchorEl(null);
  };

  const handleAlarmHistMore = () => {
    moveTo('/alarm/hist');
    handleAlarmClose();
  };

  const handleToggleColorMode = () => {
    setColorMode((prev) => (prev === 'light' ? 'dark' : 'light'));
  };

  const checkViewAuth = (authorStr: string[], page: RenderSideTree): RenderSideTree => {
    return {
      ...page,
      visible: authorStr.includes(page.auth ?? ''),
      children: page.children?.map((child) => checkViewAuth(authorStr, child)),
    };
  };

  const { data: alarmHists } = useQuery([], () => findAlarmHistPageAPI({ page: 0, size: 20 }), {
    refetchInterval: 1000 * 60,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  });

  const authPages = useMemo(() => {
    const authorStr = user?.authorities?.map((auth) => auth.author) ?? [];
    return PAGES.map((page) => checkViewAuth(authorStr, page));
  }, [user]);

  const alarmHistMenu = useMemo(
    () => (
      <Menu
        anchorEl={alarmAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleAlarmClose}
        open={Boolean(alarmAnchorEl)}
        keepMounted
        sx={{ maxHeight: 400 }}
      >
        {alarmHists?.data?.content?.map((hist) => (
          <MenuItem key={hist.id} onClick={handleAlarmHistMore}>
            <AlarmHistNoti data={hist} />
          </MenuItem>
        ))}
        {(alarmHists?.data?.content?.length || 0) > 10 && (
          <Button fullWidth color="error" onClick={handleAlarmHistMore}>
            더보기
          </Button>
        )}
      </Menu>
    ),
    [alarmAnchorEl, alarmHists],
  );

  const visible = useMemo(() => {
    const path = location.pathname;

    if (path.includes('fullscreen')) return false;

    return true;
  }, [location]);

  return visible ? (
    <>
      <AppBar position="fixed" color="inherit" sx={{ zIndex: 5 }}>
        <Toolbar variant="dense">
          <Box component="div" display="flex" justifyContent="center" onClick={() => moveTo('/')}>
            <Typography variant="h5" fontWeight="bold" sx={{ ml: -1 }}>
              {user?.site?.siteCode}
            </Typography>
          </Box>
          <Box component="div" sx={{ flexGrow: 1 }} />
          <Box component="div">
            <Moment format="YYYY/MM/DD hh:mm">{timeNow}</Moment>
            <IconButton size="large" color="inherit" onClick={handleAlarmMenu}>
              <Badge badgeContent={alarmHists?.data?.totalElements || undefined} color="error">
                <Notifications />
              </Badge>
            </IconButton>
            {alarmHistMenu}
            <IconButton size="large" color="inherit" onClick={handleMenu}>
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleClose}
              onClick={handleClose}
              open={Boolean(anchorEl)}
              keepMounted
            >
              <MenuItem onClick={onOpenModifiyModal}>내 정보 수정</MenuItem>
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
          </Box>
          <Typography>
            {user?.userId} | {user?.site?.siteName}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 1 }}>
            <LightModeRounded color="action" fontSize="small" />
            <Switch onChange={handleToggleColorMode} checked={colorMode === 'dark'} />
            <DarkModeRounded color="action" fontSize="small" />
          </Stack>
        </Toolbar>
      </AppBar>
      <AnimeCard
        sx={{
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          height: '100vh',
          top: 0,
          zIndex: 3,
          paddingTop: 6,
        }}
        style={{
          width: toggleStyle.width,
        }}
      >
        {toggleMenu ? <SideTreeMenu data={authPages} /> : <SideTreeIconMenu data={authPages} />}
        <Box
          component="div"
          flex={1}
          display="flex"
          justifyContent={toggleMenu ? 'flex-end' : 'center'}
          alignItems="flex-end"
          sx={{ m: 1 }}
        >
          <IconButton size="large" onClick={() => setToggleMenu((prev) => !prev)}>
            {toggleMenu ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </IconButton>
        </Box>
      </AnimeCard>
      <AnimeBox
        style={{
          marginLeft: toggleStyle.width,
          minHeight: '100vh',
        }}
        sx={{ paddingTop: 5 }}
      >
        {children}
      </AnimeBox>
      <ToastContainer />
      <UserSaveModal open={modalOpen} onClose={onCloseModifiyModal} onSave={userSave} data={selectedUser} isMyModify />
    </>
  ) : (
    <Box component="div" sx={{ width: '100vw', height: '100vh' }}>
      {children}
    </Box>
  );
}

const AnimeCard = animated(Card);

const AnimeBox = animated(Box);

export default Nav;
