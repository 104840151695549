import React, { useMemo } from 'react';
import BaseTree, { RenderBaseTree } from 'components/common/BaseTree';
import { SxProps, Theme } from '@mui/material';
import { ICode } from '@types';
import { flatten } from 'common/lib';

interface Props {
  data?: ICode[];
  containerStyle?: SxProps<Theme>;
  onClickItem?(code: ICode): void;
  showAddBtn?: boolean;
  onAddItem?(code: string): void;
}

CodeTree.defaultProps = {
  data: [],
  showAddBtn: true,
  onClickItem: undefined,
  onAddItem: undefined,
  containerStyle: {},
};

export default function CodeTree({ containerStyle, showAddBtn, onClickItem, onAddItem, data }: Props) {
  const createTree = (code: ICode): RenderBaseTree => {
    return {
      id: code.id.toString(),
      name: `${code.code} | ${code.codeName} | ${code.useYN ? 'Y' : 'N'}`,
      children: code.children.map((sub) => createTree(sub)),
    };
  };

  const onClick = (tree: RenderBaseTree) => {
    if (typeof onClickItem === 'function') {
      const selected = flatDatas.filter((code) => code.id.toString() === tree.id);

      if (selected && selected.length > 0) {
        onClickItem(selected[0]);
      }
    }
  };

  const onClickAdd = (parentCode: string) => {
    if (typeof onAddItem === 'function') {
      const selected = flatDatas.filter((code) => code.id.toString() === parentCode);

      if (selected && selected.length > 0) {
        onAddItem(selected[0].code);
      }
    }
  };

  const flatDatas = useMemo(() => flatten(data ?? []) as ICode[], [data]);

  const treeData: RenderBaseTree[] = useMemo(() => {
    return (data ?? []).map((tree) => createTree(tree));
  }, [data]);

  return (
    <BaseTree
      showAddBtn={showAddBtn}
      data={treeData}
      onClickItem={onClick}
      onClickAdd={onClickAdd}
      containerStyle={containerStyle}
    />
  );
}
