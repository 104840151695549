import { ENDPOINT } from '@const';
import {
  TAlarmHistListResponse,
  TAlarmHistPageRequest,
  TAlarmHistPageResponse,
  TAlarmHistResponse,
  TAlarmHistStatusCountResponse,
  TAlarmHistTaskBatchRequest,
} from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const findMySiteAlarmHists = async (): Promise<TAlarmHistListResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.alarmHist.findMySiteAlarmHists, ...createAuth() })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('사이트 알람 이력 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const findAlarmHistPageAPI = async (request: TAlarmHistPageRequest): Promise<TAlarmHistPageResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.alarmHist.findPages, ...createAuth(), data: request })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('알람 이력 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const findAlarmHistStatusCountAPI = async (
  request: TAlarmHistPageRequest,
): Promise<TAlarmHistStatusCountResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.alarmHist.findStatusCount,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('알람 상태 이력 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteAlarmHistAPI = async (id: number): Promise<TAlarmHistResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.alarmHist.deleteAlarm,
      url: ENDPOINT.alarmHist.deleteAlarm.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('알람 이력 삭제 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const createAlarmHistTaskAPI = async (request: TAlarmHistTaskBatchRequest): Promise<TAlarmHistPageResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.alarmHist.alarmHistCreateTask,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('작업 이력 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};
