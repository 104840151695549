import { filterGroupFieldData, getFileURL } from 'common/lib';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { flatGroupState, threeModelState } from 'states';
import { IMapThreeInfo, IThreeModel } from '@types';

type Props = {
  groups?: number[];
};

export default function useGroupThreeInfo({ groups = [] }: Props) {
  const flatGroups = useRecoilValue(flatGroupState);
  const threeModels = useRecoilValue(threeModelState);

  const groupDatas = useMemo(() => _.filter(flatGroups, (gr) => groups.includes(gr.id)), [flatGroups]);

  const threeInfo = useMemo((): IMapThreeInfo[] => {
    return groupDatas
      .map((gr): IMapThreeInfo | undefined => {
        const threeNumber = filterGroupFieldData(gr, 'FIELD_3D').at(-1);
        const gps = filterGroupFieldData(gr, 'FIELD_GPS').at(-1);

        if (!threeNumber && !gps) return undefined;

        const three = _.find(threeModels, (model) => model.id === Number(threeNumber?.data));
        const gpsInfo = JSON.parse(gps?.data ?? '{}');

        if (three === undefined) return undefined;

        return {
          data: {
            location: gpsInfo,
            group: gr.id,
            three: three as IThreeModel,
          },
          file: three?.file,
        };
      })
      .filter((gd) => gd !== undefined) as IMapThreeInfo[];
  }, [groupDatas]);

  return threeInfo;
}
