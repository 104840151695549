import React, { useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, TablePagination, Typography, useTheme } from '@mui/material';
import { IData10, TData1ChartResponse, TDataSet } from '@types';
import * as xlsx from 'xlsx';

interface Column {
  id: keyof IDataGridData;
  label: string;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
}

export interface IDataGridData {
  id: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  dtt: string;
  tagName: string;
  aggType: string;
  tag: string;
  value: number;
}

function createData(dataset: TDataSet, data: IData10): IDataGridData {
  return {
    id: `${data.dtt}_${data.tag}`,
    tagName: `${dataset.tag.tagName}${dataset.type === 'WON' ? '-원단위' : ''}`,
    aggType: dataset.aggType ?? '',
    ...data,
  };
}

const columns: readonly Column[] = [
  { id: 'year', label: '년' },
  { id: 'month', label: '월' },
  {
    id: 'day',
    label: '일',
  },
  { id: 'hour', label: '시간' },
  {
    id: 'minute',
    label: '분',
  },
  {
    id: 'second',
    label: '초',
  },
  {
    id: 'tag',
    label: '태그',
  },
  {
    id: 'tagName',
    label: '태그이름',
  },
  {
    id: 'aggType',
    label: '데이터유형',
  },
  {
    id: 'value',
    label: '값',
  },
];

type Props = {
  data?: TData1ChartResponse;
};

DataGrid.defaultProps = {
  data: [],
};

export default function DataGrid({ data }: Props) {
  const theme = useTheme();
  const [state, setState] = useState<{ page: number; rowsPerPage: number }>({ page: 0, rowsPerPage: 100 });
  const rows = useMemo(
    () => data?.data?.datasets?.map((dataset) => dataset.data.map((data) => createData(dataset, data))).flat(),
    [data],
  );

  const filteredRows = useMemo(
    () => rows?.slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage),
    [rows, state],
  );

  const onSave = () => {
    const ws = xlsx.utils.json_to_sheet(rows || []);
    const wb = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, `excel_download.xlsx`);
  };

  const renderCell = (row: any, column: Column) => {
    const value = row[column.id];
    const newValue = column.format && typeof value === 'number' ? column.format(value) : value;

    if (column.id === 'id') return undefined;

    return newValue;
  };

  const handleChangePage = (e: unknown, changePage: number) => {
    setState((prev) => ({ ...prev, page: changePage }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rowsPerPage = +event.target.value;
    setState((prev) => ({ ...prev, rowsPerPage }));
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 450, minHeight: 450 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    backgroundColor: theme.palette.mode === 'light' ? '#f7fafc !important' : '#0f1018 !important',
                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows?.map((row) => {
              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.id} align={column.align} sx={{ paddingY: 0 }}>
                        {renderCell(row, column)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[100, 250, 500]}
        showFirstButton
        showLastButton
        count={rows?.length ?? 0}
        rowsPerPage={state.rowsPerPage}
        page={state.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Box component="div" display="flex" justifyContent="flex-end" sx={{ p: 1 }}>
        <Button onClick={onSave}>
          <Typography>Excel Download</Typography>
        </Button>
      </Box>
    </Paper>
  );
}
