import { Delete } from '@mui/icons-material';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import ColorPickerInput from 'components/common/ColorPickerInput';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { TGaugeLevel } from './GaugeChart';

type Props = {
  defaultValue?: TGaugeLevel[];
  onChange?(value: TGaugeLevel[]): void;
};

GaugeLevelForm.defaultProps = {
  defaultValue: [],
  onChange: undefined,
};

export default function GaugeLevelForm({ defaultValue, onChange }: Props) {
  const [state, setState] = useState<TGaugeLevel[]>(defaultValue ?? []);

  const onAdd = () => {
    setState((prev) => {
      const newState = [...prev, { id: moment().format('YYYYMMDDHHmmsss'), value: 0, color: '#fff' }];

      if (typeof onChange === 'function') {
        onChange(newState);
      }

      return newState;
    });
  };

  const onDelete = (id: string) => {
    setState((prev) => {
      const newState = prev.filter((p) => p.id !== id);

      if (typeof onChange === 'function') {
        onChange(newState);
      }

      return newState;
    });
  };

  const onLevelChange = (id: string, name: string, value?: string | number) => {
    setState((prev) => {
      const newState = prev.map((p) => (p.id === id ? { ...p, [name]: value } : p));

      if (typeof onChange === 'function') {
        onChange(newState);
      }

      return newState;
    });
  };

  const renderGaugeLevel = (level: TGaugeLevel) => (
    <Box key={level.id || ''} component="div" display="flex" alignItems="center">
      <TextField
        required
        variant="standard"
        fullWidth
        sx={{ marginX: 1 }}
        type="number"
        label="게이지값"
        onChange={(e) => onLevelChange(level.id, 'value', e.target.value)}
        defaultValue={level.value ?? ''}
      />
      <ColorPickerInput
        variant="standard"
        fullWidth
        sx={{ marginX: 1 }}
        label="게이지 색상"
        required
        onChange={(color) => onLevelChange(level.id, 'color', color)}
        defaultValue={level.color || ''}
      />
      <TextField
        variant="standard"
        fullWidth
        sx={{ marginX: 1 }}
        label="게이지 라벨"
        onChange={(e) => onLevelChange(level.id, 'labelText', e.target.value)}
        defaultValue={level.labelText || ''}
      />
      <ColorPickerInput
        variant="standard"
        fullWidth
        sx={{ marginX: 1 }}
        label="라벨 색상"
        onChange={(color) => onLevelChange(level.id, 'labelColor', color)}
        defaultValue={level.labelColor || ''}
      />
      <IconButton size="small" onClick={() => onDelete(level.id || '')}>
        <Delete fontSize="small" />
      </IconButton>
    </Box>
  );

  const memoedLevels = useMemo(() => state.map((level) => renderGaugeLevel(level)), [state]);

  return (
    <Box component="div">
      <Button fullWidth onClick={onAdd}>
        <Typography>게이지 추가</Typography>
      </Button>
      {memoedLevels}
    </Box>
  );
}
