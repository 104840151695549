import { ENDPOINT } from '@const';
import { TNodeSystemCreateRequest, TNodeSystemListResponse, TNodeSystemResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const createNodeSystemAPI = async (request: TNodeSystemCreateRequest): Promise<TNodeSystemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.nodesystem.createNodeSystem,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('계통유형 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyNodeSystemAPI = async (request: TNodeSystemCreateRequest): Promise<TNodeSystemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.nodesystem.modifyNodeSystem,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('계통유형 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteNodeSystemAPI = async (id: number): Promise<TNodeSystemResponse> => {
  const result = await axios
    .request({
      method: ENDPOINT.nodesystem.deleteNodeSystem.method,
      url: ENDPOINT.nodesystem.deleteNodeSystem.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('계통유형 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const findAllNodeSystemAPI = async (): Promise<TNodeSystemListResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.nodesystem.findAllNodeSystem,
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('계통유형 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};
