import { Box, Typography } from '@mui/material';
import { IGroup } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import React, { useEffect, useState } from 'react';
import GroupTree from './GroupTree';

type Props = Omit<IBaseModalProps, 'onConfirm'> & {
  onConfirm?(groups: IGroup[]): void;
  data?: IGroup[];
  singleSelect?: boolean;
};

GroupSelectModal.defaultProps = {
  onConfirm: undefined,
  data: [],
  singleSelect: false,
};

export default function GroupSelectModal({ onConfirm, data, open, singleSelect, ...others }: Props) {
  const [selectedGroups, setSelectedGroups] = useState<IGroup[]>([]);

  const handleChecked = (group: IGroup, checked: boolean) => {
    if (checked) {
      setSelectedGroups((prev) => [...prev, group]);
    } else {
      setSelectedGroups((prev) => prev.filter((p) => p.id !== group.id));
    }
  };

  const handleConfirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm(selectedGroups);
    }
  };

  useEffect(() => {
    if (!open) {
      setSelectedGroups([]);
    }
  }, [open]);

  return (
    <BaseModal
      {...others}
      open={open}
      title="그룹 선택"
      confirmTitle="추가"
      onConfirm={handleConfirm}
      disableConfirm={singleSelect && selectedGroups.length > 1}
    >
      <Box component="div" sx={{ width: 600, height: 530 }}>
        <GroupTree
          data={data}
          showCheckBtn
          showAddBtn={false}
          containerStyle={{ height: 470 }}
          onChecked={handleChecked}
        />
        {singleSelect && selectedGroups.length > 1 && <Typography color="red">하나의 그룹만 선택해주세요.</Typography>}
      </Box>
    </BaseModal>
  );
}
