import { ENDPOINT } from '@const';
import { TThreemodelCreateRequest, TThreeModelListResponse, TThreeModelResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, createFormData, toastErrorMessage } from 'common/lib';

export const createThreeAPI = async (request: TThreemodelCreateRequest): Promise<TThreeModelResponse> => {
  const frm = createFormData(request);

  const result = await axios
    .request({
      ...ENDPOINT.threeModel.createThree,
      headers: {
        ...createAuth().headers,
        'Content-Type': 'multipart/form-data',
      },
      data: frm,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 모델 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyThreeAPI = async (request: TThreemodelCreateRequest): Promise<TThreeModelResponse> => {
  const frm = createFormData(request);

  const result = await axios
    .request({
      ...ENDPOINT.threeModel.modifyThree,
      headers: {
        ...createAuth().headers,
        'Content-Type': 'multipart/form-data',
      },
      data: frm,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 모델 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteThreeAPI = async (id: number): Promise<TThreeModelResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.threeModel.deleteThree,
      url: ENDPOINT.threeModel.deleteThree.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 모델 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const findAllThreeAPI = async (): Promise<TThreeModelListResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.threeModel.findAllThrees,
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 모델 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};
