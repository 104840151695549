import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';

export default function ThreeModelErrorComponent({ resetErrorBoundary }: FallbackProps) {
  return (
    <Box component="div" sx={{ p: 1 }}>
      <p>잘못된 3D 모델 정보입니다.</p>
      <p>모델을 불러오지 못했습니다.</p>
      <Button onClick={resetErrorBoundary} variant="contained" fullWidth>
        <Typography>새로고침</Typography>
      </Button>
    </Box>
  );
}
