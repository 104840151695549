import { Box, SxProps, Theme, Typography } from '@mui/material';
import { IAlarm } from '@types';
import useLastTagData from 'common/hooks/useLastTagData';
import { getPrevNameByDateProp } from 'common/lib';
import AlertCard from 'components/alarm/AlertCard';
import { DateSearchProps } from 'components/common/DateSearchBox';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { animated, easings, useSpring } from 'react-spring';

type Props = {
  title?: string;
  tags?: string[];
  alarm?: IAlarm;
  sx?: SxProps<Theme>;
  fixedNumber?: number;
  showTagName?: boolean;
  showTagDesc?: boolean;
  showDtt?: boolean;
  showAlert?: boolean;
  dateProps?: DateSearchProps;
  isIncrease?: boolean;
  valueTypoVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  unitTypoVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  cardVariant?: 'outlined' | 'elevation';
  showPrev?: boolean;
  prevColorReverse?: boolean;
  aggFunc?: string;
};

TagDataCard.defaultProps = {
  title: undefined,
  tags: [],
  alarm: undefined,
  sx: undefined,
  showTagName: false,
  showTagDesc: false,
  showDtt: true,
  showAlert: false,
  fixedNumber: 2,
  dateProps: undefined,
  isIncrease: undefined,
  valueTypoVariant: 'h4',
  unitTypoVariant: 'h6',
  cardVariant: 'elevation',
  showPrev: false,
  prevColorReverse: false,
  aggFunc: undefined,
};

export default function TagDataCard({
  title,
  showTagName,
  showTagDesc,
  valueTypoVariant,
  unitTypoVariant,
  tags,
  alarm,
  sx,
  dateProps,
  isIncrease,
  fixedNumber,
  cardVariant,
  showDtt,
  showPrev,
  showAlert,
  prevColorReverse,
  aggFunc,
}: Props) {
  const dataset = useLastTagData({
    tagTypes: tags?.map((tag) => ({ tag, aggFunc: aggFunc ?? '' })),
    dateProps,
    isIncrease,
    refetchOnFocus: false,
  });

  const prevDataset = useLastTagData({
    tagTypes: showPrev ? tags?.map((tag) => ({ tag, aggFunc: aggFunc ?? '' })) : [],
    dateProps,
    isIncrease,
    refetchOnFocus: false,
    isPrev: showPrev,
  });

  const value = useMemo(() => dataset?.at(0)?.data.at(0)?.value, [dataset]);
  const prevValue = useMemo(() => prevDataset?.at(0)?.data.at(0)?.value, [prevDataset]);
  const dtt = useMemo(() => dataset?.at(0)?.data.at(0)?.dtt, [dataset]);

  const codeName = useMemo(() => dataset?.at(0)?.tag.tagUnit.codeName, [dataset]);

  const [animeEnd, setAnimeEnd] = useState<boolean>(false);
  const [stateValue, setStateValue] = useState<number>(0);

  // 숫자 애니메이션의 toFixed가, 같은값으로 업데이트되는 경우( 15 -> 15 ) 동작하지않는 이슈로 인해 수정
  const anime = useSpring({
    value: stateValue,
    config: {
      easing: easings.easeOutCubic,
      duration: 2000,
    },
    onStart: () => {
      setAnimeEnd(false);
    },
    onRest: () => {
      setAnimeEnd(true);
    },
    onPause: () => {
      setAnimeEnd(true);
    },
  });

  // 숫자 애니메이션의 toFixed가, 같은값으로 업데이트되는 경우( 15 -> 15 ) 동작하지않는 이슈로 인해 수정
  const memoedAnimeValue = useMemo(() => {
    if (stateValue === 0)
      return (0).toLocaleString(undefined, { minimumFractionDigits: fixedNumber, maximumFractionDigits: fixedNumber });

    if (animeEnd)
      return stateValue.toLocaleString(undefined, {
        minimumFractionDigits: fixedNumber,
        maximumFractionDigits: fixedNumber,
      });

    return anime.value.to((x) =>
      x.toLocaleString(undefined, { minimumFractionDigits: fixedNumber, maximumFractionDigits: fixedNumber }),
    );
  }, [anime, animeEnd, stateValue, fixedNumber]);

  const prevDiffValue = useMemo(() => {
    const diff = (value ?? 0) - (prevValue ?? 0);
    const preFix = diff > 0 ? '+' : '';
    const dateName = getPrevNameByDateProp(dateProps, '', ' 대비');
    let color = diff > 0 ? 'green' : 'red';

    if (prevColorReverse) color = diff > 0 ? 'red' : 'green';

    return (
      <Typography variant="caption" sx={{ marginTop: -0.5 }} color={color}>
        {dateName} {preFix}
        {diff.toLocaleString(undefined, {
          minimumFractionDigits: fixedNumber,
          maximumFractionDigits: fixedNumber,
        })}
      </Typography>
    );
  }, [prevColorReverse, codeName, dateProps, fixedNumber, prevValue, value]);

  useEffect(() => {
    setStateValue(value ?? 0);
  }, [value]);

  return (
    <>
      {title && <Typography variant="caption">{title}</Typography>}
      {showTagName && <Typography variant="caption">{dataset?.at(0)?.tag.tagName}</Typography>}
      {showTagDesc && <Typography variant="caption">{dataset?.at(0)?.tag.tagDesc}</Typography>}
      <AlertCard
        alarm={alarm}
        value={value}
        dtt={moment(dtt)}
        variant={cardVariant}
        sx={{ position: 'relative', display: 'flex', justifyContent: 'center', ...sx }}
        showAlert={showAlert}
      >
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          sx={{ height: '100%' }}
          justifyContent="center"
          alignItems="center"
        >
          {showDtt && (
            <Typography variant="caption" sx={{ marginBottom: -1 }}>
              {dtt}
            </Typography>
          )}
          <Box component="div" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <AnimeTypo variant={valueTypoVariant} fontWeight="bold">
              {memoedAnimeValue}
            </AnimeTypo>
            <Typography variant={unitTypoVariant} fontWeight="bold" sx={{ ml: 1 }}>
              {codeName}
            </Typography>
          </Box>
          {showPrev && prevDiffValue}
        </Box>
      </AlertCard>
    </>
  );
}

const AnimeTypo = animated(Typography);
