import React, { useEffect, useMemo, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { INodeTagData, ITag } from '@types';
import useBaseModalControl from 'common/hooks/useBaseModalControl';
import TagSearchModal from 'components/tag/TagSearchModal';
import _ from 'lodash';
import MultiTagData1ChartCard from 'components/data/MultiTagData1ChartCard';
import { NodeSearchFormType } from 'components/reactflow/BaseNodeType';

type Props = {
  defaultData?: INodeTagData[];
  onChange?(data: INodeTagData[]): void;
  readonly?: boolean;
  nodeSearchForm?: NodeSearchFormType;
};

NodeTagForm.defaultProps = {
  defaultData: [],
  onChange: undefined,
  readonly: false,
  nodeSearchForm: undefined,
};

export default function NodeTagForm({ defaultData, onChange, readonly, nodeSearchForm }: Props) {
  const { open, onClose, onOpen } = useBaseModalControl();

  const [tagDatas, setTagDatas] = useState<INodeTagData[]>([]);

  // Tag 라벨 데이터 변경 이벤트
  const handleChangeTagLabel = (tag: string, label: string) => {
    setTagDatas((prev) => {
      const newData = prev.map((p) => (p.tag === tag ? { ...p, label } : p));
      handleChangeData(newData);
      return newData;
    });
  };

  // 태그 삭제 이벤트
  const handleDeleteTag = (tag: string) => {
    setTagDatas((prev) => {
      const newData = prev.filter((p) => p.tag !== tag);
      handleChangeData(newData);
      return newData;
    });
  };

  // 태그 추가 이벤트
  const handleConfirm = (tags: ITag[]) => {
    const prevData = tagDatas;
    const currentData: INodeTagData[] = tags.map((tag) => ({ tag: tag.tagCode, label: tag.tagName }));
    const newData = _.uniqBy([...prevData, ...currentData], 'tag');

    setTagDatas(newData);
    handleChangeData(newData);

    onClose();
  };

  // 데이터 변경 이벤트 -> 부모 컴포넌트에 전달
  const handleChangeData = (data: INodeTagData[]) => {
    if (typeof onChange === 'function') {
      onChange(data);
    }
  };

  // 태그데이터 차트 조회
  const renderTagDataChart = useMemo(() => {
    if (!readonly) return [];

    return tagDatas.map((data) => (
      <MultiTagData1ChartCard
        id={data.tag}
        key={data.tag}
        showDeleteBtn={false}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        chartWidth="100%"
        chartHeight={150}
        height={150}
        tagStr={[data.tag]}
        duration="1h"
        gridSx={{ mt: 1 }}
        nodeSearchForm={nodeSearchForm}
      />
    ));
  }, [readonly, tagDatas, nodeSearchForm]);

  // 디폴트 데이터 세팅
  useEffect(() => {
    if (defaultData) {
      setTagDatas(defaultData);
    }
  }, [defaultData]);

  useEffect(() => {
    console.log(nodeSearchForm);
  }, [nodeSearchForm]);

  return (
    <Box component="div">
      {!readonly && (
        <>
          <Button fullWidth variant="contained" onClick={onOpen} sx={{ marginY: 1 }}>
            <Typography>태그 추가</Typography>
          </Button>
          <Box component="div" display="flex" flexDirection="column" sx={{ maxHeight: 200, overflowY: 'auto' }}>
            {tagDatas.map((tagData) => (
              <Box key={tagData.tag} component="div" display="flex" alignItems="center" sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  name="label"
                  label="Label"
                  variant="standard"
                  value={tagData.label}
                  InputLabelProps={{ shrink: true }}
                  sx={{ mr: 1 }}
                  onChange={(e) => handleChangeTagLabel(tagData.tag, e.target.value)}
                />
                <TextField
                  fullWidth
                  name="tag"
                  label="Tag"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: true }}
                  value={tagData.tag}
                  sx={{ ml: 1 }}
                />
                <IconButton onClick={() => handleDeleteTag(tagData.tag)}>
                  <Delete />
                </IconButton>
              </Box>
            ))}
          </Box>
          <TagSearchModal open={open} onConfirm={handleConfirm} onClose={onClose} />
        </>
      )}

      {renderTagDataChart}
    </Box>
  );
}
