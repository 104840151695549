import { useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import ReactSpeedometer, { CustomSegmentLabelPosition, Props, Transition } from 'react-d3-speedometer';

export type TGaugeLevel = {
  id: string;
  value: number;
  color: string;
  labelColor?: string;
  labelText?: string;
};

export type GuageChartProps = Props & {
  levels: TGaugeLevel[];
  data?: number;
  unit?: string;
};

GuageChart.defaultProps = {
  data: 0,
  unit: '',
};

export default function GuageChart({ data, levels, unit, ...others }: GuageChartProps) {
  const theme = useTheme();
  const textColor = useMemo(() => (theme.palette.mode === 'dark' ? '#fff' : '#333'), [theme]);

  return (
    <ReactSpeedometer
      fluidWidth
      minValue={Number(levels?.at(0)?.value ?? 0)}
      maxValue={Number(levels?.at(-1)?.value ?? 0)}
      value={data}
      segments={levels.length}
      needleColor="steelblue"
      needleHeightRatio={0.6}
      paddingHorizontal={2}
      paddingVertical={-5}
      customSegmentLabels={levels.slice(1).map((level) => ({
        text: level.labelText || level.value.toString(),
        position: CustomSegmentLabelPosition.Inside,
        color: level.labelColor,
      }))}
      textColor={textColor}
      segmentColors={levels.slice(1).map((level) => level.color)}
      customSegmentStops={[...levels.map((level) => Number(level.value))]}
      currentValuePlaceholderStyle="#{value}"
      currentValueText={`#{value} ${unit}`}
      valueFormat=","
      needleTransition={Transition.easeBounceOut}
      needleTransitionDuration={1000}
      forceRender
      {...others}
    />
  );
}
