import { IDashboard, IDashboardItem } from '@types';
import DashboardItemSaveModal from 'components/dashboard/DashboardItemSaveModal';
import React, { useMemo, useState } from 'react';

type Props = {
  dashboard?: IDashboard;
  reload?(): void;
};

const useDashboardItemModal = ({ dashboard, reload }: Props) => {
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDashboardItem(undefined);
  };

  const handleEdit = (item: IDashboardItem) => {
    setDashboardItem(item);
    handleOpen();
  };

  const handleSave = () => {
    handleClose();
    if (typeof reload === 'function') {
      reload();
    }
  };

  const [open, setOpen] = useState<boolean>(false);
  const [dashboardItem, setDashboardItem] = useState<IDashboardItem | undefined>(undefined);

  const modal = useMemo(
    () => (
      <DashboardItemSaveModal
        open={open}
        data={dashboardItem}
        dashboard={dashboard}
        onClose={handleClose}
        onSaveComplete={handleSave}
      />
    ),
    [open, dashboardItem, dashboard],
  );

  return {
    modal,
    handleOpen,
    handleClose,
    handleEdit,
  };
};

export default useDashboardItemModal;
