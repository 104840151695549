import React, { ChangeEvent, useMemo, useState } from 'react';
import { Box, Button, Card, Chip, Fab, FormControl, FormLabel, SelectChangeEvent, Typography } from '@mui/material';
import { ChartType, ITag } from '@types';
import { Data10ChartCardProps } from 'components/data/Data10ChartCard';
import TagSearchModal from 'components/tag/TagSearchModal';
import moment from 'moment';
import { DataLayout } from 'layout/data';
import { Save } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { customConfigState } from 'states';
import { toast } from 'react-toastify';
import DateSearchBox, { DateSearchProps } from 'components/common/DateSearchBox';
import { AggUnitType, Data10AggType } from 'common/hooks/useTagChartData10';
import ChartTypeSelectBox from 'components/data/ChartTypeSelectBox';
import AggTypeSelectBox from 'components/data/AggTypeSelectBox';
import BaseCheckbox from 'components/common/BaseCheckbox';
import { useChart10Card } from 'common/hooks/useChart10Card';

export default function DataTagPage() {
  // 태그 검색창 오픈 이벤트
  const handleOpenTagSearchModal = () => {
    setOpenTagSearchModal(true);
  };
  // 태그 검색창 닫기 이벤트
  const handleCloseTagSearchModal = () => {
    setOpenTagSearchModal(false);
  };
  // 태그 검색창 검색결과 전달받기
  const handleTagSearch = (tags: ITag[]) => {
    setSelectedTags((prev) => {
      const newArr = new Set([...prev, ...tags]);
      return Array.from(newArr);
    });

    handleCloseTagSearchModal();
  };
  // 태그 Chip 삭제
  const handleDeleteTag = (tagCode: string) => {
    setSelectedTags((prev) => prev.filter((ptag) => ptag.tagCode !== tagCode));
  };
  // 날짜 조회 정보 변경
  const handleDateChange = (data: DateSearchProps) => {
    setDateState(data);
  };
  // 검색폼 - 차트 유형 변경
  const handleChartTypeChange = (e: SelectChangeEvent) => {
    const { value: chartType } = e.target;
    setSearchState((prev) => ({ ...prev, chartType: chartType as ChartType }));
  };
  // 검색폼 - 차트 취합 유형 변경
  const handleAggType = (e: SelectChangeEvent) => {
    const { value: aggType } = e.target;
    setSearchState((prev) => ({ ...prev, aggType: aggType as Data10AggType }));
  };
  // 검색폭 - 적산값처리 변경
  const handleIsIncrease = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchState((prev) => ({ ...prev, isIncrease: !prev.isIncrease }));
  };
  // 차트추가
  const handleAddChart = (card: Data10ChartCardProps[]) => {
    addCard(card);
    setSelectedTags([]);
  };

  // ChartCard 병합 추가
  const handleAddMergedChartCard = () => {
    const newChartCards: Data10ChartCardProps = {
      id: `${selectedTags.map((tag) => tag.tagCode)}_${moment().format('x')}`,
      tags: selectedTags.map((tag) => tag.tagCode),
      height: 380,
      chartHeight: 380,
      dateProps: { ...dateState },
      aggType: searchState.aggType,
      isIncrease: searchState.isIncrease,
      aggUnit: searchState.aggUnit,
      aggVal: searchState.aggVal,
      chartType: searchState.chartType,
      useGridRowData: false,
      lg: 12,
      xl: 12,
    };

    handleAddChart([newChartCards]);
  };

  // ChartCard 개별 추가
  const handleAddIndividualChart = () => {
    const newChartCards: Data10ChartCardProps[] = selectedTags.map((tag, index) => ({
      id: `${tag}_${index}_${moment().format('x')}`,
      tags: [tag.tagCode],
      height: 200,
      chartHeight: 200,
      dateProps: { ...dateState },
      aggType: searchState.aggType,
      isIncrease: searchState.isIncrease,
      aggUnit: searchState.aggUnit,
      aggVal: searchState.aggVal,
      chartType: searchState.chartType,
      useGridRowData: false,
    }));

    handleAddChart(newChartCards);
  };

  // CustomConfig 설정 저장
  const handleSaveConfig = () => {
    setCustomConfig((prev) => ({ ...prev, dataTagConfig: getCardData() }));
    toast('차트 설정 저장 완료', { type: 'success' });
  };

  const [openTagSearchModal, setOpenTagSearchModal] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  // 검색 - 날짜유형
  const [dateState, setDateState] = useState<DateSearchProps>({
    type: 'day',
    duration: 1,
    durationUnit: 'day',
    start: moment(),
    end: moment(),
  });
  // 검색 - 차트유형
  const [searchState, setSearchState] = useState<{
    chartType: ChartType;
    aggType: Data10AggType;
    aggUnit: AggUnitType;
    aggVal: number;
    isIncrease: boolean;
  }>({
    chartType: 'line',
    aggType: 'mean',
    aggUnit: 'minute',
    aggVal: 15,
    isIncrease: false,
  });

  const [customConfig, setCustomConfig] = useRecoilState(customConfigState);
  const { cards, addCard, getCardData } = useChart10Card({ defaultValue: customConfig.dataTagConfig });

  const renderTagChips = useMemo(
    () =>
      selectedTags.map((tag) => (
        <Chip key={tag.tagCode} sx={{ m: 0.5 }} label={tag.tagCode} onDelete={() => handleDeleteTag(tag.tagCode)} />
      )),
    [selectedTags],
  );

  const memoedSearchBox = useMemo(
    () => (
      <Box component="div" sx={{ p: 2 }}>
        <Card variant="outlined" sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
          <FormControl sx={{ mb: 1 }}>
            <FormLabel>태그 목록</FormLabel>
            <Box component="div" sx={{ display: 'flex', flexWrap: 'wrap', minHeight: 25 }}>
              {renderTagChips}
            </Box>
            <Button fullWidth variant="contained" color="info" onClick={handleOpenTagSearchModal}>
              <Typography>태그 검색</Typography>
            </Button>
          </FormControl>
          <Box component="div" sx={{ my: 1 }}>
            <DateSearchBox {...dateState} onChange={handleDateChange} />
          </Box>
          <Box component="div" display="flex" sx={{ my: 1 }}>
            <ChartTypeSelectBox
              name="chartType"
              sx={{ minWidth: 100, mr: 2 }}
              fullWidth={false}
              value={searchState.chartType}
              onChange={handleChartTypeChange}
            />
            <AggTypeSelectBox
              name="aggType"
              sx={{ minWidth: 100, mr: 2 }}
              fullWidth={false}
              value={searchState.aggType}
              onChange={handleAggType}
            />
            <BaseCheckbox
              name="isIncrease"
              label="적산값 처리"
              checked={searchState.isIncrease}
              onChange={handleIsIncrease}
            />
          </Box>
          <Box component="div" display="flex">
            <Button
              fullWidth
              disabled={selectedTags.length < 1}
              onClick={handleAddIndividualChart}
              variant="contained"
              sx={{ mr: 1 }}
            >
              <Typography>개별 차트추가</Typography>
            </Button>
            <Button
              fullWidth
              disabled={selectedTags.length < 1}
              onClick={handleAddMergedChartCard}
              variant="contained"
              sx={{ ml: 1 }}
            >
              <Typography>병합 차트추가</Typography>
            </Button>
          </Box>
        </Card>
      </Box>
    ),
    [dateState, searchState, renderTagChips],
  );

  return (
    <DataLayout title="태그별 데이터 조회">
      <Box component="div">
        {memoedSearchBox}
        {cards}
        {/* {menoedChartCards} */}
      </Box>
      <TagSearchModal open={openTagSearchModal} onClose={handleCloseTagSearchModal} onConfirm={handleTagSearch} />
      <Fab sx={{ position: 'fixed', right: 10, bottom: 10 }} color="success" size="small" onClick={handleSaveConfig}>
        <Save />
      </Fab>
    </DataLayout>
  );
}
