import { Card, SxProps, Theme } from '@mui/material';
import { PieType } from '@types';
import useLastTagData from 'common/hooks/useLastTagData';
import { DateSearchProps } from 'components/common/DateSearchBox';
import React, { useMemo } from 'react';
import PieChart from './PieChart';

type Props = {
  type?: PieType;
  name: string;
  dateProps: DateSearchProps;
  isIncrease?: boolean;
  tags: string[];
  cardSx?: SxProps<Theme>;
};

TagDataPieChartCard.defaultProps = {
  type: undefined,
  isIncrease: false,
  cardSx: {},
};

export default function TagDataPieChartCard({ type, name, cardSx, dateProps, tags, isIncrease }: Props) {
  const datasets = useLastTagData({ dateProps, tagTypes: tags.map((tag) => ({ tag, aggFunc: 'mean' })), isIncrease });
  const labels = useMemo(() => datasets?.map((dt) => `${dt.tag.tagName} ${dt.tag.tagUnit.codeName}`), [datasets]);
  const data = useMemo(() => datasets?.map((dt) => dt.data.at(0)?.value.toFixed(2)), [datasets]);

  return (
    <Card sx={cardSx}>
      <PieChart type={type} labels={labels || []} data={data || []} />
    </Card>
  );
}
