import { ENDPOINT } from '@const';
import {
  TMatterportCreateRequest,
  TMatterportListResponse,
  TMatterportDeleteResponse,
  TMatterportResponse,
  TMatterportItemListResponse,
  TMatterportItemResponse,
  TMatterportItemCreateRequest,
  IResponse,
} from '@types';
import axios from 'axios';
import { checkError, createAuth, createFormData, toastErrorMessage } from 'common/lib';

/**
 * 메타포트 조회
 */
export const findAllMatterportsAPI = async (): Promise<TMatterportListResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.matterport.findAllMatterports, ...createAuth() })
    .catch((e) => checkError(e));

  if (!result) throw new Error('메타포트 조회 실패');
  toastErrorMessage(result.data);

  return result.data;
};

/**
 * 메타포트 저장
 */
export const saveMatterportAPI = async (request: TMatterportCreateRequest): Promise<TMatterportResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.matterport.saveMatterport, ...createAuth(), data: createFormData(request) })
    .catch((e) => checkError(e));

  if (!result) throw new Error('메타포트 저장 실패');
  toastErrorMessage(result.data);

  return result.data;
};

/**
 * 메타포트 삭제
 */
export const deleteMatterportAPI = async (deleteIdx: number): Promise<TMatterportDeleteResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.matterport.deleteMatterport,
      ...createAuth(),
      url: ENDPOINT.matterport.deleteMatterport.url.replace('{idx}', deleteIdx.toString()),
    })
    .catch((e) => checkError(e));

  if (!result) throw new Error('메타포트 삭제 실패');
  toastErrorMessage(result.data);

  return result.data;
};

/**
 * 메타포트 아이템 조회
 */
export const findAllMatterportItemsAPI = async (matterportIdx: number): Promise<TMatterportItemListResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.matterport.findAllMatterportItem,
      ...createAuth(),
      url: ENDPOINT.matterport.findAllMatterportItem.url.replace('{matterport}', matterportIdx.toString()),
    })
    .catch((e) => checkError(e));

  if (!result) throw new Error('메타포트 아이템 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};

/**
 * 메타포트 아이템 저장
 */
export const saveMatterportItemAPI = async (
  request: TMatterportItemCreateRequest,
): Promise<TMatterportItemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.matterport.saveMatterportItem,
      ...createAuth(),
      data: { ...request, data: request.data ? JSON.stringify(request.data) : undefined },
    })
    .catch((e) => checkError(e));

  if (!result) throw new Error('메타포트 아이템 저장 실패');
  toastErrorMessage(result.data);

  return result.data;
};

/**
 * 메타포트 아이템 삭제
 */
export const deleteMatterportItemAPI = async (idx: number): Promise<IResponse<any>> => {
  const result = await axios
    .request({
      ...ENDPOINT.matterport.deleteMatterportItem,
      ...createAuth(),
      url: ENDPOINT.matterport.deleteMatterportItem.url.replace('{idx}', idx.toString()),
    })
    .catch((e) => checkError(e));

  if (!result) throw new Error('메타포트 아이템 저장실패');

  toastErrorMessage(result.data);

  return result.data;
};
