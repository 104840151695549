import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { IMatterportItem } from '@types';
import React, { useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import MatterportTagNavigationListItem from './MatterportTagNavigationListItem';

type Props = {
  width?: number;
  datas: IMatterportItem[];
  onClickInfo?(data: IMatterportItem): void;
  onClickLocation?(data: IMatterportItem): void;
};

MatterportTagNavigation.defaultProps = {
  width: 250,
  onClickInfo: undefined,
  onClickLocation: undefined,
};

export default function MatterportTagNavigation({ width, datas, onClickInfo, onClickLocation }: Props) {
  const [open, setOpen] = useState<boolean>(true);

  const anime = useSpring({
    x: open ? 0 : ((width ?? 250) - 30) * -1,
    radius: open ? 10 : 250,
  });

  const toggle = () => setOpen((prev) => !prev);

  const ArrowIcon = useMemo(
    () => (open ? <ArrowLeft sx={{ color: '#ddd' }} /> : <ArrowRight sx={{ color: '#ddd' }} />),
    [open],
  );

  return (
    <AnimatedBox
      component="div"
      position="absolute"
      display="flex"
      justifyContent="flex-end"
      sx={{
        left: 0,
        top: 0,
        height: '100%',
        zIndex: 999,
        opacity: 0.8,
        backgroundColor: 'black',
      }}
      style={{
        width: width ?? 250,
        translate: anime.x,
        borderTopRightRadius: anime.radius,
        borderBottomRightRadius: anime.radius,
      }}
    >
      <AnimatedBox component="div" display="flex" flexDirection="column" sx={{ flex: 1, alignItems: 'center', p: 1 }}>
        <Box component="div" sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
          {datas.map((data) => (
            <MatterportTagNavigationListItem
              key={data.idx}
              data={data}
              onClickInfo={onClickInfo}
              onClickLocation={onClickLocation}
            />
          ))}
        </Box>
      </AnimatedBox>
      <Box component="div" display="flex" justifyContent="center" alignItems="center" alignContent="center">
        <IconButton onClick={toggle} size="small">
          {ArrowIcon}
        </IconButton>
      </Box>
    </AnimatedBox>
  );
}

const AnimatedBox = animated(Box);
