import { Grid } from '@mui/material';
import { DateSearchProps } from 'components/common/DateSearchBox';
import MesTagData10Card from 'components/data/MesData10Card';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatGroupState, groupState } from 'states';
import { Data10AggType } from './useGroupChartMesData10';

type Props = {
  mesParentGroupCode?: string;
  dateProps?: DateSearchProps;
};

export const useChart10MESCard = ({ dateProps, mesParentGroupCode }: Props) => {
  const [state, setState] = useState<Props>({
    mesParentGroupCode,
    dateProps,
  });

  const groups = useRecoilValue(flatGroupState);
  const childrenGroups = useMemo(
    () => groups.filter((gr) => gr.parent === state.mesParentGroupCode),
    [state.mesParentGroupCode, groups],
  );

  const search = (props: Props) => {
    setState(props);
  };

  const memoedChartCard = useMemo(
    () => (
      <Grid container spacing={1} sx={{ p: 2 }}>
        {childrenGroups?.map((group) => (
          <MesTagData10Card
            key={group.id.toString()}
            id={group.id.toString()}
            tags={[]}
            groupCode={group.groupCode}
            dateProps={state.dateProps ?? { type: 'day', duration: 3, durationUnit: 'hour' }}
            height={300}
            chartHeight={300}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
          />
        ))}
      </Grid>
    ),
    [state, childrenGroups],
  );

  return { cards: memoedChartCard, search };
};
