import { Box, Button, Chip, SxProps, Theme, Typography } from '@mui/material';
import { IGroup } from '@types';
import useBaseModalControl from 'common/hooks/useBaseModalControl';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatGroupState, groupState } from 'states';
import GroupSelectModal from './GroupSelectModal';

type Props = {
  onChange?(groups: IGroup[]): void;
  defaultValue?: number[];
  sx?: SxProps<Theme>;
};

GroupMultipleSelectInput.defaultProps = {
  onChange: undefined,
  defaultValue: undefined,
  sx: undefined,
};

export default function GroupMultipleSelectInput({ onChange, defaultValue, sx }: Props) {
  const [groups, setGroups] = useState<IGroup[]>([]);
  const { onOpen, ...modalControl } = useBaseModalControl();
  const flatGroupData = useRecoilValue(flatGroupState);
  const groupData = useRecoilValue(groupState);

  const defaultGroups = useMemo(() => {
    if (defaultValue && flatGroupData) {
      return _.filter(flatGroupData, (gr) => defaultValue.includes(gr.id));
    }

    return [];
  }, [flatGroupData, defaultValue]);

  const handleChangeGroup = (group: IGroup[]) => {
    setGroups((prev) => {
      const newGroups = _.uniqBy([...prev, ...group], (gr) => gr.id);

      triggerChangeEvent(newGroups);

      return newGroups;
    });
    modalControl.onClose();
  };

  const handleDeleteGroup = (groupId: number) => {
    setGroups((prev) => {
      const newGroups = _.filter(prev, (gr) => gr.id !== groupId);

      triggerChangeEvent(newGroups);

      return newGroups;
    });
  };

  const triggerChangeEvent = (changedGroup: IGroup[]) => {
    if (typeof onChange === 'function') {
      onChange(changedGroup);
    }
  };

  const renderSelectedChip = useMemo(
    () =>
      groups.map((group) => (
        <Chip
          key={group.id}
          label={group.groupName}
          onDelete={() => handleDeleteGroup(group.id)}
          sx={{ margin: 0.5 }}
        />
      )),
    [groups],
  );

  useEffect(() => {
    if (groups.length === 0 && defaultGroups) {
      setGroups(defaultGroups);
    }
  }, [groups, defaultGroups]);

  return (
    <Box component="div" sx={sx} padding={1}>
      <Button variant="contained" fullWidth onClick={onOpen}>
        <Typography>그룹조회</Typography>
      </Button>
      <Box component="div">{renderSelectedChip}</Box>

      <GroupSelectModal {...modalControl} data={groupData} onConfirm={handleChangeGroup} />
    </Box>
  );
}
