/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Typography, SvgIconProps, styled, Icon } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { TreeItem, treeItemClasses, TreeItemProps, TreeView } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  [`& .${treeItemClasses.content}`]: {
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(0),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(0),
    },
  },
}));

function StyledTreeItem(props: StyledTreeItemProps) {
  const { bgColor, color, labelIcon: LabelIcon, labelText, ...other } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box component="div" sx={{ display: 'flex', alignItems: 'center', p: 0.4, pr: 0 }}>
          <Box component="div" color="inherit" />
          {LabelIcon && <LabelIcon />}
          <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            {labelText}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

export interface RenderSideTree {
  id: string;
  name: string;
  icon?: React.ElementType<SvgIconProps>;
  url?: string;
  auth?: string;
  visible?: boolean;
  children?: readonly RenderSideTree[];
}

type Props = {
  data?: RenderSideTree[];
};

function SideTreeMenu({ data }: Props) {
  const navigate = useNavigate();

  const moveTo = (url?: string) => {
    if (url) {
      navigate(url);
    }
  };

  const renderTree = (nodes: RenderSideTree) =>
    nodes.visible ? (
      <StyledTreeItem
        sx={{ width: 170 }}
        key={nodes.id}
        nodeId={nodes.id}
        labelText={nodes.name}
        labelIcon={nodes.icon}
        onClick={() => moveTo(nodes.url)}
        color="#eee"
        bgColor="#030529"
      >
        {Array.isArray(nodes.children)
          ? nodes.children.filter((node) => node.visible).map((node) => renderTree(node))
          : null}
      </StyledTreeItem>
    ) : null;

  return (
    <TreeView
      sx={{ width: '100%', overflowY: 'auto', overflowX: 'hidden' }}
      defaultCollapseIcon={<ArrowDropDown />}
      defaultExpandIcon={<ArrowDropUp />}
    >
      {data?.map((nodes) => renderTree(nodes))}
    </TreeView>
  );
}

export default SideTreeMenu;
