/* eslint-disable import/no-absolute-path */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { ControlCamera, Delete, Edit, Refresh, Save } from '@mui/icons-material';
import { Box, Fab, IconButton, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { IDashboard, IDashboardItem } from '@types';
import MultiTagData1ChartCard from 'components/data/MultiTagData1ChartCard';
import NodeSystemToggleCard from 'components/dashboard/NodeSystemToggleCard';
import DefaultTagData10Card from 'components/data/DefaultTagData10Card';
import moment from 'moment';
import { DateSearchProps } from 'components/common/DateSearchBox';
import _ from 'lodash';
import TagDataCard from 'components/data/TagDataCard';
import AlarmHistStatCard from 'components/alarmhist/AlarmHistStatCard';
import VRTourIDCard from 'components/vrtour/VRTourIDCard';
import TopNDataPolarChartCard from 'components/data/TopNDataPolarChartCard';
import { CHART_COLORS } from '@const';
import { Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import GridItem from 'components/dashboard/GridItem';
import TagDataPieChartCard from 'components/data/TagDataPieChartCard';
import TagDataGaugeChartCard from 'components/data/TagDataGaugeChartCard';
import ProductDataCard from 'components/data/ProductDataCard';
import CostTagData10Card from 'components/data/CostTagData10Card';
import ProductTagData10ChartCard from 'components/data/ProductTagData10ChartCard';
import uuid from 'react-uuid';
import ProductTagDataCard from 'components/data/ProductTagDataCard';
import GroupMap from 'components/googlemap/GroupMap';
import MatterportCard from 'components/matterport/MatterportCard';

const ResponsiveGridLayout = WidthProvider(Responsive);

type COLSIZE = { [key: string]: number };
const COL_SIZES: COLSIZE = { lg: 30, sm: 2 };

type Props = {
  dashboard?: IDashboard;
  showControls?: boolean;
  onEditClick?(item: IDashboardItem): void;
  onDeleteClick?(item: IDashboardItem): void;
  onSaveClick?(items: IDashboardItem[]): void;
};

const useNewDashboard = ({ dashboard, showControls, onEditClick, onDeleteClick, onSaveClick }: Props) => {
  const [refresh, setRefresh] = useState<boolean>(false);
  const [bp, setBp] = useState<string>('');
  // Grid 레이아웃 상태
  const [layouts, setLayouts] = useState<Layouts | undefined>(undefined);
  // Dashboard의 아이템 값
  const items = useMemo(() => dashboard?.items ?? [], [dashboard, refresh]);

  // 수정 버튼 클릭 이벤트
  const handleEdit = (item: IDashboardItem) => {
    if (typeof onEditClick === 'function') {
      onEditClick(item);
    }
  };

  // 삭제 버튼 클릭 이벤트
  const handleDelete = (item: IDashboardItem) => {
    if (typeof onDeleteClick === 'function' && window.confirm('아이템을 삭제하시겠습니까?')) {
      onDeleteClick(item);
    }
  };

  // 저장 버튼 클릭 이벤트
  const handleSave = () => {
    if (typeof onSaveClick === 'function') {
      const newItems = items.map((item) => {
        const layoutItem = _.find(layouts?.lg, (layout) => layout.i === item.id.toString());

        return {
          ...item,
          w: layoutItem?.w ?? item.w,
          h: layoutItem?.h ?? item.h,
          x: layoutItem?.x ?? item.x,
          y: layoutItem?.y ?? item.y,
        };
      });

      onSaveClick(newItems);
    }
  };

  // Item Refresh
  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };

  // 레이아웃 변경 이벤트
  const handleLayoutChange = (changedLayouts: Layouts) => {
    setLayouts(changedLayouts);
  };

  const renderMatterportCard = (item: IDashboardItem) => {
    const card = <MatterportCard data={item.data?.matterport} />;
    return renderDefault(item, card);
  };

  // 구글맵 렌더링
  const renderGoogleMapCard = (item: IDashboardItem) => {
    const map = (
      <GroupMap
        containerStyle={{ width: '100%', height: '100%' }}
        defaultCenter={item.data?.centerLocation}
        defaultZoom={item.data?.mapZoom}
        groups={item.data?.groups ?? []}
        showGroupModel={item.data?.showGroupModel}
      />
    );

    return renderDefault(item, map);
  };

  // 제품 생산량 대비 조회 카드
  const renderProductTagDataCard = (item: IDashboardItem) => {
    const dateProps: DateSearchProps = item.data?.dateSearch ?? { type: 'currentYear' };
    const card = (
      <ProductTagDataCard
        site={item.data?.site}
        product={item.data?.product}
        tags={item.data?.tags}
        cost={item.data?.cost}
        sx={{ width: '100%', height: '100%' }}
        dateProps={dateProps}
        isIncrease={item.data?.isIncrease}
        showPrev={item.data?.showPrev}
        prevColorReverse={item.data?.prevColorReverse}
      />
    );

    return renderDefault(item, card, { mt: 3 });
  };

  // 제품 생산량 대비 차트 조회
  const renderProductDataChart = (item: IDashboardItem) => {
    const dateProps: DateSearchProps = item.data?.dateSearch
      ? {
          ...item.data?.dateSearch,
          start: moment(item.data?.dateSearch?.start),
          end: moment(item.data?.dateSearch?.end),
        }
      : { type: 'currentMonth' };

    const card = (
      <ProductTagData10ChartCard
        id={uuid()}
        width="100%"
        height="100%"
        chartWidth="100%"
        chartHeight="100%"
        dateProps={dateProps}
        tags={item.data?.tags ?? []}
        site={item.data?.site}
        product={item.data?.product}
        cost={item.data?.cost}
        aggType={item.data?.aggType}
        showPrev={item.data?.showPrev}
        showLegend={item.data?.showLegend}
        isIncrease={item.data?.isIncrease}
        showControls={false}
        useGridRowData={false}
      />
    );

    return renderDefault(item, card);
  };

  // 요금 차트 조회
  const renderCostDataChartCard = (item: IDashboardItem) => {
    const dateProps: DateSearchProps = item.data?.dateSearch
      ? {
          ...item.data?.dateSearch,
          start: moment(item.data?.dateSearch?.start),
          end: moment(item.data?.dateSearch?.end),
        }
      : { type: 'currentDay' };

    const card = (
      <CostTagData10Card
        id={item.id.toString()}
        width="100%"
        height="100%"
        chartWidth="100%"
        chartHeight="100%"
        dateProps={dateProps}
        aggType="max"
        tags={item.data?.tags ?? []}
        cost={item.data?.cost}
        showControls={false}
        showPrev={item.data?.showPrev}
        showLegend={item.data?.showLegend}
        useGridRowData={false}
      />
    );

    return renderDefault(item, card);
  };

  // 제품생산량 조회 카드
  const renderProductDataCard = (item: IDashboardItem) => {
    const card = (
      <ProductDataCard
        dateProps={item.data?.dateSearch}
        productId={item.data?.product}
        siteCode={item.data?.site}
        cardSx={{ width: '100%', height: '100%' }}
      />
    );

    return renderDefault(item, card, { mt: 3 });
  };

  // VRTour 카드 렌더링
  const renderVRTourCard = (item: IDashboardItem) => {
    const card = (
      <VRTourIDCard
        data={item.data?.vrtour}
        hideTitle
        hideControls
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        containerSx={{ width: '100%', height: '100%' }}
        cardSx={{ height: '100%' }}
      />
    );

    return renderDefault(item, card, { mt: 3 });
  };

  // 게이지 차트 카드
  const renderGaugeChart = (item: IDashboardItem) => {
    const card = (
      <TagDataGaugeChartCard
        isIncrease={item.data?.isIncrease}
        dateProps={item.data?.dateSearch || { type: 'dayLast' }}
        tags={item.data?.tags || []}
        cardSx={{ width: '100%', height: '100%' }}
        levels={item.data?.gaugeLevels}
        captionContent={['name', 'dtt']}
      />
    );

    return renderDefault(item, card, { mt: 3 });
  };

  // Area TopN 차트 카드
  const renderTopNChartCard = (item: IDashboardItem) => {
    const card = (
      <TopNDataPolarChartCard
        type={item.data?.pieType}
        name={item.id.toString()}
        isIncrease={item.data?.isIncrease}
        dateProps={item.data?.dateSearch || { type: 'dayLast' }}
        tags={item.data?.tags || []}
        topn={item.data?.topn}
        cardSx={{ width: '100%', height: '100%' }}
      />
    );

    return renderDefault(item, card, { mt: 3 });
  };

  // Area 차트 렌더링
  const renderPolarAreaChartCard = (item: IDashboardItem) => {
    const chart = (
      <TagDataPieChartCard
        type={item.data?.pieType}
        name={item.id.toString()}
        isIncrease={item.data?.isIncrease}
        cardSx={{ width: '100%', height: '100%' }}
        dateProps={item.data?.dateSearch || { type: 'dayLast' }}
        tags={item.data?.tags || []}
      />
    );

    return renderDefault(item, chart, { mt: 3 });
  };

  // 알람현황 카드 렌더링
  const renderAlarmStatusCard = (item: IDashboardItem) => {
    const card = (
      <AlarmHistStatCard
        search={item.data?.alarmSearch}
        dateSearch={item.data?.dateSearch}
        sx={{ width: '100%', height: '100%' }}
      />
    );

    return renderDefault(item, card, { mt: 3 });
  };

  // 현재값 조회 카드
  const renderLastValueCard = (item: IDashboardItem) => {
    const card = (
      <TagDataCard
        sx={{ width: '100%', height: '100%' }}
        dateProps={item.data?.dateSearch}
        tags={item.data?.tags}
        isIncrease={item.data?.isIncrease}
        showPrev={item.data?.showPrev}
        prevColorReverse={item.data?.prevColorReverse}
        aggFunc={item.data?.aggType}
      />
    );

    return renderDefault(item, card, { mt: 3 });
  };

  // 전일 대비 조회 차트
  const renderPrevDayChart = (item: IDashboardItem) => {
    const chart = (
      <DefaultTagData10Card
        id={`${item.id}`}
        fill
        tags={item.data?.tags || []}
        aggType={item.data?.aggType || 'mean'}
        isIncrease={item.data?.isIncrease}
        dateProps={{ type: 'prevDay' }}
        chartType={item.data?.chartType}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        width="100%"
        height="100%"
        chartWidth="100%"
        chartHeight="100%"
        showControls={false}
        showGridBtn={false}
        useGridRowData={false}
        bgColors={item.data?.chartType === 'bar' || item.data?.chartType === 'stackedBar' ? CHART_COLORS : undefined}
      />
    );

    return renderDefault(item, chart, { mt: 3 });
  };

  // 전월 대비 조회 차트
  const renderPrevMonthChart = (item: IDashboardItem) => {
    const chart = (
      <DefaultTagData10Card
        id={`${item.id}`}
        fill
        tags={item.data?.tags || []}
        aggType={item.data?.aggType || 'mean'}
        dateProps={{ type: 'prevMonth' }}
        isIncrease={item.data?.isIncrease}
        showGridBtn={false}
        chartType={item.data?.chartType}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        width="100%"
        height="100%"
        chartWidth="100%"
        chartHeight="100%"
        showControls={false}
        bgColors={item.data?.chartType === 'bar' || item.data?.chartType === 'stackedBar' ? CHART_COLORS : undefined}
      />
    );

    return renderDefault(item, chart, { mt: 3 });
  };

  // 전년 대비 조회 차트
  const renderPrevYearChart = (item: IDashboardItem) => {
    const chart = (
      <DefaultTagData10Card
        id={`${item.id}`}
        fill
        tags={item.data?.tags || []}
        aggType={item.data?.aggType || 'mean'}
        dateProps={{ type: 'prevYear' }}
        isIncrease={item.data?.isIncrease}
        chartType={item.data?.chartType}
        showGridBtn={false}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        width="100%"
        height="100%"
        chartWidth="100%"
        chartHeight="100%"
        showControls={false}
        bgColors={item.data?.chartType === 'bar' || item.data?.chartType === 'stackedBar' ? CHART_COLORS : undefined}
      />
    );

    return renderDefault(item, chart, { mt: 3 });
  };

  // 실시간 차트 조회 카드
  const renderData1Chart = (item: IDashboardItem) => {
    const chart = (
      <MultiTagData1ChartCard
        id={`${item.id}`}
        duration="1h"
        title={item.name}
        showDeleteBtn={false}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        width="100%"
        height="100%"
        chartWidth="100%"
        chartHeight="100%"
        hideTitle
        gridSx={{ width: '100%' }}
        tagStr={item.data?.tags || []}
      />
    );

    return renderDefault(item, chart, { mt: 3 });
  };

  // 데이터 조회 카드
  const renderData10Chart = (item: IDashboardItem) => {
    const dateProps: DateSearchProps = item.data?.dateSearch
      ? {
          ...item.data?.dateSearch,
          start: moment(item.data?.dateSearch?.start),
          end: moment(item.data?.dateSearch?.end),
        }
      : { duration: 1, durationUnit: 'day', type: 'day' };

    const chart = (
      <DefaultTagData10Card
        id={`${item.id}`}
        fill={(item.data?.chartType ?? 'line') !== 'line'}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        width="100%"
        height="100%"
        chartWidth="100%"
        chartHeight="100%"
        dateProps={dateProps}
        aggType={item.data?.aggType}
        isIncrease={item.data?.isIncrease}
        tags={item.data?.tags || []}
        showControls={false}
        aggUnit={item.data?.aggUnit}
        aggVal={item.data?.aggVal}
        chartType={item.data?.chartType}
        useGridRowData={false}
        showGridBtn
        bgColors={item.data?.chartType === 'bar' || item.data?.chartType === 'stackedBar' ? CHART_COLORS : undefined}
        showPrev={item.data?.showPrev}
        showLegend={item.data?.showLegend}
      />
    );

    return renderDefault(item, chart, { mt: 3 });
  };

  // 2D/3D 조회 카드
  const renderNodeCard = (item: IDashboardItem) => {
    const card = (
      <NodeSystemToggleCard sx={{ width: '100%', height: '100%' }} controllBoxSx={{ mt: -5, mb: 1 }} item={item} />
    );

    return renderDefault(item, card, { mt: 4 });
  };

  // 기본 카드
  const renderDefault = (item: IDashboardItem, contents: ReactElement, sx: SxProps<Theme> = {}) => {
    return (
      <GridItem
        key={item.id.toString()}
        // sx={{ height: item.height, mt: 1, ...sx }}
      >
        <Box component="div" display="flex" flexDirection="row" alignItems="center" sx={{ width: '100%' }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {item.name}
          </Typography>
          {showControls && (
            <>
              <IconButton size="small" onClick={handleRefresh}>
                <Refresh fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={() => handleEdit(item)}>
                <Edit fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={() => handleDelete(item)}>
                <Delete fontSize="small" />
              </IconButton>
            </>
          )}
        </Box>
        <Box component="div" display="flex" sx={{ width: '100%', height: '100%' }}>
          {contents}
        </Box>
      </GridItem>
    );
  };

  // Item 렌더링
  const renderItem = (item: IDashboardItem) => {
    switch (item.type) {
      case 'DATA1_CHART':
        return renderData1Chart(item);
      case 'DATA10_CHART':
        return renderData10Chart(item);
      case 'PREV_DAY':
        return renderPrevDayChart(item);
      case 'PREV_MONTH':
        return renderPrevMonthChart(item);
      case 'PREV_YEAR':
        return renderPrevYearChart(item);
      case 'LAST_DATA_VALUE':
        return renderLastValueCard(item);
      case 'NODE_AND_3D':
        return renderNodeCard(item);
      case 'ALARM_STATS':
        return renderAlarmStatusCard(item);
      case 'POLAR_AREA_CHART':
        return renderPolarAreaChartCard(item);
      case 'TOPN_CHART':
        return renderTopNChartCard(item);
      case 'GAUGE_CHART':
        return renderGaugeChart(item);
      case 'VRTOUR':
        return renderVRTourCard(item);
      case 'PRODUCT_CARD':
        return renderProductDataCard(item);
      case 'COST_CHART':
        return renderCostDataChartCard(item);
      case 'PRODUCT_DATA_CHART':
        return renderProductDataChart(item);
      case 'PRODUCT_DATA_CARD':
        return renderProductTagDataCard(item);
      case 'GOOGLE_MAP':
        return renderGoogleMapCard(item);
      case 'MATTERPORT':
        return renderMatterportCard(item);
      default:
        return renderDefault(item, <div />);
    }
  };

  // 조회된 레이아웃과 실제 화면에 표출되는 레이아웃 정리
  const memoedLayout = useMemo((): Layouts => {
    if (layouts?.lg && layouts.lg.length > 0) {
      return layouts;
    }

    return {
      sm: items.map((item) => ({
        i: item.id.toString(),
        w: item.type === 'GAUGE_CHART' ? 1 : 2,
        h: item.type === 'GAUGE_CHART' ? 15 : item.h || 2,
        x: 0,
        y: item.y || 0,
        minW: 1,
        minH: 2,
      })),
      lg: items.map((item) => ({
        i: item.id.toString(),
        w: item.w || 1,
        h: item.h || 1,
        x: item.x || 0,
        y: item.y || 0,
        minW: 1,
        minH: 1,
      })),
    };
  }, [layouts, items, refresh]);

  // 화면 사이즈에 따라 레이아웃 재정렬
  const orderedLayout = useMemo(() => {
    if (bp === 'lg' || bp === '') {
      return {
        ...memoedLayout,
      };
    }

    return {
      ...memoedLayout,
      [bp]: memoedLayout[bp]
        ?.map((lo) => ({
          ...lo,
          order: lo.y * COL_SIZES[bp] + lo.x, // Turning 2D to 1D
        }))
        .sort((a, b) => (a.order > b.order ? 1 : -1)),
    };
  }, [memoedLayout, bp, refresh]);

  // 화면 사이즈에 따라 아이템 재정렬
  const orderedItem = useMemo(() => {
    if (bp === 'lg' || bp === '') {
      return items;
    }

    return items
      ?.map((lo) => ({
        ...lo,
        order: (lo.y || 0) * COL_SIZES[bp] + (lo.x || 0), // Turning 2D to 1D
      }))
      .sort((a, b) => (a.order > b.order ? 1 : -1));
  }, [memoedLayout, bp, items, refresh]);

  // 그리드 화면 렌더링
  const grid = useMemo(
    () => (
      <>
        <ResponsiveGridLayout
          key={bp}
          isDraggable={showControls}
          isResizable={showControls}
          resizeHandle={<ControlCamera className="react-resizable-handle" sx={{ right: 0, bottom: 1, zIndex: 999 }} />}
          layouts={orderedLayout}
          compactType="vertical"
          rowHeight={10}
          breakpoints={{ lg: 1000, sm: 768 }}
          cols={COL_SIZES}
          onBreakpointChange={(bp) => setBp(bp)}
          onLayoutChange={(current, all) => handleLayoutChange(all)}
        >
          {orderedItem.map((item) => renderItem(item))}
        </ResponsiveGridLayout>
        {showControls && (
          <Fab onClick={handleSave} sx={{ position: 'fixed', right: 5, bottom: 5 }} size="small">
            <Save />
          </Fab>
        )}
      </>
    ),
    [bp, showControls, dashboard, orderedLayout, orderedItem, refresh],
  );

  useEffect(() => {
    if (items) {
      setLayouts(undefined);
    }
  }, [items]);

  return {
    grid,
  };
};

export default useNewDashboard;
