import React, { ReactElement, useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Chip, IconButton, useTheme } from '@mui/material';
import { ISort, ITag, TTagPageResponse } from '@types';
import { Add, ArrowDownward, ArrowUpward } from '@mui/icons-material';
import BaseTablePagination from 'components/common/BaseTablePagination';

interface Column {
  id: keyof ITagGridData;
  label: string;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  render?: (data: any) => ReactElement;
  sortable?: boolean;
}

export interface ITagGridData {
  id: number;
  tagCode: string;
  tagName: string;
  tagDesc: string;
  tagType: string;
  useYN: string;
  site: string;
  tagUnit: string;
  tagDetailType: string;
}

interface ButtonRendererProps {
  value: any;
  onClick?(): void;
}

const buttonRenderer = (data: ButtonRendererProps) => <Button onClick={data.onClick}>{data.value}</Button>;

function createData(data: ITag): ITagGridData {
  return {
    id: data.id,
    tagCode: data.tagCode,
    tagName: data.tagName,
    tagType: data.tagType === 'REAL' ? '일반' : '가상',
    useYN: data.useYN ? 'Y' : 'F',
    tagDesc: data.tagDesc ?? '',
    site: data.site?.siteName ?? '',
    tagUnit: data.tagUnit.codeName,
    tagDetailType: data.tagDetailType?.codeName ?? '',
  };
}

const columns: readonly Column[] = [
  { id: 'id', label: 'ID', minWidth: 50, sortable: true },
  { id: 'tagCode', label: 'Code', render: buttonRenderer, sortable: true },
  { id: 'tagName', label: 'Name', sortable: true },
  {
    id: 'site',
    label: 'Site',
    sortable: true,
  },
  { id: 'tagType', label: 'Type', sortable: true },
  {
    id: 'tagDetailType',
    label: 'DetailType',
  },

  {
    id: 'tagUnit',
    label: 'Unit',
  },
  {
    id: 'useYN',
    label: 'UseYN',
    sortable: true,
  },
  {
    id: 'tagDesc',
    label: 'Desc',
  },
];

type Props = {
  onClick?(data: ITagGridData): void;
  page?: number;
  rowPerPage?: number;
  data?: TTagPageResponse;
  onChangePage?(page: number): void;
  onChangeRowPerPage?(rowPerPage: number): void;
  onChangeSort?(sorts: ISort[]): void;
};

TagDataGrid.defaultProps = {
  data: [],
  onClick: undefined,
  onChangePage: undefined,
  onChangeRowPerPage: undefined,
  onChangeSort: undefined,
  page: 0,
  rowPerPage: 10,
};

export default function TagDataGrid({
  onClick,
  data,
  page,
  rowPerPage,
  onChangePage,
  onChangeRowPerPage,
  onChangeSort,
}: Props) {
  const theme = useTheme();
  const rows = useMemo(() => data?.data?.content?.map((tag) => createData(tag)), [data]);

  const renderCell = (row: any, column: Column) => {
    const value = row[column.id];
    const newValue = column.format && typeof value === 'number' ? column.format(value) : value;

    if (column.render) {
      switch (column.id) {
        case 'id':
          return column.render(newValue);
        case 'tagCode':
          return column.render({ value: newValue, onClick: () => typeof onClick === 'function' && onClick(row) });
        default:
          return newValue;
      }
    }

    return newValue;
  };

  const renderSortAddButton = (column: Column) => {
    if (!column.sortable) return null;

    const sorted = findSort(column.id);

    if (!sorted) {
      return (
        <IconButton size="small" onClick={() => addSort({ name: column.id, sort: 'ASC' })}>
          <Add />
        </IconButton>
      );
    }

    return null;
  };

  const renderSortChangeButton = (sort: ISort) => {
    if (sort.sort === 'ASC') {
      return (
        <IconButton size="small" onClick={() => changeSort({ ...sort, sort: 'DESC' })}>
          <ArrowUpward />
        </IconButton>
      );
    }

    return (
      <IconButton size="small" onClick={() => changeSort({ ...sort, sort: 'ASC' })}>
        <ArrowDownward />
      </IconButton>
    );
  };

  const addSort = (sort: ISort) => {
    setSorts((prev) => [...prev, sort]);
  };

  const deleteSort = (sort: ISort) => {
    setSorts((prev) => prev.filter((tSort) => tSort.name !== sort.name));
  };

  const changeSort = (sort: ISort) => {
    const newSort = sorts.map((st) => (st.name === sort.name ? sort : st));
    setSorts(newSort);

    if (typeof onChangeSort === 'function') {
      onChangeSort(newSort);
    }
  };

  const findSort = (name: string) => sorts.find((prev) => prev.name === name);

  const [sorts, setSorts] = useState<ISort[]>([]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box component="div" p={1}>
        {sorts.map((sort) => (
          <Chip
            key={sort.name}
            label={sort.name}
            icon={renderSortChangeButton(sort)}
            onDelete={() => deleteSort(sort)}
          />
        ))}
      </Box>
      <TableContainer sx={{ maxHeight: 500, minHeight: 500 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    backgroundColor: theme.palette.mode === 'light' ? '#f7fafc !important' : '#0f1018 !important',
                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                  }}
                >
                  {column.label}
                  {renderSortAddButton(column)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.id} align={column.align} sx={{ paddingY: 0 }}>
                        {renderCell(row, column)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <BaseTablePagination
        totalCount={data?.data?.totalElements}
        rowsPerPage={rowPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowPerPage}
      />
    </Paper>
  );
}
