/* eslint-disable no-return-assign */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';
import { IThreeModel } from '@types';
import { getFileURL } from 'common/lib';
import { Euler, Mesh, Vector3 } from 'three';

export type ThreeModelProps = {
  data: IThreeModel;
  position?: Vector3;
  rotation?: Euler;
  onClick?(e: ThreeEvent<globalThis.MouseEvent>): void;
};

ThreeModel.defaultProps = {
  position: undefined,
  rotation: undefined,
  onClick: undefined,
};

type ModelLoaderProps = {
  data: IThreeModel;
};

const useModelLoader = ({ data }: ModelLoaderProps) => {
  const { file } = data;

  const getLoader = () => {
    return useGLTF(getFileURL(file) ?? '', undefined, true);
  };

  const gltf = getLoader();

  const memoedScene = useMemo(() => {
    const { scene } = gltf;
    return scene.clone();
  }, [gltf]);

  return memoedScene;
};

export default function ThreeModel({ data, onClick, position, rotation }: ThreeModelProps) {
  const ref = useRef<Mesh>(null);
  const model = useModelLoader({ data });

  const handleOnClick = (e: ThreeEvent<globalThis.MouseEvent>) => {
    e.stopPropagation();
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  useEffect(() => {
    if (ref.current && model) {
      ref.current.add(model);
    }
  }, [ref, model]);

  return <mesh ref={ref} rotation={rotation} position={position} scale={data.scale ?? 0.005} onClick={handleOnClick} />;
}
