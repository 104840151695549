import React from 'react';
import MatterportBoxList from 'components/matterport/MatterportBoxList';
import { SystemLayout } from 'layout/system';

export default function MatterportPage() {
  return (
    <SystemLayout title="메타포트관리">
      <MatterportBoxList />
    </SystemLayout>
  );
}
