import React, { useEffect, useState } from 'react';
import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import {
  deleteProductManageAPI,
  findAllProductManageAPI,
  ProductManageSearchProps,
  saveProductManageAPI,
} from 'apis/productManage';
import { IProductManage, TProductManageCreateRequest } from '@types';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import ProductManageGrid from './ProductManageGrid';
import ProductManageSaveModal from './ProductManageSaveModal';

type Props = {
  search: ProductManageSearchProps;
  containerSx?: SxProps<Theme>;
};

ProductManageForm.defaultProps = {
  containerSx: undefined,
};

export default function ProductManageForm({ search, containerSx }: Props) {
  const [selected, setSelected] = useState<IProductManage | undefined>(undefined);
  const [modal, setModal] = useState<boolean>(false);
  const { data, refetch } = useQuery(['findProductManage', search], () => findAllProductManageAPI(search));

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleSave = (id: number, data: TProductManageCreateRequest) => {
    if (id > 0) {
      saveMutation.mutate(data);
    }
  };

  const handleDelete = (id: number, data: TProductManageCreateRequest) => {
    if (id > 0 && data.site) {
      deleteMutation.mutate({ id, siteCode: data.site });
    }
  };

  const saveMutation = useMutation(saveProductManageAPI, {
    onSuccess: (result) => {
      if (result.success) {
        toast('저장성공', { type: 'success' });
        refetch();
      }
    },
  });

  const deleteMutation = useMutation(deleteProductManageAPI, {
    onSuccess: (result) => {
      if (result.success) {
        toast('삭제성공', { type: 'success' });
        refetch();
      }
    },
  });

  return (
    <Box component="div" sx={containerSx}>
      {/* <ProductManagaSelectBox search={search} onSelectProductManage={setSelected} isEdit /> */}
      <ProductManageGrid data={data?.data} onSave={handleSave} onDelete={handleDelete} onAddYear={openModal} />
      <ProductManageSaveModal open={modal} onClose={closeModal} onSaveSuccess={closeModal} search={search} />
    </Box>
  );
}
