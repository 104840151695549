import { Cancel, Search } from '@mui/icons-material';
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material';
import { IThreeModel } from '@types';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { threeModelState } from 'states';
import ThreeModelSelectDrawer from './ThreeModelSelectDrawer';

type Props = Omit<TextFieldProps, 'defaultValue' | 'onChange'> & {
  defaultValue?: string;
  onChange?(id: string | undefined): void;
};

ThreeModelSelectInput.defaultProps = {
  defaultValue: undefined,
  onChange: undefined,
};

export default function ThreeModelSelectInput({ defaultValue, onChange, ...others }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | undefined>(defaultValue ? Number(defaultValue) : undefined);
  const models = useRecoilValue(threeModelState);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (model?: IThreeModel | null) => {
    setSelectedId(model?.id);
    handleChange(model?.id.toString());
  };

  const handleChange = (id?: string) => {
    if (typeof onChange === 'function') {
      onChange(id);
    }
  };

  const selectedModel = useMemo(() => _.find(models, (model) => model.id === selectedId), [selectedId, models]);

  return (
    <Box component="div" display="flex" alignContent="center" alignItems="center">
      <TextField
        variant="standard"
        sx={{ flex: 1 }}
        InputLabelProps={{ shrink: true }}
        InputProps={{ readOnly: true }}
        {...others}
        value={selectedModel?.name || ''}
      />
      {selectedId ? (
        <IconButton size="small" onClick={() => handleSelect(undefined)}>
          <Cancel />
        </IconButton>
      ) : (
        <IconButton size="small" onClick={handleOpen}>
          <Search />
        </IconButton>
      )}
      <ThreeModelSelectDrawer open={open} onClose={handleClose} onSelectModel={handleSelect} />
    </Box>
  );
}
