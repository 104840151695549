import { CHART_COLORS } from '@const';
import { Box, Button, Card, Chip, FormControl, FormLabel, Typography } from '@mui/material';
import { ITag } from '@types';
import TagSearchModal from 'components/tag/TagSearchModal';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { MultiTagData1ChartCardProps } from './MultiTagData1ChartCard';

type Props = {
  onAddChartCards?(charts: MultiTagData1ChartCardProps[]): void;
};

Data1SearchBox.defaultProps = {
  onAddChartCards: undefined,
};

export default function Data1SearchBox({ onAddChartCards }: Props) {
  // 태그 검색창 오픈 이벤트
  const handleOpenTagSearchModal = () => {
    setOpenTagSearchModal(true);
  };
  // 태그 검색창 닫기 이벤트
  const handleCloseTagSearchModal = () => {
    setOpenTagSearchModal(false);
  };
  // 태그 검색창 검색결과 전달받기
  const handleTagSearch = (tags: ITag[]) => {
    setSelectedTags((prev) => {
      const newArr = new Set([...prev, ...tags]);
      return Array.from(newArr);
    });

    handleCloseTagSearchModal();
  };

  // 태그 Chip 삭제
  const handleDeleteTag = (tagCode: string) => {
    setSelectedTags((prev) => prev.filter((ptag) => ptag.tagCode !== tagCode));
  };

  // 차트 카드 추가
  const handleAddChart = (chartProps: MultiTagData1ChartCardProps[]) => {
    if (typeof onAddChartCards === 'function') {
      onAddChartCards(chartProps);
      setSelectedTags([]);
    }
  };

  // 개별 Chart Card 추가
  const handleAddChartCard = () => {
    const newCharts: MultiTagData1ChartCardProps[] = selectedTags.map((tag) => ({
      id: tag.tagCode,
      duration: '10m',
      tagStr: [tag.tagCode],
      lineColors: [_.shuffle(CHART_COLORS)[0]],
    }));

    handleAddChart(newCharts);
  };

  // 병합 Chard Card 추가
  const handleAddMergedChartCard = () => {
    const tagCodes = selectedTags.map((tag) => tag.tagCode);
    const newChart: MultiTagData1ChartCardProps = {
      id: tagCodes.join('_'),
      duration: '10m',
      tagStr: tagCodes,
      lineColors: CHART_COLORS,
      height: 300,
      chartHeight: 300,
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    };

    handleAddChart([newChart]);
  };

  const [openTagSearchModal, setOpenTagSearchModal] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);

  // Save Tag Chips
  const renderTagChips = useMemo(
    () =>
      selectedTags.map((tag) => (
        <Chip key={tag.tagCode} sx={{ m: 0.5 }} label={tag.tagCode} onDelete={() => handleDeleteTag(tag.tagCode)} />
      )),
    [selectedTags],
  );

  return (
    <Box component="div" sx={{ p: 2 }}>
      <Card variant="outlined" sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
        <FormControl sx={{ mb: 2 }}>
          <FormLabel>태그 목록</FormLabel>
          <Box component="div" sx={{ display: 'flex', flexWrap: 'wrap', minHeight: 25 }}>
            {renderTagChips}
          </Box>
          <Button fullWidth variant="contained" color="info" onClick={handleOpenTagSearchModal}>
            <Typography>태그 검색</Typography>
          </Button>
        </FormControl>
        <Box component="div" display="flex">
          <Button
            fullWidth
            disabled={selectedTags.length < 1}
            sx={{ mr: 1 }}
            onClick={handleAddChartCard}
            variant="contained"
          >
            <Typography>개별 차트추가</Typography>
          </Button>
          <Button
            fullWidth
            disabled={selectedTags.length < 1}
            sx={{ ml: 1 }}
            onClick={handleAddMergedChartCard}
            variant="contained"
          >
            <Typography>병합 차트추가</Typography>
          </Button>
        </Box>
      </Card>
      <TagSearchModal open={openTagSearchModal} onClose={handleCloseTagSearchModal} onConfirm={handleTagSearch} />
    </Box>
  );
}
