import { Edit } from '@mui/icons-material';
import { Box, Card, Grid, IconButton, Skeleton, SxProps, Theme, Typography } from '@mui/material';
import { IThreeModel } from '@types';
import { BaseCanvasProps } from 'components/three/BaseCanvas';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ThreeBox from './ThreeBox';
import ThreeModelErrorComponent from './ThreeModelErrorComponent';

export type ThreeModelCardProps = BaseCanvasProps & {
  data?: IThreeModel | null;
  height?: number | string;
  onEditClick?(data: IThreeModel): void;
  onClick?(data: IThreeModel): void;
  selected?: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  containerSx?: SxProps<Theme>;
};

ThreeModelCard.defaultProps = {
  data: undefined,
  onEditClick: undefined,
  onClick: undefined,
  selected: false,
  height: 400,
  xs: 12,
  sm: 6,
  md: 6,
  lg: 6,
  xl: 4,
  containerSx: {},
};

export default function ThreeModelCard({
  data,
  height,
  onEditClick,
  onClick,
  selected,
  xs,
  sm,
  md,
  lg,
  xl,
  containerSx,
  ...others
}: ThreeModelCardProps) {
  const handleClickEdit = () => {
    if (typeof onEditClick === 'function' && data) {
      onEditClick(data);
    }
  };

  const handleClick = () => {
    if (typeof onClick === 'function' && data) {
      onClick(data);
    }
  };

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} sx={{ height, ...containerSx }}>
      <Box component="div" display="flex" alignItems="center">
        <Typography variant="body1" fontWeight="bold">
          {data?.name}
          {data?.site && ` | ${data.site}`}
        </Typography>
        {onEditClick && (
          <Box component="div" sx={{ ml: 1 }}>
            <IconButton onClick={handleClickEdit}>
              <Edit />
            </IconButton>
          </Box>
        )}
      </Box>
      <Card
        sx={{
          width: '100%',
          height: '100%',
          border: 'transparent 3px solid',
          ...(selected && { border: 'red 5px solid' }),
        }}
        onClick={handleClick}
      >
        {data && (
          <ErrorBoundary FallbackComponent={ThreeModelErrorComponent}>
            <Suspense fallback={<Skeleton variant="rectangular" width="100%" height="100%" />}>
              <ThreeBox data={data} {...others} />
            </Suspense>
          </ErrorBoundary>
        )}
      </Card>
    </Grid>
  );
}
