import { Box, Drawer, DrawerProps, Grid, Typography } from '@mui/material';
import { IThreeModel } from '@types';
import usePageInfo from 'common/hooks/usePageInfo';
import BaseTablePagination from 'components/common/BaseTablePagination';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { threeModelState } from 'states';
import ThreeModelCard from './ThreeModelCard';

type Props = DrawerProps & {
  onSelectModel?(model: IThreeModel | null): void;
};

ThreeModelSelectDrawer.defaultProps = {
  onSelectModel: undefined,
};

export default function ThreeModelSelectDrawer({ open, onSelectModel, ...others }: Props) {
  const { changePage, pageInfo } = usePageInfo({ defaultPage: 0, defaultSize: 6 });
  const threeModels = useRecoilValue(threeModelState);
  const [selectedModel, setSelectedModel] = useState<IThreeModel | null>(null);

  const handleClickCard = (model: IThreeModel | null) => {
    setSelectedModel((prev) => (prev?.id === model?.id ? null : model));
  };

  useEffect(() => {
    if (!open) {
      setSelectedModel(null);
    }
  }, [open]);

  useEffect(() => {
    if (open && typeof onSelectModel === 'function') {
      onSelectModel(selectedModel);
    }
  }, [selectedModel, open]);

  const renderCards = useMemo(
    () =>
      open &&
      threeModels
        .slice(pageInfo.page * pageInfo.size, (pageInfo.page + 1) * pageInfo.size)
        .map((model) => (
          <ThreeModelCard
            key={model.id}
            onClick={handleClickCard}
            selected={model.id === selectedModel?.id}
            containerSx={{ mt: 3 }}
            data={model}
            height={200}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            enableZoom={false}
          />
        )),
    [open, threeModels, selectedModel, pageInfo],
  );

  return (
    <Drawer anchor="left" open={open} keepMounted {...others}>
      <Box component="div" sx={{ p: 2 }}>
        <Typography variant="h5" fontWeight="bold">
          3D 모델 선택
        </Typography>
      </Box>

      <Grid container spacing={1} sx={{ maxWidth: 600, minWidth: 600, p: 2, mb: -3, overflowY: 'auto' }}>
        {renderCards}
        <Grid item sm={12}>
          <BaseTablePagination
            rowsPerPageOptions={[pageInfo.size]}
            page={pageInfo.page}
            rowsPerPage={pageInfo.size}
            totalCount={threeModels.length ?? 0}
            onPageChange={changePage}
          />
        </Grid>
      </Grid>
    </Drawer>
  );
}
