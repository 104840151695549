import React from 'react';
import { TAlarmSearchField, TUserSearchField } from '@types';
import BaseSearchField, { SearchFieldProps } from 'components/common/BaseSearchField';

const fields: SearchFieldProps[] = [
  {
    id: 'alarmName',
    name: 'Name',
    type: 'text',
  },
  {
    id: 'site',
    name: 'Site',
    type: 'site',
  },
  {
    id: 'tag',
    name: 'Tag',
    type: 'text',
  },
  {
    id: 'useYN',
    name: 'UseYN',
    type: 'useYN',
  },
];

type Props = {
  onChange?(search: TAlarmSearchField): void;
};

AlarmSearchField.defaultProps = {
  onChange: undefined,
};

export default function AlarmSearchField({ onChange }: Props) {
  return <BaseSearchField<TUserSearchField> field={fields} onCommit={onChange} />;
}
