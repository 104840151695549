import { SelectChangeEvent } from '@mui/material';
import { IGroup } from '@types';
import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';
import _ from 'lodash';
import React, { useMemo } from 'react';

type Props = BaseSelectBoxProps & {
  data: IGroup[];
  onSelectGroup?(name: string | undefined, group: IGroup): void;
};

GroupCodeSelectBox.defaultProps = {
  onSelectGroup: undefined,
};

export default function GroupCodeSelectBox({ data, onSelectGroup, ...others }: Props) {
  const items: SelectItemProps[] = useMemo(
    () => data.map((group) => ({ id: group.groupCode, name: group.groupName })),
    [data],
  );

  const handleChange = (e: SelectChangeEvent) => {
    const { name, value: code } = e.target;
    const selectedGroup = _.find(data, (gr) => gr.groupCode === code);

    if (typeof onSelectGroup === 'function' && selectedGroup) {
      onSelectGroup(name, selectedGroup);
    }
  };

  return <BaseSelectBox items={items} onChange={handleChange} {...others} />;
}
