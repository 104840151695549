import { ChartType } from '@types';
import BaseSelectBox, { BaseSelectBoxProps } from 'components/common/BaseSelectBox';
import React from 'react';

export type ChartTypeSelectBoxProps = Omit<BaseSelectBoxProps, 'value'> & {
  value?: ChartType;
};

ChartTypeSelectBox.defaultProps = {
  value: 'line',
};

export default function ChartTypeSelectBox({ value, ...others }: ChartTypeSelectBoxProps) {
  return (
    <BaseSelectBox
      label="차트 유형"
      value={value}
      items={[
        { id: 'line', name: '라인차트' },
        { id: 'bar', name: '바차트' },
        { id: 'stackedBar', name: '스택바차트' },
      ]}
      {...others}
    />
  );
}
