import { Box } from '@mui/material';
import { useMutableCallback } from '@react-three/fiber/dist/declarations/src/core/utils';
import { IProductManage, TProductManageCreateRequest, TProductManageResponse } from '@types';
import { ProductManageSearchProps, saveProductManageAPI } from 'apis/productManage';
import BaseDatePicker from 'components/common/BaseDatePicker';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

type Props = IBaseModalProps & {
  search: ProductManageSearchProps;
  onSaveSuccess?(productManage: IProductManage): void;
};

ProductManageSaveModal.defaultProps = {
  onSaveSuccess: undefined,
};

export default function ProductManageSaveModal({ search, onSaveSuccess, ...others }: Props) {
  const queryClient = useQueryClient();
  const [state, setState] = useState<TProductManageCreateRequest>({});

  useEffect(() => {
    setState((prev) => ({ ...prev, site: search.siteCode, product: search.productId }));
  }, [search]);

  useEffect(() => {
    setState((prev) => ({ ...prev, year: moment().year() }));
  }, []);

  const save = () => {
    createMutation.mutate(state);
  };

  const createMutation = useMutation(saveProductManageAPI, {
    onSuccess: (result: TProductManageResponse) => {
      if (result.success && result.data) {
        toast('제품 생산 연도 저장 성공', { type: 'success' });
        queryClient.invalidateQueries(['findProductManage']);

        if (typeof onSaveSuccess === 'function') {
          onSaveSuccess(result.data);
        }
      }
    },
  });

  const handleChangeYear = (name: string, changeDate: moment.Moment) => {
    const year = changeDate.year();
    setState((prev) => ({ ...prev, year }));
  };

  return (
    <BaseModal {...others} title="제품 생산 연도 저장" onConfirm={save}>
      <Box component="div" display="flex" justifyContent="center" alignItems="center" sx={{ width: 300, height: 100 }}>
        <BaseDatePicker
          name="year"
          fullWidth
          views={['year']}
          mask="____"
          inputFormat="YYYY"
          onChange={handleChangeYear}
        />
      </Box>
    </BaseModal>
  );
}
