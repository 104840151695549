import { Box, Typography } from '@mui/material';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatGroupState } from 'states';
import { filterGroupFieldType } from 'common/lib';
import DefaultTagData10Card from 'components/data/DefaultTagData10Card';
import { NLPResponse } from './VoiceChat';

type Props = IBaseModalProps & {
  //
  voice?: NLPResponse;
};

VoiceChatGroupTagModal.defaultProps = {
  voice: undefined,
};

export default function VoiceChatGroupTagModal({ voice, open, ...others }: Props) {
  const [state, setState] = useState<NLPResponse | undefined>(undefined);
  const flatGroups = useRecoilValue(flatGroupState);

  const selectedGroup = useMemo(() => {
    if (voice && voice.nlp && voice.nlp.text && flatGroups) {
      const { nlp } = voice;

      return _.find(flatGroups, (group) =>
        group.synonym.includes(nlp.text.replaceAll(' 전력량', '').replaceAll(' 전력', '')),
      );
    }

    return undefined;
  }, [voice, flatGroups]);

  const selectedTags = useMemo(() => {
    if (selectedGroup) {
      return filterGroupFieldType(selectedGroup, 'FIELD_TAG').groupFieldDatas;
    }

    return [];
  }, [selectedGroup]);

  useEffect(() => {
    if (voice && state === undefined) {
      setState(voice);
    }
  }, [state, voice]);

  useEffect(() => {
    if (!open) {
      setState(undefined);
    }
  }, [open]);

  return (
    <BaseModal {...others} open={open} title={`${selectedGroup?.groupName ?? ''} 음성 조회`} confirmTitle="">
      <Box component="div" sx={{ width: 800, maxHeight: 500 }}>
        {selectedGroup ? (
          selectedTags
            .filter((tag) => tag.data)
            .map((tag) => (
              <DefaultTagData10Card
                id={tag.data ?? ''}
                key={tag.data ?? ''}
                width="100%"
                chartWidth="100%"
                chartHeight="100%"
                tags={tag.data ? [tag.data] : []}
                dateProps={{ type: 'currentDay' }}
                chartType="bar"
                isIncrease={tag.data?.includes('KWH') || tag.data?.includes('TOE') || tag.data?.includes('CO2EQ')}
                aggType={tag.data?.includes('KWH') ? 'max' : 'mean'}
              />
            ))
        ) : (
          <Typography>해당 그룹 동의어를 찾을수가 없습니다.</Typography>
        )}
      </Box>
    </BaseModal>
  );
}
