import { ENDPOINT } from '@const';
import { TThreeCanvasCreateRequest, TThreeCanvasListResponse, TThreeCanvasResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const createThreeCanvasAPI = async (request: TThreeCanvasCreateRequest): Promise<TThreeCanvasResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.threeCanvas.createThreeCanvas,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 캔버스 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyThreeCanvasAPI = async (request: TThreeCanvasCreateRequest): Promise<TThreeCanvasResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.threeCanvas.modifyThreeCanvas,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 캔버스 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteThreeCanvasAPI = async (id: number): Promise<TThreeCanvasResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.threeCanvas.deleteThreeCanvas,
      url: ENDPOINT.threeCanvas.deleteThreeCanvas.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 캔버스 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const findAllThreeCanvasAPI = async (): Promise<TThreeCanvasListResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.threeCanvas.findAllThreeCanvases,
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 캔버스 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};
