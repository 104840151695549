import { IGroupFieldData } from '@types';
import ThreeModelCard, { ThreeModelCardProps } from 'components/threemodel/ThreeModelCard';
import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { threeModelState } from 'states';

type Props = Omit<ThreeModelCardProps, 'data'> & {
  data?: IGroupFieldData;
};

GroupFieldThreeModel.defaultProps = {
  data: undefined,
};

export default function GroupFieldThreeModel({ data, ...others }: Props) {
  const threeModels = useRecoilValue(threeModelState);
  const selectedModel = useMemo(() => {
    if (data?.field.fieldType.code !== 'FIELD_3D') return undefined;
    if (!data || !data.data) return undefined;

    const modelId = data.data;

    return _.find(threeModels, (model) => model.id.toString() === modelId);
  }, [data, threeModels]);

  return <ThreeModelCard data={selectedModel} {...others} />;
}
