import { ENDPOINT } from '@const';
import { TTagPageRequest, TTagPageResponse, TTagCreateRequest, TTagCreateResponse, TTagListResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

/**
 * 태그 페이지 조회 API
 * @param request 태그 페이지 요청 정보
 * @returns 조회된 태그 페이지 정보
 */
const findTagAPI = async (request: TTagPageRequest): Promise<TTagPageResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.tag.findPages,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('태그 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

const findTagByCodeAPI = async (code: string[]): Promise<TTagListResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.tag.findByCode,
      url: ENDPOINT.tag.findByCode.url.replace('{code}', code.join('|')),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('태그 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};

const createTagAPI = async (request: TTagCreateRequest): Promise<TTagCreateResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.tag.create,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('태그 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

const modifyTagAPI = async (request: TTagCreateRequest): Promise<TTagCreateResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.tag.modify,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('태그 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export { findTagAPI, findTagByCodeAPI, createTagAPI, modifyTagAPI };
