import { Add } from '@mui/icons-material';
import { Box, Chip, IconButton, SxProps, TextField, TextFieldProps, Theme } from '@mui/material';
import { IFile } from '@types';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import FileDownloadChip from './FileDownloadChip';

type Props = {
  name?: string;
  onChange?(name?: string, files?: File[]): void;
  containerSx?: SxProps<Theme>;
  fieldProps?: TextFieldProps;
  singleSelect?: boolean;
  downloadFiles?: IFile[];
  accept?: string;
};

FileUploadBox.defaultProps = {
  name: undefined,
  onChange: undefined,
  containerSx: {},
  fieldProps: undefined,
  singleSelect: false,
  downloadFiles: [],
  accept: undefined,
};

export default function FileUploadBox({
  name,
  containerSx,
  fieldProps,
  singleSelect,
  onChange,
  downloadFiles,
  accept,
}: Props) {
  const inputRef = useRef<any | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    const length = files?.length || 0;
    const newFiles: File[] = [];

    let i = 0;

    while (i < length) {
      if (files && files?.item(i) !== null && files?.item(i) !== undefined) {
        newFiles.push(files.item(i) as File);
      }
      i += 1;
    }

    setSelectedFiles(newFiles);
  };

  const handleAddFiles = () => {
    if (singleSelect) {
      setFiles([selectedFiles[0]]);
      handleChange([selectedFiles[0]]);
    } else {
      setFiles((prev) => {
        const newState = [...prev, ...selectedFiles];
        handleChange(newState);
        return newState;
      });
    }
    setSelectedFiles([]);

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleDeleteFile = (file: File) => {
    setFiles((prev) => {
      const newState = prev.filter((pfile) => pfile.name !== file.name);
      handleChange(newState);

      return newState;
    });
  };

  const handleChange = (files: File[]) => {
    if (typeof onChange === 'function') {
      onChange(name, files);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.multiple = !singleSelect;
    }
  }, [inputRef, singleSelect]);

  return (
    <Box component="div" display="flex" flexDirection="column" sx={containerSx}>
      <Box component="div" display="flex" alignItems="center">
        <TextField
          inputRef={inputRef}
          fullWidth
          type="file"
          inputProps={{ accept }}
          onChange={onFileSelect}
          {...fieldProps}
        />
        <IconButton disabled={selectedFiles?.length === 0} onClick={handleAddFiles}>
          <Add />
        </IconButton>
      </Box>
      <Box component="div">
        {files.map((file) => (
          <Chip key={file.name} label={file.name} onDelete={() => handleDeleteFile(file)} sx={{ mt: 1 }} />
        ))}
      </Box>
      <Box component="div">
        {downloadFiles?.map((file) => (
          <FileDownloadChip key={file.id} file={file} />
        ))}
      </Box>
    </Box>
  );
}
