import React, { useEffect, useMemo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from 'pages/LoginPage';
import { Route, Routes } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  codeState,
  loginState,
  siteState,
  roleState,
  loadingState,
  groupState,
  groupTypeState,
  threeModelState,
  threeCanvasState,
  dashboardState,
  colorModeState,
  nodeSystemState,
  emissionItemState,
  costState,
  matterportState,
} from 'states';
import MainPage from 'pages/MainPage';
import Nav from 'components/Nav';
import UserPage from 'pages/system/UserPage';
import CodePage from 'pages/system/CodePage';
import { findAllCodesAPI } from 'apis/code';
import { useQuery } from 'react-query';
import { sortCode, sortGroupType } from 'common/lib';
import SitePage from 'pages/system/SitePage';
import { findAllSitesAPI } from 'apis/site';
import RolePage from 'pages/system/RolePage';
import { findRoles } from 'apis/role';
import TagPage from 'pages/system/TagPage';
import Loading from 'components/common/Loading';
import GroupPage from 'pages/system/GroupPage';
import { findGroupsAPI } from 'apis/group';
import Data1MTagPage from 'pages/data1m/Data1MTagPage';
import DataTagPage from 'pages/data/DataTagPage';
import AlarmPage from 'pages/system/AlarmPage';
import AlarmHistPage from 'pages/alarm/AlarmHistPage';
import { TaskPage } from 'pages/task/TaskPage';
import DataNodePage from 'pages/data/DataNodePage';
import { findAllGroupTypeAPI } from 'apis/grouptype';
import GroupTypePage from 'pages/system/GroupTypePage';
import NodePage from 'pages/system/NodePage';
import ThreeModelPage from 'pages/system/ThreeModelPage';
import { findAllThreeAPI } from 'apis/threemodel';
import ThreeCanvasPage from 'pages/system/ThreeCanvasPage';
import { findAllThreeCanvasAPI } from 'apis/threecanvas';
import DashboardPage from 'pages/system/DashboardPage';
import { findAllDashboardAPI } from 'apis/dashboard';
import { createTheme, CssBaseline, PaletteMode, ThemeProvider } from '@mui/material';
import { findAllNodeSystemAPI } from 'apis/nodesystem';
import VRTourPage from 'pages/vrtour/VRTourPage';
import EmissionPage from 'pages/system/EmissionPage';
import { findAllEmissionItemAPI } from 'apis/emission';
import NodeFullScreenPage from 'pages/NodeFullScreenPage';
import DashboardFullScreenPage from 'pages/DashboardFullScreenPage';
import CostPage from 'pages/system/CostPage';
import { findAllCostAPI } from 'apis/cost';
import DataTagCostPage from 'pages/data/DataTagCostPage';
import DataMesPage from 'pages/data/DataMesPage';
import ProductPage from 'pages/product/ProductPage';
import DataProductPage from 'pages/data/DataProductPage';
import useReactGA from 'common/hooks/useReactGA';
import { findAllMatterportsAPI } from 'apis/matterport';
import MatterportPage from 'pages/system/MatterportPage';
import MatterportDetailPage from 'pages/system/MatterportDetailPage';
import MatterportFullScreen from 'pages/MatterportFullScreenPage';
import ExcelTagDataUploadPage from 'pages/system/ExcelTagDataUploadPage';

const paths = [
  {
    id: 'main',
    path: '/',
    element: <MainPage />,
  },
  {
    id: 'system_user',
    path: '/system/user',
    element: <UserPage />,
  },
  {
    id: 'system_role',
    path: '/system/role',
    element: <RolePage />,
  },
  {
    id: 'system_tag',
    path: '/system/tag',
    element: <TagPage />,
  },
  {
    id: 'system_tag_excel',
    path: '/system/tag_excel',
    element: <ExcelTagDataUploadPage />,
  },
  {
    id: 'system_emission',
    path: '/system/emission',
    element: <EmissionPage />,
  },
  {
    id: 'system_cost',
    path: '/system/cost',
    element: <CostPage />,
  },
  {
    id: 'system_alarm',
    path: '/system/alarm',
    element: <AlarmPage />,
  },
  {
    id: 'system_site',
    path: '/system/site',
    element: <SitePage />,
  },
  {
    id: 'system_group',
    path: '/system/group',
    element: <GroupPage />,
  },
  {
    id: 'system_grouptype',
    path: '/system/grouptype',
    element: <GroupTypePage />,
  },
  {
    id: 'system_code',
    path: '/system/code',
    element: <CodePage />,
  },
  {
    id: 'system_node',
    path: '/system/node',
    element: <NodePage />,
  },
  {
    id: 'system_matterport',
    path: '/system/matterport',
    element: <MatterportPage />,
  },
  {
    id: 'rtdata_tag',
    path: '/rtdata/tag',
    element: <Data1MTagPage />,
  },
  {
    id: 'data_tag',
    path: '/data/tag',
    element: <DataTagPage />,
  },
  {
    id: 'data_cost',
    path: '/data/cost',
    element: <DataTagCostPage />,
  },
  {
    id: 'data_mes',
    path: '/data/mes',
    element: <DataMesPage />,
  },
  {
    id: 'data_product',
    path: '/data/product',
    element: <DataProductPage />,
  },
  {
    id: 'data_node',
    path: '/data/node',
    element: <DataNodePage />,
  },
  {
    id: 'alarm_alarm',
    path: '/alarm/alarm',
    element: <AlarmPage />,
  },
  {
    id: 'hist_alarm',
    path: '/alarm/hist',
    element: <AlarmHistPage />,
  },
  {
    id: 'task',
    path: '/task/task',
    element: <TaskPage />,
  },
  {
    id: 'product',
    path: '/product/list',
    element: <ProductPage />,
  },
  {
    id: 'system_3dmodel',
    path: '/system/3dmodel',
    element: <ThreeModelPage />,
  },
  {
    id: 'system_3dcanvas',
    path: '/system/3dcanvas',
    element: <ThreeCanvasPage />,
  },
  {
    id: 'system_dashboard',
    path: '/system/dashboard',
    element: <DashboardPage />,
  },
  {
    id: 'vrtour',
    path: '/vrtour',
    element: <VRTourPage />,
  },
  {
    id: 'node_fullscreen',
    path: '/node/fullscreen/:itemId',
    element: <NodeFullScreenPage />,
  },
  {
    id: 'matterport_fullscreen',
    path: '/matterport/fullscreen/:itemId',
    element: <MatterportFullScreen />,
  },
  {
    id: 'matterport_detial',
    path: '/matterport/:matterportIdx',
    element: <MatterportDetailPage />,
  },
  {
    id: 'dashboard_fullscreen',
    path: '/dashboard/fullscreen',
    element: <DashboardFullScreenPage />,
  },
];

function App() {
  // use Google Analytics
  useReactGA();

  // Recoil User State
  const user = useRecoilValue(loginState);
  // Recoil Loading State
  const loading = useRecoilValue(loadingState);
  // Recoil ColorMode State
  const colorMode = useRecoilValue(colorModeState);
  // MUI Theme
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colorMode as PaletteMode,
          text: { primary: colorMode === 'light' ? '#333' : '#ddd' },
          background: {
            default: colorMode === 'light' ? '#F3F6F9' : '#00041a',
            paper: colorMode === 'light' ? '#f7fafc' : '#0f1018',
          },
        },
        components: {
          MuiTableCell: {
            styleOverrides: {
              root: { backgroundColor: 'transparent' },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root:
                colorMode === 'light'
                  ? {
                      backgroundColor: '#f9f9fd',
                      opacity: 0.9,
                    }
                  : {
                      backgroundColor: '#010311',
                      opacity: 0.9,
                    },
            },
          },
          MuiCard: {
            styleOverrides: {
              root:
                colorMode === 'light'
                  ? {
                      backgroundColor: '#f9f9fd',
                    }
                  : {
                      // backgroundColor: '#121525',
                      backgroundColor: '#0d0d1b',
                      // backgroundColor: '#0f1018',
                    },
            },
          },
          MuiTypography: {
            styleOverrides: {
              caption: {
                color: colorMode === 'light' ? '#444' : '#ccc',
              },
            },
          },
        },
      }),
    [colorMode],
  );

  // 코드 조회
  const { data: codes, refetch: codeRefetch } = useQuery(['codes'], findAllCodesAPI, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });
  // 사이트 조회
  const { data: sites, refetch: siteRefetch } = useQuery(['sites'], findAllSitesAPI, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });
  // 권한 목록 조회
  const { data: roles, refetch: roleRefetch } = useQuery(['roles'], () => findRoles({ page: 0, size: 100 }), {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });
  // 그룹 유형 조회
  const { data: groupTypes, refetch: groupTypeRefetch } = useQuery(['groupTypes'], findAllGroupTypeAPI, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });
  // 2D 계통 조회
  const { data: nodeSystem, refetch: nodeSystemRefetch } = useQuery(['nodeSystems'], findAllNodeSystemAPI, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });

  // 그룹 조회
  const { data: groups, refetch: groupRefetch } = useQuery(['groups'], findGroupsAPI, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });
  // Three Models 조회
  const { data: threeModels, refetch: threeModelRefetch } = useQuery(['threemodels'], findAllThreeAPI, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });
  // Three Canvas 조회
  const { data: threeCanvases, refetch: threeCanvasRefetch } = useQuery(['threecnvases'], findAllThreeCanvasAPI, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });
  // 대시보드 조회
  const { data: dashboards, refetch: dashboardRefetch } = useQuery(['dashboards'], findAllDashboardAPI, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });
  // 배출아이템 조회
  const { data: emissionItems, refetch: emissionItemRefetch } = useQuery(['emission'], findAllEmissionItemAPI, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });
  // 비용관리 조회
  const { data: costs, refetch: costRefetch } = useQuery(['cost'], findAllCostAPI, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });
  // 메타포트 조회
  const { data: matterports, refetch: matterportRefetch } = useQuery(['matterports'], findAllMatterportsAPI, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user),
    refetchInterval: 60 * 1000 * 5,
  });

  // Recoil Code Setter
  const setCodes = useSetRecoilState(codeState);
  // Recoil Site Setter
  const setSites = useSetRecoilState(siteState);
  // Recoil Role Setter
  const setRoles = useSetRecoilState(roleState);
  // Recoil Group Setter
  const setGroups = useSetRecoilState(groupState);
  // Recoil GroupTpe Setter
  const setGroupTypes = useSetRecoilState(groupTypeState);
  // Recoil 2D Node System Setter
  const setNodeSystem = useSetRecoilState(nodeSystemState);
  // Recoil ThreeModels Setter
  const setThreeModels = useSetRecoilState(threeModelState);
  // Recoil ThreeCanvas Setter
  const setThreeCanvases = useSetRecoilState(threeCanvasState);
  // Recoil Dashboard Setter
  const setDashboards = useSetRecoilState(dashboardState);
  // Recoil EmissionItem Setter
  const setEmissionItem = useSetRecoilState(emissionItemState);
  // Recoil Cost Setter
  const setCost = useSetRecoilState(costState);
  // Recoil Matterport Setter
  const setMatterport = useSetRecoilState(matterportState);

  const memoedLoading = useMemo(() => <Loading {...loading} />, [loading]);

  // 로그인 여부에 따라 메인 렌더링 페이지 변경
  const renderRoutes = useMemo(() => {
    if (!user) {
      return (
        <Routes>
          <Route path="/*" element={<LoginPage />} />
        </Routes>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Nav>
          <Routes>
            {paths.map((path) => (
              <Route key={path.id} path={path.path} element={path.element} />
            ))}
          </Routes>
        </Nav>
      </ThemeProvider>
    );
  }, [user, theme]);

  // 코드 데이터 조회 후, 정렬 후 RecoilState Setting
  useEffect(() => {
    setCodes(sortCode(codes?.data ?? []));
  }, [codes]);

  // Site 데이터 조회 후, RecoilState Setting
  useEffect(() => {
    setSites(sites?.data ?? []);
  }, [sites]);

  // 권한 조회 후, RecoilState Setting
  useEffect(() => {
    setRoles(roles?.data?.content ?? []);
  }, [roles]);

  // 그룹 조회 후, Recoil State Setting
  useEffect(() => {
    setGroups(groups?.data ?? []);
  }, [groups]);

  // 그룹 유형 조회 후, Recoil State Setting
  useEffect(() => {
    setGroupTypes(sortGroupType(groupTypes?.data ?? []));
  }, [groupTypes]);

  // 계통 조회 후, Recoil State Setting
  useEffect(() => {
    setNodeSystem(nodeSystem?.data ?? []);
  }, [nodeSystem]);

  // Three 모델 조회 후, Recoil State Setting
  useEffect(() => {
    setThreeModels(threeModels?.data ?? []);
  }, [threeModels]);

  // Three Canvas 조회 후, Recoil State Setting
  useEffect(() => {
    setThreeCanvases(threeCanvases?.data ?? []);
  }, [threeCanvases]);

  // Dashboard 조회 후, Recoil State Setting
  useEffect(() => {
    setDashboards(dashboards?.data ?? []);
  }, [dashboards]);

  // 배출아이템 조회 후, Recoil State Setting
  useEffect(() => {
    setEmissionItem(emissionItems?.data ?? []);
  }, [emissionItems]);

  // 비용관리 조회 후, Recoil StateSetting
  useEffect(() => {
    setCost(costs?.data ?? []);
  }, [costs]);

  useEffect(() => {
    setMatterport(matterports?.data ?? []);
  }, [matterports]);

  // 유저 로그인 후, 조회
  useEffect(() => {
    if (user) {
      siteRefetch();
      codeRefetch();
      roleRefetch();
      groupRefetch();
      groupTypeRefetch();
      threeModelRefetch();
      threeCanvasRefetch();
      dashboardRefetch();
      emissionItemRefetch();
      costRefetch();
      matterportRefetch();
    }
  }, [user]);

  return (
    <>
      {memoedLoading}
      {renderRoutes}
    </>
  );
}

export default App;
