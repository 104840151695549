import React, { useCallback, useMemo } from 'react';
import { ChartData } from 'chart.js';
import { useQuery } from 'react-query';
import { CHART_COLORS, CHART_COLORS_BG } from '@const';
import { ChartType, IProductMonthData } from '@types';
import { findAllProductManageAPI, ProductManageSearchProps } from 'apis/productManage';
import { useTheme } from '@mui/material';
import useLoading from './useLoading';

type Props = {
  type?: ChartType;
  search?: ProductManageSearchProps;
  year?: number;
  isIncrease?: boolean;
  colors?: string[];
  bgColors?: string[];
  fill?: boolean;
  showLoading?: boolean;
  showAnnotation?: boolean;
  xAxis?: string;
  yAxis?: string;
  stackId?: string;
  xFormatter?(xValue: string): string;
  yFormatter?(yValue: number): number;
  isPrev?: boolean;
  showDataLabel?: boolean;
};

const useProductChartData10 = ({
  type = 'line',
  year = undefined,
  search = {},
  showLoading = false,
  colors,
  bgColors,
  fill,
  yAxis = undefined,
  xAxis = 'x',
  stackId = 'x',
  xFormatter = undefined,
  yFormatter = undefined,
  isPrev = false,
  showDataLabel = false,
}: Props) => {
  const theme = useTheme();

  const themeColors = useMemo(() => {
    if (theme.palette.mode === 'dark') {
      return {
        textColor: '#eee',
        labelBackgroundColor: isPrev ? 'rgb(50, 50, 50)' : 'black',
      };
    }

    return {
      textColor: '#222',
      labelBackgroundColor: isPrev ? 'rgb(200,200,200)' : 'rgb(250,250,250)',
    };
  }, [theme.palette.mode, isPrev]);

  const { data: dataList, isFetching } = useQuery(
    ['findProductManage', search.productId, search.siteCode],
    () => findAllProductManageAPI(search),
    {
      enabled: Boolean(search),
      refetchInterval: 60 * 1000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );

  const chartType = useMemo(() => {
    if (type === 'stackedBar') return 'bar';

    return type;
  }, [type]);

  const createData = useCallback(
    (data: IProductMonthData[] | undefined) => {
      if (data === undefined) return [];

      if (typeof xFormatter === 'function' || typeof yFormatter === 'function') {
        return data.map((dt) => ({
          originX: dt.dt,
          x: typeof xFormatter === 'function' ? xFormatter(dt.dt) : dt.dt,
          y: typeof yFormatter === 'function' ? yFormatter(dt.value) : dt.value,
          isPrev,
        }));
      }

      return data.map((dt) => ({
        x: dt.dt,
        y: dt.value,
        isPrev,
      }));
    },
    [yFormatter, xFormatter, isPrev],
  );

  const chartData = useMemo((): ChartData<'line' | 'bar' | 'scatter', any> => {
    const tempData = dataList?.data ?? [];

    let lineColor = colors ?? CHART_COLORS;
    let bgColor = bgColors ?? CHART_COLORS_BG;

    if (isPrev) {
      lineColor = lineColor.slice().reverse();
      bgColor = bgColor.slice().reverse();
    }

    if (tempData.length < 1) {
      return {
        labels: [],
        datasets: [],
      };
    }

    const datasets: ChartData<'line' | 'bar' | 'scatter', any> = {
      datasets: tempData
        .filter((pm) => pm.year === year)
        .map((data, index) => ({
          id: `${data.product.name}-${isPrev ? 'prev' : ''}`,
          fill,
          type: chartType,
          label: `${isPrev ? '전년 ' : ''}${data.product.name} 생산량`,
          data: createData(data.data),
          borderColor: lineColor.at(index),
          backgroundColor: bgColor.at(index),
          barThickness: 'flex',
          maxBarThickness: 100,
          xAxisID: xAxis,
          yAxisID: yAxis,
          stack: stackId,
          datalabels: {
            formatter: (value, context) => {
              return `${Number(value.y.toFixed(0))} 개`;
            },
            backgroundColor: themeColors.labelBackgroundColor,
            borderRadius: 5,
            color: themeColors.textColor,
            display: showDataLabel,
            font: {
              weight: 'bold',
              size: 9,
            },
            padding: 3,
            anchor: 'center',
            align: 'top',
          },
        })),
    };

    return datasets;
  }, [dataList, chartType, colors, bgColors, isPrev]);

  useLoading({ loading: isFetching, message: '데이터 로딩중', trigger: showLoading });

  return { data: chartData, dataList, isFetching };
};

export default useProductChartData10;
