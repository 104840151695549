import React, { useEffect, useMemo } from 'react';
import useTagChartMesData10 from 'common/hooks/useGroupChartMesData10';
import { useRecoilValue } from 'recoil';
import { flatGroupState } from 'states';
import _ from 'lodash';
import Data10ChartCard, { Data10ChartCardProps } from './Data10ChartCard';

export type MesTagData10CardProps = Data10ChartCardProps & {
  groupCode?: string;
};

MesTagData10Card.defaultProps = {
  groupCode: undefined,
};

export default function MesTagData10Card({ groupCode, ...props }: MesTagData10CardProps) {
  const groups = useRecoilValue(flatGroupState);
  const selectedGroup = useMemo(() => _.find(groups, (group) => group.groupCode === groupCode), [groups, groupCode]);
  const { lineColors, bgColors, dateProps, aggUnit, fill } = props;

  const {
    data: dataset,
    dataList,
    isFetching,
    annotation,
  } = useTagChartMesData10({
    colors: lineColors,
    dateProps,
    fill,
    bgColors,
    aggUnit,
    group: selectedGroup,
  });

  return (
    <Data10ChartCard
      chartType="line"
      dataset={dataset}
      gridData={dataList}
      annotation={annotation}
      isFetching={isFetching}
      useGridRowData={false}
      label={selectedGroup?.groupName}
      {...props}
    />
  );
}
