import React, { useMemo } from 'react';
import { findDataAPI } from 'apis/data';
import { ChartData, ChartDataset } from 'chart.js';
import { useQuery } from 'react-query';
import { CHART_COLORS, CHART_COLORS_BG } from '@const';
import { DateSearchProps } from 'components/common/DateSearchBox';
import { IData1, IGroup, TData1ChartResponse } from '@types';
import { getDateRange } from 'common/lib';
import _ from 'lodash';
import { useTheme } from '@mui/material';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import useLoading from './useLoading';

export type Data10AggType = 'mean' | 'min' | 'max' | 'sum' | 'count';
export type AggUnitType = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';

type Props = {
  group?: IGroup;
  dateProps?: DateSearchProps;
  colors?: string[];
  bgColors?: string[];
  fill?: boolean;
  showLoading?: boolean;
  showAnnotation?: boolean;
  aggUnit?: AggUnitType;
};

const useGroupChartMesData10 = ({
  group = undefined,
  showLoading = false,
  colors,
  bgColors,
  fill,
  dateProps = undefined,
  aggUnit,
}: Props) => {
  const theme = useTheme();

  const mesTags = useMemo(() => {
    const mesTag = group?.groupFieldDatas.find((gfd) => gfd.field.code === 'FIELD_MES_MEASURE_TAG'); // 계측데이터
    const ptolTag = group?.groupFieldDatas.find((gfd) => gfd.field.code === 'FIELD_MES_MEASURE_PTOL_TAG'); // 공차상한
    const ntolTag = group?.groupFieldDatas.find((gfd) => gfd.field.code === 'FIELD_MES_MEASURE_NTOL_TAG'); // 공차하한
    const lclTag = group?.groupFieldDatas.find((gfd) => gfd.field.code === 'FIELD_MES_MEASURE_LCL_TAG'); // 관리하한
    const uclTag = group?.groupFieldDatas.find((gfd) => gfd.field.code === 'FIELD_MES_MEASURE_UCL_TAG'); // 관리상한

    return {
      mesTag,
      ptolTag,
      ntolTag,
      lclTag,
      uclTag,
    };
  }, [group]);

  const themeColors = useMemo(() => {
    if (theme.palette.mode === 'dark') {
      return {
        textColor: '#eee',
        labelBackgroundColor: 'rgba(255, 255, 255, 0.1)',
      };
    }

    return {
      textColor: '#333',
      labelBackgroundColor: 'rgba(0, 0, 0, 0.1)',
    };
  }, [theme.palette.mode]);

  const createNullFetch = (): Promise<TData1ChartResponse> =>
    new Promise((resolve, reject) => {
      resolve({
        success: true,
      });
    });

  const onFetchData = (): Promise<TData1ChartResponse> => {
    if (!dateProps) return createNullFetch();
    if (!group) return createNullFetch();
    if (!mesTags) return createNullFetch();

    return findDataAPI({
      tagTypes: [
        { tag: mesTags.mesTag?.data ?? '', aggFunc: 'min' },
        { tag: mesTags.mesTag?.data ?? '', aggFunc: 'max' },
        { tag: mesTags.mesTag?.data ?? '', aggFunc: 'mean' },
        { tag: mesTags.ptolTag?.data ?? '', aggFunc: 'mean' },
        { tag: mesTags.ntolTag?.data ?? '', aggFunc: 'mean' },
        { tag: mesTags.uclTag?.data ?? '', aggFunc: 'mean' },
        { tag: mesTags.lclTag?.data ?? '', aggFunc: 'mean' },
      ],
      isIncrease: false,
      aggUnit: 'minute',
      aggVal: 15,
      ...getDateRange(dateProps),
    });
  };

  const { data: dataList, isFetching } = useQuery(['measureData10', group, dateProps, aggUnit], onFetchData, {
    enabled: Boolean(group),
    refetchInterval: 60 * 1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const mesDataList = useMemo(() => {
    return {
      mesMinTag: _.find(
        dataList?.data?.datasets,
        (dataset) => dataset.tag.tagCode === mesTags.mesTag?.data && dataset?.aggType === 'min',
      ),
      mesMaxTag: _.find(
        dataList?.data?.datasets,
        (dataset) => dataset.tag.tagCode === mesTags.mesTag?.data && dataset?.aggType === 'max',
      ),
      mesMeanTag: _.find(
        dataList?.data?.datasets,
        (dataset) => dataset.tag.tagCode === mesTags.mesTag?.data && dataset?.aggType === 'mean',
      ),
      ntolTag: _.find(dataList?.data?.datasets, (dataset) => dataset.tag.tagCode === mesTags.ntolTag?.data),
      ptolTag: _.find(dataList?.data?.datasets, (dataset) => dataset.tag.tagCode === mesTags.ptolTag?.data),
      uclTag: _.find(dataList?.data?.datasets, (dataset) => dataset.tag.tagCode === mesTags.uclTag?.data),
      lclTag: _.find(dataList?.data?.datasets, (dataset) => dataset.tag.tagCode === mesTags.lclTag?.data),
    };
  }, [mesTags, dataList]);

  const createData = (data: IData1[]) => {
    return data.map((dt) => ({
      x: dt.dtt,
      y: dt.value,
    }));
  };

  const chartData = useMemo((): ChartData<'line' | 'bar' | 'scatter', any> => {
    const datasetList: ChartDataset<'line' | 'bar' | 'scatter', any>[] = [];
    const tempData = dataList?.data?.datasets ?? [];

    if (tempData.length < 1) {
      return {
        labels: [],
        datasets: [],
      };
    }

    if (mesDataList.mesMinTag) {
      datasetList.push({
        fill,
        type: 'line',
        label: '계측-min',
        data: createData(mesDataList.mesMinTag?.data),
        borderColor: colors && colors.length >= tempData.length ? colors[1] : CHART_COLORS[1],
        backgroundColor: bgColors && bgColors.length >= tempData.length ? bgColors[1] : CHART_COLORS_BG[1],
        yAxisID: 'y',
        spanGaps: true,
        pointRadius: 0,
        borderWidth: 1,
        borderDash: [2, 3],
        borderCapStyle: 'round',
      });
    }

    if (mesDataList.mesMaxTag) {
      datasetList.push({
        fill,
        type: 'line',
        label: '계측-max',
        data: createData(mesDataList.mesMaxTag?.data),
        borderColor: colors && colors.length >= tempData.length ? colors[1] : CHART_COLORS[1],
        backgroundColor: bgColors && bgColors.length >= tempData.length ? bgColors[1] : CHART_COLORS_BG[1],
        yAxisID: 'y',
        spanGaps: true,
        pointRadius: 0,
        borderWidth: 1,
        borderDash: [2, 3],
        borderCapStyle: 'round',
      });
    }

    if (mesDataList.mesMeanTag) {
      datasetList.push({
        fill,
        type: 'line',
        label: '계측-avg',
        data: createData(mesDataList.mesMeanTag?.data),
        borderColor: colors && colors.length >= tempData.length ? colors[0] : CHART_COLORS[0],
        backgroundColor: bgColors && bgColors.length >= tempData.length ? bgColors[0] : CHART_COLORS_BG[0],
        yAxisID: 'y',
        spanGaps: true,
        pointRadius: 3,
      });
    }

    return { datasets: datasetList };
  }, [mesDataList, colors, group, themeColors]);

  const chartAnnotation: AnnotationOptions[] = useMemo(() => {
    const chartAnnotations: AnnotationOptions[] = [];

    const tempData =
      [mesDataList.ntolTag, mesDataList.ptolTag, mesDataList.lclTag, mesDataList.uclTag].filter(
        (data) => data !== undefined,
      ) ?? [];
    if (tempData.length < 1) {
      return chartAnnotations;
    }

    // 공차상한 - 어노테이션
    if (mesDataList.ptolTag) {
      const lastValue = mesDataList.ptolTag.data.at(-1)?.value;
      if (lastValue) {
        chartAnnotations.push({
          type: 'line',
          yMin: lastValue,
          yMax: lastValue,
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 1,
          label: {
            backgroundColor: themeColors.labelBackgroundColor,
            color: themeColors.textColor,
            content: `공차상한 ${lastValue.toFixed(1)}`,
            position: 'start',
            enabled: true,
          },
        });
      }
    }

    // 공차하한 - 어노테이션
    if (mesDataList.ntolTag) {
      const lastValue = mesDataList.ntolTag.data.at(-1)?.value;
      if (lastValue) {
        chartAnnotations.push({
          type: 'line',
          yMin: lastValue,
          yMax: lastValue,
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 1,
          label: {
            backgroundColor: themeColors.labelBackgroundColor,
            color: themeColors.textColor,
            content: `공차하한 ${lastValue.toFixed(1)}`,
            position: 'start',
            enabled: true,
          },
        });
      }
    }

    // 관리상한 - 어노테이션
    if (mesDataList.uclTag) {
      const lastValue = mesDataList.uclTag.data.at(-1)?.value;
      if (lastValue) {
        chartAnnotations.push({
          type: 'line',
          yMin: lastValue,
          yMax: lastValue,
          borderColor: 'red',
          borderWidth: 1,
          label: {
            backgroundColor: themeColors.labelBackgroundColor,
            color: themeColors.textColor,
            content: `관리상한 ${lastValue.toFixed(1)}`,
            position: 'end',
            enabled: true,
          },
        });
      }
    }

    // 관리하한 - 어노테이션
    if (mesDataList.lclTag) {
      const lastValue = mesDataList.lclTag.data.at(-1)?.value;
      if (lastValue) {
        chartAnnotations.push({
          type: 'line',
          yMin: lastValue,
          yMax: lastValue,
          borderColor: 'red',
          borderWidth: 1,
          label: {
            backgroundColor: themeColors.labelBackgroundColor,
            color: themeColors.textColor,
            content: `관리하한 ${lastValue.toFixed(1)}`,
            position: 'end',
            textAlign: 'center',
            enabled: true,
          },
        });
      }
    }

    return chartAnnotations;
  }, [themeColors, mesDataList]);

  useLoading({ loading: isFetching, message: '데이터 로딩중', trigger: showLoading });

  return { data: chartData, annotation: chartAnnotation, dataList, isFetching };
};

export default useGroupChartMesData10;
