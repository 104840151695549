import { ENDPOINT } from '@const';
import { TTaskHistCreateRequest, TTaskHistResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const createTaskHistAPI = async (request: TTaskHistCreateRequest): Promise<TTaskHistResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.taskhist.createTaskHist,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('작업 이력 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyTaskHistAPI = async (request: TTaskHistCreateRequest): Promise<TTaskHistResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.taskhist.modifyTaskHist,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('작업 이력 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteTaskHistAPI = async (id: number): Promise<TTaskHistResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.taskhist.deleteTaskHist,
      ...createAuth(),
      url: ENDPOINT.taskhist.deleteTaskHist.url.replace('{id}', id.toString()),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('작업 이력 삭제 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};
