import { Add } from '@mui/icons-material';
import { Box, Chip, IconButton, SxProps, Theme } from '@mui/material';
import { IThreeCanvas } from '@types';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ThreeCanvasSelectBox from './ThreeCanvasSelectBox';

type Props = {
  name: string;
  data?: IThreeCanvas[];
  defaultValue?: number[];
  label?: string;
  onChange?(name: string, data: number[]): void;
  sx?: SxProps<Theme>;
};

ThreeCanvasMultiSelectBox.defaultProps = {
  data: [],
  label: undefined,
  defaultValue: [],
  sx: {},
  onChange: undefined,
};

export default function ThreeCanvasMultiSelectBox({ onChange, name, label, data, sx, defaultValue }: Props) {
  const [selected, setSelected] = useState<IThreeCanvas | undefined>(undefined);
  const [canvasIds, setCanvasIds] = useState<number[]>(defaultValue || []);

  const canvasList = useMemo(() => data?.filter((canvas) => canvasIds.includes(canvas.id)), [data, canvasIds]);

  const handleAddCanvasId = () => {
    if (selected) {
      setCanvasIds((prev) => {
        const newState = _.uniqBy([...prev, selected.id], (p) => p);
        handleChange(newState);
        return newState;
      });

      setSelected(undefined);
    }
  };

  const handleDeleteCanvasId = (id: number) => {
    setCanvasIds((prev) => {
      const newState = prev.filter((p) => p !== id);
      handleChange(newState);
      return newState;
    });
  };

  const handleChange = (ids: number[]) => {
    if (typeof onChange === 'function') {
      onChange(name, ids);
    }
  };

  const renderChip = useMemo(
    () =>
      canvasList?.map((canvas) => (
        <Chip
          key={canvas.id}
          label={`${canvas.site} | ${canvas.name}`}
          onDelete={() => handleDeleteCanvasId(canvas.id)}
        />
      )),
    [canvasList],
  );

  return (
    <Box component="div" display="flex" flexDirection="column" sx={sx}>
      <Box component="div" display="flex" flexDirection="row" alignItems="center">
        <ThreeCanvasSelectBox
          label={label}
          data={data}
          value={selected?.id.toString() || ''}
          onSelectThreeCanvas={(name, item) => setSelected(item)}
        />
        <IconButton disabled={!selected} onClick={handleAddCanvasId}>
          <Add />
        </IconButton>
      </Box>
      <Box component="div" display="flex" sx={{ marginX: 0.5, mt: 1 }}>
        {renderChip}
      </Box>
    </Box>
  );
}
