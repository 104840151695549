import React, { useMemo, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { IVRTour, TVRTourCreateRequest, TVRTourResponse } from '@types';
import { createVRTourAPI, deleteVRTourAPI, findAllVRTourAPI, modifyVRTourAPI } from 'apis/vrtour';
import VRTourCard from 'components/vrtour/VRTourCard';
import VRTourSaveModal from 'components/vrtour/VRTourSaveModal';
import { SystemLayout } from 'layout/system';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

export default function VRTourPage() {
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedVRTour(undefined);
  };

  const handleEdit = (vrtour: IVRTour) => {
    setSelectedVRTour(vrtour);
    handleModalOpen();
  };

  const handleSave = (request: TVRTourCreateRequest) => {
    if (request.id && request.id > 0) {
      modifyMutation.mutate(request);
    } else {
      createMutation.mutate(request);
    }
  };

  const handleDelete = (vrtour: IVRTour) => {
    deleteMutation.mutate(vrtour.id);
  };

  const onSaveSuccess = (result: TVRTourResponse) => {
    if (result && result.success) {
      toast('저장성공', { type: 'success' });
      handleModalClose();
      refetch();
    }
  };

  const onDeleteSuccess = (result: TVRTourResponse) => {
    if (result && result.success) {
      toast('삭제성공', { type: 'success' });
      refetch();
    }
  };

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedVRTour, setSelectedVRTour] = useState<IVRTour | undefined>(undefined);
  const { data, refetch } = useQuery(['vrtours'], findAllVRTourAPI);

  const createMutation = useMutation(createVRTourAPI, {
    onSuccess: onSaveSuccess,
  });

  const modifyMutation = useMutation(modifyVRTourAPI, {
    onSuccess: onSaveSuccess,
  });

  const deleteMutation = useMutation(deleteVRTourAPI, {
    onSuccess: onDeleteSuccess,
  });

  const renderItems = useMemo(
    () =>
      data?.data?.map((vrtour) => (
        <VRTourCard
          key={vrtour.id}
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={4}
          data={vrtour}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )),
    [data?.data],
  );

  return (
    <SystemLayout title="VR투어">
      <Button fullWidth variant="contained" onClick={handleModalOpen}>
        <Typography>VR투어 추가</Typography>
      </Button>
      <Grid container sx={{ mt: 2 }} spacing={2}>
        {renderItems}
      </Grid>
      <VRTourSaveModal data={selectedVRTour} open={modalOpen} onClose={handleModalClose} onSave={handleSave} />
    </SystemLayout>
  );
}
