import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Grid, IconButton, TextField } from '@mui/material';
import { ICostItemMonth, TCostItemMonthCreateRequest } from '@types';
import { Delete, Save } from '@mui/icons-material';

type Props = {
  item: number;
  data?: ICostItemMonth;
  onSave?(request: TCostItemMonthCreateRequest): void;
  onDelete?(id: number): void;
};

CostItemMonthForm.defaultProps = {
  data: undefined,
  onSave: undefined,
  onDelete: undefined,
};

export default function CostItemMonthForm({ item, data, onSave, onDelete }: Props) {
  const [state, setState] = useState<TCostItemMonthCreateRequest>({});

  const handleSaveClick = () => {
    if (typeof onSave === 'function') {
      onSave(state);
    }

    if (!data) {
      setState({ item });
    }
  };

  const handleDeleteClick = () => {
    if (typeof onDelete === 'function' && data?.id && window.confirm(`${state.name}를 정말 삭제하시겠습니까?`)) {
      onDelete(data.id);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (data) {
      setState({ ...data });
    } else {
      setState({});
    }
  }, [data]);

  useEffect(() => {
    setState((prev) => ({ ...prev, item }));
  }, [item]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={3}>
        <TextField
          name="name"
          label="이름"
          variant="standard"
          value={state.name ?? ''}
          fullWidth
          required
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <TextField
          name="startMonth"
          label="시작월"
          variant="standard"
          value={state.startMonth ?? ''}
          type="number"
          fullWidth
          required
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <TextField
          name="endMonth"
          label="종료월"
          variant="standard"
          value={state.endMonth ?? ''}
          type="number"
          fullWidth
          required
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Box component="div" display="flex">
          <IconButton onClick={handleSaveClick}>
            <Save />
          </IconButton>
          <IconButton onClick={handleDeleteClick} disabled={Boolean(!data?.id)}>
            <Delete />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
}
