import { StandaloneSearchBox } from '@react-google-maps/api';
import { ILocation } from '@types';
import React, { useState } from 'react';

type Props = {
  onLocaionSearched?(location: ILocation): void;
};

PlaceSearchBox.defaultProps = {
  onLocaionSearched: undefined,
};

export default function PlaceSearchBox({ onLocaionSearched }: Props) {
  const [state, setState] = useState<google.maps.places.SearchBox>();

  const handlePlaceChanged = () => {
    if (!state) return;

    const place = state.getPlaces()?.at(0);

    if (!place) return;

    const searchLocation: ILocation = {
      lat: place.geometry?.location?.lat(),
      lng: place.geometry?.location?.lng(),
    };

    if (!searchLocation.lat || !searchLocation.lng) return;

    if (typeof onLocaionSearched === 'function') {
      onLocaionSearched(searchLocation);
    }
  };

  return (
    <StandaloneSearchBox onLoad={setState} onPlacesChanged={handlePlaceChanged}>
      <input
        type="text"
        placeholder="원하는 위치를 검색하세요."
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `240px`,
          height: `32px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
          position: 'absolute',
          left: '50%',
          marginLeft: '-120px',
        }}
      />
    </StandaloneSearchBox>
  );
}
