import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { IThreeModel, TThreemodelCreateRequest } from '@types';
import BaseCheckbox from 'components/common/BaseCheckbox';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import BaseSelectBox from 'components/common/BaseSelectBox';
import FileUploadBox from 'components/file/FileUploadBox';
import SiteSelectBox from 'components/site/SiteSelectBox';
import { useRecoilValue } from 'recoil';
import { flatSiteState } from 'states';

type Props = Omit<IBaseModalProps, 'onDelete' | 'onConfirm'> & {
  data?: IThreeModel | null;
  onConfirm?(data: TThreemodelCreateRequest): void;
  onDelete?(id: number): void;
};

ThreeModelSaveModal.defaultProps = {
  data: null,
  onConfirm: undefined,
  onDelete: undefined,
};

export default function ThreeModelSaveModal({ data, onConfirm, onDelete, open, ...others }: Props) {
  const flatSite = useRecoilValue(flatSiteState);
  const [state, setState] = useState<TThreemodelCreateRequest>({});

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setState((prev) => ({ ...prev, [name]: checked }));
  };

  const onChangeSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onFileChange = (name: string, files?: File[]) => {
    if (files?.at(0)) {
      setState((prev) => ({ ...prev, file: files.at(0) }));
    }
  };

  const handleSave = () => {
    if (typeof onConfirm === 'function') {
      onConfirm(state);
    }
  };

  const handleDelete = () => {
    if (data && data.id && typeof onDelete === 'function') {
      if (window.confirm('3D 모델을 삭제하시겠습니까?')) {
        onDelete(data.id);
      }
    }
  };

  useEffect(() => {
    if (data) {
      setState({ ...data, file: undefined });
    } else {
      setState({});
    }
  }, [data]);

  useEffect(() => {
    if (!open) {
      setState({});
    }
  }, [open]);

  return (
    <BaseModal
      {...others}
      open={open}
      onConfirm={handleSave}
      onDelete={data ? handleDelete : undefined}
      title="3D 모델 저장"
    >
      <Box
        component="div"
        sx={{ width: 400, height: 400 }}
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
      >
        <SiteSelectBox
          name="site"
          label="사이트"
          fullWidth
          showEmpty
          data={flatSite}
          defaultValue={data?.site}
          value={state.site}
          onChange={onChangeSelect}
        />
        <TextField
          name="name"
          fullWidth
          variant="standard"
          label="이름"
          onChange={onChangeInput}
          defaultValue={data?.name}
          value={state.name}
          required
        />
        <BaseSelectBox
          name="type"
          label="File Type"
          showEmpty
          defaultValue={data?.type || ''}
          items={[
            // { id: 'FBX', name: 'FBX' },
            // { id: 'OBJ', name: 'OBJ' },
            { id: 'GLTF', name: 'GLTF' },
          ]}
          onChange={onChangeSelect}
          value={state.type}
          required
        />
        <FileUploadBox
          name="file"
          containerSx={{ marginTop: 1 }}
          fieldProps={{ variant: 'standard' }}
          onChange={onFileChange}
          downloadFiles={data?.file ? [data.file] : []}
          singleSelect
        />
        <TextField
          name="scale"
          fullWidth
          variant="standard"
          label="모델 스케일"
          type="number"
          onChange={onChangeInput}
          defaultValue={data?.scale}
          value={state.scale}
          required
        />
        <BaseCheckbox
          title="소품 여부"
          defaultChecked={data?.isDecoration}
          name="isDecoration"
          checked={state.isDecoration}
          onChange={onChangeChecked}
        />
      </Box>
    </BaseModal>
  );
}
