import { ENDPOINT } from '@const';
import {
  TCostCreateRequest,
  TCostItemCreateRequest,
  TCostItemMonthCreateRequest,
  TCostItemMonthResponse,
  TCostItemResponse,
  TCostItemTimeCreateRequest,
  TCostItemTimeResponse,
  TCostListResponse,
  TCostResponse,
} from '@types';
import axios from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const findAllCostAPI = async (): Promise<TCostListResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.cost.findAllCost,
      ...createAuth(),
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('요금 데이터 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const saveCostAPI = async (request: TCostCreateRequest): Promise<TCostResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.cost.saveCost,
      ...createAuth(),
      data: request,
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('요금 데이터 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteCostAPI = async (code: string): Promise<TCostResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.cost.deleteCost,
      url: ENDPOINT.cost.deleteCost.url.replace('{code}', code),
      ...createAuth(),
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('요금 데이터 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const saveCostItemAPI = async (request: TCostItemCreateRequest): Promise<TCostItemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.cost.saveCostItem,
      ...createAuth(),
      data: request,
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('요금 데이터 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteCostItemAPI = async (id: number): Promise<TCostItemResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.cost.deleteCostItem,
      url: ENDPOINT.cost.deleteCostItem.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('요금 데이터 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const saveCostItemMonthAPI = async (request: TCostItemMonthCreateRequest): Promise<TCostItemMonthResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.cost.saveCostItemMonth,
      ...createAuth(),
      data: request,
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('요금 데이터 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteCostItemMonthAPI = async (id: number): Promise<TCostItemMonthResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.cost.deleteCostItemMonth,
      url: ENDPOINT.cost.deleteCostItemMonth.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('요금 데이터 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const saveCostItemTimeAPI = async (request: TCostItemTimeCreateRequest): Promise<TCostItemTimeResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.cost.saveCostItemTime,
      ...createAuth(),
      data: request,
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('요금 데이터 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteCostItemTimeAPI = async (id: number): Promise<TCostItemTimeResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.cost.deleteCostItemTime,
      url: ENDPOINT.cost.deleteCostItemTime.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('요금 데이터 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};
