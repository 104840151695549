import { Box, Button, Card, Chip, Typography } from '@mui/material';
import { ITag, TTagPageRequest, TTagSearchField } from '@types';
import { findTagAPI } from 'apis/tag';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import BaseTablePagination from 'components/common/BaseTablePagination';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import TagListItemCard from './TagListItemCard';
import TagSearchField from './TagSearchField';

type Props = Omit<IBaseModalProps, 'onConfirm'> & {
  onConfirm?(tags: ITag[]): void;
  singleTag?: boolean;
};

TagSearchModal.defaultProps = {
  onConfirm: undefined,
  singleTag: false,
};

export default function TagSearchModal({ onConfirm, singleTag, open, ...others }: Props) {
  // 검색아이템 Check 상태 변경
  const handleCheckChange = (id: string, checked: boolean) => {
    if (checked) {
      const selectedTag = searchData.filter((tag) => tag.tagCode === id);

      if (singleTag) {
        setCheckedTags([...selectedTag]);
      } else {
        setCheckedTags((prev) => [...prev, ...selectedTag]);
      }
    } else {
      setCheckedTags((prev) => prev.filter((tag) => tag.tagCode !== id));
    }
  };

  // 최종 선택된 Tag 에 대한 추가 이벤트
  const handleAddClick = () => {
    if (typeof onConfirm === 'function') {
      onConfirm(checkedTags);
    }
  };
  // 검색된 모든 태그 선택
  const handleCheckAll = () => {
    setCheckedTags((prev) => {
      const newArr = new Set([...prev, ...(data?.data?.content ?? [])]);
      return Array.from(newArr);
    });
  };
  const handleUnCheckAll = () => {
    setCheckedTags((prev) => {
      const unCheckDataId = (data?.data?.content ?? []).map((tag) => tag.tagCode);
      return prev.filter((ptag) => !unCheckDataId.includes(ptag.tagCode));
    });
  };

  // 검색정보 변경 이벤트
  const handleSearchChange = (search: TTagSearchField) => {
    setPageInfo((prev) => ({ ...prev, search }));
  };
  // 페이지 로우 갯수 변경 이벤트
  const handleRowsPerPageChange = (size: number) => {
    setPageInfo((prev) => ({ ...prev, size }));
  };
  // 페이지 번호 변경 이벤트
  const handlePageChange = (page: number) => {
    setPageInfo((prev) => ({ ...prev, page }));
  };

  // PageInfo Reset
  const handlePageInfoReset = () => {
    setPageInfo({
      page: 0,
      size: 10,
    });
  };

  const [checkedTags, setCheckedTags] = useState<ITag[]>([]);
  const [pageInfo, setPageInfo] = useState<TTagPageRequest>({
    page: 0,
    size: 10,
  });
  const { data, refetch } = useQuery(['tags', pageInfo], () => findTagAPI(pageInfo), {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const searchData = useMemo(() => data?.data?.content ?? [], [data]);

  const renderTagsCard = useMemo(
    () => (
      <Card variant="outlined" sx={{ height: 300, overflowY: 'auto', p: 1 }}>
        {searchData.map((tag) => (
          <TagListItemCard
            key={tag.tagCode}
            checked={checkedTags.find((ftag) => ftag.tagCode === tag.tagCode) !== undefined}
            onCheck={handleCheckChange}
            data={tag}
          />
        ))}
      </Card>
    ),
    [checkedTags, searchData],
  );

  useEffect(() => {
    if (open) {
      refetch();
    }
    if (!open) {
      handlePageInfoReset();
      setCheckedTags([]);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, pageInfo]);

  return (
    <BaseModal
      {...others}
      title="태그검색"
      confirmTitle="추가"
      cancleTitle="취소"
      onConfirm={handleAddClick}
      open={open}
    >
      <Box component="div" sx={{ width: 800 }}>
        <TagSearchField onChange={handleSearchChange} />
        <Box
          component="div"
          display="flex"
          flexWrap="wrap"
          sx={{ p: 1, minHeight: 40, maxHeight: 100, overflowY: 'auto' }}
        >
          {checkedTags.map((tag) => (
            <Chip
              sx={{ m: 0.5 }}
              key={tag.tagCode}
              label={tag.tagCode}
              onDelete={() => handleCheckChange(tag.tagCode, false)}
            />
          ))}
        </Box>
        {!singleTag &&
          (checkedTags.length > 0 ? (
            <Button onClick={handleUnCheckAll}>
              <Typography>UNSELECT ALL</Typography>
            </Button>
          ) : (
            <Button onClick={handleCheckAll}>
              <Typography>SELECT ALL</Typography>
            </Button>
          ))}
        {renderTagsCard}
        <BaseTablePagination
          page={pageInfo.page}
          rowsPerPage={pageInfo.size}
          totalCount={data?.data?.totalElements}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </BaseModal>
  );
}
