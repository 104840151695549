import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Box, Grid, SelectChangeEvent, TextField } from '@mui/material';
import {
  IDashboard,
  IDashboardItem,
  IDashboardItemData,
  TDashboardItemCreateRequest,
  TDashboardItemResponse,
} from '@types';
import { createDashboardItemAPI, modifyDashboardItemAPI } from 'apis/dashboarditem';
import AlarmHistSearchField from 'components/alarmhist/AlarmHistSearchField';
import BaseCheckbox from 'components/common/BaseCheckbox';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import BaseSelectBox from 'components/common/BaseSelectBox';
import DateSearchBox from 'components/common/DateSearchBox';
import AggTypeSelectBox from 'components/data/AggTypeSelectBox';
import NodeSystemMultiSelectBox from 'components/nodesystem/NodeSystemMultiSelectBox';
import TagMutlipleSearchField from 'components/tag/TagMultipleSearchField';
import ThreeCanvasMultiSelectBox from 'components/threecanvas/ThreeCanvasMultiSelectBox';
import VRTourSelectBox from 'components/vrtour/VRTourSelectBox';
import moment from 'moment';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { flatSiteState, nodeSystemState, threeCanvasState } from 'states';
import ChartTypeSelectBox from 'components/data/ChartTypeSelectBox';
import PieTypeSelectBox from 'components/data/PieTypeSelectBox';
import GaugeLevelForm from 'components/data/GaugeLevelForm';
import ProductSearchForm from 'components/product/ProductSearchForm';
import CostSelectBox from 'components/cost/CostSelectBox';
import SiteSelectBox from 'components/site/SiteSelectBox';
import ProductSelecBox from 'components/product/ProductSelectBox';
import LocationInput from 'components/googlemap/LocationInput';
import GroupMultipleSelectInput from 'components/group/GroupMultipleSelectInput';
import MatterportSelectBox from 'components/matterport/MatterportSelectBox';

type Props = IBaseModalProps & {
  dashboard?: IDashboard | null;
  data?: IDashboardItem | null;
  onSaveComplete?(dashboard: IDashboardItem | undefined): void;
};

DashboardItemSaveModal.defaultProps = {
  dashboard: undefined,
  data: undefined,
  onSaveComplete: undefined,
};

export default function DashboardItemSaveModal({ dashboard, data, open, onSaveComplete, ...others }: Props) {
  const [state, setState] = useState<TDashboardItemCreateRequest>({});
  const nodeSystems = useRecoilValue(nodeSystemState);
  const threeCanvas = useRecoilValue(threeCanvasState);
  const flatSite = useRecoilValue(flatSiteState);

  const handleSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeData = (name: keyof IDashboardItemData, data: any) => {
    setState((prev) => ({ ...prev, data: { ...prev.data, [name]: data } }));
  };

  const handleSaveClick = () => {
    if (state.id && state.id > 0) {
      modifyMutation.mutate({ ...state });
    } else {
      createMutation.mutate({ ...state, dashboard: dashboard?.id, orderNum: (dashboard?.items.length || 0) + 1 });
    }
  };

  useEffect(() => {
    if (!open) {
      setState({});
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      setState(data);
    } else {
      setState({});
    }
  }, [data]);

  const renderForm = useMemo(() => {
    switch (state.type) {
      case 'DATA1_CHART':
        return (
          <Box component="div">
            <TagMutlipleSearchField
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
          </Box>
        );
      case 'DATA10_CHART':
        return (
          <Box component="div">
            <TagMutlipleSearchField
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
            <DateSearchBox
              {...state.data?.dateSearch}
              start={moment(state.data?.dateSearch?.start)}
              end={moment(state.data?.dateSearch?.end)}
              onChange={(dateSearch) => handleChangeData('dateSearch', dateSearch)}
            />
            <ChartTypeSelectBox
              name="chartType"
              sx={{ minWidth: 100, mr: 2 }}
              fullWidth={false}
              value={state.data?.chartType}
              onChange={(e) => handleChangeData('chartType', e.target.value)}
            />
            <AggTypeSelectBox
              name="aggType"
              sx={{ minWidth: 100, mr: 2 }}
              fullWidth={false}
              value={state.data?.aggType || undefined}
              onChange={(e) => handleChangeData('aggType', e.target.value)}
            />
            <BaseCheckbox
              name="isIncrease"
              label="적산값 처리"
              checked={state.data?.isIncrease}
              onChange={(e) => handleChangeData('isIncrease', e.target.checked)}
            />
            <BaseCheckbox
              name="showPrev"
              label="이전값 같이 보기"
              checked={state.data?.showPrev}
              onChange={(e) => handleChangeData('showPrev', e.target.checked)}
            />
            <BaseCheckbox
              name="showLegend"
              label="Legend 표시"
              checked={state.data?.showLegend}
              onChange={(e) => handleChangeData('showLegend', e.target.checked)}
            />
          </Box>
        );
      case 'PREV_DAY':
        return (
          <Box component="div">
            <TagMutlipleSearchField
              isSingleTag
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
            <AggTypeSelectBox
              name="aggType"
              fullWidth
              value={state.data?.aggType || undefined}
              onChange={(e) => handleChangeData('aggType', e.target.value)}
            />
            <ChartTypeSelectBox
              name="chartType"
              sx={{ minWidth: 100, mr: 2 }}
              fullWidth={false}
              value={state.data?.chartType}
              onChange={(e) => handleChangeData('chartType', e.target.value)}
            />
            <BaseCheckbox
              name="isIncrease"
              label="증가값"
              checked={state.data?.isIncrease}
              onChange={(e) => handleChangeData('isIncrease', e.target.checked)}
            />
          </Box>
        );
      case 'PREV_MONTH':
        return (
          <Box component="div">
            <TagMutlipleSearchField
              isSingleTag
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
            <AggTypeSelectBox
              name="aggType"
              fullWidth
              value={state.data?.aggType || undefined}
              onChange={(e) => handleChangeData('aggType', e.target.value)}
            />
            <ChartTypeSelectBox
              name="chartType"
              sx={{ minWidth: 100, mr: 2 }}
              fullWidth={false}
              value={state.data?.chartType}
              onChange={(e) => handleChangeData('chartType', e.target.value)}
            />
            <BaseCheckbox
              name="isIncrease"
              label="증가값"
              checked={state.data?.isIncrease}
              onChange={(e) => handleChangeData('isIncrease', e.target.checked)}
            />
          </Box>
        );
      case 'PREV_YEAR':
        return (
          <Box component="div">
            <TagMutlipleSearchField
              isSingleTag
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
            <AggTypeSelectBox
              name="aggType"
              fullWidth
              value={state.data?.aggType || undefined}
              onChange={(e) => handleChangeData('aggType', e.target.value)}
            />
            <ChartTypeSelectBox
              name="chartType"
              sx={{ minWidth: 100, mr: 2 }}
              fullWidth={false}
              value={state.data?.chartType}
              onChange={(e) => handleChangeData('chartType', e.target.value)}
            />
            <BaseCheckbox
              name="isIncrease"
              label="증가값"
              checked={state.data?.isIncrease}
              onChange={(e) => handleChangeData('isIncrease', e.target.checked)}
            />
          </Box>
        );
      case 'LAST_DATA_VALUE':
        return (
          <Box component="div">
            <TagMutlipleSearchField
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
            <DateSearchBox
              {...state.data?.dateSearch}
              start={moment()}
              end={moment()}
              onChange={(dateSearch) => handleChangeData('dateSearch', dateSearch)}
              menu={[
                'dayLast',
                'monthLast',
                'yearLast',
                'currentDayASC',
                'currentDayDESC',
                'currentMonthASC',
                'currentMonthDESC',
                'currentYearASC',
                'currentYearDESC',
              ]}
            />
            {['dayLast', 'monthLast', 'yearLast'].includes(state.data?.dateSearch?.type ?? '') && (
              <AggTypeSelectBox
                showEmpty
                defaultValue={state.data?.aggType ?? ''}
                value={state.data?.aggType}
                onChange={(e) => handleChangeData('aggType', e.target.value)}
              />
            )}
            <BaseCheckbox
              name="isIncrease"
              label="증가값"
              checked={state.data?.isIncrease}
              onChange={(e) => handleChangeData('isIncrease', e.target.checked)}
            />
            <BaseCheckbox
              name="showPrev"
              label="이전값 같이 보기"
              checked={state.data?.showPrev}
              onChange={(e) => handleChangeData('showPrev', e.target.checked)}
            />
            <BaseCheckbox
              name="prevColorReverse"
              label="이전값 대비 색상 반전"
              checked={state.data?.prevColorReverse}
              onChange={(e) => handleChangeData('prevColorReverse', e.target.checked)}
            />
          </Box>
        );
      case 'NODE_AND_3D':
        return (
          <Box component="div">
            <NodeSystemMultiSelectBox
              name="nodes"
              defalutValues={state.data?.nodes}
              data={nodeSystems}
              label="2D 계통유형"
              onChange={handleChangeData}
            />
            <ThreeCanvasMultiSelectBox
              name="canvases"
              defaultValue={state.data?.canvases}
              data={threeCanvas}
              label="3D 계통유형"
              sx={{ mt: 1 }}
              onChange={handleChangeData}
            />
          </Box>
        );
      case 'ALARM_STATS':
        return (
          <Box component="div">
            <DateSearchBox
              {...state.data?.dateSearch}
              start={moment(state.data?.dateSearch?.start)}
              end={moment(state.data?.dateSearch?.end)}
              onChange={(dateSearch) => handleChangeData('dateSearch', dateSearch)}
              menu={['currentDay', 'currentMonth', 'currentYear']}
            />
            <AlarmHistSearchField
              defaultFields={state.data?.searchFields}
              defaultSearch={state.data?.alarmSearch}
              onChangeSearch={(search) => handleChangeData('alarmSearch', search)}
              onChangeFields={(field) => handleChangeData('searchFields', field)}
              hideButton
            />
          </Box>
        );
      case 'POLAR_AREA_CHART':
        return (
          <Box component="div">
            <TagMutlipleSearchField
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
            <DateSearchBox
              {...state.data?.dateSearch}
              start={moment()}
              end={moment()}
              showLast
              onChange={(dateSearch) => handleChangeData('dateSearch', dateSearch)}
            />
            <PieTypeSelectBox
              name="pieType"
              sx={{ minWidth: 100, mr: 2 }}
              fullWidth={false}
              value={state.data?.pieType}
              onChange={(e) => handleChangeData('pieType', e.target.value)}
            />
            <BaseCheckbox
              name="isIncrease"
              label="증가값"
              checked={state.data?.isIncrease}
              onChange={(e) => handleChangeData('isIncrease', e.target.checked)}
            />
          </Box>
        );
      case 'TOPN_CHART':
        return (
          <Box component="div">
            <TagMutlipleSearchField
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
            <DateSearchBox
              {...state.data?.dateSearch}
              start={moment()}
              end={moment()}
              showLast
              onChange={(dateSearch) => handleChangeData('dateSearch', dateSearch)}
            />
            <TextField
              name="topn"
              type="number"
              label="Top N"
              variant="standard"
              fullWidth
              defaultValue={state.data?.topn}
              onChange={(e) => handleChangeData('topn', e.target.value)}
            />
            <PieTypeSelectBox
              name="pieType"
              sx={{ minWidth: 100, mr: 2 }}
              fullWidth={false}
              value={state.data?.pieType}
              onChange={(e) => handleChangeData('pieType', e.target.value)}
            />
            <BaseCheckbox
              name="isIncrease"
              label="증가값"
              checked={state.data?.isIncrease}
              onChange={(e) => handleChangeData('isIncrease', e.target.checked)}
            />
          </Box>
        );
      case 'GAUGE_CHART':
        return (
          <Box component="div">
            <TagMutlipleSearchField
              // isSingleTag
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
            <DateSearchBox
              {...state.data?.dateSearch}
              start={moment()}
              end={moment()}
              showLast
              menu={[
                'dayLast',
                'monthLast',
                'yearLast',
                'currentDayASC',
                'currentDayDESC',
                'currentMonthASC',
                'currentMonthDESC',
                'currentYearASC',
                'currentDayDESC',
              ]}
              onChange={(dateSearch) => handleChangeData('dateSearch', dateSearch)}
            />
            <BaseCheckbox
              name="isIncrease"
              label="증가값"
              checked={state.data?.isIncrease}
              onChange={(e) => handleChangeData('isIncrease', e.target.checked)}
            />
            <GaugeLevelForm
              defaultValue={state.data?.gaugeLevels}
              onChange={(value) => handleChangeData('gaugeLevels', value)}
            />
          </Box>
        );
      case 'VRTOUR':
        return (
          <Box component="div">
            <VRTourSelectBox
              name="vrtour"
              label="VR투어 선택"
              defaultValue={state.data?.vrtour?.toString() || ''}
              onChange={(e) => handleChangeData('vrtour', e.target.value)}
            />
          </Box>
        );
      case 'PRODUCT_CARD':
        return (
          <Box component="div">
            <ProductSearchForm
              onSelectSite={(site) => handleChangeData('site', site)}
              onSelectProduct={(product) => handleChangeData('product', product?.id)}
              defaultProduct={state.data?.product}
              defaultSite={state.data?.site}
              editable={false}
            />
            <DateSearchBox
              {...state.data?.dateSearch}
              start={moment(state.data?.dateSearch?.start)}
              end={moment(state.data?.dateSearch?.end)}
              menu={['currentMonth', 'currentYear']}
              onChange={(dateSearch) => handleChangeData('dateSearch', dateSearch)}
            />
          </Box>
        );
      case 'COST_CHART':
        return (
          <Box component="div">
            <TagMutlipleSearchField
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
            <CostSelectBox
              defaultValue={state.data?.cost ?? ''}
              sx={{ marginTop: 1 }}
              onChange={(e) => handleChangeData('cost', e.target.value)}
            />
            <DateSearchBox
              {...state.data?.dateSearch}
              start={moment(state.data?.dateSearch?.start)}
              end={moment(state.data?.dateSearch?.end)}
              onChange={(dateSearch) => handleChangeData('dateSearch', dateSearch)}
              menu={['day', 'month', 'currentDay', 'currentMonth']}
            />
            <BaseCheckbox
              name="showPrev"
              label="이전값 같이 보기"
              checked={state.data?.showPrev}
              onChange={(e) => handleChangeData('showPrev', e.target.checked)}
            />
            <BaseCheckbox
              name="showLegend"
              label="Legend 표시"
              checked={state.data?.showLegend}
              onChange={(e) => handleChangeData('showLegend', e.target.checked)}
            />
          </Box>
        );
      case 'PRODUCT_DATA_CHART':
        return (
          <Box component="div">
            <SiteSelectBox
              name="site"
              defaultValue={state.data?.site}
              data={flatSite}
              onChange={(e) => handleChangeData('site', e.target.value)}
              showEmpty
            />
            <ProductSelecBox
              site={state.data?.site}
              defaultValue={state.data?.product}
              containerSx={{ mt: 1 }}
              onSelectProduct={(product) => handleChangeData('product', product?.id)}
              showEmpty
            />
            <TagMutlipleSearchField
              sx={{ mt: 1 }}
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
            <CostSelectBox
              defaultValue={state.data?.cost ?? ''}
              sx={{ marginTop: 1 }}
              onChange={(e) => handleChangeData('cost', e.target.value)}
              showEmpty
            />
            <DateSearchBox
              {...state.data?.dateSearch}
              start={moment(state.data?.dateSearch?.start)}
              end={moment(state.data?.dateSearch?.end)}
              onChange={(dateSearch) => handleChangeData('dateSearch', dateSearch)}
              menu={['currentYear', 'year']}
            />
            <AggTypeSelectBox
              name="aggType"
              value={state.data?.aggType || undefined}
              onChange={(e) => handleChangeData('aggType', e.target.value)}
            />
            <BaseCheckbox
              name="isIncrease"
              label="적산값 처리"
              checked={state.data?.isIncrease}
              onChange={(e) => handleChangeData('isIncrease', e.target.checked)}
            />
            <BaseCheckbox
              name="showPrev"
              label="이전값 같이 보기"
              checked={state.data?.showPrev}
              onChange={(e) => handleChangeData('showPrev', e.target.checked)}
            />
            <BaseCheckbox
              name="showLegend"
              label="Legend 표시"
              checked={state.data?.showLegend}
              onChange={(e) => handleChangeData('showLegend', e.target.checked)}
            />
          </Box>
        );
      case 'PRODUCT_DATA_CARD':
        return (
          <Box component="div">
            <SiteSelectBox
              name="site"
              defaultValue={state.data?.site}
              data={flatSite}
              onChange={(e) => handleChangeData('site', e.target.value)}
              showEmpty
            />
            <ProductSelecBox
              site={state.data?.site}
              defaultValue={state.data?.product}
              containerSx={{ mt: 1 }}
              onSelectProduct={(product) => handleChangeData('product', product?.id)}
              showEmpty
            />
            <TagMutlipleSearchField
              defaultTagCodes={state.data?.tags}
              onChange={(tagCodes) => handleChangeData('tags', tagCodes)}
            />
            <CostSelectBox
              defaultValue={state.data?.cost ?? ''}
              sx={{ marginTop: 1 }}
              onChange={(e) => handleChangeData('cost', e.target.value)}
              showEmpty
            />
            <DateSearchBox
              {...state.data?.dateSearch}
              start={moment()}
              end={moment()}
              onChange={(dateSearch) => handleChangeData('dateSearch', dateSearch)}
              menu={['month', 'monthLast']}
            />
            <BaseCheckbox
              name="isIncrease"
              label="증가값"
              checked={state.data?.isIncrease}
              onChange={(e) => handleChangeData('isIncrease', e.target.checked)}
            />
            <BaseCheckbox
              name="showPrev"
              label="이전값 같이 보기"
              checked={state.data?.showPrev}
              onChange={(e) => handleChangeData('showPrev', e.target.checked)}
            />
            <BaseCheckbox
              name="prevColorReverse"
              label="이전값 대비 색상 반전"
              checked={state.data?.prevColorReverse}
              onChange={(e) => handleChangeData('prevColorReverse', e.target.checked)}
            />
          </Box>
        );
      case 'GOOGLE_MAP':
        return (
          <Box component="div">
            <GroupMultipleSelectInput
              defaultValue={state.data?.groups}
              onChange={(e) =>
                handleChangeData(
                  'groups',
                  e.map((gr) => gr.id),
                )
              }
            />
            <LocationInput
              defaultValue={state.data?.centerLocation}
              onChange={(e) => handleChangeData('centerLocation', e)}
            />
            <TextField
              name="mapZoom"
              label="줌 레벨(높을수록 확대)"
              type="number"
              fullWidth
              defaultValue={18}
              value={state.data?.mapZoom}
              onChange={(e) => handleChangeData('mapZoom', e.target.value)}
              sx={{ mt: 1 }}
              variant="standard"
            />
            <BaseCheckbox
              name="showGroupModel"
              checked={state.data?.showGroupModel}
              label="그룹 3D모델 표시"
              onChange={(e) => handleChangeData('showGroupModel', e.target.checked)}
            />
          </Box>
        );
      case 'MATTERPORT':
        return (
          <Box component="div">
            <MatterportSelectBox
              label="메타포트"
              defaultValue={state.data?.matterport?.toString() ?? ''}
              onSelectMatterport={(matterport) => handleChangeData('matterport', matterport?.idx)}
            />
          </Box>
        );
      default:
        break;
    }

    return <Box component="div" />;
  }, [state.type, state.data]);

  const onSaveSuccess = (result: TDashboardItemResponse) => {
    if (result && result.success) {
      toast('대시보드 아이템 저장 성공', { type: 'success' });

      if (typeof onSaveComplete === 'function') {
        onSaveComplete(result.data);
      }
    }
  };

  const createMutation = useMutation(createDashboardItemAPI, {
    onSuccess: onSaveSuccess,
  });

  const modifyMutation = useMutation(modifyDashboardItemAPI, {
    onSuccess: onSaveSuccess,
  });

  return (
    <BaseModal title="대시보드 아이템 저장" open={open} {...others} onConfirm={handleSaveClick}>
      <Box component="div" sx={{ width: 600, height: 500 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="아이템 이름"
              fullWidth
              variant="standard"
              name="name"
              value={state.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <BaseSelectBox
              label="아이템 유형"
              onChange={handleSelect}
              fullWidth
              name="type"
              items={[
                { id: 'DATA10_CHART', name: '데이터 차트' },
                { id: 'DATA1_CHART', name: '실시간 데이터 차트' },
                { id: 'PREV_DAY', name: '전일대비 조회 차트' },
                { id: 'PREV_MONTH', name: '전월대비 조회 차트' },
                { id: 'PREV_YEAR', name: '전년대비 조회 차트' },
                { id: 'LAST_DATA_VALUE', name: '현재값 조회' },
                { id: 'NODE_AND_3D', name: '2D/3D 계통' },
                { id: 'ALARM_STATS', name: '알람 현황' },
                { id: 'POLAR_AREA_CHART', name: '도넛 차트' },
                { id: 'TOPN_CHART', name: 'Top N 도넛 차트' },
                { id: 'GAUGE_CHART', name: '게이지 차트' },
                { id: 'VRTOUR', name: 'VR투어' },
                { id: 'MATTERPORT', name: '메타포트' },
                { id: 'PRODUCT_CARD', name: '생산량 현황 카드' },
                { id: 'COST_CHART', name: '원단위 차트' },
                { id: 'PRODUCT_DATA_CHART', name: '생산량 단위 차트' },
                { id: 'PRODUCT_DATA_CARD', name: '생산량 단위 카드' },
                { id: 'GOOGLE_MAP', name: '구글 지도' },
              ]}
              defaultValue={data?.type}
              value={state.type}
            />
          </Grid>
          <Grid item xs={12}>
            {renderForm}
          </Grid>
        </Grid>
      </Box>
    </BaseModal>
  );
}
