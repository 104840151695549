import { Autocomplete, Box, TextField } from '@mui/material';
import { IUser, TPageUserResponse } from '@types';
import _ from 'lodash';
import React, { SyntheticEvent, useMemo } from 'react';

interface AutoCompleteOption {
  label: string;
  userId: string;
  id: number;
}

type Props = {
  data?: TPageUserResponse;
  defaultUser?: IUser;
  onChange?(changed: IUser): void;
  label?: string;
  required?: boolean;
};

UserAutoCompleteInput.defaultProps = {
  data: undefined,
  defaultUser: undefined,
  onChange: undefined,
  label: '사용자',
  required: false,
};

export function UserAutoCompleteInput({ onChange, defaultUser, data, label, required }: Props) {
  const options = useMemo(
    (): AutoCompleteOption[] =>
      data?.data?.content?.map((user) => ({
        label: `${user.name} | ${user.userId}`,
        id: user.id || 0,
        userId: user.userId || '',
      })) || [],
    [data],
  );

  const defaultValue = useMemo(
    (): AutoCompleteOption | undefined =>
      defaultUser && {
        id: defaultUser.id || 0,
        label: `${defaultUser?.name} | ${defaultUser?.userId}`,
        userId: defaultUser.userId || '',
      },
    [defaultUser],
  );

  const handleOnChange = (e: SyntheticEvent, changed: AutoCompleteOption | null) => {
    if (changed && data && typeof onChange === 'function') {
      const changedUser = _.find(data.data?.content, (user) => user.id === changed.id);

      if (changedUser) {
        onChange(changedUser);
      }
    }
  };

  return (
    <Autocomplete
      options={options}
      disablePortal
      fullWidth
      defaultValue={defaultValue}
      onChange={handleOnChange}
      renderInput={(params) => <TextField {...params} variant="standard" label={label} required={required} />}
    />
  );
}
