import { Box } from '@mui/material';
import MatterportItemBox from 'components/matterportitem/MatterportItemBox';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { matterportState } from 'states';

export default function MatterportFullScreen() {
  const matterports = useRecoilValue(matterportState);
  const { itemId } = useParams();

  const selectedItem = useMemo(
    () => _.find(matterports, (data) => data.idx === (itemId ? parseInt(itemId, 10) : undefined)),
    [matterports],
  );

  return (
    <Box component="div" display="flex" sx={{ width: '100%', height: '100%' }}>
      {selectedItem && <MatterportItemBox readonly hideBack data={selectedItem} />}
    </Box>
  );
}
