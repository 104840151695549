import React, { useEffect, useState } from 'react';
import { SystemLayout } from 'layout/system';
import { ISort, ITag, TTagCreateRequest, TTagCreateResponse, TTagPageRequest, TTagSearchField } from '@types';
import { useMutation, useQuery } from 'react-query';
import { createTagAPI, findTagAPI, modifyTagAPI } from 'apis/tag';
import TagDataGrid, { ITagGridData } from 'components/tag/TagDataGrid';
import { Box, Button, Card, Typography } from '@mui/material';
import TagSearchField from 'components/tag/TagSearchField';
import TagSaveModal from 'components/tag/TagSaveModal';
import { toast } from 'react-toastify';
import useLoading from 'common/hooks/useLoading';

export default function TagPage() {
  const onClickUserRow = (selected: ITagGridData) => {
    const filteredUser = data?.data?.content?.filter((tag) => tag.tagCode === selected.tagCode);

    if (filteredUser && filteredUser.length > 0) {
      setSelectedTag(filteredUser[0]);
      setModalOpen(true);
    }
  };

  const onChangeRowPerPage = (size: number) => setPageInfo((prev) => ({ ...prev, size, page: 0 }));

  const onChangePage = (page: number) => setPageInfo((prev) => ({ ...prev, page }));

  const onChangeSort = (sort: ISort[]) => setPageInfo((prev) => ({ ...prev, sort }));

  const onChangeSearch = (search: TTagSearchField) => setPageInfo((prev) => ({ ...prev, search }));

  const onClickTagCreateButton = () => setModalOpen(true);

  const onCloseModal = () => {
    setSelectedTag(null);
    setModalOpen(false);
  };

  const onSaveClick = (request: TTagCreateRequest) => {
    if (request.id) {
      modifyMutation.mutate(request);
    } else {
      createMutation.mutate(request);
    }
  };

  const onSaveSuccess = (result: TTagCreateResponse) => {
    if (!result.success) {
      toast(result.errMessage, { type: 'error' });
    } else {
      toast(`${result.data?.tagCode}저장 성공`, { type: 'success' });
      onCloseModal();
      refetch();
    }
  };

  const [selectedTag, setSelectedTag] = useState<ITag | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pageInfo, setPageInfo] = useState<TTagPageRequest>({
    page: 0,
    size: 10,
    sort: undefined,
    search: undefined,
  });

  const { data, refetch, isFetching } = useQuery(['tags', pageInfo], () => findTagAPI(pageInfo));

  const createMutation = useMutation(createTagAPI, {
    onSuccess: onSaveSuccess,
  });
  const modifyMutation = useMutation(modifyTagAPI, {
    onSuccess: onSaveSuccess,
  });

  const changeLoading = useLoading({ trigger: false });

  useEffect(() => {
    changeLoading({ loading: createMutation.isLoading, message: '태그 저장중...' });
  }, [createMutation.isLoading]);

  useEffect(() => {
    changeLoading({ loading: modifyMutation.isLoading, message: '태그 저장중...' });
  }, [modifyMutation.isLoading]);

  useEffect(() => {
    changeLoading({ loading: isFetching, message: '태그 조회중...' });
  }, [isFetching]);

  return (
    <SystemLayout title="태그관리">
      <Card sx={{ mb: 1, mt: 1 }}>
        <TagSearchField onChange={onChangeSearch} />
      </Card>
      <TagDataGrid
        data={data}
        onClick={onClickUserRow}
        onChangePage={onChangePage}
        onChangeSort={onChangeSort}
        onChangeRowPerPage={onChangeRowPerPage}
        page={pageInfo.page}
        rowPerPage={pageInfo.size}
      />
      <TagSaveModal open={modalOpen} onClose={onCloseModal} data={selectedTag} onSave={onSaveClick} />
      <Box component="div" display="flex" p={1} justifyContent="flex-end">
        <Button variant="contained" onClick={onClickTagCreateButton}>
          <Typography>태그생성</Typography>
        </Button>
      </Box>
    </SystemLayout>
  );
}
