import axios, { AxiosError } from 'axios';
import {
  TPageRoleRequest,
  TPageRoleResponse,
  TRoleCreateRequest,
  TRoleCreateResponse,
  TRoleDeleteRequest,
  TRoleDeleteResponse,
} from '@types';
import { ENDPOINT } from '@const';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';
import { toast } from 'react-toastify';
/**
 * 모든 권한 조회
 * @param request 권한 조회 페이지 요청 데이터
 * @returns 조회된 권한 페이지 목록
 */
const findRoles = async (request: TPageRoleRequest = { page: 0, size: 100 }): Promise<TPageRoleResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.role.findRoles, ...createAuth(), params: request })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('권한 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

const createRoleAPI = async (request: TRoleCreateRequest): Promise<TRoleCreateResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.role.createRole, ...createAuth(), data: request })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('권한 생성 실패');
  }

  toastErrorMessage(result.data);

  const data = result.data as TRoleCreateResponse;

  return data;
};

const modifyRoleAPI = async (request: TRoleCreateRequest): Promise<TRoleCreateResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.role.modifyRole, ...createAuth(), data: request })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('권한 수정 실패');
  }

  toastErrorMessage(result.data);

  const data = result.data as TRoleCreateResponse;

  return data;
};

const deleteRoleAPI = async (request: TRoleDeleteRequest): Promise<TRoleDeleteResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.role.deleteRole,
      url: ENDPOINT.role.deleteRole.url.replace('{id}', (request.id ?? -1).toString()),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('권한 삭제 실패');
  }

  toastErrorMessage(result.data);

  const data = result.data as TRoleDeleteResponse;

  return data;
};

export { findRoles, createRoleAPI, modifyRoleAPI, deleteRoleAPI };
