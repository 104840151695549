import { ENDPOINT } from '@const';
import { toast } from 'react-toastify';
import { TSiteCreateRequest, TSiteCreateResponse, TSiteResponse } from '@types';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';
import axios, { AxiosError } from 'axios';

/**
 * 모든 SITE 조회 API
 * @returns 조회된 Sites
 */
const findAllSitesAPI = async (): Promise<TSiteResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.site.findAllSites,
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('코드 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

/**
 * Site 생성 API
 * @param request Site 생성 요청 데이터
 * @returns 생성된 Site 정보
 */
const createSiteAPI = async (request: TSiteCreateRequest): Promise<TSiteCreateResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.site.create,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    toast('코드 저장 실패', { type: 'error', autoClose: 5000 });
    throw Error('코드 생성 실패');
  }

  toastErrorMessage(result.data);

  const data = result.data as TSiteCreateResponse;

  return data;
};

/**
 * Site 수정 API
 * @param request Site 수정 요청 데이터
 * @returns 수정된 Site 정보
 */
const modifySiteAPI = async (request: TSiteCreateRequest): Promise<TSiteCreateResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.site.modify,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    toast('코드 저장 실패', { type: 'error', autoClose: 5000 });
    throw Error('코드 저장 실패');
  }

  toastErrorMessage(result.data);

  const data = result.data as TSiteCreateResponse;

  return data;
};

export { findAllSitesAPI, createSiteAPI, modifySiteAPI };
