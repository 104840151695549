import { Box, SxProps, Theme, Typography } from '@mui/material';
import { findAllProductManageAPI } from 'apis/productManage';
import useLastTagData from 'common/hooks/useLastTagData';
import useTagChartCostData10 from 'common/hooks/useTagChartCostData10';
import { getPrevNameByDateProp } from 'common/lib';
import AlertCard from 'components/alarm/AlertCard';
import { DateSearchProps } from 'components/common/DateSearchBox';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { animated, easings, useSpring } from 'react-spring';

type Props = {
  site?: string;
  product?: number;
  cost?: string;
  tags?: string[];
  sx?: SxProps<Theme>;
  fixedNumber?: number;
  dateProps?: DateSearchProps;
  isIncrease?: boolean;
  valueTypoVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  unitTypoVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  cardVariant?: 'outlined' | 'elevation';
  showPrev?: boolean;
  prevColorReverse?: boolean;
};

ProductTagDataCard.defaultProps = {
  site: undefined,
  product: undefined,
  cost: undefined,
  tags: [],
  sx: undefined,
  fixedNumber: 2,
  dateProps: undefined,
  isIncrease: undefined,
  valueTypoVariant: 'h4',
  unitTypoVariant: 'h6',
  cardVariant: 'elevation',
  showPrev: false,
  prevColorReverse: false,
};

export default function ProductTagDataCard({
  product,
  site,
  cost,
  valueTypoVariant,
  unitTypoVariant,
  tags,
  sx,
  dateProps,
  isIncrease,
  fixedNumber,
  cardVariant,
  showPrev,
  prevColorReverse,
}: Props) {
  // 제품생산량 조회
  const { data: productData } = useQuery(['findProductManage', product, site], () =>
    findAllProductManageAPI({ productId: product, siteCode: site }),
  );

  // 현재년도 제품생산량 데이터 조회
  const currentProduct = useMemo(() => {
    if (productData?.data) {
      const date = moment();
      const yearProduct = _.find(productData.data, (pm) => pm.year === date.year());

      return yearProduct;
    }

    return undefined;
  }, [productData]);

  // 현재년도 제품생산량 값
  const currentProductValue = useMemo(() => {
    if (productData?.data) {
      const date = moment();
      const yearProduct = _.find(productData.data, (pm) => pm.year === date.year());

      return _.find(yearProduct?.data, (pmd) => moment(pmd.dt).month() === date.month())?.value ?? 1;
    }

    return 1;
  }, [productData]);

  // 이전년도 제품생산량 ㄱ밧
  const prevProductValue = useMemo(() => {
    if (productData?.data) {
      const date = moment().subtract(1, 'month');
      const yearProduct = _.find(productData.data, (pm) => pm.year === date.year());

      return _.find(yearProduct?.data, (pmd) => moment(pmd.dt).month() === date.month())?.value ?? 1;
    }

    return 1;
  }, [productData]);

  // 현재 태그 데이터 조회
  const dataset = useLastTagData({
    tagTypes: cost ? [] : tags?.map((tag) => ({ tag, aggFunc: 'mean' })),
    dateProps,
    isIncrease,
    refetchOnFocus: false,
  });

  // 이전 태그 데이터 조회 =
  const prevDataset = useLastTagData({
    // eslint-disable-next-line no-nested-ternary
    tagTypes: cost ? [] : showPrev ? tags?.map((tag) => ({ tag, aggFunc: 'mean' })) : [],
    dateProps,
    isIncrease,
    refetchOnFocus: false,
    isPrev: showPrev,
  });

  // 태그 + 가격 데이터 조회
  const { dataList: costDataset } = useTagChartCostData10({
    tags: cost ? tags : [],
    cost,
    aggUnit: 'month',
    dateProps: { ...dateProps, type: 'currentMonth' },
  });

  const prevCostDateProps = useMemo(
    () =>
      ({
        ...dateProps,
        type: 'month',
        start: moment().subtract(1, 'month'),
        end: moment().subtract(1, 'month'),
      } as DateSearchProps),
    [dateProps],
  );

  // 태그 + 가격 이전 데이터 조회
  // const { dataList: prevCostDataset } = useTagChartCostData10({
  //   tags: cost ? tags : [],
  //   cost,
  //   aggUnit: 'month',
  //   dateProps: prevCostDateProps,
  // });

  // 금월 가격 데이터 취합 ( 일단위로 분산되어있기 때문에 월단위로 취합)
  const currentCostSumData = useMemo(() => {
    return (
      costDataset?.data?.datasets
        .filter((dataset) => dataset.type === 'WON')
        .map((dataset) => dataset.data)
        .flat()
        .reduce((prev, datas) => datas.value + prev, 0) ?? undefined
    );
  }, [costDataset]);

  // 최종 값 산정 ( 가격 데이터 및 태그 데이터 조건을 통해 계산 )
  const value = useMemo(() => {
    const tempValue = dataset?.at(0)?.data.at(0)?.value || 0.0;

    if (currentCostSumData) return currentCostSumData / currentProductValue;

    return tempValue / currentProductValue;
  }, [currentCostSumData, currentProductValue, dataset]);

  // 이전 값 산정
  const prevValue = useMemo(() => {
    const tempValue = prevDataset?.at(0)?.data.at(0)?.value || 0.0;
    return tempValue / prevProductValue;
  }, [prevProductValue, prevDataset]);

  // 태그데이터 unit
  const codeName = useMemo(() => dataset?.at(0)?.tag.tagUnit.codeName, [dataset]);

  // 값 변경 애니메이션 종료 여부 상태
  const [animeEnd, setAnimeEnd] = useState<boolean>(false);
  // 애니메이션 적용 값을 상태관리
  const [stateValue, setStateValue] = useState<number>(0);

  // 숫자 애니메이션의 toFixed가, 같은값으로 업데이트되는 경우( 15 -> 15 ) 동작하지않는 이슈로 인해 수정
  const anime = useSpring({
    value: stateValue,
    config: {
      easing: easings.easeOutCubic,
      duration: 2000,
    },
    onStart: () => {
      setAnimeEnd(false);
    },
    onRest: () => {
      setAnimeEnd(true);
    },
    onPause: () => {
      setAnimeEnd(true);
    },
  });

  // 숫자 애니메이션의 toFixed가, 같은값으로 업데이트되는 경우( 15 -> 15 ) 동작하지않는 이슈로 인해 수정
  const memoedAnimeValue = useMemo(() => {
    if (cost)
      return anime.value.to((x) => x.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }));
    if (stateValue === 0)
      return (0).toLocaleString(undefined, { minimumFractionDigits: fixedNumber, maximumFractionDigits: fixedNumber });

    if (animeEnd)
      return stateValue.toLocaleString(undefined, {
        minimumFractionDigits: fixedNumber,
        maximumFractionDigits: fixedNumber,
      });

    return anime.value.to((x) =>
      x.toLocaleString(undefined, { minimumFractionDigits: fixedNumber, maximumFractionDigits: fixedNumber }),
    );
  }, [anime, animeEnd, stateValue, fixedNumber, cost]);

  // 현재값과 이전값의 차이 계산
  const prevDiffValue = useMemo(() => {
    if (!prevValue) return undefined;
    const diff = (value ?? 0) - (prevValue ?? 0);
    const preFix = diff > 0 ? '+' : '';
    const dateName = getPrevNameByDateProp(dateProps, '', ' 대비');
    let color = diff > 0 ? 'green' : 'red';

    if (prevColorReverse) color = diff > 0 ? 'red' : 'green';

    return (
      <Typography variant="caption" sx={{ marginTop: -0.5 }} color={color}>
        {dateName} {preFix}
        {diff.toLocaleString(undefined, {
          minimumFractionDigits: fixedNumber,
          maximumFractionDigits: fixedNumber,
        })}
      </Typography>
    );
  }, [prevColorReverse, codeName, dateProps, fixedNumber, prevValue, value]);

  // 계산값이 변경되면 상태값에 반영
  useEffect(() => {
    setStateValue(value);
  }, [value]);

  return (
    <AlertCard variant={cardVariant} sx={{ position: 'relative', display: 'flex', justifyContent: 'center', ...sx }}>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        sx={{ height: '100%' }}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="caption" sx={{ marginBottom: -1 }}>
          금월 {currentProduct?.product.name} 생산량 {currentProductValue}개
        </Typography>
        <Box component="div" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <AnimeTypo variant={valueTypoVariant} fontWeight="bold">
            {memoedAnimeValue}
          </AnimeTypo>
          <Typography variant={unitTypoVariant} fontWeight="bold" sx={{ ml: 1 }}>
            {cost ? '₩' : codeName}/ea
          </Typography>
        </Box>
        {showPrev && prevDiffValue}
      </Box>
    </AlertCard>
  );
}

const AnimeTypo = animated(Typography);
