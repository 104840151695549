import React from 'react';
import { TUserSearchField } from '@types';
import BaseSearchField, { SearchFieldProps } from 'components/common/BaseSearchField';

const fields: SearchFieldProps[] = [
  {
    id: 'userId',
    name: 'UserID',
    type: 'text',
  },
  {
    id: 'name',
    name: 'Name',
    type: 'text',
  },
  {
    id: 'dept',
    name: 'Dept',
    type: 'text',
  },
  {
    id: 'role',
    name: 'Role',
    type: 'role',
  },
  {
    id: 'site',
    name: 'Site',
    type: 'site',
  },
  {
    id: 'useYN',
    name: 'UseYN',
    type: 'useYN',
  },
];

type Props = {
  onChange?(search: TUserSearchField): void;
};

UserSearchField.defaultProps = {
  onChange: undefined,
};

export default function UserSearchField({ onChange }: Props) {
  return <BaseSearchField<TUserSearchField> field={fields} onCommit={onChange} />;
}
