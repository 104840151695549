import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { ITaskHist, IUser, TTaskCreateRequest, TTaskHistCreateRequest } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import { UserAutoCompleteInput } from 'components/user/UserAutoCompleteInput';
import { useQuery } from 'react-query';
import { findUsersAPI } from 'apis/user';
import { useRecoilValue } from 'recoil';
import { flatCodeState } from 'states';
import CodeSelectBox from 'components/code/CodeSelectBox';

export interface TaskHistSaveModalProps extends IBaseModalProps {
  onSaveTaskHist?(data: TTaskCreateRequest): void;
  onDeleteTaskHist?(id: number): void;
  site?: string;
  taskId?: number;
  data?: ITaskHist;
  defaultWorker?: IUser;
  defaultMessage?: string;
  dataReset?: boolean;
}

TaskHistSaveModal.defaultProps = {
  onSaveTaskHist: undefined,
  onDeleteTaskHist: undefined,
  data: {},
  taskId: 0,
  site: undefined,
  defaultWorker: undefined,
  defaultMessage: undefined,
  dataReset: false,
};

export default function TaskHistSaveModal({
  open,
  data,
  site,
  taskId,
  defaultWorker,
  defaultMessage,
  dataReset,
  onClose,
  onSaveTaskHist,
  onDeleteTaskHist,
}: TaskHistSaveModalProps) {
  const handleDelete = () => {
    if (data && typeof onDeleteTaskHist === 'function') {
      if (window.confirm('작업 이력을 삭제하시겠습니까?')) {
        onDeleteTaskHist(data.id);
      }
    }
  };

  const handleSave = () => {
    if (typeof onSaveTaskHist === 'function') {
      onSaveTaskHist(state);
    }
    if (dataReset) {
      setState((state) => ({ ...state, statusCode: undefined }));
    }
  };

  const handleWorkerChange = (changed: IUser) => {
    setState((prev) => ({ ...prev, worker: changed.userId }));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const [state, setState] = useState<TTaskHistCreateRequest>({});
  const flatCodes = useRecoilValue(flatCodeState);

  const { data: users } = useQuery(
    ['task_users', site],
    () => findUsersAPI({ page: 0, size: 999, search: { site, useYN: true } }),
    {
      enabled: Boolean(site),
    },
  );

  useEffect(() => {
    setState({
      ...data,
      statusCode: data?.statusCode?.code,
      worker: data?.worker?.userId,
      task: taskId,
    });
  }, [data]);

  useEffect(() => {
    if (defaultMessage) {
      setState((prev) => ({ ...prev, message: defaultMessage }));
    }
  }, [defaultMessage]);

  useEffect(() => {
    if (defaultWorker) {
      setState((prev) => ({ ...prev, worker: defaultWorker.userId }));
    }
  }, [defaultWorker]);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="작업이력"
      confirmTitle="저장"
      cancleTitle="취소"
      onConfirm={handleSave}
      onDelete={data?.id ? handleDelete : undefined}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{ width: 400, height: 200 }}
      >
        <TextField name="id" sx={{ display: 'none' }} type="hidden" value={state.id} />
        <TextField name="task" sx={{ display: 'none' }} type="hidden" value={taskId} />
        <TextField
          name="message"
          variant="standard"
          label="작업이력"
          onChange={handleInputChange}
          value={state.message}
          required
          defaultValue={defaultMessage ?? ''}
        />
        <CodeSelectBox
          fullWidth
          name="statusCode"
          defaultValue={data?.statusCode?.code}
          data={flatCodes}
          parentId="TASK_STATUS"
          onChange={handleSelectChange}
        />
        <UserAutoCompleteInput
          required
          defaultUser={defaultWorker ?? data?.worker}
          label="작업자"
          data={users}
          onChange={handleWorkerChange}
        />
      </Box>
    </BaseModal>
  );
}
