import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { SelectItemProps } from 'components/common/BaseSelectBox';
import { useMutation } from 'react-query';
import { createAlarmHistTaskAPI } from 'apis/alarmhist';
import { TAlarmHistPageResponse, TTaskHistCreateRequest } from '@types';
import { toast } from 'react-toastify';
import useLoading from 'common/hooks/useLoading';
import BaseSearchField, { BaseSearchFieldProps } from 'components/common/BaseSearchField';
import TaskHistSaveModal from 'components/taskhist/TaskHistSaveModal';
import { useRecoilValue } from 'recoil';
import { loginState } from 'states';

export type SearchFieldProps = SelectItemProps & {
  type: 'text' | 'useYN' | 'number' | 'site' | 'role' | 'code' | 'tagType' | 'start_end_dtt' | 'dataType';
  code?: string;
};

export type BaseAlarmHistSearchFieldProps<T> = BaseSearchFieldProps<T> & {
  refetch?(): void;
};

BaseAlarmHistSearchField.defaultProps = {
  refetch: undefined,
};

export default function BaseAlarmHistSearchField<T>({
  refetch,
  onCommit,
  ...others
}: BaseAlarmHistSearchFieldProps<T>) {
  const [buttonHide, setButtonHide] = useState<boolean>(false);
  const [search, setSearch] = useState<Partial<T>>({});
  const [taskModal, setTaskModal] = useState<boolean>(false);
  const user = useRecoilValue(loginState);
  const loading = useLoading({ trigger: false });

  const handleCommit = (search: Partial<T> | null) => {
    setSearch(search ?? {});
    if (typeof onCommit === 'function') {
      onCommit(search);
    }
    setButtonHide(true);
  };

  const onSuccessTaskCreate = (data: TAlarmHistPageResponse) => {
    if (data && data.success) {
      if (typeof refetch === 'function') {
        refetch();
      }
      toast('일괄처리 완료', { type: 'success' });
    }
  };

  const createMutation = useMutation(createAlarmHistTaskAPI, {
    onSuccess: onSuccessTaskCreate,
  });

  const handleTaskCreate = () => {
    setTaskModal(true);
  };

  const handleOnHistSave = (request: TTaskHistCreateRequest) => {
    createMutation.mutate({
      page: {
        page: 0,
        size: 999999,
        search: {
          ...search,
        },
      },
      taskHist: request,
    });
    if (request.statusCode) {
      setTaskModal(false);
    }
  };

  const handleTaskModalClose = () => {
    setTaskModal(false);
  };

  useEffect(() => {
    if (!taskModal) {
      loading({ loading: createMutation.isLoading, message: '알람이력 일괄 처리 진행중...' });
    }
  }, [createMutation]);

  const openTaskModal = useMemo(
    () => (
      <TaskHistSaveModal
        open={taskModal}
        onClose={handleTaskModalClose}
        site={user?.site?.siteCode}
        onSaveTaskHist={handleOnHistSave}
        defaultWorker={user}
        defaultMessage="일괄처리"
        dataReset={taskModal}
      />
    ),
    [taskModal],
  );

  return (
    <BaseSearchField {...others} onCommit={handleCommit}>
      {buttonHide ? (
        <Box component="div" mt={2}>
          <Button fullWidth variant="contained" onClick={handleTaskCreate}>
            <Typography>전체 알람이력 일괄 처리</Typography>
          </Button>
        </Box>
      ) : (
        <div />
      )}
      {openTaskModal}
    </BaseSearchField>
  );
}
