import React, { useMemo } from 'react';
import BaseTree, { RenderBaseTree } from 'components/common/BaseTree';
import { SxProps, Theme } from '@mui/material';
import { IGroupType } from '@types';
import { flatten } from 'common/lib';

interface Props {
  data?: IGroupType[];
  containerStyle?: SxProps<Theme>;
  showAddBtn?: boolean;
  onClickItem?(group: IGroupType): void;
  onAddItem?(parent: string): void;
}

GroupTypeTree.defaultProps = {
  data: [],
  showAddBtn: true,
  onClickItem: undefined,
  onAddItem: undefined,
  containerStyle: {},
};

export default function GroupTypeTree({ containerStyle, showAddBtn, onClickItem, onAddItem, data }: Props) {
  const createTree = (group: IGroupType): RenderBaseTree => {
    return {
      id: group.code.toString(),
      name: `${group.code} | ${group.name} | ${group.useYN ? 'Y' : 'F'}`,
      children: group.children?.map((sub) => createTree(sub)),
    };
  };

  const onClick = (tree: RenderBaseTree) => {
    if (typeof onClickItem === 'function') {
      const selected = flatDatas.filter((code) => code.code.toString() === tree.id);

      if (selected && selected.length > 0) {
        onClickItem(selected[0]);
      }
    }
  };

  const onClickAdd = (parentId: string) => {
    if (typeof onAddItem === 'function') {
      const selected = flatDatas.filter((code) => code.code.toString() === parentId);

      if (selected && selected.length > 0) {
        onAddItem(selected[0].code);
      }
    }
  };

  const flatDatas = useMemo(() => flatten(data ?? []) as IGroupType[], [data]);

  const treeData: RenderBaseTree[] = useMemo(() => {
    return (data ?? []).map((tree) => createTree(tree));
  }, [data]);

  return (
    <BaseTree
      showAddBtn={showAddBtn}
      data={treeData}
      onClickItem={onClick}
      onClickAdd={onClickAdd}
      containerStyle={containerStyle}
    />
  );
}
