import React from 'react';
import { TAlarmHistSearchField } from '@types';
import BaseSearchField, {
  BaseAlarmHistSearchFieldProps,
  SearchFieldProps,
} from 'components/alarmhist/BaseAlarmHistSearchField';

const fields: SearchFieldProps[] = [
  {
    id: 'alarmName',
    name: 'Name',
    type: 'text',
  },
  {
    id: 'site',
    name: 'Site',
    type: 'site',
  },
  {
    id: 'tag',
    name: 'Tag',
    type: 'text',
  },
  {
    id: 'tagCode',
    name: 'TagCode',
    type: 'text',
  },
  {
    id: 'status',
    name: 'Status',
    type: 'code',
    code: 'TASK_STATUS',
  },
  {
    id: 'useYN',
    name: 'UseYN',
    type: 'useYN',
  },
  {
    id: 'date',
    name: 'Date',
    type: 'start_end_dtt',
  },
];

type Props = BaseAlarmHistSearchFieldProps<TAlarmHistSearchField> & {
  onChange?(search: TAlarmHistSearchField): void;
};

AlarmHistSearchField.defaultProps = {
  onChange: undefined,
};

export default function AlarmHistSearchField({ onChange, ...others }: Props) {
  return <BaseSearchField<TAlarmHistSearchField> field={fields} onCommit={onChange} {...others} />;
}
