import React, { useMemo } from 'react';
import useTagChartCostData10 from 'common/hooks/useTagChartCostData10';
import { AggUnitType } from 'common/hooks/useTagChartData10';
import moment from 'moment';
import Data10ChartCard, { Data10ChartCardProps } from './Data10ChartCard';

export type CostTagData10CardProps = Data10ChartCardProps & {
  cost?: string;
  showPrev?: boolean;
};

CostTagData10Card.defaultProps = {
  cost: undefined,
  showPrev: false,
};

export default function CostTagData10Card({ cost, showPrev, ...props }: CostTagData10CardProps) {
  const { lineColors, bgColors, tags, dateProps, aggType, chartType, fill } = props;

  const {
    data: dataset,
    dataList,
    isFetching,
  } = useTagChartCostData10({
    tags,
    type: chartType,
    colors: lineColors,
    dataType: aggType,
    dateProps,
    fill,
    bgColors,
    cost,
  });

  const prevDttFormatter = (prevDtt: string): string => {
    const prevMoment = moment(prevDtt);

    switch (dateProps.type) {
      case 'day':
      case 'currentDay':
        return prevMoment.add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
      case 'currentMonth':
      case 'month':
        return prevMoment
          .add(1, 'month')
          .set('hours', 0)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss');
      case 'currentYear':
      case 'year':
        return prevMoment.add(1, 'year').format('YYYY-MM-DD HH:mm:ss');
      default:
        break;
    }

    return prevMoment.add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
  };

  const { data: prevDataset } = useTagChartCostData10({
    tags: showPrev ? tags : [],
    type: chartType,
    colors: lineColors,
    dataType: aggType,
    dateProps,
    fill,
    bgColors,
    cost,
    isPrev: showPrev,
    stackId: 'x2',
    xFormatter: prevDttFormatter,
  });

  const dateAggUnit = useMemo((): AggUnitType => {
    switch (dateProps.type) {
      case 'currentDay':
      case 'currentDayASC':
      case 'currentDayDESC':
      case 'day':
        return 'minute';
      case 'currentMonth':
      case 'currentMonthASC':
      case 'currentMonthDESC':
      case 'month':
        return 'day';
      case 'currentYear':
      case 'currentYearASC':
      case 'currentYearDESC':
      case 'year':
        return 'month';
      default:
        break;
    }

    return 'minute';
  }, [dateProps]);

  return (
    <Data10ChartCard
      chartType="stackedBar"
      scales={{
        wonY: {
          position: 'right',
          grid: {
            display: false,
          },
          ticks: {
            stepSize: 10,
          },
          display: true,
        },
      }}
      dataset={{ datasets: [...prevDataset.datasets, ...dataset.datasets] }}
      gridData={dataList}
      isFetching={isFetching}
      {...props}
      dateProps={{ ...dateProps, menu: ['day', 'month', 'currentDay', 'currentMonth'] }}
      aggUnit={dateAggUnit}
    />
  );
}
