import { Cancel, Delete, Edit, Save, TableView } from '@mui/icons-material';
import { Box, Card, Grid, IconButton, SelectChangeEvent, SxProps, TextField, Theme, Typography } from '@mui/material';
import { ITag } from '@types';
import { findTagByCodeAPI } from 'apis/tag';
import useTagRTChartData, { Data1MDuration } from 'common/hooks/useTagRTChartData';
import BaseSelectBox from 'components/common/BaseSelectBox';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { NodeSearchFormType } from 'components/reactflow/BaseNodeType';
import DataGridModal from './DataGridModal';
import DataLineChart from './DataLineChart';

export type MultiTagData1ChartCardProps = {
  id: string;
  tagStr: string[];
  lineColors?: string[];
  duration: Data1MDuration;
  width?: string | number;
  height?: string | number;
  title?: string;
  chartLabel?: string;
  chartWidth?: number | string;
  chartHeight?: number | string;
  chartPadding?: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
  onDelete?(id: string): void;
  onTitleChange?(id: string, title: string): void;
  onDurationChange?(id: string, duration: Data1MDuration): void;
  showDeleteBtn?: boolean;
  showGridBtn?: boolean;
  useGridRowData?: boolean;
  hideTitle?: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  gridSx?: SxProps<Theme>;
  nodeSearchForm?: NodeSearchFormType;
};

MultiTagData1ChartCard.defaultProps = {
  width: undefined,
  height: 200,
  chartLabel: '',
  chartPadding: undefined,
  title: undefined,
  lineColors: undefined,
  chartWidth: 370,
  chartHeight: 120,
  onDelete: undefined,
  onTitleChange: undefined,
  onDurationChange: undefined,
  showDeleteBtn: true,
  showGridBtn: true,
  useGridRowData: false,
  hideTitle: false,
  xs: 12,
  sm: 12,
  md: 6,
  lg: 4,
  xl: 3,
  gridSx: undefined,
  nodeSearchForm: undefined,
};

export default function MultiTagData1ChartCard({
  id,
  width,
  height,
  duration,
  lineColors,
  onDelete,
  onTitleChange,
  onDurationChange,
  title,
  chartLabel,
  chartWidth,
  chartHeight,
  chartPadding,
  tagStr,
  showDeleteBtn,
  showGridBtn,
  useGridRowData,
  hideTitle,
  xs,
  sm,
  md,
  lg,
  xl,
  gridSx,
  nodeSearchForm,
}: MultiTagData1ChartCardProps) {
  const { data: tagResponse } = useQuery(['FND_TAG', tagStr], () => findTagByCodeAPI(tagStr));

  const tags: ITag[] = useMemo(() => tagResponse?.data ?? [], [tagResponse]);
  const labels = useMemo(() => tags.map((tag) => `${tag.tagName}(${tag.tagUnit.codeName})`), [tags]);

  const {
    data: chartData,
    annotation,
    dataList,
  } = useTagRTChartData({
    tags: tags.map((tag) => tag.tagCode).join('|'),
    duration,
    colors: lineColors,
    nodeSearchForm,
  });

  const handleDelete = () => {
    if (typeof onDelete === 'function') {
      onDelete(id);
    }
  };

  const handleOnTitleEditMode = () => {
    setIsTitleEditMode(true);
  };

  const handleOffTitleEditMode = () => {
    setIsTitleEditMode(false);
  };

  const handleTitleCancle = () => {
    setTitleState(title);
    handleOffTitleEditMode();
  };

  const handleDurationChange = (e: SelectChangeEvent) => {
    if (typeof onDurationChange === 'function') {
      const { value } = e.target;
      onDurationChange(id, value as Data1MDuration);
    }
  };

  const handleTitleSave = () => {
    if (!titleState) {
      toast('타이틀을 입력해주세요.', { type: 'error' });
      return;
    }

    if (typeof onTitleChange === 'function') {
      onTitleChange(id, titleState);
    }
    handleOffTitleEditMode();
  };

  const [isTitleEditMode, setIsTitleEditMode] = useState(false);
  const [titleState, setTitleState] = useState<string | undefined>(title);
  const [openGrid, setOpenGrid] = useState<boolean>(false);

  useEffect(() => {
    setTitleState(title);
  }, [title]);

  const handleOpenGrid = () => {
    setOpenGrid(true);
  };

  const handleCloseGrid = () => {
    setOpenGrid(false);
  };

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} sx={gridSx}>
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ mb: -1 }}
      >
        {isTitleEditMode ? (
          <>
            <TextField
              value={titleState}
              variant="standard"
              fullWidth
              placeholder={title}
              onChange={(e) => setTitleState(e.target.value)}
            />
            <IconButton color="success" onClick={handleTitleSave}>
              <Save />
            </IconButton>
            <IconButton color="error" onClick={handleTitleCancle}>
              <Cancel />
            </IconButton>
          </>
        ) : (
          !hideTitle && (
            <Typography variant="body1" fontWeight="bold">
              {titleState || `${labels.join(', ')}`}
            </Typography>
          )
        )}
        {isTitleEditMode || (
          <Box
            component="div"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            justifyItems="center"
          >
            {onDurationChange && (
              <BaseSelectBox
                name="duration"
                items={[
                  { id: '10m', name: '10분' },
                  { id: '1h', name: '1시간' },
                  { id: '3h', name: '3시간' },
                  { id: '6h', name: '6시간' },
                  { id: '12h', name: '12시간' },
                ]}
                fullWidth={false}
                defaultValue={duration}
                sx={{ px: 1 }}
                onChange={handleDurationChange}
              />
            )}

            {onTitleChange && (
              <IconButton onClick={handleOnTitleEditMode}>
                <Edit />
              </IconButton>
            )}
            {showDeleteBtn && (
              <IconButton onClick={handleDelete}>
                <Delete />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
      <Card
        sx={{
          mt: 1,
          height,
          position: 'relative',
        }}
      >
        {showGridBtn && (
          <Box component="div" sx={{ position: 'absolute', top: 5, left: 5 }}>
            <IconButton onClick={handleOpenGrid} size="small">
              <TableView fontSize="small" />
            </IconButton>
          </Box>
        )}
        <DataLineChart
          label={chartLabel}
          width={chartWidth}
          height={chartHeight}
          dataset={chartData}
          showLegend={tags.length > 1}
          annotation={annotation}
          padding={chartPadding}
          aggUnit={nodeSearchForm?.dateSearch?.durationUnit ?? 'minute'}
          showX
          showY
        />
      </Card>
      <DataGridModal title="그리드 조회" open={openGrid} onClose={handleCloseGrid} data={dataList} />
    </Grid>
  );
}
