import React from 'react';
import { findTopNDataAPI } from 'apis/data';
import { DateSearchProps } from 'components/common/DateSearchBox';
import moment from 'moment';
import { useQuery } from 'react-query';

export type TagType = {
  tag: string;
  aggFunc: string;
};

type Props = {
  tagTypes?: TagType[];
  dateProps?: DateSearchProps;
  isIncrease?: boolean;
  topn: number;
  desc?: boolean;
};

const useTopNTagData = ({ topn, tagTypes, dateProps, isIncrease, desc }: Props) => {
  const fetch = () => {
    let start = moment();
    const end = moment();

    switch (dateProps?.type) {
      case 'dayLast':
        start = start.set('hour', 0).set('minute', 0).set('second', 0);
        break;
      case 'monthLast':
        start = start.set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
        break;
      case 'yearLast':
        start = start.set('month', 0).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
        break;
      default:
        start = start.set('hour', 0).set('minute', 0).set('second', 0);
    }

    return findTopNDataAPI({
      start: start.format('YYYY-MM-DD HH:mm:ss'),
      end: end.format('YYYY-MM-DD HH:mm:ss'),
      tagTypes: tagTypes || [],
      isIncrease,
      n: topn,
      desc,
    });
  };

  const { data: dataList } = useQuery(['lastData', tagTypes, dateProps, isIncrease, desc], fetch, {
    enabled: Boolean(tagTypes),
    refetchInterval: 60 * 1000,
    refetchIntervalInBackground: true,
  });

  return dataList?.data?.datasets;
};

export default useTopNTagData;
