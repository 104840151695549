import React, { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { Box, Button, TextField, Typography, SelectChangeEvent, IconButton } from '@mui/material';
import BaseSelectBox, { SelectItemProps } from 'components/common/BaseSelectBox';
import { Delete } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { roleState, flatSiteState, flatCodeState } from 'states';
import SiteSelectBox from 'components/site/SiteSelectBox';
import RoleSelectBox from 'components/role/RoleSelectBox';
import CodeSelectBox from 'components/code/CodeSelectBox';
import moment from 'moment';
import TagTypeSelectBox from 'components/tag/TagTypeSelectBox';
import TagDataTypeSelectBox from 'components/tag/TagDataTypeSelectBox';
import BaseDateRangePicker from './BaseDateRangePicker';

export type SearchFieldProps = SelectItemProps & {
  type: 'text' | 'useYN' | 'number' | 'site' | 'role' | 'code' | 'tagType' | 'start_end_dtt' | 'dataType';
  code?: string;
};

export type BaseSearchFieldProps<T> = {
  field?: SearchFieldProps[];
  defaultFields?: SearchFieldProps[];
  defaultSearch?: Partial<T | any>;
  hideButton?: boolean;
  buttonLabel?: string;
  onCommit?(search: Partial<T> | null): void;
  onChangeSearch?(search: Partial<T> | null): void;
  onChangeFields?(fields: SearchFieldProps[]): void;
  children?: ReactElement | ReactElement[];
};

BaseSearchField.defaultProps = {
  field: [],
  buttonLabel: '검색',
  hideButton: false,
  defaultFields: undefined,
  defaultSearch: undefined,
  onCommit: undefined,
  onChangeSearch: undefined,
  onChangeFields: undefined,
  children: undefined,
};

export default function BaseSearchField<T>({
  buttonLabel,
  hideButton,
  defaultFields,
  defaultSearch,
  field,
  onCommit,
  onChangeFields,
  onChangeSearch,
  children,
}: BaseSearchFieldProps<T>) {
  const [selected, setSelected] = useState<SearchFieldProps | null>();
  const [selectFields, setSelectFields] = useState<SearchFieldProps[]>(defaultFields || []);
  const [search, setSearch] = useState<Partial<T | any> | null>(defaultSearch || null);
  const flatSite = useRecoilValue(flatSiteState);
  const flatCode = useRecoilValue(flatCodeState);
  const roles = useRecoilValue(roleState);

  /**
   * 필드 변경 이벤트 발생
   *
   */
  const handleSelectedFieldsChange = (fields: SearchFieldProps[]) => {
    if (typeof onChangeFields === 'function') {
      onChangeFields(fields);
    }
  };

  const handleSearchChange = (newSearch: Partial<T | any>) => {
    if (typeof onChangeSearch === 'function') {
      onChangeSearch(newSearch);
    }
  };

  /**
   * 검색조건 셀렉트박스 변경 이벤트
   */
  const onChangeSelectField = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setSelected(field?.find((f) => f.id === value));
  };

  /**
   * 텍스트 입력필드 변경 이벤트
   */
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setSearch((prev) => {
      const newState = { ...prev, [name]: value.length > 0 ? value : undefined };
      handleSearchChange(newState);
      return newState;
    });
  };

  /**
   * 셀렉트 입력필드 변경
   */
  const onInputSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setSearch((prev) => {
      const newState = { ...prev, [name]: value.length > 0 ? value : undefined };
      handleSearchChange(newState);
      return newState;
    });
  };

  /**
   * 날짜 범위 변경
   */
  const onDateRangeChnage = (name: string, changedDate: moment.Moment) => {
    setSearch((prev) => {
      const newState = { ...prev, [name]: changedDate.format('YYYY-MM-DD HH:mm') };
      handleSearchChange(newState);
      return newState;
    });
  };

  /**
   * 검색버튼 클릭
   */
  const onClickCommitButton = () => {
    if (typeof onCommit === 'function') {
      onCommit(search ?? {});
    }
  };

  /**
   * 검색필드 추가 이벤트
   */
  const onAddSelectField = () => {
    if (selected) {
      setSelectFields((prev) => {
        const newState = [...prev, selected];
        handleSelectedFieldsChange(newState);
        return newState;
      });
    }
  };

  /**
   * 검색필드 삭제 이벤트
   */
  const onDeleteSelectField = (select: SearchFieldProps) => {
    setSelectFields((prev) => {
      const newState = prev.filter((p) => p.id !== select.id);
      handleSelectedFieldsChange(newState);
      return newState;
    });

    if (select.type === 'start_end_dtt') {
      setSearch((prev) => {
        const newState = { ...prev, start: undefined, end: undefined };
        handleSearchChange(newState);
        return newState;
      });
    } else {
      setSearch((prev) => {
        const newState = { ...prev, [select.id]: undefined };
        handleSearchChange(newState);
        return newState;
      });
    }
  };

  const renderInput = (select: SearchFieldProps) => {
    switch (select.type) {
      case 'text':
      case 'number':
        return (
          <TextField
            name={select.id}
            onChange={onInputChange}
            label={select.name}
            fullWidth
            variant="standard"
            defaultValue={defaultSearch && defaultSearch[select.id]}
          />
        );
      case 'site':
        return (
          <SiteSelectBox
            name={select.id}
            data={flatSite}
            onChange={onInputSelectChange}
            defaultValue={defaultSearch && defaultSearch[select.id]}
          />
        );
      case 'role':
        return (
          <RoleSelectBox
            name={select.id}
            data={roles}
            onChange={onInputSelectChange}
            defaultValue={defaultSearch && defaultSearch[select.id]}
          />
        );
      case 'code':
        return (
          <CodeSelectBox
            name={select.id}
            data={flatCode}
            onChange={onInputSelectChange}
            parentId={select.code}
            defaultValue={defaultSearch && defaultSearch[select.id]}
          />
        );
      case 'tagType':
        return (
          <TagTypeSelectBox
            fullWidth
            name={select.id}
            onChange={onInputSelectChange}
            defaultValue={defaultSearch && defaultSearch[select.id]}
          />
        );
      case 'start_end_dtt':
        // const defaultValue = ;
        return (
          <BaseDateRangePicker
            label="조회기간"
            onChange={onDateRangeChnage}
            defaultValue={
              defaultSearch?.start && defaultSearch?.end
                ? {
                    start: moment(defaultSearch.start),
                    end: moment(defaultSearch.end),
                  }
                : { start: moment().add(-1, 'days'), end: moment() }
            }
          />
        );
      case 'dataType':
        return (
          <TagDataTypeSelectBox
            name={select.id}
            label={select.name}
            defaultValue={defaultSearch && defaultSearch[select.id]}
            onChange={onInputSelectChange}
          />
        );
      case 'useYN':
        return (
          <BaseSelectBox
            onChange={onInputSelectChange}
            name={select.id}
            label={select.name}
            defaultValue={defaultSearch && defaultSearch[select.id]}
            items={[
              { id: '', name: '전체' },
              { id: 'true', name: 'Y' },
              { id: 'false', name: 'F' },
            ]}
          />
        );
      default:
        return null;
    }
  };

  // SelectBox에서 현재 선택된 필드
  const selectOptions = useMemo(
    () => field?.filter((f) => !selectFields.map((sf) => sf.id).includes(f.id)),
    [field, selectFields],
  );

  // 추가된 모든 필드 렌더링
  const memoedSelectFieldBoxs = useMemo(
    () =>
      selectFields.map((select) => (
        <Box key={select.id} component="div" mt={1} display="flex" flexDirection="row" alignItems="center">
          <Box component="div" flex={1}>
            {renderInput(select)}
          </Box>
          <Box component="div" ml={1}>
            <IconButton onClick={() => onDeleteSelectField(select)}>
              <Delete />
            </IconButton>
          </Box>
        </Box>
      )),
    [defaultSearch, selectFields],
  );

  useEffect(() => {
    if (selectOptions && selectOptions.length > 0) {
      setSelected(selectOptions[0]);
    } else {
      setSelected(null);
    }
  }, [selectOptions]);

  return (
    <Box component="div" color="primary.secondary" p={2} display="flex" flexDirection="column">
      <Box component="div" display="flex" flexDirection="row" alignItems="flex-end">
        <Box component="div" flex={1}>
          <BaseSelectBox value={selected?.id} onChange={onChangeSelectField} label="검색조건" items={selectOptions} />
        </Box>
        <Box component="div" ml={1}>
          <Button disabled={!selected} onClick={onAddSelectField}>
            <Typography>추가</Typography>
          </Button>
        </Box>
      </Box>

      {memoedSelectFieldBoxs}

      {!hideButton && (
        <Box component="div" mt={2}>
          <Button fullWidth variant="contained" onClick={onClickCommitButton}>
            <Typography>{buttonLabel}</Typography>
          </Button>
        </Box>
      )}

      {children}
    </Box>
  );
}
