import React, { useMemo, useState } from 'react';
import { Box, Button, Card, Chip, Fab, FormControl, FormLabel, SelectChangeEvent, Typography } from '@mui/material';
import { ITag } from '@types';
import { Data10ChartCardProps } from 'components/data/Data10ChartCard';
import TagSearchModal from 'components/tag/TagSearchModal';
import moment from 'moment';
import { DataLayout } from 'layout/data';
import { Save } from '@mui/icons-material';
import { toast } from 'react-toastify';
import DateSearchBox, { DateSearchProps } from 'components/common/DateSearchBox';
import { AggUnitType } from 'common/hooks/useTagChartData10';
import CostSelectBox from 'components/cost/CostSelectBox';
import { useChart10CostCard } from 'common/hooks/useChart10CostCard';
import { CostTagData10CardProps } from 'components/data/CostTagData10Card';

export default function DataTagCostPage() {
  // 태그 검색창 오픈 이벤트
  const handleOpenTagSearchModal = () => {
    setOpenTagSearchModal(true);
  };
  // 태그 검색창 닫기 이벤트
  const handleCloseTagSearchModal = () => {
    setOpenTagSearchModal(false);
  };
  // 태그 검색창 검색결과 전달받기
  const handleTagSearch = (tags: ITag[]) => {
    setSelectedTags((prev) => {
      const newArr = new Set([...prev, ...tags]);
      return Array.from(newArr);
    });

    handleCloseTagSearchModal();
  };
  // 태그 Chip 삭제
  const handleDeleteTag = (tagCode: string) => {
    setSelectedTags((prev) => prev.filter((ptag) => ptag.tagCode !== tagCode));
  };
  // 날짜 조회 정보 변경
  const handleDateChange = (data: DateSearchProps) => {
    setDateState(data);
  };
  // 검색폼 - 요금 선택
  const handleCost = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setSearchState((prev) => ({ ...prev, cost: value }));
  };
  // 검색폼 - 차트 취합 단위 변경
  const handleAggUnitType = (e: SelectChangeEvent) => {
    const { value: aggUnit } = e.target;
    setSearchState((prev) => ({ ...prev, aggUnit: aggUnit as AggUnitType }));
  };
  // 차트추가
  const handleAddChart = (card: Data10ChartCardProps[]) => {
    addCard(card);
    setSelectedTags([]);
  };

  // ChartCard 병합 추가
  const handleAddMergedChartCard = () => {
    const newChartCards: CostTagData10CardProps = {
      id: `${selectedTags.map((tag) => tag.tagCode)}_${moment().format('x')}`,
      tags: selectedTags.map((tag) => tag.tagCode),
      height: 380,
      chartHeight: 380,
      dateProps: { ...dateState },
      aggType: 'max',
      aggUnit: searchState.aggUnit,
      cost: searchState.cost,
      useGridRowData: false,
      lg: 12,
      xl: 12,
    };

    handleAddChart([newChartCards]);
  };

  // ChartCard 개별 추가
  const handleAddIndividualChart = () => {
    const newChartCards: CostTagData10CardProps[] = selectedTags.map((tag, index) => ({
      id: `${tag}_${index}_${moment().format('x')}`,
      tags: [tag.tagCode],
      height: 300,
      chartHeight: 300,
      dateProps: { ...dateState },
      aggType: 'max',
      aggUnit: searchState.aggUnit,
      cost: searchState.cost,
      useGridRowData: false,
      lg: 12,
      xl: 12,
    }));

    handleAddChart(newChartCards);
  };

  // CustomConfig 설정 저장
  const handleSaveConfig = () => {
    // setCustomConfig((prev) => ({ ...prev, dataTagConfig: getCardData() }));
    toast('차트 설정 저장 완료', { type: 'success' });
  };

  const [openTagSearchModal, setOpenTagSearchModal] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  // 검색 - 날짜유형
  const [dateState, setDateState] = useState<DateSearchProps>({
    type: 'day',
    duration: 1,
    durationUnit: 'day',
    start: moment(),
    end: moment(),
  });
  // 검색 - 차트유형
  const [searchState, setSearchState] = useState<{
    aggUnit: AggUnitType;
    cost?: string;
  }>({
    aggUnit: 'hour',
    cost: undefined,
  });

  // const [customConfig, setCustomConfig] = useRecoilState(customConfigState);
  const { cards, addCard } = useChart10CostCard({});

  const renderTagChips = useMemo(
    () =>
      selectedTags.map((tag) => (
        <Chip key={tag.tagCode} sx={{ m: 0.5 }} label={tag.tagCode} onDelete={() => handleDeleteTag(tag.tagCode)} />
      )),
    [selectedTags],
  );

  const memoedSearchBox = useMemo(
    () => (
      <Box component="div" sx={{ p: 2 }}>
        <Card variant="outlined" sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
          <FormControl sx={{ mb: 1 }}>
            <FormLabel>태그 목록</FormLabel>
            <Box component="div" sx={{ display: 'flex', flexWrap: 'wrap', minHeight: 25 }}>
              {renderTagChips}
            </Box>
            <Button fullWidth variant="contained" color="info" onClick={handleOpenTagSearchModal}>
              <Typography>태그 검색</Typography>
            </Button>
          </FormControl>
          <Box component="div" sx={{ my: 1 }}>
            <CostSelectBox onChange={handleCost} label="계산요금" />
          </Box>
          <Box component="div" display="flex" alignItems="center" sx={{ my: 1 }}>
            <DateSearchBox
              {...dateState}
              menu={['day', 'month', 'year', 'currentDay', 'currentMonth', 'currentYear']}
              onChange={handleDateChange}
            />
          </Box>
          <Box component="div" display="flex">
            <Button
              fullWidth
              disabled={selectedTags.length < 1 || Boolean(!searchState.cost)}
              onClick={handleAddIndividualChart}
              variant="contained"
              sx={{ mr: 1 }}
            >
              <Typography>개별 차트추가</Typography>
            </Button>
            <Button
              fullWidth
              disabled={selectedTags.length < 1 || Boolean(!searchState.cost)}
              onClick={handleAddMergedChartCard}
              variant="contained"
              sx={{ ml: 1 }}
            >
              <Typography>병합 차트추가</Typography>
            </Button>
          </Box>
        </Card>
      </Box>
    ),
    [dateState, searchState, renderTagChips, searchState.cost],
  );

  return (
    <DataLayout title="원단위 데이터 조회">
      <Box component="div">
        {memoedSearchBox}
        {cards}
      </Box>
      <Fab sx={{ position: 'fixed', right: 10, bottom: 10 }} color="success" size="small" onClick={handleSaveConfig}>
        <Save />
      </Fab>
      <TagSearchModal open={openTagSearchModal} onClose={handleCloseTagSearchModal} onConfirm={handleTagSearch} />
    </DataLayout>
  );
}
