import React, { useEffect } from 'react';
import { ILoginRequest, TLoginResponse } from '@types';
import { loginAPI, refreshAPI } from 'apis/user';
import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { loginState } from 'states';
import LOGO from 'resource/image/SEP_LOGO_WHITE.png';
import LoginLayout from 'layout/login';
import { useCookies } from 'react-cookie';
import useLoading from 'common/hooks/useLoading';
import { ToastContainer } from 'react-toastify';
import { Box } from '@mui/material';
import Snowfall from 'react-snowfall';
import TUK_LOGO from 'resource/image/TUK_LOGO_FULL.png';
import BackgroundImage from 'resource/image/loginBackground3.jpg';

function LoginPage() {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const setUser = useSetRecoilState(loginState);

  const onLoginSuccess = (data: TLoginResponse) => {
    if (data.success && data.data) {
      setUser(data.data);
      setCookie('token', data.data.token, { path: '/' });
    }
  };

  const loginMutation = useMutation(loginAPI, {
    onSuccess: onLoginSuccess,
  });

  const refreshMutation = useMutation(refreshAPI, {
    onSuccess: onLoginSuccess,
  });

  const loginLoading = useLoading({ trigger: false });

  const onLogin = async (data: ILoginRequest) => {
    const result = await loginMutation.mutateAsync(data);
    return result.success;
  };

  useEffect(() => {
    if (cookies.token) {
      refreshMutation.mutate(cookies.token);
    }
  }, []);

  useEffect(() => {
    loginLoading({ loading: loginMutation.isLoading, message: '로그인 진행중...' });
  }, [loginMutation.isLoading]);

  return (
    <>
      <LoginLayout logoImage={LOGO} subLogoImage={TUK_LOGO} backgroundImage={BackgroundImage} onLogin={onLogin} />
      <ToastContainer />
      <Box component="div" sx={{ width: '100vw', height: '100vh', position: 'absolute', top: 1 }}>
        <Snowfall snowflakeCount={200} speed={[0.5, 1.0]} color="rgba(255, 255, 255, 0.2)" />
      </Box>
    </>
  );
}

export default LoginPage;
