import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { IGroup, ILocation } from '@types';
import React, { useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useRecoilValue } from 'recoil';
import { flatGroupState } from 'states';
import GroupNavigationListItem from './GroupNavigationListItem';

type Props = {
  groupIds: number[];
  width?: number;
  onClickGroup?(group: IGroup): void;
  onClickGroupsLocation?(location?: ILocation): void;
};

GroupNavigation.defaultProps = {
  width: 250,
  onClickGroup: undefined,
  onClickGroupsLocation: undefined,
};

export default function GroupNavigation({ width, groupIds, onClickGroup, onClickGroupsLocation }: Props) {
  const flatGroups = useRecoilValue(flatGroupState);
  const groups = useMemo(() => flatGroups.filter((group) => groupIds.includes(group.id)), [flatGroups, groupIds]);
  const [open, setOpen] = useState<boolean>(true);

  const anime = useSpring({
    x: open ? 0 : ((width ?? 250) - 30) * -1,
    radius: open ? 10 : 250,
  });

  const toggle = () => setOpen((prev) => !prev);

  const ArrowIcon = useMemo(
    () => (open ? <ArrowLeft sx={{ color: '#ddd' }} /> : <ArrowRight sx={{ color: '#ddd' }} />),
    [open],
  );

  return (
    <AnimatedBox
      component="div"
      position="absolute"
      display="flex"
      justifyContent="flex-end"
      sx={{
        left: 0,
        top: 0,
        height: '100%',
        zIndex: 999,
        opacity: 0.7,
        backgroundColor: 'black',
      }}
      style={{
        width: width ?? 250,
        translate: anime.x,
        borderTopRightRadius: anime.radius,
        borderBottomRightRadius: anime.radius,
      }}
    >
      <AnimatedBox component="div" display="flex" flexDirection="column" sx={{ flex: 1, alignItems: 'center', p: 1 }}>
        <Box component="div" sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
          {groups.map((group) => (
            <GroupNavigationListItem
              key={group.id}
              group={group}
              onClickInfo={onClickGroup}
              onClickLocation={onClickGroupsLocation}
            />
          ))}
        </Box>
      </AnimatedBox>
      <Box component="div" display="flex" justifyContent="center" alignItems="center" alignContent="center">
        <IconButton onClick={toggle} size="small">
          {ArrowIcon}
        </IconButton>
      </Box>
    </AnimatedBox>
  );
}

const AnimatedBox = animated(Box);
