import { Box, Grid, TextField } from '@mui/material';
import { IThreeNode, TThreeNodeCreateRequest } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import GroupFieldDataRenderBox from 'components/group/GroupFieldDataRenderBox';
import GroupSelectInput from 'components/group/GroupSelectInput';
import { MaterialMap } from 'components/three/EquipMesh';
import ThreeModelCard from 'components/threemodel/ThreeModelCard';
import _ from 'lodash';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

export type Props = Omit<IBaseModalProps, 'onConfirm' | 'onDelete'> & {
  data?: Partial<IThreeNode>;
  materialMap?: MaterialMap;
  onConfirm?(data: TThreeNodeCreateRequest): void;
  onDelete?(id: number): void;
  readonly?: boolean;
};

ThreeNodeSaveModal.defaultProps = {
  data: undefined,
  materialMap: {},
  onConfirm: undefined,
  onDelete: undefined,
  readonly: false,
};

export default function ThreeNodeSaveModal({ open, data, onConfirm, onDelete, readonly, ...others }: Props) {
  const [state, setState] = useState<TThreeNodeCreateRequest>({});

  const validate = () => {
    if (!state.name || state.name.length < 1) {
      toast('이름을 입력해주세요.', { type: 'error' });
      return false;
    }

    return true;
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeGroup = (name: string, groupCode: string) => {
    setState((prev) => ({ ...prev, [name]: groupCode }));
  };

  const handleConfirm = () => {
    if (typeof onConfirm === 'function' && validate()) {
      onConfirm(state);
    }
  };

  const handleDelete = () => {
    if (typeof onDelete === 'function' && data && data.id && data.id > 0) {
      onDelete(data.id);
    }
  };

  const renderGroupFieldDatas = useMemo(
    () =>
      _.sortBy(data?.group?.groupFieldDatas, (gfd) => gfd.field.orderNum).map((gfd) => (
        <GroupFieldDataRenderBox exceptCodes={['FIELD_3D']} key={gfd.field.id} data={gfd} containerSx={{ mt: 1 }} />
      )),
    [data],
  );

  useEffect(() => {
    if (data) {
      setState({
        ...data,
        group: data?.group?.groupCode,
        model: data?.model?.id,
      });
    } else {
      setState({});
    }
  }, [data]);

  useEffect(() => {
    if (!open) {
      setState({});
    }
  }, [open]);

  return (
    <BaseModal
      title="3D 노드 저장"
      open={open}
      {...others}
      onConfirm={handleConfirm}
      onDelete={(data?.id || 0) > 0 ? handleDelete : undefined}
    >
      <Box component="div" display="flex" flexDirection="row" sx={{ width: '80vw', height: 600, overflowY: 'hidden' }}>
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          flex={1}
          sx={{ mr: 1, maxHeight: '100%', overflowY: 'auto' }}
        >
          <TextField
            label="이름"
            name="name"
            fullWidth
            variant="standard"
            onChange={onChangeInput}
            defaultValue={data?.name || ''}
            value={state.name}
            InputProps={{ readOnly: readonly }}
          />
          <GroupSelectInput
            name="group"
            label="그룹 선택"
            defaultValue={data?.group?.groupCode}
            onChange={onChangeGroup}
            containerSx={{ mt: 1 }}
            disabled={readonly}
            disableSearch={readonly}
          />
          {renderGroupFieldDatas}
        </Box>
        <Box component="div" display="flex" flex={1} sx={{ ml: 1, mr: 1 }}>
          <Grid container sx={{ width: '100%', height: '100%' }}>
            <ThreeModelCard height="100%" data={data?.model} xs={11} sm={11} md={11} lg={11} xl={11} autoRotate />
          </Grid>
        </Box>
      </Box>
    </BaseModal>
  );
}
