import { Download } from '@mui/icons-material';
import { Chip, IconButton, SxProps, Theme } from '@mui/material';
import { IFile } from '@types';
import { downloadFile } from 'apis/file';
import React from 'react';

type Props = {
  file: IFile;
  sx?: SxProps<Theme>;
};

FileDownloadChip.defaultProps = {
  sx: undefined,
};

export default function FileDownloadChip({ file, sx }: Props) {
  const download = () => {
    downloadFile(file);
  };

  return (
    <Chip
      label={file.filename}
      size="small"
      icon={
        <IconButton size="small" onClick={download}>
          <Download fontSize="small" />
        </IconButton>
      }
      sx={{ mt: 1, ...sx }}
    />
  );
}
