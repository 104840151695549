import React, { useMemo } from 'react';
import { findData10, findData10M, findData1H, findData1Search } from 'apis/data';
import { ChartData } from 'chart.js';
import { useQuery } from 'react-query';
import { CHART_COLORS } from '@const';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import moment from 'moment';
import { NodeSearchFormType } from 'components/reactflow/BaseNodeType';
import { getDateRange } from 'common/lib';
import useLoading from './useLoading';

export type Data1MDuration = '10m' | '1h' | '3h' | '6h' | '12h';

type Props = {
  type?: 'line' | 'bar' | 'scatter';
  tags?: string | null | undefined;
  date?: {
    start: moment.Moment;
    end: moment.Moment;
  };
  colors?: string[];
  duration?: Data1MDuration;
  showLoading?: boolean;
  nodeSearchForm?: NodeSearchFormType;
};

const useTagRTChartData = ({
  tags,
  type = 'line',
  showLoading = false,
  duration = '10m',
  colors,
  date = undefined,
  nodeSearchForm = undefined,
}: Props) => {
  const onFetchData = () => {
    console.log(nodeSearchForm);
    if (nodeSearchForm?.dateSearch && nodeSearchForm.aggFunc) {
      return findData10({
        ...getDateRange(nodeSearchForm.dateSearch),
        tagTypes: tags?.split('|').map((tag) => ({ tag, aggFunc: nodeSearchForm.aggFunc ?? 'mean' })) ?? [],
      });
    }

    if (date) {
      return findData1Search({
        tag: tags ?? '',
        start: date.start.format('YYYY-MM-DD HH:mm:ss'),
        end: date.end.format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    if (duration === '10m') {
      return findData10M({ tag: tags ?? '' });
    }

    if (duration === '3h') {
      const end = moment();
      const start = end.clone().subtract(3, 'hours');

      return findData1Search({
        tag: tags ?? '',
        start: start.format('YYYY-MM-DD HH:mm:ss'),
        end: end.format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    if (duration === '6h') {
      const end = moment();
      const start = end.clone().subtract(6, 'hours');

      return findData1Search({
        tag: tags ?? '',
        start: start.format('YYYY-MM-DD HH:mm:ss'),
        end: end.format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    if (duration === '12h') {
      const end = moment();
      const start = end.clone().subtract(12, 'hours');

      return findData1Search({
        tag: tags ?? '',
        start: start.format('YYYY-MM-DD HH:mm:ss'),
        end: end.format('YYYY-MM-DD HH:mm:ss'),
      });
    }

    return findData1H({ tag: tags ?? '' });
  };

  const { data: dataList, isFetching } = useQuery(['data1', tags, duration, date], onFetchData, {
    enabled: Boolean(tags),
    refetchInterval: 15 * 1000,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  });

  const chartData = useMemo((): ChartData<'line' | 'bar' | 'scatter', any> => {
    const tempData = dataList?.data?.datasets ?? [];

    if (tempData.length < 1) {
      return {
        datasets: [],
      };
    }

    return {
      datasets: tempData.map((data, index) => ({
        type,
        fill: false,
        label: `${data.tag.tagName}(${data.tag.tagUnit.codeName})`,
        data: data.data.map((dt) => ({ x: dt.dtt, y: dt.value })),
        borderColor: colors && colors.length >= tempData.length ? colors[index] : CHART_COLORS[index],
        datalabels: {
          formatter: (value, context) => {
            return Number(value.y.toFixed(2));
          },
        },
      })),
    };
  }, [dataList]);

  const chartAnnotation: AnnotationOptions[] = useMemo(() => {
    const tempData = dataList?.data?.datasets ?? [];
    if (tempData.length < 1) {
      return [];
    }

    return tempData
      .map((data, index) =>
        (data.tag.alarms ?? [])
          .map((alarm) => {
            const annotation: AnnotationOptions[] = [];

            if (alarm.min) {
              annotation.push({
                type: 'line',
                yMin: alarm.min,
                yMax: alarm.min,
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1,
                label: {
                  enabled: true,
                  position: 'start',
                  backgroundColor: 'rgba(0,0,0,0.4)',
                  content: `${alarm.alarmName}-MIN`,
                  padding: {
                    y: 10,
                    x: 5,
                  },
                  font: {
                    family: 'Roboto',
                    size: 11,
                    lineHeight: 0,
                    style: 'normal',
                    weight: 'bold',
                  },
                },
              });
            }

            if (alarm.max) {
              annotation.push({
                type: 'line',
                yMin: alarm.max,
                yMax: alarm.max,
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 1,
                label: {
                  enabled: true,
                  position: 'start',
                  backgroundColor: 'rgba(0,0,0,0.4)',
                  content: `${alarm.alarmName}-MAX`,
                  padding: {
                    y: 10,
                    x: 5,
                  },
                  font: {
                    family: 'Roboto',
                    size: 11,
                    lineHeight: 0,
                    style: 'normal',
                    weight: 'bold',
                  },
                },
              });
            }

            return annotation;
          })
          .flat(),
      )
      .flat();
  }, [dataList]);

  useLoading({ loading: isFetching, message: '데이터 로딩중', trigger: showLoading });

  return { data: chartData, annotation: chartAnnotation, dataList };
};

export default useTagRTChartData;
