import { Box, Typography } from '@mui/material';
import { IEmissionItem } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { emissionItemState } from 'states';
import EmissionItemTree from './EmissionItemTree';

type Props = Omit<IBaseModalProps, 'onConfirm'> & {
  onConfirm?(emissions: IEmissionItem[]): void;
  singleSelect?: boolean;
};

EmissionItemSelectModal.defaultProps = {
  onConfirm: undefined,
  singleSelect: false,
};

export default function EmissionItemSelectModal({ onConfirm, open, singleSelect, ...others }: Props) {
  const [selectedEmissions, setSelectedEmissions] = useState<IEmissionItem[]>([]);
  const emission = useRecoilValue(emissionItemState);

  const handleChecked = (emission: IEmissionItem, checked: boolean) => {
    if (checked) {
      setSelectedEmissions((prev) => [...prev, emission]);
    } else {
      setSelectedEmissions((prev) => prev.filter((p) => p.id !== emission.id));
    }
  };

  const handleConfirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm(selectedEmissions);
    }
  };

  useEffect(() => {
    if (!open) {
      setSelectedEmissions([]);
    }
  }, [open]);

  return (
    <BaseModal
      {...others}
      open={open}
      title="배출계수 선택"
      confirmTitle="추가"
      onConfirm={handleConfirm}
      disableConfirm={singleSelect && selectedEmissions.length > 1}
    >
      <Box component="div" sx={{ width: 600, height: 530 }}>
        <EmissionItemTree
          data={emission}
          showAddBtn={false}
          containerStyle={{ height: 470 }}
          showCheckBtn
          onCheckEmission={handleChecked}
        />
        {singleSelect && selectedEmissions.length > 1 && (
          <Typography color="red">하나의 배출계수만 선택해주세요.</Typography>
        )}
      </Box>
    </BaseModal>
  );
}
