import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  PieController,
  DoughnutController,
  PolarAreaController,
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
} from 'chart.js';
import { PieType } from '@types';

ChartJS.register(
  PolarAreaController,
  PieController,
  DoughnutController,
  RadialLinearScale,
  ArcElement,

  Tooltip,
  Legend,
  ChartDataLabels,
);

type Props = {
  type?: PieType;
  data: (number | string | undefined)[];
  labels: string[];
};

PieChart.defaultProps = {
  type: 'pie',
};

export default function PieChart({ type, data, labels }: Props) {
  const DATASET = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(255, 205, 86, 0.8)',
          'rgba(201, 203, 207, 0.8)',
          'rgba(54, 162, 235, 0.8)',
        ],
      },
    ],
  };

  return (
    <Chart
      type={type || 'pie'}
      width="100%"
      height="100%"
      data={DATASET}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
          radialLinear: {
            display: false,
          },
        },
        transitions: {
          active: {
            animations: {},
          },
        },
        datasets: {
          pie: {},
        },
        elements: {
          arc: {
            borderColor: 'rgba(255, 255, 255, 0.1)',
          },
        },
        plugins: {
          legend: { position: 'bottom', fullSize: true, display: true },
          datalabels: {
            color: '#fff',
            textStrokeColor: '#666',
            textStrokeWidth: 1,
          },
        },
        layout: { padding: { bottom: 5, top: 5, left: 5, right: 5 } },
      }}
    />
  );
}
