import React, { useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { IRole } from '@types';
import BaseSelectBox, { SelectItemProps } from 'components/common/BaseSelectBox';

type Props = {
  name?: string;
  label?: string;
  defaultValue?: string;
  value?: string;
  data?: IRole[];
  onChange?(e: SelectChangeEvent): void;
};

RoleSelectBox.defaultProps = {
  name: undefined,
  label: '권한',
  defaultValue: undefined,
  value: undefined,
  data: [],
  onChange: undefined,
};

export default function RoleSelectBox({ data, label, defaultValue, value, name, onChange }: Props) {
  const renderSelectItem: SelectItemProps[] = useMemo(
    () => data?.map((role) => ({ id: role.roleCode, name: role.roleCode })) ?? [],
    [data],
  );

  return (
    <BaseSelectBox
      label={label}
      items={renderSelectItem}
      defaultValue={defaultValue}
      value={value}
      name={name}
      onChange={onChange}
    />
  );
}
