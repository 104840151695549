import React from 'react';
import { TTaskSearchField } from '@types';
import BaseSearchField, { SearchFieldProps } from 'components/common/BaseSearchField';

const fields: SearchFieldProps[] = [
  {
    id: 'taskName',
    name: 'Name',
    type: 'text',
  },
  {
    id: 'status',
    name: 'Status',
    type: 'code',
    code: 'TASK_STATUS',
  },
  {
    id: 'manager',
    name: 'User',
    type: 'text',
  },
  {
    id: 'site',
    name: 'Site',
    type: 'site',
  },
  {
    id: 'date',
    name: 'Date',
    type: 'start_end_dtt',
  },
];

type Props = {
  onChange?(search: TTaskSearchField): void;
};

TaskSearchField.defaultProps = {
  onChange: undefined,
};

export default function TaskSearchField({ onChange }: Props) {
  return <BaseSearchField<TTaskSearchField> field={fields} onCommit={onChange} />;
}
