import React, { CSSProperties, ReactElement, useCallback, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { ILocation, IMapThreeInfo, IMapThreeInfoData } from '@types';
import { GoogleMapsOverlay } from '@deck.gl/google-maps/typed';
import { ScenegraphLayer } from '@deck.gl/mesh-layers/typed';
import { getFileURL } from 'common/lib';
import PlaceSearchBox from './PlaceSearchBox';

export type IMapProps = {
  defaultCenter?: ILocation;
  defaultZoom?: number;
  containerStyle?: CSSProperties;
  children?: ReactElement | ReactElement[];
  onClickedLocation?(location: ILocation): void;
  showSearchBox?: boolean;
  mapThrees?: IMapThreeInfo[];
  moveLocation?: ILocation;
  tilt?: number;
};

Map.defaultProps = {
  defaultCenter: undefined,
  defaultZoom: 18,
  containerStyle: undefined,
  children: undefined,
  onClickedLocation: undefined,
  showSearchBox: false,
  moveLocation: undefined,
  tilt: 40,

  mapThrees: [],
};

export default function Map({
  containerStyle,
  defaultCenter,
  defaultZoom,
  onClickedLocation,
  showSearchBox,
  moveLocation,
  mapThrees,
  tilt,
  children,
}: IMapProps) {
  const { isLoaded } = useJsApiLoader({
    id: 'SEP_MAP_SCRIPT',
    googleMapsApiKey: 'AIzaSyAZETDlAZJ0UdJHUsR_lzgxmp0wOMENfvU',
    libraries: ['places'],
  });
  const [moveLocationState, setMoveLocationState] = useState<ILocation | undefined>(undefined);
  const [overlay, setOverlay] = useState<GoogleMapsOverlay>();
  const [map, setMap] = useState<google.maps.Map | null>(null);

  // Map Loaded
  const onLoad = useCallback(
    (map: google.maps.Map) => {
      setMap(map);

      // DeckGL GoogleMap Overlay 설정
      const overlay = new GoogleMapsOverlay({
        layers: [],
      });

      overlay.setMap(map);

      setOverlay(overlay);

      setTimeout(() => {
        map.setTilt(tilt ?? 75);
      }, 2000);
    },
    [tilt],
  );

  // Map Unmounted
  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // 중앙좌표 변경
  const changeCenter = (center?: ILocation) => {
    if (center && center.lat && center.lng) {
      map?.setCenter({ lat: center.lat, lng: center.lng });
    }
  };

  // 클릭 이벤트
  const handleClick = (e: google.maps.MapMouseEvent) => {
    e.domEvent.preventDefault();

    if (e.latLng) {
      const { lat, lng } = e.latLng;
      const clickLocation: ILocation = {
        lat: lat(),
        lng: lng(),
      };

      if (typeof onClickedLocation === 'function') {
        onClickedLocation(clickLocation);
      }
    }
  };

  // 위치검색 이벤트
  const handleSearchLocation = (location: ILocation) => {
    if (typeof onClickedLocation === 'function') {
      onClickedLocation(location);
    }
    changeCenter(location);
  };

  useEffect(() => {
    if (map) {
      changeCenter(defaultCenter);
    }
  }, [map, defaultCenter]);

  // ThreeJs Layer 추가
  useEffect(() => {
    if (overlay && mapThrees) {
      const fileMap: { [key: string]: IMapThreeInfo[] } = {};

      mapThrees.forEach((data) => {
        const { file } = data;
        const id = `${file?.id ?? 0}`;

        if (fileMap[id]) {
          fileMap[id].push(data);
        } else {
          fileMap[id] = [data];
        }
      });

      const layers = Object.keys(fileMap).map((fileKey) => {
        const dataList = fileMap[fileKey];
        const data = dataList.map((data) => data.data);
        const scale = dataList.at(-1)?.data?.three.scale ?? 1;
        const file = dataList.at(-1)?.file;

        return new ScenegraphLayer({
          id: `${fileKey}`,
          pickable: true,
          getPosition: (d: IMapThreeInfoData) => [d.location.lng ?? 0, d.location.lat ?? 0],
          getOrientation: (d) => [0, 0, 90],
          sizeScale: scale * 0.5,
          _lighting: 'flat',
          scenegraph: getFileURL(file),
          data,
        });
      });

      overlay.setProps({ layers });
    }
  }, [overlay, mapThrees]);

  useEffect(() => {
    if (isLoaded && map && moveLocationState && moveLocationState.lat && moveLocationState.lng) {
      map.moveCamera({ center: { lat: moveLocationState.lat, lng: moveLocationState.lng }, zoom: 20, tilt: 50 });
      setMoveLocationState(undefined);
    }
  }, [moveLocationState, map, isLoaded]);

  useEffect(() => {
    setMoveLocationState(moveLocation);
  }, [moveLocation]);

  return isLoaded ? (
    <GoogleMap
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={handleClick}
      mapContainerStyle={containerStyle}
      zoom={Number(defaultZoom) ?? 18}
      tilt={75}
      options={{
        disableDefaultUI: true,
        rotateControl: true,
        streetViewControl: true,
        mapId: 'dfa71d6fbc2a9cf7',
        fullscreenControl: true,
      }}
    >
      {showSearchBox && <PlaceSearchBox onLocaionSearched={handleSearchLocation} />}
      {children}
    </GoogleMap>
  ) : (
    <div style={containerStyle} />
  );
}
