import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Grid, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { ICost, TCostCreateRequest } from '@types';
import CodeSelectBox from 'components/code/CodeSelectBox';
import { useRecoilValue } from 'recoil';
import { flatCodeState } from 'states';

type Props = {
  data?: ICost;
  onSave?(request: TCostCreateRequest): void;
  onDelete?(code: string): void;
};

CostForm.defaultProps = {
  data: undefined,
  onSave: undefined,
  onDelete: undefined,
};

export default function CostForm({ data, onSave, onDelete }: Props) {
  const codes = useRecoilValue(flatCodeState);
  const [state, setState] = useState<TCostCreateRequest>({});

  const handleSaveClick = () => {
    if (typeof onSave === 'function') {
      onSave(state);
    }

    if (!data) {
      setState({});
    }
  };

  const handleDeleteClick = () => {
    if (typeof onDelete === 'function' && data?.code && window.confirm(`${state.name}를 정말 삭제하시겠습니까?`)) {
      onDelete(data.code);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (data) {
      setState({ ...data, unitCode: data.unitCode.code });
    } else {
      setState({});
    }
  }, [data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          name="code"
          label="코드"
          variant="standard"
          value={state.code ?? ''}
          fullWidth
          required
          disabled={Boolean(data?.code)}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          name="name"
          label="이름"
          variant="standard"
          value={state.name ?? ''}
          fullWidth
          required
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <CodeSelectBox
          name="unitCode"
          label="단위"
          parentId="TAG_UNIT"
          value={state.unitCode ?? ''}
          data={codes}
          fullWidth
          required
          onChange={handleSelectChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" onClick={handleSaveClick}>
              <Typography>저장</Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="error"
              disabled={Boolean(!data?.code)}
              onClick={handleDeleteClick}
            >
              <Typography>삭제</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
