import React, { ReactElement } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, SxProps, Theme } from '@mui/material';

export interface IBaseModalProps {
  open?: boolean;
  title?: string;
  confirmTitle?: string;
  cancleTitle?: string;
  disableConfirm?: boolean;
  onConfirm?(): void;
  onClose?(): void;
  onDelete?(): void;
  children?: ReactElement | ReactElement[];
  dialogSx?: SxProps<Theme>;
}

BaseModal.defaultProps = {
  open: false,
  disableConfirm: false,
  title: 'Base Modal Title',
  confirmTitle: '저장',
  cancleTitle: '취소',
  onConfirm: undefined,
  onClose: undefined,
  onDelete: undefined,
  children: undefined,
  dialogSx: undefined,
};

export function BaseModal({
  open,
  title,
  confirmTitle,
  cancleTitle,
  disableConfirm,
  dialogSx,
  children,
  onConfirm,
  onClose,
  onDelete,
}: IBaseModalProps) {
  return (
    <Dialog
      open={open ?? false}
      onClose={onClose}
      sx={{ zIndex: '900 !important', ...dialogSx }}
      PaperProps={{ sx: { maxWidth: 1500 } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ overflowX: 'hidden' }}>{children}</DialogContent>
      <DialogActions>
        {cancleTitle && (
          <Button color="inherit" onClick={onClose}>
            {cancleTitle}
          </Button>
        )}
        {onDelete && (
          <Button color="error" onClick={onDelete}>
            삭제
          </Button>
        )}
        {confirmTitle && (
          <Button onClick={onConfirm} disabled={disableConfirm}>
            {confirmTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
