import { Box, IconButton, SxProps, Theme } from '@mui/material';
import { INodeSystem, TNodeCreateRequest, TNodeEdgeCreateRequest, TNodeType } from '@types';
import { deleteNodeAPI, modifyAllNodeAPI } from 'apis/node';
import { createAllNodeEdgeAPI } from 'apis/nodeedge';
import { DateType, NodeDataProps, NodeSearchFormType } from 'components/reactflow/BaseNodeType';
import ReactFlowCard from 'components/reactflow/ReactFlowCard';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  Position,
} from 'react-flow-renderer';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import BaseSelectBox from 'components/common/BaseSelectBox';
import BaseDatePicker, { ViewType } from 'components/common/BaseDatePicker';
import AggTypeSelectBox from 'components/data/AggTypeSelectBox';
import { Search } from '@mui/icons-material';
import moment from 'moment';
import useNodeModal from './useNodeModal';
import { Data10AggType } from './useTagChartData10';

type Props = {
  nodeSystem?: INodeSystem | null;
  sx?: SxProps<Theme>;
  cardSx?: SxProps<Theme>;
  reload?(): void;
  isEditMode?: boolean;
};

const DEFAULT_NODE_OPTION = {
  position: {
    x: 0,
    y: 0,
  },
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
};

export default function useReactFlow({ nodeSystem, reload, isEditMode, sx, cardSx }: Props) {
  const [dateType, setDateType] = useState<DateType | undefined>('realtime');
  const [aggType, setAggType] = useState<Data10AggType | undefined>('sum');
  const [date, setDate] = useState<moment.Moment>(moment());
  const [searchForm, setSearchForm] = useState<NodeSearchFormType | undefined>(undefined);
  const [nodes, setNodes] = useState<Node<NodeDataProps>[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const chartSearchForm = useMemo((): NodeSearchFormType | undefined => {
    if (searchForm === undefined || searchForm.aggFunc === undefined || searchForm?.dateSearch === undefined)
      return undefined;

    switch (dateType) {
      case 'year':
        return {
          ...searchForm,
          dateSearch: {
            ...searchForm.dateSearch,
            durationUnit: 'month',
          },
        };
      case 'month':
        return {
          ...searchForm,
          dateSearch: {
            ...searchForm.dateSearch,
            durationUnit: 'day',
          },
        };
      case 'day':
      default:
        return {
          ...searchForm,
          dateSearch: {
            ...searchForm.dateSearch,
            durationUnit: 'hour',
          },
        };
    }
  }, [dateType, searchForm]);

  const { modal, openModal } = useNodeModal({
    readonly: !isEditMode,
    nodeSystem,
    reload,
    nodeSearchForm: chartSearchForm,
  });

  const handleReload = () => {
    if (typeof reload === 'function') {
      reload();
    }
  };

  // 노드 리스트 초기화
  const initNods = useCallback(() => {
    if (nodeSystem && nodeSystem.nodes) {
      setNodes(
        nodeSystem.nodes.map((node) => ({
          ...DEFAULT_NODE_OPTION,
          ...node,
          data: {
            ...node.data,
            isEditMode,
            onEdit: handleEdit,
            onClick: isEditMode ? undefined : handleEdit,
            onDelete: handleDelete,
            search: searchForm,
          },
          id: node.id.toString(),
        })),
      );
    } else {
      setNodes([]);
    }
  }, [isEditMode, nodeSystem, searchForm]);

  // 노드를 이어주는 edge 초기화
  const initEdges = useCallback(() => {
    if (nodeSystem && nodeSystem.edges) {
      setEdges(
        nodeSystem.edges.map((edge) => ({
          ...edge,
          type: 'buttonEdge',
          sourceHandle: edge.sourceHandler,
          targetHandle: edge.targetHandler,
          data: {
            isEditMode,
            onDelete: handleEdgeDelete,
          },
        })),
      );
    } else {
      setEdges([]);
    }
  }, [nodeSystem]);

  // 노드 변경 이벤트
  const onNodesChange = useCallback(
    (changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes],
  );
  // 선 변경 이벤트
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges],
  );

  // 선 연결 이벤트
  const onConnect = useCallback((connection: Connection) => setEdges((eds) => addEdge(connection, eds)), [setEdges]);

  // 노드 및 엣지 정보 저장
  const onSave = async () => {
    await onSaveNodes();
    await onSaveNodeEdges();
  };

  // 모든 노드 변경사항 저장
  const onSaveNodes = async () => {
    const saveDatas: TNodeCreateRequest[] = nodes.map((node) => ({
      ...node,
      type: node.type as TNodeType | undefined,
      id: node.id,
      nodeSystem: nodeSystem?.id,
    }));

    await modifyAllNodeMutation.mutateAsync(saveDatas);
  };

  // 모든 노드 Edge 저장
  const onSaveNodeEdges = async () => {
    const saveEdges: TNodeEdgeCreateRequest = {
      edges: edges.map((edge) => ({
        id: edge.id,
        label: edge.label as string | undefined,
        source: edge.source,
        target: edge.target,
        type: edge.type,
        nodeSystem: nodeSystem?.id,
        sourceHandler: edge.sourceHandle,
        targetHandler: edge.targetHandle,
      })),
      nodeSystem: nodeSystem?.id,
    };

    await createAllNodeEdgeMutation.mutateAsync(saveEdges);
  };

  // 노드 수정 이벤트
  const handleEdit = (selected: TNodeCreateRequest) => {
    const { id } = selected;
    const selectedNode = _.find(nodeSystem?.nodes, (node) => node.id === id);

    if (selectedNode) {
      openModal(selectedNode);
    }
  };

  // 노드 삭제 이벤트
  const handleDelete = (id: string) => {
    deleteNodeMutation.mutate(id);
  };

  // 노드 엣지 삭제 이벤트
  const handleEdgeDelete = (id: string | undefined) => {
    if (id) {
      setEdges((prev) => prev.filter((p) => p.id !== id));
    }
  };

  // 노드 추가 버튼 클릭 이벤트
  const handleAddClick = () => {
    openModal(null);
  };

  // 모든 노드 변경사항 저장 Mutation
  const modifyAllNodeMutation = useMutation(modifyAllNodeAPI, {
    onSuccess: (data) => {
      if (data && data.success) {
        toast('노드 저장성공', { type: 'success' });
      }
      handleReload();
    },
  });

  // 노드 엣지 생성 Mutation
  const createAllNodeEdgeMutation = useMutation(createAllNodeEdgeAPI, {
    onSuccess: () => {
      handleReload();
    },
  });

  // 노드 삭제 Mutation
  const deleteNodeMutation = useMutation(deleteNodeAPI, {
    onSuccess: (data) => {
      if (data && data.success) {
        toast('노드 삭제성공', { type: 'success' });
      }

      handleReload();
    },
  });

  // 날짜 조회 검색 Form 생성
  const generateSearchForm = () => {
    // 실시간 데이터 조회의 경우 SearchForm을 세팅하지않음
    if (dateType === 'realtime' || aggType === undefined || dateType === undefined) {
      setSearchForm(undefined);
      return;
    }

    const originDate = date.clone();
    let start = moment();
    let end = moment();

    switch (dateType) {
      case 'year':
        start = originDate.clone().set('month', 0).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
        end = originDate.clone().set('month', 11).set('date', 31).set('hour', 23).set('minute', 59).set('second', 59);
        break;
      case 'month':
        start = originDate.clone().set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
        end = originDate.clone().set('date', date.daysInMonth()).set('hour', 23).set('minute', 59).set('second', 59);
        break;
      case 'day':
      default:
        start = originDate.clone().set('hour', 0).set('minute', 0).set('second', 0);
        end = originDate.clone().set('hour', 23).set('minute', 59).set('second', 59);
        break;
    }

    setSearchForm({
      dateSearch: {
        start,
        end,
        type: 'CUSTOM',
        durationUnit: dateType,
        duration: 1,
      },
      aggFunc: aggType,
    });
  };

  // 조회필드 랜더링
  const renderSearchField = useMemo(() => {
    const dateViews: ViewType[] = [];
    let mask = '____-__-__';
    let inputFormat = 'YYYY-MM-DD';

    // 연, 월, 일에 따른 날짜선택 방식 변경
    if (dateType === 'year' || dateType === 'month' || dateType === 'day') dateViews.push('year');
    if (dateType === 'month' || dateType === 'day') dateViews.push('month');
    if (dateType === 'day') dateViews.push('day');

    // 연, 월, 일에 따른 input format 및 text mask 변경
    if (dateType === 'year') {
      mask = '____';
      inputFormat = 'YYYY';
    }

    if (dateType === 'month') {
      mask = '____-__';
      inputFormat = 'YYYY-MM';
    }

    return (
      <Box component="div" display="flex" alignItems="center" sx={{ mb: 1 }}>
        <BaseSelectBox
          label="조회기준"
          fullWidth={false}
          sx={{ minWidth: 150 }}
          onChange={(e) => setDateType(e.target.value as DateType)}
          items={[
            { id: 'year', name: '연' },
            { id: 'month', name: '월' },
            { id: 'day', name: '일' },
            { id: 'realtime', name: '실시간' },
          ]}
          value={dateType}
        />
        {dateType !== 'realtime' && (
          <>
            <BaseDatePicker
              name="start"
              label="조회날짜"
              views={dateViews}
              mask={mask}
              inputFormat={inputFormat}
              sx={{ mx: 1 }}
              onChange={(name, changedDate) => setDate(changedDate)}
              value={date}
            />
            <AggTypeSelectBox
              fullWidth={false}
              sx={{ minWidth: 150 }}
              value={aggType}
              onChange={(e) => setAggType(e.target.value as any)}
            />
          </>
        )}
        <IconButton onClick={generateSearchForm}>
          <Search />
        </IconButton>
      </Box>
    );
  }, [dateType, aggType, date]);

  useEffect(() => {
    initNods();
  }, [initNods]);

  useEffect(() => {
    initEdges();
  }, [initEdges]);

  const reactFlow = useMemo(
    () => (
      <>
        {modal}
        {renderSearchField}
        <ReactFlowCard
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          showControl
          onClickAdd={isEditMode ? handleAddClick : undefined}
          onClickSave={isEditMode ? onSave : undefined}
          onClickRefresh={isEditMode ? handleReload : undefined}
          sx={sx}
          cardSx={cardSx}
          preventScrolling
          minZoom={0.2}
          maxZoom={1.5}
          fitView
          defaultZoom={0.2}
        />
      </>
    ),
    [cardSx, modal, nodeSystem, nodes, edges],
  );

  return {
    reactFlow,
  };
}
