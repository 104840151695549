import { Box, Card, SxProps, Theme, Typography } from '@mui/material';
import { findAllProductManageAPI } from 'apis/productManage';
import { getPrevNameByDateProp } from 'common/lib';
import AlertCard from 'components/alarm/AlertCard';
import { DateSearchProps } from 'components/common/DateSearchBox';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { animated, easings, useSpring } from 'react-spring';

type Props = {
  siteCode?: string;
  productId?: number;
  dateProps?: DateSearchProps;
  cardSx?: SxProps<Theme>;
};

ProductDataCard.defaultProps = {
  siteCode: undefined,
  productId: undefined,
  dateProps: undefined,
  cardSx: undefined,
};

export default function ProductDataCard({ siteCode, productId, dateProps, cardSx }: Props) {
  const { data } = useQuery(['findAllProductManage', siteCode, productId], () =>
    findAllProductManageAPI({ siteCode, productId }),
  );

  const [animeEnd, setAnimeEnd] = useState<boolean>(false);
  const [stateValue, setStateValue] = useState<number>(0);

  // 숫자 애니메이션의 toFixed가, 같은값으로 업데이트되는 경우( 15 -> 15 ) 동작하지않는 이슈로 인해 수정
  const anime = useSpring({
    value: stateValue,
    config: {
      easing: easings.easeOutCubic,
      duration: 2000,
    },
    onStart: () => {
      setAnimeEnd(false);
    },
    onRest: () => {
      setAnimeEnd(true);
    },
    onPause: () => {
      setAnimeEnd(true);
    },
  });

  // 숫자 애니메이션의 toFixed가, 같은값으로 업데이트되는 경우( 15 -> 15 ) 동작하지않는 이슈로 인해 수정
  const memoedAnimeValue = useMemo(() => {
    if (stateValue === 0) return (0).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });

    if (animeEnd)
      return stateValue.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

    return anime.value.to((x: any) =>
      x.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
    );
  }, [anime, animeEnd, stateValue]);

  const value = useMemo(() => {
    const now = moment();
    switch (dateProps?.type) {
      case 'currentMonth':
        return data?.data
          ?.filter((data) => data.year === now.year())
          .map((data) => data.data?.filter((monthData) => moment(monthData.dt).month() === now.month()))
          .at(-1)
          ?.at(-1)?.value;
      case 'currentYear':
        return data?.data
          ?.filter((data) => data.year === now.year())
          .map((data) => data.data?.reduce((sum, month) => sum + month.value, 0))
          .at(-1);
      default:
        return null;
    }
  }, [data, dateProps]);

  const prevValue = useMemo(() => {
    let now = moment();

    switch (dateProps?.type) {
      case 'currentMonth':
        now = now.add(-1, 'month');
        return data?.data
          ?.filter((data) => data.year === now.year())
          .map((data) => data.data?.filter((monthData) => moment(monthData.dt).month() === now.month()))
          .at(-1)
          ?.at(-1)?.value;
      case 'currentYear':
        now = now.add(-1, 'year');
        return data?.data
          ?.filter((data) => data.year === now.year())
          .map((data) => data.data?.reduce((sum, month) => sum + month.value, 0))
          .at(-1);
      default:
        return null;
    }
  }, [data, dateProps]);

  const prevTitle = useMemo(() => {
    const diffValue = (value ?? 0) - (prevValue ?? 0);
    const diffText = diffValue > 0 ? '+' : '';
    const color = diffValue > 0 ? 'green' : 'red';
    const dateText = getPrevNameByDateProp(dateProps, '', ' 대비');

    return (
      <Typography
        variant="caption"
        color={color}
      >{`${dateText} ${diffText}${diffValue.toLocaleString()}개`}</Typography>
    );
  }, [prevValue, value, dateProps]);

  useEffect(() => {
    setStateValue(value ?? 0);
  }, [value]);

  return (
    <AlertCard sx={{ position: 'relative', display: 'flex', justifyContent: 'center', ...cardSx }} variant="elevation">
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        sx={{ height: '100%' }}
        justifyContent="center"
        alignItems="center"
      >
        <Box component="div" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <AnimeTypo variant="h4" fontWeight="bold">
            {memoedAnimeValue}
          </AnimeTypo>
          <Typography variant="h6" fontWeight="bold" sx={{ ml: 1 }}>
            개
          </Typography>
        </Box>
        <Typography variant="caption">{prevTitle}</Typography>
      </Box>
    </AlertCard>
  );
}

const AnimeTypo = animated(Typography);
