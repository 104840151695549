import React, { useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import BaseSelectBox, { SelectItemProps } from 'components/common/BaseSelectBox';

type Props = {
  required?: boolean;
  name?: string;
  defaultValue?: string;
  value?: string;
  onChange?(e: SelectChangeEvent): void;
  fullWidth?: boolean;
};

TagTypeSelectBox.defaultProps = {
  required: false,
  name: undefined,
  defaultValue: undefined,
  value: undefined,
  onChange: undefined,
  fullWidth: false,
};

const TYPES = [
  {
    id: 'REAL',
    name: '일반',
  },
  {
    id: 'VIRTUAL',
    name: '가상',
  },
];

export default function TagTypeSelectBox({ defaultValue, required, value, name, onChange, fullWidth }: Props) {
  const renderSelectItem: SelectItemProps[] = useMemo(() => TYPES.map((type) => ({ ...type })), []);

  return (
    <BaseSelectBox
      required={required}
      label="태그유형"
      items={renderSelectItem}
      defaultValue={defaultValue}
      value={value}
      name={name}
      onChange={onChange}
      fullWidth={fullWidth}
    />
  );
}
