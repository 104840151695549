import { Box, TextField } from '@mui/material';
import { IProduct, TProductCreateRequest, TProductResponse } from '@types';
import { deleteProductAPI, saveProductAPI } from 'apis/product';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

type Props = IBaseModalProps & {
  site?: string;
  data?: IProduct;
  onSaveSuccess?(product?: IProduct): void;
  onDeleteSuccess?(product?: IProduct): void;
};

ProductSaveModal.defaultProps = {
  site: undefined,
  data: undefined,
  onSaveSuccess: undefined,
  onDeleteSuccess: undefined,
};

export default function ProductSaveModal({ site, data, onSaveSuccess, onDeleteSuccess, ...others }: Props) {
  const queryClient = useQueryClient();
  const { open } = others;
  const [state, setState] = useState<TProductCreateRequest>({
    site,
    bigo: '',
    name: '',
  });

  const save = () => {
    createMutation.mutate(state);
  };

  const remove = () => {
    if (data && window.confirm(`${data.name} 제품 정보를 정말 삭제하시겠습니까?`)) {
      deleteMutation.mutate({ id: data.id, siteCode: data.site });
    }
  };

  const createMutation = useMutation(saveProductAPI, {
    onSuccess: (result: TProductResponse) => {
      if (result.success) {
        queryClient.invalidateQueries(['findAllProductBySite']);

        if (typeof onSaveSuccess === 'function') {
          onSaveSuccess(result.data);
        }

        toast('제품 저장 성공', { type: 'success' });
      }
    },
  });

  const deleteMutation = useMutation(deleteProductAPI, {
    onSuccess: (result: TProductResponse) => {
      if (result.success) {
        queryClient.invalidateQueries(['findAllProductBySite']);

        if (typeof onDeleteSuccess === 'function') {
          onDeleteSuccess(result.data);
        }

        toast('제품 삭제 성공', { type: 'success' });
      }
    },
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (data) {
      setState(data);
    }
  }, [data]);

  useEffect(() => {
    if (site) {
      setState((prev) => ({ ...prev, site }));
    }
  }, [site]);

  useEffect(() => {
    if (!open) {
      setState((prev) => ({ ...prev, name: undefined, bigo: undefined }));
    }
  }, [open]);

  return (
    <BaseModal title="제품저장" {...others} onDelete={data?.id ? remove : undefined} onConfirm={save}>
      <Box component="div" display="flex" flexDirection="column" sx={{ width: 600, height: 200 }}>
        <TextField
          name="name"
          label="제품명"
          defaultValue={data?.name ?? ''}
          variant="standard"
          sx={{ mt: 1 }}
          onChange={onChange}
        />
        <TextField
          name="bigo"
          label="비고"
          defaultValue={data?.bigo ?? ''}
          variant="standard"
          sx={{ mt: 1 }}
          onChange={onChange}
        />
      </Box>
    </BaseModal>
  );
}
