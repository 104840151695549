import { Box } from '@mui/material';
import { useChart10MESCard } from 'common/hooks/useChart10MESCard';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import React, { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { flatGroupState } from 'states';
import MesSearchBox from './MesSearchBox';

type Props = IBaseModalProps;

export default function MesSearchModal({ ...others }: Props) {
  const groups = useRecoilValue(flatGroupState);
  const mesGroup = useMemo(
    () =>
      groups.filter(
        (group) => group.groupType.code === 'GROUP_TYPE_MES_PRODUCT' && group.groupDetailType?.code === undefined,
      ),
    [groups],
  );

  const { cards, search } = useChart10MESCard({
    dateProps: { type: 'currentDay' },
    mesParentGroupCode: mesGroup.at(0)?.groupCode,
  });

  useEffect(() => {
    const firstGroup = mesGroup.at(0)?.groupCode;

    if (firstGroup) {
      search({ dateProps: { type: 'currentDay' }, mesParentGroupCode: firstGroup });
    }
  }, [mesGroup]);

  return (
    <BaseModal {...others} title="MES 조회" confirmTitle="" dialogSx={{ zIndex: '999 !important' }}>
      <Box component="div" sx={{ width: 1200, height: 600 }}>
        <MesSearchBox
          defaultDate={{ type: 'currentDay' }}
          defaultGroup={mesGroup.at(0)?.groupCode}
          onSearch={(searchProps) =>
            search({ dateProps: searchProps.dateProps, mesParentGroupCode: searchProps.groupCode })
          }
        />
        {cards}
      </Box>
    </BaseModal>
  );
}
