import { Box, Button, Card, Checkbox, FormControlLabel, SxProps, TextField, Theme, Typography } from '@mui/material';
import { ICode } from '@types';
import React, { ChangeEvent } from 'react';

type Props = {
  data?: Partial<ICode>;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  onSave?(code: Partial<ICode>): void;
  containerStyle?: SxProps<Theme>;
};

CodeForm.defaultProps = {
  data: undefined,
  onChange: undefined,
  onSave: undefined,
  containerStyle: {},
};

export default function CodeForm({ data, onChange, onSave, containerStyle }: Props) {
  const handleSaveClick = () => {
    if (typeof onSave === 'function' && data) {
      onSave(data);
    }
  };

  return (
    <Card variant="outlined" sx={{ p: 2, display: 'flex', ...containerStyle }}>
      <Box component="div" flex={1} display="flex" flexDirection="column" justifyContent="space-around">
        <TextField name="id" type="hidden" sx={{ display: 'none' }} value={data?.id ?? 0} />
        <TextField
          fullWidth
          name="parent"
          variant="outlined"
          label="부모코드"
          InputLabelProps={{ shrink: true }}
          value={data?.parent ?? ''}
          onChange={onChange}
        />
        <TextField
          fullWidth
          name="code"
          variant="outlined"
          label="코드"
          InputLabelProps={{ shrink: true }}
          value={data?.code ?? ''}
          onChange={onChange}
        />
        <TextField
          fullWidth
          name="codeName"
          variant="outlined"
          label="코드명"
          InputLabelProps={{ shrink: true }}
          value={data?.codeName ?? ''}
          onChange={onChange}
        />
        <TextField
          fullWidth
          name="description"
          variant="outlined"
          label="코드설명"
          InputLabelProps={{ shrink: true }}
          value={data?.description ?? ''}
          onChange={onChange}
        />
        <TextField
          fullWidth
          name="orderNum"
          variant="outlined"
          label="순서"
          type="number"
          InputLabelProps={{ shrink: true }}
          value={data?.orderNum ?? 1}
          onChange={onChange}
        />
        <FormControlLabel
          label="사용여부"
          control={<Checkbox name="useYN" checked={data?.useYN ?? false} onChange={onChange} />}
        />
        <Box component="div" display="flex" flexDirection="column">
          <Button sx={{ marginY: 1 }} onClick={handleSaveClick} fullWidth variant="outlined">
            <Typography>저장</Typography>
          </Button>
          <Button sx={{ marginY: 1 }} fullWidth variant="outlined" color="error">
            <Typography>삭제</Typography>
          </Button>
        </Box>
      </Box>
    </Card>
  );
}
