import React, { useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { ICode } from '@types';
import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';

type Props = BaseSelectBoxProps & {
  required?: boolean;
  name?: string;
  defaultValue?: string;
  label?: string;
  value?: string;
  data?: ICode[];
  parentId?: string;
  onChange?(e: SelectChangeEvent): void;
};

CodeSelectBox.defaultProps = {
  required: undefined,
  name: undefined,
  label: undefined,
  defaultValue: undefined,
  value: undefined,
  data: [],
  parentId: undefined,
  onChange: undefined,
};

export default function CodeSelectBox({
  data,
  required,
  parentId,
  label,
  defaultValue,
  value,
  name,
  onChange,
  ...others
}: Props) {
  const renderSelectItem: SelectItemProps[] = useMemo(
    () =>
      data
        ?.filter((code) => (parentId ? code.parent === parentId : true))
        .map((code) => ({ id: code.code, name: code.codeName })) ?? [],
    [data],
  );

  return (
    <BaseSelectBox
      required={required}
      label={label ?? '코드'}
      items={renderSelectItem}
      defaultValue={defaultValue}
      value={value}
      name={name}
      onChange={onChange}
      {...others}
    />
  );
}
