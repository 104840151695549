import React, { useMemo } from 'react';
import { Add, Create, Delete } from '@mui/icons-material';
import { Box, Card, Chip, Divider, IconButton, SxProps, Theme, Typography } from '@mui/material';
import { ITaskHist } from '@types';

type Props = {
  label?: string;
  data?: ITaskHist[];
  cardSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  onClickAdd?(): void;
  onClickModify?(taskHist: ITaskHist): void;
};

TaskHistCard.defaultProps = {
  label: '작업이력',
  data: [],
  cardSx: {},
  containerSx: {},
  onClickAdd: undefined,
  onClickModify: undefined,
};

export function TaskHistCard({ onClickAdd, onClickModify, label, data, containerSx, cardSx }: Props) {
  const handleModify = (hist: ITaskHist) => {
    if (typeof onClickModify === 'function') {
      onClickModify(hist);
    }
  };

  const renderCards = useMemo(
    () =>
      data ? (
        <Card sx={{ ...cardSx }} variant="outlined">
          <Box component="div">
            {data.map((hist) => (
              <Box component="div" key={hist.createdAt} sx={{ p: 1 }}>
                <Box component="div">
                  <Typography variant="caption">{hist.createdAt}</Typography>
                  <Chip size="small" color="secondary" label={hist.statusCode.codeName} sx={{ mx: 0.5 }} />
                  <Chip size="small" label={hist.worker.name} sx={{ mx: 0.5 }} />
                </Box>
                <Box component="div" display="flex" alignItems="center">
                  <Typography sx={{ flex: 1 }} variant="body2">
                    {hist.message}
                  </Typography>
                  <IconButton size="small" onClick={() => handleModify(hist)}>
                    <Create />
                  </IconButton>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        </Card>
      ) : (
        <Card sx={{ ...cardSx }} variant="outlined" />
      ),
    [data],
  );

  return (
    <Box component="div" sx={{ ...containerSx }}>
      <Box component="div" display="flex" alignItems="center">
        <Typography variant="body2">{label}</Typography>
        <IconButton size="small" onClick={onClickAdd}>
          <Add />
        </IconButton>
      </Box>
      {renderCards}
    </Box>
  );
}
