import React from 'react';
import { TData10FindRequest } from '@types';
import { findDataAPI } from 'apis/data';
import { useQuery } from 'react-query';
import useLoading from './useLoading';

type Props = {
  search?: TData10FindRequest;
};

const useTagHistoryData = ({ search }: Props) => {
  const onFetchData = () => {
    if (search) {
      return findDataAPI(search);
    }

    return undefined;
  };

  const { data: dataList, isFetching } = useQuery(['data10_grid', search], onFetchData, {
    enabled: Boolean(search),
    refetchOnWindowFocus: false,
  });

  useLoading({ loading: isFetching, message: '데이터 로딩중', trigger: true });

  return { data: dataList };
};

export default useTagHistoryData;
