import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { IThreeCanvas, TThreeCanvasCreateRequest } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import SiteSelectBox from 'components/site/SiteSelectBox';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatSiteState } from 'states';

type Props = Omit<IBaseModalProps, 'onConfirm' | 'onDelete'> & {
  data?: IThreeCanvas | null;
  onConfirm?(data: TThreeCanvasCreateRequest): void;
  onDelete?(id: number): void;
};

ThreeCanvasSaveModal.defaultProps = {
  data: undefined,
  onConfirm: undefined,
  onDelete: undefined,
};

export default function ThreeCanvasSaveModal({ data, open, onConfirm, onDelete, ...others }: Props) {
  const [state, setState] = useState<TThreeCanvasCreateRequest>({});

  const flatSites = useRecoilValue(flatSiteState);

  const handleConfirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm(state);
    }
  };

  const handleDelete = () => {
    if (typeof onDelete === 'function' && data && data.id) {
      if (window.confirm('3D 캔버스를 삭제하시겠습니까?')) {
        onDelete(data.id);
      }
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (!open) {
      setState({});
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      setState({ ...data });
    } else {
      setState({ orderNum: 1 });
    }
  }, [data]);

  return (
    <BaseModal title="3D 캔버스 저장" open={open} {...others} onConfirm={handleConfirm} onDelete={handleDelete}>
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        sx={{ width: 400, height: 200 }}
      >
        <TextField
          fullWidth
          variant="standard"
          name="name"
          label="이름"
          onChange={onChangeInput}
          defaultValue={data?.name}
          value={state.name}
        />
        <SiteSelectBox
          name="site"
          label="사이트"
          onChange={onChangeSelect}
          defaultValue={data?.site}
          data={flatSites}
        />
        <TextField
          fullWidth
          variant="standard"
          name="orderNum"
          type="number"
          label="순서"
          onChange={onChangeInput}
          defaultValue={data?.name}
          value={state.orderNum}
        />
      </Box>
    </BaseModal>
  );
}
