import {
  ICode,
  ICost,
  ICustomConfig,
  IDashboard,
  IEmissionItem,
  IGroup,
  IGroupType,
  IMatterport,
  INodeSystem,
  IRole,
  ISite,
  IThreeCanvas,
  IThreeModel,
  IThreeNode,
  IUser,
  TLoading,
} from '@types';
import { atom, selector } from 'recoil';
import { flatten } from 'common/lib';

// 로그인 상태
const loginState = atom<IUser | undefined>({
  key: 'loginState',
  default: undefined,
});

// 코드 목록 상태
const codeState = atom<ICode[]>({
  key: 'codeState',
  default: [],
});

// Flatten 코드 목록
const flatCodeState = selector<ICode[]>({
  key: 'flatCodeState',
  get: ({ get }) => {
    return flatten(get(codeState)) as ICode[];
  },
});

// 사이트 목록 상태
const siteState = atom<ISite[]>({
  key: 'siteState',
  default: [],
});

// Flatten 사이트 목록 상태
const flatSiteState = selector<ISite[]>({
  key: 'flatSiteSelector',
  get: ({ get }) => {
    return flatten(get(siteState)) as ISite[];
  },
});

// 그룹유형 목록 상태
const groupTypeState = atom<IGroupType[]>({
  key: 'groupTypeState',
  default: [],
});

// Flatten 그룹유형 목록 상태
const flatGroupTypeState = selector<IGroupType[]>({
  key: 'flatGroupTypeState',
  get: ({ get }) => {
    return flatten(get(groupTypeState)) as IGroupType[];
  },
});

// 그룹 목록 상태
const groupState = atom<IGroup[]>({
  key: 'groupState',
  default: [],
});

// Flatten 그룹 목록 상태
const flatGroupState = selector<IGroup[]>({
  key: 'flatGroupState',
  get: ({ get }) => {
    return flatten(get(groupState)) as IGroup[];
  },
});

// 권한 목록
const roleState = atom<IRole[]>({
  key: 'roleState',
  default: [],
});

// 노드 계통 목록
const nodeSystemState = atom<INodeSystem[]>({
  key: 'nodeSystemState',
  default: [],
});

// 3D 모델 정보 목록
const threeModelState = atom<IThreeModel[]>({
  key: 'threeModelState',
  default: [],
});

// 3D 캔버스 목록
const threeCanvasState = atom<IThreeCanvas[]>({
  key: 'threeCanvasState',
  default: [],
});

// 대시보드 목록
const dashboardState = atom<IDashboard[]>({
  key: 'dashboardState',
  default: [],
});

// 배출아이템 목록
const emissionItemState = atom<IEmissionItem[]>({
  key: 'emissionItemState',
  default: [],
});

// 배출아이템 목록 - Flatten
const flattenEmissionItemState = selector<IEmissionItem[]>({
  key: 'flattenEmissionItemState',
  get: ({ get }) => {
    return flatten(get(emissionItemState)) as IEmissionItem[];
  },
});

// 비용관리 목록
const costState = atom<ICost[]>({
  key: 'costState',
  default: [],
});

// Flatted EmissionItem

// CusotmConfig상태
const customConfigState = atom<ICustomConfig>({
  key: 'customConfig',
  default: {},
});

// 로딩 상태
const loadingState = atom<TLoading>({
  key: 'loading',
  default: {
    message: undefined,
    loading: false,
  },
});

// Light/Dark 모드 상태
const colorModeState = atom<string>({
  key: 'colorModeState',
  default: 'dark',
});

const selectedThreeNodeState = atom<IThreeNode | undefined>({
  key: 'selectedThreeNodeState',
  default: undefined,
});

const matterportState = atom<IMatterport[]>({
  key: 'matterportState',
  default: [],
});

export {
  loginState,
  codeState,
  flatCodeState,
  siteState,
  roleState,
  flatSiteState,
  loadingState,
  groupState,
  flatGroupState,
  customConfigState,
  groupTypeState,
  flatGroupTypeState,
  threeModelState,
  threeCanvasState,
  dashboardState,
  colorModeState,
  nodeSystemState,
  emissionItemState,
  flattenEmissionItemState,
  costState,
  selectedThreeNodeState,
  matterportState,
};
