import { Box } from '@mui/material';
import React from 'react';
import MainPage from './MainPage';

export default function DashboardFullScreenPage() {
  return (
    <Box component="div" sx={{ width: '100%', height: '100%' }}>
      <MainPage isFullscreen />
    </Box>
  );
}
