import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { MaterialMap } from 'components/three/EquipMesh';
import { SystemLayout } from 'layout/system';
import { useRecoilState } from 'recoil';
import { threeCanvasState } from 'states';
import {
  IResponse,
  IThreeCanvas,
  TThreeCanvasCreateRequest,
  TThreeCanvasResponse,
  TThreeNodeCreateRequest,
  TThreeNodeListModifyRequest,
  TThreeNodeResponse,
} from '@types';
import ThreeCanvasSelectBox from 'components/threecanvas/ThreeCanvasSelectBox';
import { useMutation, useQuery } from 'react-query';
import {
  createThreeCanvasAPI,
  deleteThreeCanvasAPI,
  findAllThreeCanvasAPI,
  modifyThreeCanvasAPI,
} from 'apis/threecanvas';
import { Add, Edit } from '@mui/icons-material';
import ThreeCanvasSaveModal from 'components/threecanvas/ThreeCanvasSaveModal';
import ModelEditCanvas from 'components/threecanvas/ModelEditCanvas';
import { toast } from 'react-toastify';
import { createThreeNodeAPI, deleteThreeNodeAPI, modiftThreeNodeListAPI, modifyThreeNodeAPI } from 'apis/threenode';
import _ from 'lodash';

export default function ThreeCanvasPage() {
  const [threeCanvases, setThreeCanvases] = useRecoilState(threeCanvasState);
  const [selectedCanvas, setSelectedCanvas] = useState<IThreeCanvas | null>(null);
  const [modalSelected, setModalSelected] = useState<IThreeCanvas | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { data, refetch } = useQuery(['threecanvases'], findAllThreeCanvasAPI);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalSelected(null);
  };

  const handleAdd = () => {
    setModalSelected(null);
    handleModalOpen();
  };

  const handleEdit = () => {
    setModalSelected(selectedCanvas);
    handleModalOpen();
  };

  const handleCanvasSelect = (_: string | undefined, selected: IThreeCanvas | undefined) => {
    setSelectedCanvas(selected || null);
  };

  const handleSave = (request: TThreeCanvasCreateRequest) => {
    if (request.id && request.id > 0) {
      modifyMutation.mutate(request);
    } else {
      createMutation.mutate(request);
    }
  };

  const handleDelete = (id: number) => {
    deleteMutation.mutate(id);
  };

  const onSaveSuccess = (data: IResponse<any>) => {
    if (data && data.success) {
      toast('3D 캔버스 저장 성공', { type: 'success' });
      handleModalClose();
      refetch();
    }
  };

  const onDeleteSuccess = (data: TThreeCanvasResponse) => {
    if (data && data.success) {
      toast('3D 캔버스 삭제 성공', { type: 'success' });
      handleModalClose();
      refetch();
    }
  };

  const onDeleteNodeSuccess = (data: TThreeNodeResponse) => {
    if (data && data.success) {
      toast('3D 캔버스 노드 삭제 성공', { type: 'success' });
      handleModalClose();
      refetch();
    }
  };

  const handleAddNode = (node: TThreeNodeCreateRequest) => {
    if (selectedCanvas) {
      if (node.id && node.id > 0) {
        modifyNodeMutation.mutate(node);
      } else {
        createNodeMutation.mutate(node);
      }
    }
  };

  const handleDeleteNode = (id: number) => {
    deleteNodeMutation.mutate(id);
  };

  const handleSaveAll = (nodeList: TThreeNodeListModifyRequest) => {
    modiftThreeNodeListMutation.mutate(nodeList);
  };

  const handleRefresh = () => {
    refetch();
  };

  // Canvas Mutations
  const createMutation = useMutation(createThreeCanvasAPI, {
    onSuccess: onSaveSuccess,
  });

  const modifyMutation = useMutation(modifyThreeCanvasAPI, {
    onSuccess: onSaveSuccess,
  });

  const deleteMutation = useMutation(deleteThreeCanvasAPI, {
    onSuccess: onDeleteSuccess,
  });

  // Node Mutations
  const createNodeMutation = useMutation(createThreeNodeAPI, {
    onSuccess: onSaveSuccess,
  });

  const modifyNodeMutation = useMutation(modifyThreeNodeAPI, {
    onSuccess: onSaveSuccess,
  });

  const modiftThreeNodeListMutation = useMutation(modiftThreeNodeListAPI, {
    onSuccess: onSaveSuccess,
  });

  const deleteNodeMutation = useMutation(deleteThreeNodeAPI, {
    onSuccess: onDeleteNodeSuccess,
  });

  useEffect(() => {
    if (data) {
      setThreeCanvases(data?.data || []);
    }
  }, [data]);

  useEffect(() => {
    if (threeCanvases) {
      setSelectedCanvas((prev) => {
        const newSelected = _.find(threeCanvases, (dt) => dt.id === prev?.id);
        return newSelected ? { ...newSelected } : prev;
      });
    }
  }, [threeCanvases]);

  return (
    <SystemLayout title="3D Canvas 관리">
      <Box component="div" display="flex" alignItems="center">
        <ThreeCanvasSelectBox label="3D 캔버스" data={threeCanvases} onSelectThreeCanvas={handleCanvasSelect} />
        <IconButton onClick={handleAdd}>
          <Add />
        </IconButton>
        <IconButton onClick={handleEdit} disabled={!selectedCanvas}>
          <Edit />
        </IconButton>
      </Box>
      <ModelEditCanvas
        data={selectedCanvas || undefined}
        onAddNode={handleAddNode}
        onDeleteNode={handleDeleteNode}
        onRefresh={handleRefresh}
        onSaveAll={handleSaveAll}
      />
      <ThreeCanvasSaveModal
        open={modalOpen}
        onConfirm={handleSave}
        onClose={handleModalClose}
        onDelete={handleDelete}
        data={modalSelected}
      />
    </SystemLayout>
  );
}
