import { useDeferredValue, useMemo } from 'react';
import { IGroup } from '@types';
import {
  checkAlertWithAlarmHist,
  checkAlertWithAlertList,
  checkAlertWithDataset,
  filterGroupFieldData,
  getAlertColor,
} from 'common/lib';
import useTagAlarmState from './useTagAlarmState';
import useLastTagData from './useLastTagData';

type Props = {
  group?: IGroup;
};

export default function useGroupAlarmState({ group }: Props) {
  const alarmTagfields = useMemo(() => filterGroupFieldData(group, 'FIELD_TAG').filter((gfd) => gfd.alarm), [group]);

  const tagAlarmStates = useTagAlarmState({ tags: alarmTagfields?.map((gfd) => gfd.data ?? '') ?? [] });
  const lastDatas = useLastTagData({
    dateProps: { type: 'dayLast' },
    tagTypes: alarmTagfields?.map((atf) => ({ aggFunc: '', tag: atf.data ?? '' })) ?? [],
  });

  const alertState = useMemo(() => {
    const alarmHistAlertType = checkAlertWithAlarmHist(tagAlarmStates);
    const dataAlertType = checkAlertWithDataset(lastDatas ?? []);
    const alertType = checkAlertWithAlertList([alarmHistAlertType, dataAlertType]);
    const alertColor = getAlertColor(alertType);

    return {
      alert: alertType,
      alertColor,
    };
  }, [lastDatas, tagAlarmStates]);

  const deferredAlertState = useDeferredValue(alertState);

  return deferredAlertState;
}
