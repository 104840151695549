import { ENDPOINT } from '@const';
import {
  IResponse,
  TData10FindRequest,
  TData1ChartResponse,
  TData1FindRequest,
  TData1SearchRequest,
  TDataCreateListRequest,
} from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

// 10분 데이터 조회( 실시간 )
export const findData10M = async (request: TData1FindRequest): Promise<TData1ChartResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.data1.find10M,
      url: ENDPOINT.data1.find10M.url.replace('{tagCode}', request.tag),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('데이터 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 1시간 데이터 조회 ( 실시간 )
export const findData1H = async (request: TData1FindRequest): Promise<TData1ChartResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.data1.find1H,
      url: ENDPOINT.data1.find1H.url.replace('{tagCode}', request.tag),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('데이터 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 1분 단위 데이터 검색
export const findData1Search = async (request: TData1SearchRequest): Promise<TData1ChartResponse> => {
  const result = await axios.request({ ...ENDPOINT.data1.findSearch, ...createAuth(), data: request });

  if (!result) {
    throw Error('데이터 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 취합 데이터 조회
export const findData10 = async (request: TData10FindRequest): Promise<TData1ChartResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.data10.findTagData,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('데이터 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 취합 데이터 조회
export const findDataAPI = async (request: TData10FindRequest): Promise<TData1ChartResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.data10.findTagData,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('데이터 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 전일-금일 데이터 조회
export const findPrevDayDataAPI = async (
  tagCode: string,
  aggType: string,
  isIncrease: boolean,
): Promise<TData1ChartResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.data10.findPrevDayData,
      ...createAuth(),
      params: {
        tagCode,
        aggType,
        isIncrease,
      },
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('데이터 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 전월-금월 데이터 조회
export const findPrevMonthDataAPI = async (
  tagCode: string,
  aggType: string,
  isIncrease: boolean,
): Promise<TData1ChartResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.data10.findPrevMonthData,
      ...createAuth(),
      params: {
        tagCode,
        aggType,
        isIncrease,
      },
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('데이터 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 전년-금년 데이터 조회
export const findPrevYearDataAPI = async (
  tagCode: string,
  aggType: string,
  isIncrease: boolean,
): Promise<TData1ChartResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.data10.findPrevYearData,
      ...createAuth(),
      params: {
        tagCode,
        aggType,
        isIncrease,
      },
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('데이터 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 마지막 데이터 조회
export const findLastDataAPI = async (request: TData10FindRequest): Promise<TData1ChartResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.data10.findLastData,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('데이터 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// TopN 데이터 조회
export const findTopNDataAPI = async (request: TData10FindRequest): Promise<TData1ChartResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.data10.findTopN,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('데이터 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 원단위 데이터 조회
export const findCostDataAPI = async (request: TData10FindRequest): Promise<TData1ChartResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.data10.findCostData,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('데이터 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

// 데이터 생성 API
export const createDatasAPI = async (request: TDataCreateListRequest): Promise<IResponse<number>> => {
  const result = await axios
    .request({
      ...ENDPOINT.data1.create,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('데이터 생성 실패');

  toastErrorMessage(result.data);

  return result.data;
};
