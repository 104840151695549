import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import Lottie from 'react-lottie';
import FileLoading from 'resource/lottie/loading/file-loading.json';

type Props = {
  isLoading?: boolean;
};

DataLoadingBox.defaultProps = {
  isLoading: false,
};

export default function DataLoadingBox({ isLoading }: Props) {
  const memoedBox = useMemo(
    () =>
      isLoading ? (
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
        >
          <Lottie
            isClickToPauseDisabled
            style={{ width: '40%', height: '50%', backgroundColor: 'transparent' }}
            options={{
              loop: true,
              autoplay: true,
              rendererSettings: {
                hideOnTransparent: false,
              },
              animationData: FileLoading,
            }}
          />
          <Typography>데이터 로딩</Typography>
        </Box>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      ),
    [isLoading],
  );

  return memoedBox;
}
