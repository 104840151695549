import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import React from 'react';
import EmissionItemHistGrid from './EmissionItemHistGrid';

type Props = IBaseModalProps & {
  emissionItemId?: number;
};

EmissionItemHistModal.defaultProps = {
  emissionItemId: undefined,
};

export default function EmissionItemHistModal({ emissionItemId, ...others }: Props) {
  return (
    <BaseModal {...others} title="배출아이템 변경 이력 조회" disableConfirm confirmTitle="">
      <EmissionItemHistGrid emissionItemId={emissionItemId} />
    </BaseModal>
  );
}
