import { Box, SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import BaseDatePicker from './BaseDatePicker';
import BaseSelectBox from './BaseSelectBox';
import BaseDateRangePicker from './BaseDateRangePicker';

export type DateSearchType =
  // 전일 비교 조회
  | 'prevDay'
  // 전월 비교 조회
  | 'prevMonth'
  // 전년 비교 조회
  | 'prevYear'
  // 금일 현재값 조회
  | 'dayLast'
  // 금월 현재값 조회
  | 'monthLast'
  // 금년 현재값 조회
  | 'yearLast'
  // 시간별 조회
  | 'day'
  // 일별 조회
  | 'month'
  // 일별 범위 조회
  | 'dayRange'
  // 월별 조회
  | 'year'
  // 금일 조회
  | 'currentDay'
  // 금월 조회
  | 'currentMonth'
  // 금년 조회
  | 'currentYear'
  // 금일 최대값 조회
  | 'currentDayDESC'
  // 금일 최저값 조회
  | 'currentDayASC'
  // 금월 최대값 조회
  | 'currentMonthDESC'
  // 금월 최저값 조회
  | 'currentMonthASC'
  // 금년 최대값 조회
  | 'currentYearDESC'
  // 금년 최저값 조회
  | 'currentYearASC'
  // 데이터 조회 기간 및 Duration 등 수동으로 조회
  | 'CUSTOM';

export type DateSearchProps = {
  type?: DateSearchType;
  start?: moment.Moment;
  showPrev?: boolean;
  showLast?: boolean;
  end?: moment.Moment;
  duration?: number;
  durationUnit?: 'year' | 'month' | 'day' | 'hour';
  onChange?(data: DateSearchProps): void;
  menu?: DateSearchType[];
  fullWidth?: boolean;
};

DateSearchBox.defaultProps = {
  type: 'fix',
  start: moment().add(-1, 'days'),
  end: moment(),
  duration: 1,
  durationUnit: 'day',
  onChange: undefined,
  showPrev: false,
  showLast: false,
  menu: undefined,
  fullWidth: false,
};

export default function DateSearchBox(props: DateSearchProps) {
  const { type, start, end, duration, durationUnit, fullWidth, onChange, showPrev, showLast, menu } = props;
  const [state, setState] = useState<DateSearchProps>({ ...props, onChange: undefined });

  // 날짜값 변경 이벤트
  const handleDateChange = (name: string, value: moment.Moment) => {
    let newData = { ...state };

    if (name === 'start') {
      newData = { ...newData, [name]: value.hour(0).minute(0).second(0) };
    } else if (name === 'end') {
      newData = { ...newData, [name]: value.hour(23).minute(59).second(59) };
    }

    setState(newData);

    if (typeof onChange === 'function') {
      onChange(newData);
    }
  };

  // Select 변경 이벤트
  const handleTypeSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;

    let newData = { ...state };
    newData = { ...newData, [name]: value };

    setState(newData);

    if (typeof onChange === 'function') {
      onChange(newData);
    }
  };

  const renderForm = useMemo(() => {
    if (showLast) return undefined;

    switch (state.type) {
      case 'day':
        return (
          <Box component="div" display="flex" flexDirection="row">
            <Box component="div" sx={{ px: 1 }}>
              <BaseDatePicker name="start" label="일자" fullWidth value={state.start} onChange={handleDateChange} />
            </Box>
          </Box>
        );
      case 'month':
        return (
          <Box component="div" display="flex" flexDirection="row">
            <Box component="div" sx={{ px: 1 }}>
              <BaseDatePicker
                name="start"
                views={['year', 'month']}
                label="일자"
                fullWidth
                value={state.start}
                onChange={handleDateChange}
                inputFormat="YYYY-MM"
                mask="____-__"
              />
            </Box>
          </Box>
        );
      case 'year':
        return (
          <Box component="div" display="flex" flexDirection="row">
            <Box component="div" sx={{ px: 1 }}>
              <BaseDatePicker
                name="start"
                views={['year']}
                label="일자"
                fullWidth
                value={state.start}
                onChange={handleDateChange}
                inputFormat="YYYY"
                mask="____"
              />
            </Box>
          </Box>
        );
      case 'dayRange':
        return (
          <Box component="div">
            <Box component="div" sx={{ px: 1 }}>
              <BaseDateRangePicker
                label="일별 조회범위"
                defaultValue={{ start: state.start, end: state.end }}
                onChange={handleDateChange}
              />
            </Box>
          </Box>
        );
      default:
        return undefined;
    }
  }, [showLast, state]);

  const items = useMemo(() => {
    if (menu) {
      return [
        { id: 'day', name: '시간별 조회' },
        { id: 'month', name: '일별 조회' },
        { id: 'year', name: '월별 조회' },
        { id: 'dayRange', name: '일별 범위 조회' },
        { id: 'currentDay', name: '금일 조회' },
        { id: 'currentMonth', name: '금월 조회' },
        { id: 'currentYear', name: '금년 조회' },
        { id: 'dayLast', name: '금일 현재값 조회' },
        { id: 'monthLast', name: '금월 현재값 조회' },
        { id: 'yearLast', name: '금년 현재값 조회' },
        { id: 'curre', name: '금일 조회' },
        { id: 'currentDayDESC', name: '금일 최대값 조회' },
        { id: 'currentDayASC', name: '금일 최저값 조회' },
        { id: 'currentMonthDESC', name: '금월 최대값 조회' },
        { id: 'currentMonthASC', name: '금월 최저값 조회' },
        { id: 'currentYearDESC', name: '금년 최대값 조회' },
        { id: 'currentYearASC', name: '금년 최저값 조회' },
      ];
    }

    if (showLast) {
      return [
        { id: 'dayLast', name: '금일 현재값 조회' },
        { id: 'monthLast', name: '금월 현재값 조회' },
        { id: 'yearLast', name: '금년 현재값 조회' },
      ];
    }

    if (showPrev) {
      return [
        { id: 'currentDay', name: '금일 조회' },
        { id: 'currentMonth', name: '금월 조회' },
        { id: 'currentYear', name: '금년 조회' },
        { id: 'prevDay', name: '전일 비교 조회' },
        { id: 'prevMonth', name: '전월 비교 조회' },
        { id: 'prevYear', name: '전년 비교 조회' },
      ];
    }

    return [
      { id: 'day', name: '시간별 조회' },
      { id: 'month', name: '일별 조회' },
      { id: 'dayRange', name: '일별 범위 조회' },
      { id: 'year', name: '월별 조회' },
      { id: 'currentDay', name: '금일 조회' },
      { id: 'currentMonth', name: '금월 조회' },
      { id: 'currentYear', name: '금년 조회' },
    ];
  }, [showLast, showPrev, menu]);

  const menuItem = useMemo(() => {
    if (menu) {
      return items.filter((item) => menu.includes(item.id as DateSearchType));
    }
    return items;
  }, [items, menu]);

  return (
    <Box component="div" display="flex" alignItems="center" sx={{ pt: 1, pb: 1 }}>
      <BaseSelectBox
        name="type"
        label="조회유형"
        defaultValue={type || 'day'}
        items={menuItem}
        sx={{ minWidth: 150 }}
        fullWidth={fullWidth}
        onChange={handleTypeSelectChange}
      />
      {renderForm}
    </Box>
  );
}
