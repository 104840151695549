import { Box, TextField } from '@mui/material';
import { TMatterportItemCreateRequest, TMatterportItemResponse, IMatterportItemData } from '@types';
import { deleteMatterportItemAPI, saveMatterportItemAPI } from 'apis/matterport';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import GroupSelectInput from 'components/group/GroupSelectInput';
import NodeTagForm from 'components/node/NodeTagForm';
import ThreeModelSelectInput from 'components/threemodel/ThreeModelSelectInput';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import MatterportItemTypeSelectBox from './MatterportItemTypeSelectBox';

type Props = IBaseModalProps & {
  data?: TMatterportItemCreateRequest;
  onSaveSuccess?(data: TMatterportItemResponse): void;
};

MatterportItemSaveModal.defaultProps = {
  data: undefined,
  onSaveSuccess: undefined,
};

export default function MatterportItemSaveModal({ data, onSaveSuccess, ...others }: Props) {
  const [state, setState] = useState<TMatterportItemCreateRequest>({});

  useEffect(() => {
    setState(data ?? {});
  }, [data]);

  // Text 입력 변경
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  // MatterportItem Data 변경
  const handleChangeData = (name: keyof IMatterportItemData, data: any) => {
    setState((prev) => ({ ...prev, data: { ...prev.data, [name]: data } }));
  };

  // 저장
  const handleSave = () => {
    createMutation.mutate(state);
  };

  // 삭제
  const handleDelete = () => {
    if (state.idx && window.confirm('정말 삭제하시겠습니까?')) {
      deleteMutation.mutate(state.idx);
    }
  };

  // DatType에 따른 Form 렌더링
  const renderForm = useMemo(() => {
    if (!state.data) return <div />;

    const { type } = state.data;

    switch (type) {
      case 'Tags':
        return (
          <Box component="div" sx={{ mt: 1 }}>
            <NodeTagForm defaultData={state.data.tags} onChange={(data) => handleChangeData('tags', data)} />
          </Box>
        );
      case 'Group':
        return (
          <Box component="div" sx={{ mt: 1 }}>
            <GroupSelectInput
              label="그룹선택"
              defaultValue={data?.data?.groupCode ?? ''}
              onChange={(name, groupCode) => handleChangeData('groupCode', groupCode)}
            />
          </Box>
        );
      default:
        return <div />;
    }
  }, [data, state]);

  // 메타포트 아이템 저장
  const createMutation = useMutation(saveMatterportItemAPI, {
    onSuccess(result) {
      if (result.success) {
        toast('메타포트 마커 저장 성공', { type: 'success' });

        if (typeof onSaveSuccess === 'function') {
          onSaveSuccess(result);
        }
      }
    },
  });

  const deleteMutation = useMutation(deleteMatterportItemAPI, {
    onSuccess(result) {
      if (result.success) {
        toast('메타포트 아이템 삭제 성공', { type: 'success' });

        if (typeof onSaveSuccess === 'function') {
          onSaveSuccess(result);
        }
      }
    },
  });

  return (
    <BaseModal
      {...others}
      title="메타포트 마커 저장"
      onConfirm={handleSave}
      onDelete={state.idx ? handleDelete : undefined}
    >
      <Box component="div" sx={{ width: 500, height: 500 }}>
        <TextField
          name="label"
          label="마커 라벨"
          fullWidth
          variant="standard"
          onChange={handleChangeInput}
          value={state.label}
        />
        <MatterportItemTypeSelectBox
          name="type"
          label="데이터 유형"
          sx={{ mt: 1 }}
          defaultValue={data?.data?.type ?? ''}
          value={state.data?.type ?? ''}
          onSelectType={handleChangeData}
        />
        <ThreeModelSelectInput
          label="3D 모델 선택"
          sx={{ mt: 1 }}
          fullWidth
          defaultValue={data?.data?.threeModel ? data.data.threeModel.toString() : ''}
          value={state.data?.threeModel ? state.data.threeModel.toString() : ''}
          onChange={(id: string) => handleChangeData('threeModel', id ? Number(id) : undefined)}
        />
        {renderForm}
      </Box>
    </BaseModal>
  );
}
