import { Box, Button, Grid, SelectChangeEvent, Typography } from '@mui/material';
import useLoading from 'common/hooks/useLoading';
import BaseSelectBox, { SelectItemProps } from 'components/common/BaseSelectBox';
import FileUploadBox from 'components/file/FileUploadBox';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { WorkBook, read, utils } from 'xlsx';
import { TExcelTagConvertData } from './ExcelTagDataGrid';

type TExcelTagDataColumnState = {
  tag: string | undefined;
  dtt: string | undefined;
  value: string | undefined;
};

type TExcelData = {
  [key: string]: number | string;
};

type TExcelState = {
  wb: WorkBook | undefined;
  data: TExcelData[];
};

type Props = {
  onChangeData?(data: TExcelTagConvertData[]): void;
};

ExcelTagFileUploadBox.defaultProps = {
  onChangeData: undefined,
};

export default function ExcelTagFileUploadBox({ onChangeData }: Props) {
  const loading = useLoading({ loading: false, trigger: false });

  // 태그 Excel 데이터 컬럼 선택을 위한 State
  const [tagDataColumn, setTagDataColumn] = useState<TExcelTagDataColumnState>({
    tag: undefined,
    dtt: undefined,
    value: undefined,
  });

  // Excel 파일 정보 State
  const [excel, setExcel] = useState<TExcelState>({
    wb: undefined,
    data: [],
  });

  // 업로드 파일 변경 이벤트
  const handleChangeFile = async (name: string | undefined, files: File[]) => {
    loading({ loading: true, message: '엑셀 데이터 조회 중...' });

    const file = files?.at(0);
    if (file) {
      const f = await file.arrayBuffer();
      const wb = read(f, {
        type: 'binary',
        raw: false,
        cellDates: true,
        cellText: false,
        dateNF: 'yyyy"."mm"."dd hh":"mm":"ss',
      });
      const data = utils.sheet_to_json<TExcelData>(wb.Sheets[wb.SheetNames[0]], {
        raw: false,
        dateNF: 'yyyy"."mm"."dd hh":"mm":"ss',
      });

      setExcel((prev) => ({
        ...prev,
        wb,
        data,
      }));
    } else {
      setExcel({ wb: undefined, data: [] });
    }

    loading({ loading: false });
  };

  // 컬럼 매핑 SelectBox 변경 이벤트
  const handleChangeSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setTagDataColumn((prev) => ({ ...prev, [name]: value }));
  };

  // Excel 컬럼(1번행) 리스트
  const excelColumns = useMemo(() => {
    if (!excel.data) return [];
    return Object.keys(excel.data.at(0) ?? {});
  }, [excel.data]);

  // 컬럼 -> Select Item으로 변경
  const excelColumnSelectItem = useMemo(
    (): SelectItemProps[] =>
      excelColumns.map((name) => ({
        id: name,
        name,
      })),
    [excelColumns],
  );

  // SelectBox가 전부 선택되었는지 판단하는 변수
  const isAllSelected = useMemo(
    () =>
      Object.keys(tagDataColumn).filter((column: string) => {
        const key = column as keyof TExcelTagDataColumnState;
        return tagDataColumn[key] === undefined;
      }).length === 0,
    [tagDataColumn],
  );

  // Excel 데이터 -> Tag데이터 저장용으로 변환
  const handleConvertExcelToTagData = useCallback(() => {
    if (!excel.data || !isAllSelected) return;

    const data: TExcelTagConvertData[] = excel.data.map((data, index) => {
      const tag = data[tagDataColumn.tag || ''] as string;
      const dtt = data[tagDataColumn.dtt || ''];
      const value = data[tagDataColumn.value || 0] as number;

      return { index, tag, value, dtt: moment(dtt).format('YYYY-MM-DD HH:mm:ss') };
    });

    if (typeof onChangeData === 'function') {
      onChangeData(data);
    }
  }, [tagDataColumn, isAllSelected, excel.data]);

  return (
    <Box component="div">
      <FileUploadBox
        name="file"
        singleSelect
        accept=".xlsx"
        onChange={handleChangeFile}
        fieldProps={{ variant: 'standard' }}
      />
      {excel.wb && (
        <>
          <Grid container sx={{ mt: 1, mb: 1 }} spacing={1} rowSpacing={0}>
            <Grid item xs={4}>
              <BaseSelectBox name="tag" label="태그코드" items={excelColumnSelectItem} onChange={handleChangeSelect} />
            </Grid>
            <Grid item xs={4}>
              <BaseSelectBox
                name="dtt"
                label="데이터일자"
                items={excelColumnSelectItem}
                onChange={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={4}>
              <BaseSelectBox name="value" label="값" items={excelColumnSelectItem} onChange={handleChangeSelect} />
            </Grid>
          </Grid>

          <Button
            fullWidth
            variant="contained"
            disabled={!isAllSelected}
            onClick={handleConvertExcelToTagData}
            sx={{ mb: 1 }}
          >
            <Typography>데이터 조회</Typography>
          </Button>
        </>
      )}
    </Box>
  );
}
