import { Info, LocationOn } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { IMatterportItem } from '@types';
import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';

type Props = {
  data: IMatterportItem;
  onClickInfo?(data: IMatterportItem): void;
  onClickLocation?(data: IMatterportItem): void;
};

MatterportTagNavigationListItem.defaultProps = {
  onClickInfo: undefined,
  onClickLocation: undefined,
};

export default function MatterportTagNavigationListItem({ data, onClickInfo, onClickLocation }: Props) {
  const [focus, setFocus] = useState<boolean>(false);
  const anime = useSpring({
    scale: focus ? 1 : 0.95,
  });

  const handleClickInfo = () => {
    if (typeof onClickInfo === 'function') {
      onClickInfo(data);
    }
  };

  const handleClickLocation = () => {
    if (typeof onClickLocation === 'function') {
      onClickLocation(data);
    }
  };

  return (
    <AnimatedBox
      key={data.idx}
      component="div"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ width: '100%', backgroundColor: 'rgb(50, 50, 50)', borderRadius: 10, p: 1, my: 0.5 }}
      style={{ scale: anime.scale }}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
    >
      <Typography fontSize={13} fontWeight="bold" color="#eee">
        {data.label}
      </Typography>
      <Box component="div" display="flex" sx={{ flex: 1, justifyContent: 'flex-end' }}>
        <IconButton size="small" onClick={handleClickInfo}>
          <Info fontSize="small" sx={{ color: '#ddd' }} />
        </IconButton>
        <IconButton size="small" onClick={handleClickLocation}>
          <LocationOn fontSize="small" sx={{ color: '#ddd' }} />
        </IconButton>
      </Box>
    </AnimatedBox>
  );
}

const AnimatedBox = animated(Box);
