import React, { useEffect, useState } from 'react';

import moment from 'moment';
import Moment from 'react-moment';
import 'moment/locale/ko';

const useDateNow = () => {
  let timeNow: any;
  const [time, setTime] = useState(moment());

  useEffect(() => {
    timeNow = setInterval(() => {
      setTime(moment());
    }, 1000);
    return () => {
      clearInterval(timeNow);
    };
  }, []);

  return timeNow
}

export default useDateNow;