import {
  Alarm,
  Dashboard,
  InsertChart,
  Inventory,
  QueryBuilder,
  Settings,
  Task,
  ThreeDRotation,
} from '@mui/icons-material';
import { RenderSideTree } from 'components/SideTreeMenu';
import randomcolor from 'randomcolor';

export const API_URL = process.env.REACT_APP_API_URL;
export const WEBSOCKET_URL = process.env.REACT_APP_VOICE_WEBSOCKET_URL;
export const DEPLOY = process.env.REACT_APP_DEPLOY;
export const GA_ID = process.env.REACT_APP_GA_ID;

export const ENDPOINT = {
  user: {
    login: {
      method: 'POST',
      url: `${API_URL}/login`,
    },
    register: {
      method: 'POST',
      url: `${API_URL}/api/user`,
    },
    modify: {
      method: 'PUT',
      url: `${API_URL}/api/user`,
    },
    refresh: {
      method: 'POST',
      url: `${API_URL}/api/refresh`,
    },
    findUsers: {
      method: 'POST',
      url: `${API_URL}/api/users`,
    },
  },
  role: {
    findRoles: {
      method: 'GET',
      url: `${API_URL}/api/roles`,
    },
    findAuthorities: {
      method: 'GET',
      url: `${API_URL}/api/authorities`,
    },
    modifyRole: {
      method: 'PUT',
      url: `${API_URL}/api/role`,
    },
    createRole: {
      method: 'POST',
      url: `${API_URL}/api/role`,
    },
    deleteRole: {
      method: 'DELETE',
      url: `${API_URL}/api/role/{id}`,
    },
  },
  code: {
    findAllCodes: {
      method: 'GET',
      url: `${API_URL}/api/codes`,
    },
    create: {
      method: 'POST',
      url: `${API_URL}/api/code`,
    },
    modify: {
      method: 'PUT',
      url: `${API_URL}/api/code`,
    },
  },
  site: {
    findAllSites: {
      method: 'GET',
      url: `${API_URL}/api/sites`,
    },
    create: {
      method: 'POST',
      url: `${API_URL}/api/site`,
    },
    modify: {
      method: 'PUT',
      url: `${API_URL}/api/site`,
    },
  },
  tag: {
    findPages: {
      method: 'POST',
      url: `${API_URL}/api/tags`,
    },
    findByCode: {
      method: 'GET',
      url: `${API_URL}/api/tags/{code}`,
    },
    create: {
      method: 'POST',
      url: `${API_URL}/api/tag`,
    },
    modify: {
      method: 'PUT',
      url: `${API_URL}/api/tag`,
    },
  },
  alarm: {
    findPages: {
      method: 'POST',
      url: `${API_URL}/api/alarms`,
    },
    createAlarm: {
      method: 'POST',
      url: `${API_URL}/api/alarm`,
    },
    modifyAlarm: {
      method: 'PUT',
      url: `${API_URL}/api/alarm`,
    },
    deleteAlarm: {
      method: 'DELETE',
      url: `${API_URL}/api/alarm/{id}`,
    },
  },
  alarmHist: {
    findMySiteAlarmHists: {
      method: 'GET',
      url: `${API_URL}/api/mysite/alarmhists`,
    },
    findPages: {
      method: 'POST',
      url: `${API_URL}/api/alarmhist/page`,
    },
    findStatusCount: {
      method: 'POST',
      url: `${API_URL}/api/alarmhist/count`,
    },
    deleteAlarm: {
      method: 'DELETE',
      url: `${API_URL}/api/alarmhist/{id}`,
    },
    alarmHistCreateTask: {
      method: 'POST',
      url: `${API_URL}/api/alarmhist/task`,
    },
  },
  task: {
    createTask: {
      method: 'POST',
      url: `${API_URL}/api/task`,
    },
    modifyTask: {
      method: 'PUT',
      url: `${API_URL}/api/task`,
    },
    deleteTask: {
      method: 'DELETE',
      url: `${API_URL}/api/task/{id}`,
    },
    findPageTask: {
      method: 'POST',
      url: `${API_URL}/api/task/search`,
    },
  },
  taskhist: {
    createTaskHist: {
      method: 'POST',
      url: `${API_URL}/api/taskhist`,
    },
    modifyTaskHist: {
      method: 'PUT',
      url: `${API_URL}/api/taskhist`,
    },
    deleteTaskHist: {
      method: 'DELETE',
      url: `${API_URL}/api/taskhist/{id}`,
    },
  },
  groupType: {
    findGroupType: {
      method: 'GET',
      url: `${API_URL}/api/grouptype`,
    },
    createGroupType: {
      method: 'POST',
      url: `${API_URL}/api/grouptype`,
    },
  },
  group: {
    findGroups: {
      method: 'GET',
      url: `${API_URL}/api/groups`,
    },
    create: {
      method: 'POST',
      url: `${API_URL}/api/group`,
    },
    modify: {
      method: 'PUT',
      url: `${API_URL}/api/group`,
    },
    delete: {
      method: 'DELETE',
      url: `${API_URL}/api/group/{groupCode}`,
    },
  },
  data1: {
    find10M: {
      method: 'GET',
      url: `${API_URL}/api/data1/{tagCode}/10m`,
    },
    find1H: {
      method: 'GET',
      url: `${API_URL}/api/data1/{tagCode}/1h`,
    },
    findSearch: {
      method: 'POST',
      url: `${API_URL}/api/data1/search`,
    },
    create: {
      method: 'POST',
      url: `${API_URL}/interface/data`,
    },
  },
  data10: {
    findTagData: {
      method: 'POST',
      url: `${API_URL}/api/data10/search`,
    },
    findPrevDayData: {
      method: 'GET',
      url: `${API_URL}/api/data10/prevd1`,
    },
    findPrevMonthData: {
      method: 'GET',
      url: `${API_URL}/api/data10/prevm1`,
    },
    findPrevYearData: {
      method: 'GET',
      url: `${API_URL}/api/data10/prevy1`,
    },
    findLastData: {
      method: 'POST',
      url: `${API_URL}/api/data10/last`,
    },
    findTopN: {
      method: 'POST',
      url: `${API_URL}/api/data10/topn`,
    },
    findCostData: {
      method: 'POST',
      url: `${API_URL}/api/data10/cost`,
    },
  },
  nodesystem: {
    createNodeSystem: {
      method: 'POST',
      url: `${API_URL}/api/nodesystem`,
    },
    modifyNodeSystem: {
      method: 'PUT',
      url: `${API_URL}/api/nodesystem`,
    },
    deleteNodeSystem: {
      method: 'DELETE',
      url: `${API_URL}/api/nodesystem/{id}`,
    },
    findAllNodeSystem: {
      method: 'GET',
      url: `${API_URL}/api/nodesystem`,
    },
  },
  node: {
    createNode: {
      method: 'POST',
      url: `${API_URL}/api/node`,
    },
    modifyNode: {
      method: 'PUT',
      url: `${API_URL}/api/node`,
    },
    deleteNode: {
      method: 'DELETE',
      url: `${API_URL}/api/node/{id}`,
    },
    modifyAllNode: {
      method: 'PUT',
      url: `${API_URL}/api/nodes`,
    },
  },
  nodeEdge: {
    createAllNodeEdge: {
      method: 'POST',
      url: `${API_URL}/api/nodeedges`,
    },
  },
  threeModel: {
    createThree: {
      method: 'POST',
      url: `${API_URL}/api/three`,
    },
    modifyThree: {
      method: 'PUT',
      url: `${API_URL}/api/three`,
    },
    deleteThree: {
      method: 'DELETE',
      url: `${API_URL}/api/three/{id}`,
    },
    findAllThrees: {
      method: 'GET',
      url: `${API_URL}/api/threes`,
    },
  },
  threeCanvas: {
    createThreeCanvas: {
      method: 'POST',
      url: `${API_URL}/api/threecanvas`,
    },
    modifyThreeCanvas: {
      method: 'PUT',
      url: `${API_URL}/api/threecanvas`,
    },
    deleteThreeCanvas: {
      method: 'DELETE',
      url: `${API_URL}/api/threecanvas/{id}`,
    },
    findAllThreeCanvases: {
      method: 'GET',
      url: `${API_URL}/api/threecanvases`,
    },
  },
  threeNode: {
    createThreeNode: {
      method: 'POST',
      url: `${API_URL}/api/threenode`,
    },
    modifyThreeNode: {
      method: 'PUT',
      url: `${API_URL}/api/threenode`,
    },
    modifyThreeNodes: {
      method: 'PUT',
      url: `${API_URL}/api/threenodes`,
    },
    deleteThreeNode: {
      method: 'DELETE',
      url: `${API_URL}/api/threenode/{id}`,
    },
  },
  dashboard: {
    createDashboard: {
      method: 'POST',
      url: `${API_URL}/api/dashboard`,
    },
    modifyDashboard: {
      method: 'PUT',
      url: `${API_URL}/api/dashboard`,
    },
    deleteDashboard: {
      method: 'DELETE',
      url: `${API_URL}/api/dashboard/{id}`,
    },
    findAllDashboard: {
      method: 'GET',
      url: `${API_URL}/api/dashboards`,
    },
    duplicateDashboard: {
      method: 'POST',
      url: `${API_URL}/api/dashboard/dupl/{id}`,
    },
  },
  dashboardItem: {
    createDashboardItem: {
      method: 'POST',
      url: `${API_URL}/api/dashboardItem`,
    },
    modifyDashboardItem: {
      method: 'PUT',
      url: `${API_URL}/api/dashboardItem`,
    },
    modifyDashboardItemList: {
      method: 'PUT',
      url: `${API_URL}/api/dashboardItemList`,
    },
    deleteDashboardItem: {
      method: 'DELETE',
      url: `${API_URL}/api/dashboardItem/{id}`,
    },
    findDashboardItem: {
      method: 'GET',
      url: `${API_URL}/api/dashboardItem/{id}`,
    },
  },
  vrtour: {
    createVRTour: {
      method: 'POST',
      url: `${API_URL}/api/vrtour`,
    },
    modifyVRTour: {
      method: 'PUT',
      url: `${API_URL}/api/vrtour`,
    },
    deleteVRTour: {
      method: 'DELETE',
      url: `${API_URL}/api/vrtour/{id}`,
    },
    findById: {
      method: 'GET',
      url: `${API_URL}/api/vrtour/{id}`,
    },
    findAllVRTours: {
      method: 'GET',
      url: `${API_URL}/api/vrtours`,
    },
  },
  emission: {
    createEmissionItem: {
      method: 'POST',
      url: `${API_URL}/api/emission`,
    },
    modifyEmissionItem: {
      method: 'PUT',
      url: `${API_URL}/api/emission`,
    },
    deleteEmissionItem: {
      method: 'delete',
      url: `${API_URL}/api/emission/{code}`,
    },
    findAllEmissionItem: {
      method: 'GET',
      url: `${API_URL}/api/emissions`,
    },
    findAllEmissionItemHist: {
      method: 'GET',
      url: `${API_URL}/api/emission/{id}/hist`,
    },
  },
  cost: {
    findAllCost: {
      method: 'GET',
      url: `${API_URL}/api/costs`,
    },
    saveCost: {
      method: 'POST',
      url: `${API_URL}/api/cost`,
    },
    deleteCost: {
      method: 'DELETE',
      url: `${API_URL}/api/cost/{code}`,
    },
    saveCostItem: {
      method: 'POST',
      url: `${API_URL}/api/cost/item`,
    },
    deleteCostItem: {
      method: 'DELETE',
      url: `${API_URL}/api/cost/item/{id}`,
    },
    saveCostItemMonth: {
      method: 'POST',
      url: `${API_URL}/api/cost/month`,
    },
    deleteCostItemMonth: {
      method: 'DELETE',
      url: `${API_URL}/api/cost/month/{id}`,
    },
    saveCostItemTime: {
      method: 'POST',
      url: `${API_URL}/api/cost/time`,
    },
    deleteCostItemTime: {
      method: 'DELETE',
      url: `${API_URL}/api/cost/time/{id}`,
    },
  },
  product: {
    saveProduct: {
      method: 'POST',
      url: `${API_URL}/api/product`,
    },
    findAllProduct: {
      method: 'GET',
      url: `${API_URL}/api/product/{siteCode}`,
    },
    deleteProduct: {
      method: 'DELETE',
      url: `${API_URL}/api/product/{siteCode}/{id}`,
    },
  },
  productManage: {
    saveProductManage: {
      method: 'POST',
      url: `${API_URL}/api/productManage`,
    },
    findAllProductManageBySiteAndProductId: {
      method: 'GET',
      url: `${API_URL}/api/productManage/{siteCode}/{productId}`,
    },
    deleteProductManage: {
      method: 'DELETE',
      url: `${API_URL}/api/productManage/{siteCode}/{id}`,
    },
  },
  matterport: {
    findAllMatterports: {
      method: 'GET',
      url: `${API_URL}/api/matterports`,
    },
    saveMatterport: {
      method: 'POST',
      url: `${API_URL}/api/matterport`,
    },
    deleteMatterport: {
      method: 'DELETE',
      url: `${API_URL}/api/matterport/{idx}`,
    },
    saveMatterportItem: {
      method: 'POST',
      url: `${API_URL}/api/matterportItem`,
    },
    deleteMatterportItem: {
      method: 'DELETE',
      url: `${API_URL}/api/matterportItem/{idx}`,
    },
    findAllMatterportItem: {
      method: 'GET',
      url: `${API_URL}/api/matterportItem/{matterport}`,
    },
  },
};

export const PAGES: RenderSideTree[] = [
  {
    id: 'matterport_detial',
    name: '',
    icon: undefined,
    visible: false,
    auth: 'NONE',
    url: '/system/matterport/:matterportIdx',
  },
  {
    id: 'node_fullscreen',
    name: '',
    icon: undefined,
    visible: false,
    auth: 'NONE',
    url: '/node/fullscreen',
  },
  {
    id: 'matterport_fullscreen',
    name: '',
    icon: undefined,
    visible: false,
    auth: 'NONE',
    url: '/matterport/fullscreen',
  },
  {
    id: 'dashboard',
    name: '대시보드',
    icon: Dashboard,
    visible: true,
    auth: 'DASHBOARD_VIEW',
    url: '/',
  },
  {
    id: 'vrtour',
    name: 'VR투어',
    icon: ThreeDRotation,
    visible: true,
    auth: 'VRTOUR_VIEW',
    url: '/vrtour',
  },
  {
    id: 'rtdata',
    name: '실시간 조회',
    icon: QueryBuilder,
    visible: true,
    auth: 'DATA_VIEW',
    children: [
      { id: 'tag_rtdata', name: '태그별 실시간 조회', url: '/rtdata/tag', auth: 'DATA_TAG_VIEW', visible: false },
    ],
  },
  {
    id: 'data',
    name: '데이터 조회',
    icon: InsertChart,
    visible: true,
    auth: 'DATA_VIEW',
    children: [
      { id: 'tag_data', name: '태그별 데이터 조회', url: '/data/tag', auth: 'DATA_TAG_VIEW', visible: false },
      { id: 'cost_data', name: '원단위 데이터 조회', url: '/data/cost', auth: 'DATA_TAG_VIEW', visible: false },
      { id: 'mes_data', name: 'MES 데이터 조회', url: '/data/mes', auth: 'DATA_TAG_VIEW', visible: false },
      {
        id: 'product_data',
        name: '제품 생산량 데이터 조회',
        url: '/data/product',
        auth: 'PRODUCT_VIEW',
        visible: false,
      },
      // { id: 'group_data', name: '그룹별 데이터 조회', url: '/data/group', auth: 'DATA_GROUP_VIEW', visible: false },
      { id: 'group_node_data', name: '그룹 노드 조회', url: '/data/node', auth: 'DATA_GROUP_VIEW', visible: false },
    ],
  },
  {
    id: 'alarm',
    name: '알람 관리',
    icon: Alarm,
    visible: true,
    auth: 'ALARM_VIEW',
    children: [
      { id: 'alarm_alarm', name: '알람 관리', url: '/alarm/alarm', auth: 'ALARM_VIEW', visible: false },
      { id: 'hist_alarm', name: '알람이력 관리', url: '/alarm/hist', auth: 'ALARMHIST_VIEW', visible: false },
    ],
  },
  {
    id: 'task',
    name: '작업 관리',
    icon: Task,
    visible: true,
    auth: 'TASK_VIEW',
    url: '/task/task',
  },
  {
    id: 'product',
    name: '생산 관리',
    icon: Inventory,
    visible: false,
    auth: 'PRODUCT_VIEW',
    url: '/product/list',
  },
  {
    id: 'system',
    name: '시스템관리',
    icon: Settings,
    visible: false,
    auth: 'SYSTEM_VIEW',
    children: [
      { id: 'system_site', name: '사이트관리', url: '/system/site', auth: 'SITE_VIEW', visible: false },
      { id: 'system_code', name: '코드관리', url: '/system/code', auth: 'CODE_VIEW', visible: false },
      { id: 'system_tag', name: '태그관리', url: '/system/tag', auth: 'TAG_VIEW', visible: false },
      {
        id: 'system_tag_excel',
        name: '태그 데이터 업로드',
        url: '/system/tag_excel',
        visible: false,
        auth: 'TAG_VIEW',
      },
      { id: 'system_emission', name: '배출계수관리', url: '/system/emission', auth: 'EMISSION_VIEW', visible: false },
      { id: 'system_cost', name: '요금관리', url: '/system/cost', auth: 'COST_VIEW', visible: false },
      { id: 'system_alarm', name: '알람관리', url: '/system/alarm', auth: 'ALARM_VIEW', visible: false },
      {
        id: 'system_grouptype',
        name: '그룹유형관리',
        url: '/system/grouptype',
        auth: 'GROUPTYPE_VIEW',
        visible: false,
      },
      { id: 'system_group', name: '그룹관리', url: '/system/group', auth: 'GROUP_VIEW', visible: false },
      { id: 'system_node', name: '계통관리', url: '/system/node', auth: 'NODESYSTEM_VIEW', visible: false },
      { id: 'system_user', name: '사용자관리', url: '/system/user', auth: 'USER_VIEW', visible: false },
      { id: 'system_role', name: '권한관리', url: '/system/role', auth: 'ROLE_VIEW', visible: false },
      { id: 'system_3dmodel', name: '3D모델관리', url: '/system/3dmodel', auth: 'THREE_VIEW', visible: false },
      { id: 'system_3dcanvas', name: '3D캔버스관리', url: '/system/3dcanvas', auth: 'THREE_VIEW', visible: false },
      {
        id: 'system_matterport',
        name: '메타포트관리',
        url: '/system/matterport',
        auth: 'MATTERPORT_VIEW',
        visible: false,
      },
      {
        id: 'system_dashboard',
        name: '대시보드관리',
        url: '/system/dashboard',
        auth: 'DASHBOARD_VIEW',
        visible: false,
      },
    ],
  },
];

export const CHART_COLORS = [
  'rgba(0, 63, 92, 0.8)',
  'rgba(212, 80, 135, 0.8)',
  'rgba(47, 75, 124, 0.8)',
  'rgba(102, 81, 145, 0.8)',
  'rgba(160, 81, 149, 0.8)',
  'rgba(249, 93, 106, 0.8)',
  'rgba(255, 124, 67, 0.8)',
  'rgba(255, 166, 0, 0.8)',
  randomcolor({ seed: 1500, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1501, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1502, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1503, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1504, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1505, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1506, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1507, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1508, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1509, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1510, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1511, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1512, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1513, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1514, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1515, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1516, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1517, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1519, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1518, alpha: 0.8, format: 'rgba' }),
  randomcolor({ seed: 1520, alpha: 0.8, format: 'rgba' }),
];

export const CHART_COLORS_BG = [
  'rgba(0, 63, 92, 0.4)',
  'rgba(212, 80, 135, 0.4)',
  'rgba(47, 75, 124, 0.4)',
  'rgba(102, 81, 145, 0.4)',
  'rgba(160, 81, 149, 0.4)',
  'rgba(249, 93, 106, 0.4)',
  'rgba(255, 124, 67, 0.4)',
  'rgba(255, 166, 0, 0.4)',
  randomcolor({ seed: 1500, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1501, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1502, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1503, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1504, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1505, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1506, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1507, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1508, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1509, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1510, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1511, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1512, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1513, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1514, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1515, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1516, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1517, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1519, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1518, alpha: 0.4, format: 'rgba' }),
  randomcolor({ seed: 1520, alpha: 0.4, format: 'rgba' }),
];
