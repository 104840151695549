import { SelectChangeEvent } from '@mui/material';
import { IMatterport } from '@types';
import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { matterportState } from 'states';

type Props = BaseSelectBoxProps & {
  onSelectMatterport?(matterport: IMatterport | undefined): void;
};

MatterportSelectBox.defaultProps = {
  onSelectMatterport: undefined,
};

export default function MatterportSelectBox({ onSelectMatterport, ...others }: Props) {
  const matterports = useRecoilValue(matterportState);

  const items = useMemo(
    (): SelectItemProps[] => matterports.map((data) => ({ id: data.idx.toString(), name: data.name })),
    [matterports],
  );

  const handleSelectMatterport = (e: SelectChangeEvent) => {
    const { value } = e.target;

    if (typeof others.onChange === 'function') {
      others.onChange(e);
    }

    if (typeof onSelectMatterport === 'function') {
      const selected = _.find(
        matterports,
        (matterport) => matterport.idx === (value ? parseInt(value, 10) : undefined),
      );

      onSelectMatterport(selected);
    }
  };

  return <BaseSelectBox items={items} {...others} onChange={handleSelectMatterport} />;
}
