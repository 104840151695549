import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { INodeSystem, TNodeSystemCreateRequest } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import SiteSelectBox from 'components/site/SiteSelectBox';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatSiteState } from 'states';

type Props = IBaseModalProps & {
  data?: INodeSystem | null;
  onSaveNodeSystem?(data: TNodeSystemCreateRequest): void;
  onDeleteNodeSystem?(data: TNodeSystemCreateRequest): void;
};

NodeSystemSaveModal.defaultProps = {
  data: undefined,
  onSaveNodeSystem: undefined,
  onDeleteNodeSystem: undefined,
};

export default function NodeSystemSaveModal({ data, onSaveNodeSystem, onDeleteNodeSystem, open, ...others }: Props) {
  const handleSave = () => {
    if (typeof onSaveNodeSystem === 'function') {
      onSaveNodeSystem(state);
    }
  };

  const handleDelete = () => {
    if (typeof onDeleteNodeSystem === 'function' && window.confirm('계통유형을 정말 삭제하시겠습니까?')) {
      onDeleteNodeSystem(state);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const [state, setState] = useState<TNodeSystemCreateRequest>({});
  const flatSite = useRecoilValue(flatSiteState);

  useEffect(() => {
    if (data) {
      setState({ ...data });
    } else {
      setState({ id: 0, name: '', site: '', orderNum: 1 });
    }
  }, [data]);

  useEffect(() => {
    if (!open) {
      setState({ id: 0, name: '', site: '', orderNum: 1 });
    }
  }, [open]);

  return (
    <BaseModal {...others} open={open} title="계통유형 저장" onConfirm={handleSave} onDelete={handleDelete}>
      <Box component="div" display="flex" flexDirection="column" sx={{ width: 500 }}>
        <TextField required name="name" label="계통유형명" variant="standard" value={state.name} onChange={onChange} />
        <Box component="div" mt={2} />
        <SiteSelectBox required useYN name="site" data={flatSite} value={state.site} onChange={onSelectChange} />
        <Box component="div" mt={2} />
        <TextField
          name="orderNum"
          label="순서"
          variant="standard"
          type="number"
          value={state.orderNum}
          onChange={onChange}
        />
      </Box>
    </BaseModal>
  );
}
