import React from 'react';
import { Box } from '@mui/material';
import CostAccordion from 'components/cost/CostAccordion';
import { SystemLayout } from 'layout/system';
import { useRecoilState } from 'recoil';
import { costState } from 'states';

export default function CostPage() {
  const [costs] = useRecoilState(costState);

  return (
    <SystemLayout title="요금관리">
      <Box component="div" sx={{ p: 2 }}>
        {costs.map((cost) => (
          <CostAccordion key={cost.code} data={cost} />
        ))}
        <CostAccordion />
      </Box>
    </SystemLayout>
  );
}
