import { Box, Card, SxProps, Theme } from '@mui/material';
import { IAlarm } from '@types';
import { checkAlert, getAlertColor } from 'common/lib';
import _ from 'lodash';
import React, { ReactElement, useMemo } from 'react';

type Props = {
  alarm?: IAlarm;
  value?: number;
  dtt?: moment.Moment;
  sx?: SxProps<Theme>;
  showAlert?: boolean;
  variant?: 'outlined' | 'elevation';
  children?: ReactElement | ReactElement[];
};

AlertCard.defaultProps = {
  alarm: undefined,
  value: undefined,
  dtt: undefined,
  showAlert: undefined,
  sx: undefined,
  variant: 'outlined',
  children: undefined,
};

export default function AlertCard({ alarm, value, dtt, showAlert, sx, variant, children }: Props) {
  const colorSx = useMemo((): SxProps<Theme> => getAlertColor(checkAlert(alarm, value, dtt)), [alarm, value, dtt]);

  const defaultSx = useMemo((): SxProps<Theme> => {
    if (!showAlert) return _.merge(sx);

    return _.merge(sx, colorSx);
  }, [showAlert, colorSx, sx]);

  return (
    <Card variant={variant} sx={{ ...defaultSx }}>
      {children}
    </Card>
  );
}
