import { Info, LocationOn } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { IGroup, ILocation } from '@types';
import useGroupAlarmState from 'common/hooks/useGroupAlarmState';
import { getLocationFromGroup } from 'common/lib';
import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';

type Props = {
  group: IGroup;
  onClickInfo?: (group: IGroup) => void;
  onClickLocation?: (location?: ILocation) => void;
};

GroupNavigationListItem.defaultProps = {
  onClickInfo: undefined,
  onClickLocation: undefined,
};

export default function GroupNavigationListItem({ group, onClickInfo, onClickLocation }: Props) {
  const alertState = useGroupAlarmState({ group });
  const [focus, setFocus] = useState<boolean>(false);
  const anime = useSpring({
    scale: focus ? 1 : 0.95,
  });

  const handleClickInfo = () => {
    if (typeof onClickInfo === 'function') {
      onClickInfo(group);
    }
  };

  const handleClickLocation = () => {
    if (typeof onClickLocation === 'function') {
      onClickLocation(getLocationFromGroup(group));
    }
  };

  return (
    <AnimatedBox
      key={group.id}
      component="div"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.08)', borderRadius: 10, p: 1, my: 0.5 }}
      style={{ scale: anime.scale }}
      onMouseEnter={() => setFocus(true)}
      onMouseLeave={() => setFocus(false)}
    >
      <Box
        component="div"
        sx={{
          width: 15,
          height: 15,
          borderRadius: 5,
          mr: 0.5,
          backgroundColor: alertState.alertColor.backgroundColor ?? 'green',
        }}
      />
      <Typography fontSize={13} fontWeight="bold" color="#eee">
        {group.groupName}
      </Typography>
      <Box component="div" display="flex" sx={{ flex: 1, justifyContent: 'flex-end' }}>
        <IconButton size="small" onClick={handleClickInfo}>
          <Info fontSize="small" sx={{ color: '#ddd' }} />
        </IconButton>
        <IconButton size="small" onClick={handleClickLocation}>
          <LocationOn fontSize="small" sx={{ color: '#ddd' }} />
        </IconButton>
      </Box>
    </AnimatedBox>
  );
}

const AnimatedBox = animated(Box);
