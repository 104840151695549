import { Box, Card, Typography } from '@mui/material';
import { IAlarmHist } from '@types';
import React from 'react';

type Props = {
  data: IAlarmHist;
};

export default function AlarmHistNoti({ data }: Props) {
  return (
    <Card variant="outlined" sx={{ width: '100%', p: 1, m: -0.5 }}>
      <Box component="div" display="flex" flexDirection="column">
        <Box component="div" sx={{ mt: -0.5, mb: -0.5 }}>
          <Typography variant="caption" sx={{ color: '#333' }}>
            {data.dtt}
          </Typography>
        </Box>
        <Box component="div" display="flex">
          <Box component="div" sx={{ mr: 1 }}>
            <Typography>{data.alarm?.alarmName}</Typography>
          </Box>
          <Box component="div">
            <Typography>{data.value && data.value.toFixed(2)}</Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
