import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

type Props = {
  title: string;
  children?: ReactElement | ReactElement[];
  actions?: ReactElement | ReactElement[];
  variant?: 'elevation' | 'outlined';
};

BaseAccordion.defaultProps = {
  children: undefined,
  actions: undefined,
  variant: 'elevation',
};

export default function BaseAccordion({ title, children, actions, variant }: Props) {
  return (
    <Accordion variant={variant}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
      <AccordionActions>{actions}</AccordionActions>
    </Accordion>
  );
}
