import React, { useEffect, useState } from 'react';
import EmissionItemForm from 'components/emissionitem/EmissionItemForm';
import EmissionItemTree from 'components/emissionitem/EmissionItemTree';
import { SystemLayout } from 'layout/system';
import { useRecoilState } from 'recoil';
import { emissionItemState } from 'states';
import { Box } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import {
  createEmissionItemAPI,
  deleteEmissionItemAPI,
  findAllEmissionItemAPI,
  modifyEmissionItemAPI,
} from 'apis/emission';
import { IEmissionItem, TEmissionItemCreateRequest, TEmissionItemResponse } from '@types';
import { toast } from 'react-toastify';

export default function EmissionPage() {
  const { data, refetch } = useQuery(['emission'], findAllEmissionItemAPI, { refetchOnWindowFocus: false });
  const [emissionItems, setEmissionItems] = useRecoilState(emissionItemState);
  const [selectedItem, setSelectedItem] = useState<IEmissionItem | undefined>(undefined);

  const handleClickTreeItem = (item: IEmissionItem) => {
    setSelectedItem(item);
  };

  const handleAddTreeItem = (parent: IEmissionItem) => {
    setSelectedItem({
      parent: parent.code,
      id: 0,
      code: '',
      name: '',
      type: 'EMISSION_SECTION',
      orderNum: 1,
      value: 0,
      children: [],
    });
  };

  const handleSave = (request: TEmissionItemCreateRequest) => {
    if (request.id && request.id > 0) {
      modifyMutation.mutate(request);
    } else {
      createMutaiton.mutate(request);
    }
  };

  const handleDelete = (code: string) => {
    deleteMutation.mutate(code);
  };

  const onSaveSuccess = (result: TEmissionItemResponse) => {
    if (result.success && result.data) {
      toast('저장 성공', { type: 'success' });
      refetch();
    }
  };

  const onDeleteSuccess = (result: TEmissionItemResponse) => {
    if (result.success && result.data) {
      toast('삭제 성공', { type: 'success' });
      refetch();
    }
  };

  const createMutaiton = useMutation(createEmissionItemAPI, { onSuccess: onSaveSuccess });
  const modifyMutation = useMutation(modifyEmissionItemAPI, { onSuccess: onSaveSuccess });
  const deleteMutation = useMutation(deleteEmissionItemAPI, {
    onSuccess: onDeleteSuccess,
  });

  useEffect(() => {
    setEmissionItems(data?.data || []);
  }, [data]);

  return (
    <SystemLayout title="배출계수관리">
      <Box component="div" display="flex">
        <EmissionItemTree
          data={emissionItems}
          containerStyle={{ flex: 2, minHeight: '80vh', maxHeight: '80vh' }}
          onClickItem={handleClickTreeItem}
          onAddItem={handleAddTreeItem}
        />
        <EmissionItemForm
          data={selectedItem}
          containerSx={{ flex: 1, minHeight: '80vh', maxHeight: '80vh' }}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      </Box>
    </SystemLayout>
  );
}
