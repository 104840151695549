import { PieType } from '@types';
import BaseSelectBox, { BaseSelectBoxProps } from 'components/common/BaseSelectBox';
import React from 'react';

export type PieTypeSelectBoxProps = Omit<BaseSelectBoxProps, 'value'> & {
  value?: PieType;
};

PieTypeSelectBox.defaultProps = {
  value: 'pie',
};

export default function PieTypeSelectBox({ value, ...others }: PieTypeSelectBoxProps) {
  return (
    <BaseSelectBox
      label="차트 유형"
      value={value}
      items={[
        { id: 'pie', name: '파이차트' },
        { id: 'doughnut', name: '도넛차트' },
        { id: 'polarArea', name: '폴라차트' },
      ]}
      {...others}
    />
  );
}
