import React from 'react';
import { Backdrop, Box, Modal, Typography } from '@mui/material';
import { useSpring, animated } from 'react-spring';
import Lottie from 'react-lottie';
import Loading2 from 'resource/lottie/loading/loading-2.json';

type Props = {
  loading?: boolean;
  message?: string;
};

Loading.defaultProps = {
  loading: false,
  message: undefined,
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 99999,
};

const style = {
  bgcolor: 'rgba(255, 255, 255, 1)',
  borderRadius: 200,
  p: 2,
};

export default function Loading({ loading, message }: Props) {
  const anime = useSpring({ opacity: loading ? 1 : 0 });

  return (
    <Modal
      keepMounted
      open={loading ?? false}
      BackdropComponent={Backdrop}
      closeAfterTransition
      BackdropProps={{ timeout: 500 }}
      disableAutoFocus
    >
      <AnimatedBox sx={modalStyle} style={{ ...anime }}>
        <Box component="div" sx={style}>
          <Lottie
            isClickToPauseDisabled
            style={{ width: 170, height: 170 }}
            options={{
              loop: true,
              autoplay: true,
              animationData: Loading2,
            }}
          />
        </Box>
        <Typography
          sx={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', p: 1, mt: 1, borderRadius: 2 }}
          color="white"
          variant="h6"
        >
          {message}
        </Typography>
      </AnimatedBox>
    </Modal>
  );
}

const AnimatedBox = animated(Box);
