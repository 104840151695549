import React from 'react';
import 'react-data-grid/lib/styles.css';
import DataGrid, { Column, DataGridProps, SelectColumn } from 'react-data-grid';
import { Box, Button, Typography } from '@mui/material';
import './grid.css';

export const GRID_CHANGE_COLOR_CLASSNAME = 'changed-rdg-cell';

export type TDataGridColumn<T> = Column<T> & {
  key: keyof T | typeof SelectColumn.key;
};

export type BaseDataGridProps<T> = DataGridProps<T> & {
  columns: TDataGridColumn<T>[];
  rows?: T[];
  onClickRefersh?(): void;
  onClickAdd?(): void;
  onClickSave?(): void;
  onPressCtrlDelete?(row: T): void;
  onPressCtrlNew?(row: T): void;
};

BaseDataGrid.defaultProps = {
  rows: [],
  onClickRefersh: undefined,
  onClickAdd: undefined,
  onClickSave: undefined,
  onPressCtrlDelete: undefined,
  onPressCtrlNew: undefined,
};

export default function BaseDataGrid<T>({
  columns,
  rows = [],
  onClickRefersh,
  onClickAdd,
  onClickSave,
  onPressCtrlDelete,
  onPressCtrlNew,
  ...others
}: BaseDataGridProps<T>) {
  return (
    <Box component="div">
      <Box display="flex" component="div" sx={{ mb: 1 }}>
        <Box component="div">
          {onClickRefersh && (
            <Button onClick={onClickRefersh}>
              <Typography>새로고침</Typography>
            </Button>
          )}
          {onClickAdd && (
            <Button onClick={onClickAdd}>
              <Typography>행 추가</Typography>
            </Button>
          )}
          {onClickSave && (
            <Button onClick={onClickSave}>
              <Typography>저장</Typography>
            </Button>
          )}
        </Box>
      </Box>
      <DataGrid
        className="fill-grid"
        {...others}
        columns={columns}
        rows={rows}
        onPaste={(event) => {
          const columnKey = event.sourceColumnKey as keyof T;
          return { ...event.targetRow, [event.sourceColumnKey]: event.sourceRow[columnKey] };
        }}
        onCellKeyDown={(args, event) => {
          const { row } = args;
          if (event.ctrlKey) {
            if (event.code === 'Backspace' && typeof onPressCtrlDelete === 'function') {
              onPressCtrlDelete(row);
            }
            if (event.code === 'KeyN' && typeof onPressCtrlNew === 'function') {
              onPressCtrlNew(row);
            }
          }
        }}
      />
    </Box>
  );
}
