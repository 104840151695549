import { Box, Card, SxProps, Theme } from '@mui/material';
import { IFile } from '@types';
import { getFileURL } from 'common/lib';
import React, { CSSProperties } from 'react';

export type Props = {
  data?: IFile;
  sx?: SxProps<Theme>;
  imgStyle?: CSSProperties;
};

FileImageCard.defaultProps = {
  data: undefined,
  imgStyle: {},
  sx: {},
};

export default function FileImageCard({ data, sx, imgStyle }: Props) {
  return (
    <Card sx={sx}>
      <Box
        component="div"
        sx={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${getFileURL(data)})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
      {/* <img alt={data?.filename} src={getFileURL(data)} style={{ width: '100%', height: '100%', ...imgStyle }} /> */}
    </Card>
  );
}
