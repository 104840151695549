import React, { useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { IProductManage, TProductManageCreateRequest } from '@types';
import * as xlsx from 'xlsx';
import ProductManageGridRow from './ProductManageGridRow';

interface Column {
  id: keyof IProductManageData;
  label: string;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
}

export interface IProductManageData {
  id: number;
  product_id: number;
  site: string;
  year: number;
  month1: number;
  month2: number;
  month3: number;
  month4: number;
  month5: number;
  month6: number;
  month7: number;
  month8: number;
  month9: number;
  month10: number;
  month11: number;
  month12: number;
  product: string;
}

function createData(data: IProductManage): IProductManageData {
  return {
    id: data.id,
    year: data.year,
    month1: data.months.month1 ?? 0,
    month2: data.months.month2 ?? 0,
    month3: data.months.month3 ?? 0,
    month4: data.months.month4 ?? 0,
    month5: data.months.month5 ?? 0,
    month6: data.months.month6 ?? 0,
    month7: data.months.month7 ?? 0,
    month8: data.months.month8 ?? 0,
    month9: data.months.month9 ?? 0,
    month10: data.months.month10 ?? 0,
    month11: data.months.month11 ?? 0,
    month12: data.months.month12 ?? 0,
    product: data.product.name,
    product_id: data.product.id,
    site: data.site,
  };
}

const columns: readonly Column[] = [
  { id: 'product', label: '제품명', minWidth: 150 },
  { id: 'year', label: '연도 ' },
  { id: 'month1', label: '1월' },
  { id: 'month2', label: '2월' },
  { id: 'month3', label: '3월' },
  { id: 'month4', label: '4월' },
  { id: 'month5', label: '5월' },
  { id: 'month6', label: '6월' },
  { id: 'month7', label: '7월' },
  { id: 'month8', label: '8월' },
  { id: 'month9', label: '9월' },
  { id: 'month10', label: '10월' },
  { id: 'month11', label: '11월' },
  { id: 'month12', label: '12월' },
];

type Props = {
  data?: IProductManage[];
  onSave?(id: number, data: TProductManageCreateRequest): void;
  onDelete?(id: number, data: TProductManageCreateRequest): void;
  onAddYear?(): void;
};

ProductManageGrid.defaultProps = {
  data: [],
  onSave: undefined,
  onDelete: undefined,
  onAddYear: undefined,
};

export default function ProductManageGrid({ data, onSave, onDelete, onAddYear }: Props) {
  const theme = useTheme();
  const rows = useMemo(() => data?.map((dt) => createData(dt)) ?? [], [data]);

  const handleExcelSave = () => {
    const ws = xlsx.utils.json_to_sheet(rows ?? []);
    const wb = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, `excel_download.xlsx`);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 450, minHeight: 450 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    backgroundColor: theme.palette.mode === 'light' ? '#f7fafc !important' : '#0f1018 !important',
                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <ProductManageGridRow key={row.id} row={row} onSaveRow={onSave} onDeleteRow={onDelete} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box component="div" display="flex" justifyContent="flex-end" sx={{ p: 1 }}>
        <Button onClick={onAddYear}>
          <Typography>연도 추가</Typography>
        </Button>
        <Button onClick={handleExcelSave}>
          <Typography>Excel Download</Typography>
        </Button>
      </Box>
    </Paper>
  );
}
