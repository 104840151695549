import React, { useMemo, useState } from 'react';
import ProductSearchForm from 'components/product/ProductSearchForm';
import { SystemLayout } from 'layout/system';
import { ProductManageSearchProps } from 'apis/productManage';
import ProductManageForm from 'components/product/ProductManageForm';

export default function ProductPage() {
  const [search, setSearch] = useState<ProductManageSearchProps>({});

  const handleSearch = (siteCode: string, productId: number) => {
    setSearch({
      siteCode,
      productId,
    });
  };

  const ProductSearchFormRender = useMemo(() => <ProductSearchForm onSearch={handleSearch} />, []);

  return (
    <SystemLayout title="생산관리">
      {ProductSearchFormRender}
      {search?.productId ? <ProductManageForm search={search} containerSx={{ marginTop: 1 }} /> : <div />}
    </SystemLayout>
  );
}
