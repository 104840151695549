import { ENDPOINT } from '@const';
import { ResetTvOutlined } from '@mui/icons-material';
import { TGroupCreateRequest, TGroupFindAllResponse, TGroupResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

/**
 * 그룹조회 조회 API
 * @returns 조회된 그룹 정보
 */
export const findGroupsAPI = async (): Promise<TGroupFindAllResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.group.findGroups,
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('그룹 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const createGroupAPI = async (request: TGroupCreateRequest): Promise<TGroupResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.group.create,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('그룹 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyGroupAPI = async (request: TGroupCreateRequest): Promise<TGroupResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.group.modify,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('그룹 저장 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteGroupAPI = async (groupCode: string): Promise<TGroupResponse> => {
  const result = await axios
    .request({
      url: ENDPOINT.group.delete.url.replace('{groupCode}', groupCode),
      method: ENDPOINT.group.delete.method,
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('그룹 삭제 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};
