import { findVRTourAPI } from 'apis/vrtour';
import React from 'react';
import { useQuery } from 'react-query';
import VRTourCard, { VRTourCardProps } from './VRTourCard';

type Props = Omit<VRTourCardProps, 'data'> & {
  data?: number;
};

VRTourIDCard.defaultProps = {
  data: undefined,
};

export default function VRTourIDCard({ data, ...others }: Props) {
  const fetch = () => {
    if (data) {
      return findVRTourAPI(data);
    }

    return undefined;
  };

  const { data: vrtour } = useQuery(['vrtour', data], fetch);

  return <VRTourCard data={vrtour?.data} {...others} />;
}
