import React, { useEffect, useMemo } from 'react';
import { SystemLayout } from 'layout/system';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { createRoleAPI, findRoles, modifyRoleAPI, deleteRoleAPI } from 'apis/role';
import { getCode } from 'common/lib';
import RoleAccordion from 'components/role/RoleAccordion';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { codeState, roleState } from 'states';
import { TRoleCreateRequest, TRoleCreateResponse, TRoleDeleteResponse } from '@types';
import {} from 'apis/user';
import useLoading from 'common/hooks/useLoading';

function RolePage() {
  const {
    data: roles,
    refetch,
    isFetching,
  } = useQuery('roles', () => findRoles({ page: 0, size: 100 }), {
    refetchOnWindowFocus: false,
  });
  const codes = useRecoilValue(codeState);
  const setRoles = useSetRecoilState(roleState);
  const authCodes = useMemo(() => getCode(codes, 'AUTH').map((code) => code.code), [codes]);

  const onSaveClick = (request: TRoleCreateRequest) => {
    if (request.id) {
      modifyMutation.mutate(request);
    } else {
      createMutation.mutate(request);
    }
  };

  const onDeleteClick = (id: number) => {
    deleteMutation.mutate({ id });
  };

  const onSaveSuccess = (result: TRoleCreateResponse) => {
    if (result.success) {
      toast(`${result.data?.roleCode} 저장 성공`, { type: 'success' });
      refetch();
    }
  };

  const onDeleteSuccess = (result: TRoleDeleteResponse) => {
    if (result.success) {
      toast('삭제 성공', { type: 'success' });
      refetch();
    }
  };

  const createMutation = useMutation(createRoleAPI, {
    onSuccess: onSaveSuccess,
  });

  const modifyMutation = useMutation(modifyRoleAPI, {
    onSuccess: onSaveSuccess,
  });

  const deleteMutation = useMutation(deleteRoleAPI, {
    onSuccess: onDeleteSuccess,
  });

  const changeLoading = useLoading({ trigger: false });

  useEffect(() => {
    changeLoading({ loading: createMutation.isLoading, message: '권한 저장중...' });
  }, [createMutation.isLoading]);

  useEffect(() => {
    changeLoading({ loading: modifyMutation.isLoading, message: '권한 저장중...' });
  }, [modifyMutation.isLoading]);

  useEffect(() => {
    changeLoading({ loading: deleteMutation.isLoading, message: '권한 삭제중...' });
  }, [deleteMutation.isLoading]);

  useEffect(() => {
    changeLoading({ loading: isFetching, message: '권한 조회중...' });
  }, [isFetching]);

  useEffect(() => {
    if (roles?.data && roles.data.content) {
      setRoles(roles.data.content);
    }
  }, [roles]);

  return (
    <SystemLayout title="권한관리">
      <Box component="div" display="flex" flexDirection="column">
        {roles?.data?.content?.map((role) => (
          <RoleAccordion
            key={role.id}
            role={role}
            auths={authCodes}
            onSaveClick={onSaveClick}
            onDeleteClick={onDeleteClick}
          />
        ))}
        <RoleAccordion
          title="새 권한 생성"
          role={{ roleCode: '', id: undefined }}
          auths={authCodes}
          onSaveClick={onSaveClick}
        />
      </Box>
    </SystemLayout>
  );
}

export default RolePage;
