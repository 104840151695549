import React, { useMemo } from 'react';
import useTagChartData10, { AggUnitType } from 'common/hooks/useTagChartData10';
import moment from 'moment';
import Data10ChartCard, { Data10ChartCardProps } from './Data10ChartCard';

export type DefaultTagData10CardProps = Data10ChartCardProps & {
  showPrev?: boolean;
};

DefaultTagData10Card.defaultProps = {
  // aggType: 'mean',
  // lineColors: undefined,
  // bgColors: undefined,
  // isIncrease: false,
  // chartType: 'line',
  // aggUnit: undefined,
  // aggVal: undefined,
  // fill: false,
  showPrev: false,
};

export default function DefaultTagData10Card({ showPrev, ...props }: DefaultTagData10CardProps) {
  const { lineColors, bgColors, tags, dateProps, aggType, isIncrease, chartType, aggUnit, aggVal, fill } = props;

  const dttFormatter = (dtt: string): string => {
    const prevMoment = moment(dtt);

    switch (dateProps.type) {
      case 'currentMonth':
      case 'month':
        return prevMoment.set('hours', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss');
      default:
        break;
    }

    return dtt;
  };

  const {
    data: dataset,
    annotation,
    dataList,
    isFetching,
  } = useTagChartData10({
    tags,
    type: chartType,
    colors: lineColors,
    dataType: aggType,
    dateProps,
    isIncrease,
    fill,
    bgColors,
    aggUnit,
    aggVal,
    xFormatter: dttFormatter,
  });

  const prevDttFormatter = (prevDtt: string): string => {
    const prevMoment = moment(prevDtt);

    switch (dateProps.type) {
      case 'day':
      case 'currentDay':
        return prevMoment.add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
      case 'currentMonth':
      case 'month':
        return prevMoment
          .add(1, 'month')
          .set('hours', 0)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss');
      case 'currentYear':
      case 'year':
        return prevMoment.add(1, 'year').format('YYYY-MM-DD HH:mm:ss');
      default:
        break;
    }

    return prevMoment.add(1, 'day').format('YYYY-MM-DD HH:mm:ss');
  };

  const dateAggUnit = useMemo((): AggUnitType => {
    switch (dateProps.type) {
      case 'currentDay':
      case 'currentDayASC':
      case 'currentDayDESC':
      case 'day':
        return 'minute';
      case 'currentMonth':
      case 'currentMonthASC':
      case 'currentMonthDESC':
      case 'month':
      case 'dayRange':
        return 'day';
      case 'currentYear':
      case 'currentYearASC':
      case 'currentYearDESC':
      case 'year':
        return 'month';
      default:
        break;
    }

    return 'minute';
  }, [dateProps]);

  const { data: prevDataset } = useTagChartData10({
    tags: showPrev ? tags : [],
    type: chartType,
    colors: lineColors,
    bgColors,
    dataType: aggType,
    dateProps,
    isIncrease,
    fill,
    aggUnit,
    aggVal,
    isPrev: showPrev,
    stackId: chartType === 'stackedBar' ? 'x2' : undefined,
    xFormatter: prevDttFormatter,
  });

  return (
    <Data10ChartCard
      dataset={{ datasets: [...prevDataset.datasets, ...dataset.datasets], labels: [...(dataset.labels ?? [])] }}
      annotation={annotation}
      gridData={dataList}
      isFetching={isFetching}
      {...props}
      aggUnit={dateAggUnit}
    />
  );
}
