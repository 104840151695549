import React, { useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { IGroupType } from '@types';
import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';

type Props = BaseSelectBoxProps & {
  required?: boolean;
  name?: string;
  defaultValue?: string;
  label?: string;
  value?: string;
  data?: IGroupType[];
  parentId?: string;
  useYN?: boolean;
  onChange?(e: SelectChangeEvent): void;
};

GroupTypeSelectBox.defaultProps = {
  required: undefined,
  name: undefined,
  label: undefined,
  defaultValue: undefined,
  value: undefined,
  data: [],
  parentId: undefined,
  useYN: false,
  onChange: undefined,
};

export default function GroupTypeSelectBox({
  data,
  required,
  parentId,
  label,
  defaultValue,
  value,
  name,
  useYN,
  onChange,
  ...others
}: Props) {
  const renderSelectItem: SelectItemProps[] = useMemo(
    () =>
      data
        ?.filter((groupType) => (parentId ? groupType.parent === parentId : groupType.parent === null))
        ?.filter((groupType) => (useYN ? groupType.useYN : true))
        .map((groupType) => ({ id: groupType.code, name: groupType.name })) ?? [],
    [parentId, data, useYN],
  );

  const renderSelectBox = useMemo(
    () =>
      renderSelectItem && renderSelectItem.length > 0 ? (
        <BaseSelectBox
          required={required}
          label={label ?? '그룹유형'}
          items={renderSelectItem}
          defaultValue={defaultValue}
          value={value}
          name={name}
          onChange={onChange}
          {...others}
        />
      ) : (
        <div />
      ),
    [name, defaultValue, label, value, name, required, renderSelectItem],
  );

  return renderSelectBox;
}
