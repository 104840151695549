import { Box, Divider } from '@mui/material';
import { ICostItem, TCostItemCreateRequest, TCostItemResponse } from '@types';
import { deleteCostItemAPI, saveCostItemAPI } from 'apis/cost';
import BaseAccordion from 'components/common/BaseAccordion';
import CostItemForm from 'components/costitem/CostItemForm';
import CostItemMonthAccordion from 'components/costitemmonth/CostItemMonthAccordion';
import CostItemMonthLineBar from 'components/costitemmonth/CostItemMonthLineBar';
import React, { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

type Props = {
  cost: string;
  data?: ICostItem;
};

CostItemAccordion.defaultProps = {
  data: undefined,
};

export default function CostItemAccordion({ cost, data }: Props) {
  const queryClient = useQueryClient();

  const handleSave = (request: TCostItemCreateRequest) => {
    saveMutation.mutate(request);
  };

  const handleDelete = (id: number) => {
    deleteMutation.mutate(id);
  };

  const onSaveSuccess = (result: TCostItemResponse) => {
    if (result.success) {
      toast(`${result.data?.name} 저장 성공`, { type: 'success' });
      queryClient.invalidateQueries('cost');
    }
  };
  const onDeleteSuccess = (result: TCostItemResponse) => {
    if (result.success) {
      toast(`${result.data?.name} 삭제 성공`, { type: 'success' });
      queryClient.invalidateQueries('cost');
    }
  };

  const saveMutation = useMutation(saveCostItemAPI, {
    onSuccess: onSaveSuccess,
  });

  const deleteMutation = useMutation(deleteCostItemAPI, {
    onSuccess: onDeleteSuccess,
  });

  const renderMonths = useMemo(
    () =>
      data ? (
        <Box component="div" sx={{ p: 1 }}>
          <Divider sx={{ marginY: 1 }} />
          <CostItemMonthLineBar data={data.months} containerSx={{ paddingY: 1 }} />
          {data.months.map((month) => (
            <CostItemMonthAccordion item={data.id} data={month} />
          ))}
          <CostItemMonthAccordion item={data.id} />
        </Box>
      ) : (
        <div />
      ),
    [data],
  );

  return (
    <BaseAccordion variant="outlined" title={data ? `${data.name}` : '새 요금 아이템 생성'}>
      <CostItemForm cost={cost} data={data} onSave={handleSave} onDelete={handleDelete} />

      {renderMonths}
    </BaseAccordion>
  );
}
