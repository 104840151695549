import { KeyboardEvent } from 'react';

type Props<T> = {
  onKeyDownCallback?(keycode: string): void;
  onPressEnter?(): void;
};

useKeyboardEvent.defaultProps = {
  onKeyDownCallback: undefined,
};

export default function useKeyboardEvent<T>({ onKeyDownCallback, onPressEnter }: Props<T>) {
  const createKeyDownEvent = () => {
    const onKeydownFunc = (e: KeyboardEvent<T>) => {
      const { key } = e;

      if (typeof onKeyDownCallback === 'function') {
        onKeyDownCallback(key);
      }
    };

    return onKeydownFunc;
  };

  const createEnterKeyEvent = () => {
    return (e: KeyboardEvent<T>) => {
      const { key } = e;

      if (typeof onPressEnter === 'function' && key === 'Enter') {
        onPressEnter();
      }
    };
  };

  return {
    onKeyDown: createKeyDownEvent(),
    onEnterDown: createEnterKeyEvent(),
  };
}
