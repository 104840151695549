import { INodeSystem, TNodeSystemCreateRequest, TNodeSystemResponse } from '@types';
import { createNodeSystemAPI, deleteNodeSystemAPI, modifyNodeSystemAPI } from 'apis/nodesystem';
import NodeSystemSaveModal from 'components/nodesystem/NodeSystemSaveModal';
import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

type Props = {
  reload?(): void;
  onDeleted?(): void;
};

export default function useNodeSystemModal({ reload, onDeleted }: Props) {
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setState(null);
  }, []);

  const openModal = (data: INodeSystem | null) => {
    setState(data);
    setOpen(true);
  };

  const onReload = () => {
    if (typeof reload === 'function') {
      reload();
    }
  };

  const onSaveSuccess = (data: TNodeSystemResponse) => {
    if (data && data.success) {
      toast('계통유형 저장 성공', { type: 'success' });
      handleClose();
    }

    onReload();
  };

  const onDeleteSuccess = (data: TNodeSystemResponse) => {
    if (data && data.success) {
      toast('계통유형 삭제 성공', { type: 'success' });
      handleClose();

      if (typeof onDeleted === 'function') {
        onDeleted();
      }
    }
    onReload();
  };

  const [state, setState] = useState<INodeSystem | null>();
  const [open, setOpen] = useState<boolean>(false);

  const createMutation = useMutation(createNodeSystemAPI, {
    onSuccess: onSaveSuccess,
  });
  const modifyMutation = useMutation(modifyNodeSystemAPI, {
    onSuccess: onSaveSuccess,
  });
  const deleteMutation = useMutation(deleteNodeSystemAPI, {
    onSuccess: onDeleteSuccess,
  });

  const handleSave = useCallback(
    (data: TNodeSystemCreateRequest) => {
      if (data.id && data.id > 0) {
        modifyMutation.mutate(data);
      } else {
        createMutation.mutate(data);
      }
    },
    [modifyMutation, createMutation],
  );

  const handleDelete = useCallback(
    (data: TNodeSystemCreateRequest) => {
      if (data.id && data.id > 0) {
        deleteMutation.mutate(data.id);
      }
    },
    [deleteMutation],
  );

  const modal = useMemo(
    () => (
      <NodeSystemSaveModal
        open={open}
        onClose={handleClose}
        data={state}
        onSaveNodeSystem={handleSave}
        onDeleteNodeSystem={handleDelete}
      />
    ),
    [open, state, handleClose, handleSave, handleDelete],
  );

  return {
    handleOpen,
    handleClose,
    openModal,
    modal,
    createMutation,
    deleteMutation,
    modifyMutation,
  };
}
