import { ITask, TTaskCreateRequest, TTaskResponse } from '@types';
import { createTaskAPI, deleteTaskAPI, modifyTaskAPI } from 'apis/task';
import TaskSaveModal from 'components/task/TaskSaveModal';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import useLoading from './useLoading';

type Props = {
  onReload?(): void;
};

export default function useTaskModal<T>({ onReload }: Props) {
  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setTask(undefined);
  };

  const handleOnSave = (request: TTaskCreateRequest) => {
    if (request.id && request.id > 0) {
      modifyMutation.mutate(request);
    } else {
      createMutation.mutate(request);
    }
  };

  const handleOnDelete = (id: number) => {
    if (id > 0) {
      deleteMutation.mutate(id);
    }
  };

  const taskCreate = () => {
    setTask(undefined);
    handleModalOpen();
  };

  const taskModify = (task: ITask) => {
    setTask(task);
    handleModalOpen();
  };

  const onSaveSuccess = (data: TTaskResponse) => {
    if (data.success) {
      toast('작업 저장 성공', { type: 'success' });
      reload();
      handleModalClose();
    }
  };

  const onDeleteSucess = (data: TTaskResponse) => {
    if (data.success) {
      toast('작업 삭제 성공', { type: 'success' });
      reload();
      handleModalClose();
    }
  };

  const reload = () => {
    if (typeof onReload === 'function') {
      onReload();
    }
  };

  const [task, setTask] = useState<ITask | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const loading = useLoading({ trigger: false });
  const createMutation = useMutation(createTaskAPI, { onSuccess: onSaveSuccess });
  const modifyMutation = useMutation(modifyTaskAPI, { onSuccess: onSaveSuccess });
  const deleteMutation = useMutation(deleteTaskAPI, { onSuccess: onDeleteSucess });

  const modal = useMemo(
    () => (
      <TaskSaveModal
        open={open}
        onReload={reload}
        onSaveTask={handleOnSave}
        onDeleteTask={handleOnDelete}
        onClose={handleModalClose}
        data={task}
      />
    ),
    [open, task, reload],
  );

  useEffect(() => {
    loading({ loading: createMutation.isLoading, message: '작업 저장중...' });
  }, [createMutation.isLoading]);

  useEffect(() => {
    loading({ loading: modifyMutation.isLoading, message: '작업 저장중...' });
  }, [modifyMutation.isLoading]);

  useEffect(() => {
    loading({ loading: deleteMutation.isLoading, message: '작업 삭제중...' });
  }, [deleteMutation.isLoading]);

  return {
    task,
    setTask,
    modal,
    taskCreate,
    taskModify,
    createMutation,
    modifyMutation,
    deleteMutation,
  };
}
