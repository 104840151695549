import { TablePagination } from '@mui/material';
import React from 'react';

type Props = {
  totalCount?: number;
  page?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  onPageChange?(page: number): void;
  onRowsPerPageChange?(rowsPerPage: number): void;
};

BaseTablePagination.defaultProps = {
  totalCount: 0,
  page: 0,
  rowsPerPage: 10,
  onPageChange: undefined,
  onRowsPerPageChange: undefined,
  rowsPerPageOptions: [10, 25, 100],
};

export default function BaseTablePagination({
  totalCount,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  onPageChange,
  onRowsPerPageChange,
}: Props) {
  const handleChangePage = (e: unknown, changePage: number) => {
    if (typeof onPageChange === 'function') {
      onPageChange(changePage);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof onRowsPerPageChange === 'function') {
      onRowsPerPageChange(+event.target.value);
    }
  };

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      showFirstButton
      showLastButton
      component="div"
      count={totalCount ?? 0}
      rowsPerPage={rowsPerPage ?? 10}
      page={page ?? 0}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
