/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { Box, SvgIconProps, styled, IconButton, useTheme, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { TreeItem, treeItemClasses, TreeItemProps, TreeView } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { isNull } from 'lodash';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelText: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  [`& .${treeItemClasses.content}`]: {
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(0),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(0),
    },
  },
}));

function StyledTreeItem(props: StyledTreeItemProps) {
  const { bgColor, color, labelIcon: LabelIcon, ...other } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box component="div" sx={{ display: 'flex', alignItems: 'center', ml: -0.5 }}>
          {LabelIcon && <LabelIcon />}
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

export interface RenderSideTree {
  id: string;
  name: string;
  icon?: React.ElementType<SvgIconProps>;
  url?: string;
  auth?: string;
  visible?: boolean;
  children?: readonly RenderSideTree[];
}

type Props = {
  data?: RenderSideTree[];
};

function SideTreeIconMenu({ data }: Props) {
  const navigate = useNavigate();
  const [anchorEL, setAnchorEL] = useState<HTMLLIElement | null>(null);
  const [selectedNode, setSelectedNode] = useState<RenderSideTree[] | null>();
  const theme = useTheme();

  const moveTo = (url?: string) => {
    if (url) {
      navigate(url);
      setAnchorEL(null);
    }
  };

  const renderTree = (nodes: RenderSideTree) =>
    nodes.visible ? (
      <StyledTreeItem
        sx={{ width: 80 }}
        key={nodes.id}
        nodeId={nodes.id}
        labelText={nodes.name}
        labelIcon={nodes.icon}
        onClick={(e) => {
          if (nodes.children && nodes.children.length > 0) {
            setAnchorEL(e.currentTarget);
            setSelectedNode(nodes.children.filter((node) => node.visible));
          } else {
            moveTo(nodes.url);
          }
        }}
        color="#eee"
        bgColor={theme.palette.mode === 'light' ? '#13141f' : '#252756'}
      >
        {/* {Array.isArray(nodes.children)
          ? nodes.children.filter((node) => node.visible).map((node) => renderTree(node))
          : null} */}
      </StyledTreeItem>
    ) : null;

  return (
    <>
      <TreeView sx={{ width: '100%', overflowY: 'auto' }}>{data?.map((nodes) => renderTree(nodes))}</TreeView>
      <Menu
        anchorEl={anchorEL}
        open={Boolean(anchorEL)}
        onClose={() => setAnchorEL(null)}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {selectedNode?.map((node) => (
          <MenuItem key={node.id} onClick={() => moveTo(node.url)}>
            {node.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default SideTreeIconMenu;
