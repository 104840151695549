import { Box, Button, TextField, Typography } from '@mui/material';
import { IGroup, INodeGroupData } from '@types';
import useBaseModalControl from 'common/hooks/useBaseModalControl';
import GroupFieldDataRenderBox from 'components/group/GroupFieldDataRenderBox';
import GroupSelectModal from 'components/group/GroupSelectModal';
import { NodeSearchFormType } from 'components/reactflow/BaseNodeType';
import _ from 'lodash';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatGroupState, groupState } from 'states';

type Props = {
  readonly?: boolean;
  defaultData?: INodeGroupData;
  onChange?(data: INodeGroupData): void;
  nodeSearchForm?: NodeSearchFormType;
};

NodeGroupForm.defaultProps = {
  readonly: false,
  defaultData: undefined,
  onChange: undefined,
  nodeSearchForm: undefined,
};

export default function NodeGroupForm({ defaultData, readonly, onChange, nodeSearchForm }: Props) {
  // Modal 컨트롤
  const { open, onClose, onOpen } = useBaseModalControl();
  // 그룹 트리 조회
  const groups = useRecoilValue(groupState);
  // 그룹 조회
  const flatGroups = useRecoilValue(flatGroupState);
  // 기존 데이터를 통해 그룹 데이터 조회
  const defaultGroup = useMemo(
    () => _.find(flatGroups, (group) => group.groupCode === defaultData?.groupCode),
    [defaultData, flatGroups],
  );

  // 현재 선택된 그룹 데이터
  const [selectedGroup, setSelectedGroup] = useState<IGroup | null>(null);

  // 그룹 데이터 상태
  const [groupData, setGroupData] = useState<INodeGroupData>({
    tags: [],
    gf: {},
    groupCode: undefined,
  });

  const handleCheckChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setGroupData((prev) => {
      const newState: INodeGroupData = {
        ...prev,
        gf: { ...prev.gf, [name]: checked },
      };

      handleCommitChange(newState);

      return newState;
    });
  }, []);

  // 그룹 필드 데이터 가시화
  const renderGroupFieldDatas = useMemo(
    () =>
      _.sortBy(selectedGroup?.groupFieldDatas, (gfd) => gfd.field.orderNum).map((gfd) => {
        let defaultChecked = false;

        if (groupData && groupData.gf) {
          defaultChecked = groupData.gf[gfd.field.code] || false;
        }

        return (
          <GroupFieldDataRenderBox
            showCheckBox={!readonly}
            defaultChecked={defaultChecked}
            onCheckboxChange={handleCheckChange}
            key={gfd.field.id}
            data={gfd}
            containerSx={{ mt: 1 }}
            dateSearch={nodeSearchForm?.dateSearch}
            aggFunc={nodeSearchForm?.aggFunc}
          />
        );
      }),
    [groupData, selectedGroup, handleCheckChange, nodeSearchForm],
  );

  // Group 변경 이벤트
  const handleChangeGroup = (group: IGroup[]) => {
    if (group.length > 0) {
      setSelectedGroup(group[0]);
      setGroupData({
        tags: [],
        groupCode: group[0].groupCode,
      });

      handleCommitChange({
        tags: [],
        groupCode: group[0].groupCode,
      });
    }

    onClose();
  };

  // 그룹 데이터 변경사항을 부모컴포넌트에 전달
  const handleCommitChange = (data: INodeGroupData) => {
    if (typeof onChange === 'function') {
      onChange(data);
    }
  };

  // 기본 그룹 설정
  useEffect(() => {
    if (defaultGroup) {
      setSelectedGroup(defaultGroup);
    }
  }, [defaultGroup]);

  // 기본 데이터 설정
  useEffect(() => {
    if (defaultData) {
      setGroupData(defaultData);
    }
  }, [defaultData]);

  return (
    <Box component="div">
      {!readonly && (
        <Button fullWidth variant="contained" sx={{ marginY: 1 }} onClick={onOpen}>
          <Typography>그룹 선택</Typography>
        </Button>
      )}

      <TextField
        fullWidth
        variant="standard"
        label="그룹명"
        value={selectedGroup?.groupName}
        InputLabelProps={{ shrink: true }}
        InputProps={{ readOnly: true }}
        sx={{ mt: 1 }}
      />
      {renderGroupFieldDatas}

      <GroupSelectModal singleSelect data={groups} open={open} onConfirm={handleChangeGroup} onClose={onClose} />
    </Box>
  );
}
