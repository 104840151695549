import { Marker } from '@react-google-maps/api';
import { ILocation } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Map from './Map';

type Props = Omit<IBaseModalProps, 'onConfirm'> & {
  onConfirm?(location: ILocation): void;
};

LocationSelectModal.defaultProps = {
  onConfirm: undefined,
};

export default function LocationSelectModal({ onConfirm, ...others }: Props) {
  const [center, setCenter] = useState<ILocation>({ lat: 0, lng: 0 });
  const [location, setLocation] = useState<ILocation | undefined>(undefined);

  // 선택된 위치 State 저장
  const handleClickedLocation = useCallback((location: ILocation) => {
    setLocation(location);
  }, []);

  // 저장버튼 클릭 이벤트
  const handleConfirm = () => {
    if (typeof onConfirm === 'function' && location) {
      onConfirm(location);
    }
  };

  // 선택된 위치의 마커 표시
  const renderMarker = useMemo(() => {
    if (location && location?.lat && location.lng) {
      return <Marker position={{ lat: location.lat, lng: location.lng }} />;
    }

    return undefined;
  }, [location]);

  // 현재 위치조회 후 세팅
  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition((data) => {
      const { latitude, longitude } = data.coords;
      setCenter({ lat: latitude, lng: longitude });
    });
  }, []);

  return (
    <BaseModal title="위치를 선택해주세요." {...others} disableConfirm={!location} onConfirm={handleConfirm}>
      <Map
        containerStyle={{ width: 700, height: 600 }}
        defaultCenter={center}
        onClickedLocation={handleClickedLocation}
        showSearchBox
      >
        {renderMarker}
      </Map>
    </BaseModal>
  );
}
