import { Box } from '@mui/material';
import { PerspectiveCamera } from '@react-three/drei';
import { IThreeModel } from '@types';
import BaseCanvas, { BaseCanvasProps } from 'components/three/BaseCanvas';
import React from 'react';
import ThreeModel from './ThreeModel';

type Props = BaseCanvasProps & {
  data: IThreeModel;
  showWireframe?: boolean;
};

ThreeBox.defaultProps = {
  showWireframe: false,
};

export default function ThreeBox({ data, ...others }: Props) {
  return (
    <Box component="div" sx={{ width: '100%', height: '100%' }}>
      <BaseCanvas showGrid {...others}>
        <ThreeModel data={data} />
        <PerspectiveCamera makeDefault position={[15, 30, 30]} />
      </BaseCanvas>
    </Box>
  );
}
