import { Box } from '@mui/material';
import React, { forwardRef, ReactElement } from 'react';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  children?: ReactElement | ReactElement[];
};

const GridItem = forwardRef<HTMLDivElement, Props>(
  ({ style, className, onMouseDown, onMouseUp, onTouchEnd, children, ...props }: Props, ref) => {
    return (
      <Box
        {...props}
        ref={ref}
        component="div"
        style={{ ...style, paddingBottom: 30 }}
        className={className}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
      >
        {children}
      </Box>
    );
  },
);

GridItem.defaultProps = {
  children: undefined,
};

export default GridItem;
