import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { IMatterport, TMatterportCreateRequest, TMatterportDeleteResponse, TMatterportResponse } from '@types';
import { deleteMatterportAPI, saveMatterportAPI } from 'apis/matterport';
import BaseCheckbox from 'components/common/BaseCheckbox';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import FileUploadBox from 'components/file/FileUploadBox';
import SiteSelectBox from 'components/site/SiteSelectBox';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { flatSiteState } from 'states';

type Props = IBaseModalProps & {
  onSaveSuccess?(data: TMatterportResponse): void;
  onDeleteSuccess?(data: TMatterportDeleteResponse): void;
  data?: IMatterport;
};

MatterportSaveModal.defaultProps = {
  onSaveSuccess: undefined,
  onDeleteSuccess: undefined,
  data: undefined,
};

export default function MatterportSaveModal({ onSaveSuccess, onDeleteSuccess, data, ...others }: Props) {
  const queryClient = useQueryClient();
  const [state, setState] = useState<TMatterportCreateRequest>({});
  const sites = useRecoilValue(flatSiteState);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setState((prev) => ({ ...prev, [name]: checked }));
  };

  const handleChangeSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeUpload = (name?: string, files?: File[]) => {
    if (name) {
      const file = files?.at(-1);
      setState((prev) => ({ ...prev, [name]: file }));
    }
  };

  const validate = () => {
    if (state.name === undefined) {
      toast('이름을 입력해주세요.', { type: 'error' });
      return false;
    }
    if (state.site === undefined) {
      toast('사이트를 선택해주세요.', { type: 'error' });
      return false;
    }
    if (state.sdkKey === undefined) {
      toast('SDK키를 입력해주세요.', { type: 'error' });
      return false;
    }
    if (state.spaceId === undefined) {
      toast('공간ID를 입력해주세요.', { type: 'error' });
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (!validate()) return;
    saveMutation.mutate(state);
  };

  const handleDelete = () => {
    if (state.idx === undefined) return;
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('정말 삭제하시겠습니까?')) return;

    deleteMutation.mutate(state.idx);
  };

  const saveMutation = useMutation(saveMatterportAPI, {
    onSuccess(data) {
      if (data.success) {
        queryClient.invalidateQueries(['matterports']);
        toast('메타포트 저장성공');

        if (typeof onSaveSuccess === 'function') {
          onSaveSuccess(data);
        }
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const deleteMutation = useMutation(deleteMatterportAPI, {
    onSuccess(data) {
      if (data) {
        queryClient.invalidateQueries(['matterports']);
        toast('메타포트 삭제성공');

        if (typeof onDeleteSuccess === 'function') {
          onDeleteSuccess(data);
        }
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  useEffect(() => {
    setState(data ? { ...data, thumbnailImage: undefined } : {});
  }, [data]);

  return (
    <BaseModal {...others} title="메타포트 저장" onConfirm={handleSave} onDelete={state.idx ? handleDelete : undefined}>
      <Box component="div" sx={{ width: 500, height: 350 }}>
        <TextField
          name="name"
          label="이름"
          type="text"
          fullWidth
          required
          variant="standard"
          value={state.name}
          onChange={handleChangeInput}
        />
        <SiteSelectBox
          name="site"
          data={sites}
          fullWidth
          required
          sx={{ mt: 1 }}
          onChange={handleChangeSelect}
          defaultValue={data?.site ?? ''}
          value={state.site ?? ''}
        />
        <FileUploadBox
          name="thumbnailImage"
          fieldProps={{ variant: 'standard', sx: { mt: 1 } }}
          singleSelect
          downloadFiles={data?.thumbnailImage ? [data.thumbnailImage] : []}
          onChange={handleChangeUpload}
        />
        <TextField
          name="sdkKey"
          label="SDK키"
          type="text"
          fullWidth
          required
          variant="standard"
          sx={{ mt: 1 }}
          value={state.sdkKey}
          onChange={handleChangeInput}
        />
        <TextField
          name="spaceId"
          label="공간ID"
          type="text"
          fullWidth
          required
          variant="standard"
          sx={{ mt: 1 }}
          value={state.spaceId}
          onChange={handleChangeInput}
        />
        <BaseCheckbox
          name="defaultTagHide"
          label="기존 메타포트 태그 숨기기"
          checked={state.defaultTagHide ?? false}
          defaultChecked={data?.defaultTagHide ?? false}
          onChange={handleChangeCheck}
        />
      </Box>
    </BaseModal>
  );
}
