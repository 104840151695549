import React, { useEffect, useMemo, useRef, useState } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotation, { AnnotationOptions } from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment';
import 'chartjs-adapter-date-fns';
import { ko } from 'date-fns/locale';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ChartData,
  CategoryScale,
  LineController,
  Filler,
  ScatterController,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  ScaleOptionsByType,
} from 'chart.js';
import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material';
import moment from 'moment';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { Refresh } from '@mui/icons-material';
import { DateSearchProps } from 'components/common/DateSearchBox';
import { AggUnitType } from 'common/hooks/useTagChartData10';

ChartJS.register(
  LineController,
  ScatterController,
  BarController,

  CategoryScale,
  LinearScale,
  TimeScale,

  PointElement,
  LineElement,
  BarElement,
  Filler,

  Tooltip,
  Legend,
  ChartDataLabels,
  ChartAnnotation,
  zoomPlugin,
);

type Props = {
  label?: string;
  showGrid?: boolean;
  showY?: boolean;
  showX?: boolean;
  showLegend?: boolean;
  showDataLabel?: boolean;
  width?: number | string;
  height?: number | string;
  dataset?: ChartData<'line' | 'scatter' | 'bar', any>;
  labelSx?: SxProps<Theme>;
  annotation?: AnnotationOptions[];
  onZoomComplete?(date: DateSearchProps): void;
  onZoomStateChange?(isZoom: boolean): void;
  stacked?: boolean;
  padding?: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
  scales?: ScaleOptionsByType<any>;
  aggUnit?: AggUnitType;
};

DataLineChart.defaultProps = {
  label: undefined,
  showGrid: false,
  showY: false,
  showX: false,
  showLegend: false,
  showDataLabel: false,
  width: undefined,
  height: undefined,
  onZoomComplete: undefined,
  onZoomStateChange: undefined,
  stacked: false,
  labelSx: {},
  padding: {
    top: 40,
    bottom: 20,
    left: 10,
    right: 10,
  },
  dataset: {},
  annotation: [],
  aggUnit: undefined,
  scales: undefined,
};

function DataLineChart({
  width,
  height,
  label,
  labelSx,
  padding,
  showGrid,
  showY,
  showX,
  showLegend,
  showDataLabel,
  dataset,
  annotation,
  onZoomComplete,
  onZoomStateChange,
  stacked,
  aggUnit,
  scales,
}: Props) {
  const ref = useRef<ChartJSOrUndefined>();

  const [isZoom, setIsZoom] = useState<boolean>();

  const handleZoomComplete = (chart: ChartJS) => {
    // setIsZoom(true);

    if (typeof onZoomComplete === 'function') {
      const { min, max } = chart.scales.x;
      const start = moment(min);
      const end = moment(max);

      onZoomComplete({
        start,
        end,
      });
    }
  };

  const onResetZoom = () => {
    if (ref.current) {
      ref.current.resetZoom();
      // setIsZoom(false);
    }
  };

  useEffect(() => {
    if (typeof onZoomStateChange === 'function') {
      onZoomStateChange(isZoom || false);
    }
  }, [isZoom]);

  return (
    <Box component="div" sx={{ width, height }}>
      {label && (
        <Typography sx={{ ...labelSx }} variant="body2" color="#666">
          {label}
        </Typography>
      )}
      <Box component="div" display="flex" justifyContent="flex-end" sx={{ mb: -5 }}>
        <Box component="div" display="flex">
          <IconButton onClick={onResetZoom}>
            <Refresh />
          </IconButton>
        </Box>
      </Box>

      <Chart
        ref={ref}
        type="line"
        width={width}
        height={height}
        options={{
          locale: 'ko-KR',
          interaction: {
            mode: 'x',
            axis: 'x',
            intersect: false,
          },
          elements: {
            line: {
              tension: 0.4,
            },
            point: {
              radius: 2,
            },
          },
          scales: {
            x: {
              type: 'time',
              adapters: {
                date: {
                  locale: ko,
                },
              },
              time: {
                minUnit: 'minute',
                round: !['second', 'minute', 'hour'].includes(aggUnit || 'hour') && aggUnit,
                unit: aggUnit,
                displayFormats: {
                  minute: 'HH:mm',
                  hour: 'HH:mm',
                  day: 'dd일',
                  month: 'MM월',
                  year: 'YYYY년 MM월',
                },
              },
              grid: {
                display: showGrid,
              },
              display: showX,
              stacked,
            },
            y: {
              position: 'left',
              grid: {
                display: showGrid,
              },
              ticks: {
                stepSize: 10,
              },
              display: showY,
              stacked,
            },
            ...scales,
          },
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding,
          },
          plugins: {
            tooltip: {
              enabled: true,
              mode: 'x',
              callbacks: {
                title: (tooltipItem) => {
                  const raw = tooltipItem.at(-1)?.raw as any;

                  if (raw.isPrev) {
                    return raw.originX ?? '';
                  }

                  return (raw.x as string) ?? '';
                },
              },
            },
            legend: {
              position: 'bottom',
              display: showLegend,
              fullSize: true,
            },
            datalabels: {
              display: showDataLabel,
              anchor: 'end',
              align: 'top',
              clamp: true,
            },
            annotation: {
              annotations: annotation,
            },
            zoom: {
              zoom: {
                wheel: {
                  enabled: false,
                },
                drag: {
                  enabled: true,
                  backgroundColor: 'rgba(255, 0, 0, 0.2)',
                },
                mode: 'x',
                onZoomComplete: ({ chart }) => handleZoomComplete(chart),
              },
            },
          },
        }}
        data={dataset ?? { labels: [], datasets: [] }}
      />
    </Box>
  );
}

export default DataLineChart;
