import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Box, TextField, Typography, Divider, IconButton } from '@mui/material';
import { ILoginRequest } from '@types';
import { ArrowBack, Send } from '@mui/icons-material';
import { animated, useChain, useSpring, useSpringRef } from 'react-spring';
import useKeyboardEvent from 'common/hooks/useKeyboardEvent';

type Props = {
  onLogin?(data: ILoginRequest): Promise<boolean>;
  logoImage?: string;
  subLogoImage?: string;
};

LoginBox.defaultProps = {
  onLogin: undefined,
  logoImage: undefined,
  subLogoImage: undefined,
};

function LoginBox({ onLogin, logoImage, subLogoImage }: Props) {
  const idRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLDivElement | null>(null);

  const [step, setStep] = useState<number>(1);
  const [state, setState] = useState<ILoginRequest>({
    id: '',
    password: '',
  });

  const idFormAnimRef = useSpringRef();
  const idFormAnim = useSpring({
    opacity: step === 1 ? 1 : 0,
    transform: step === 1 ? `translate3d(0,0%,0)` : `translate3d(0,-85%,0)`,
    ref: idFormAnimRef,
  });
  const idFormHiddenAnimRef = useSpringRef();
  const idFormHiddenAnim = useSpring({
    display: step === 1 ? 'flex' : 'none',
    ref: idFormHiddenAnimRef,
  });

  const passwordFormAnimRef = useSpringRef();
  const passwordFormAnim = useSpring({
    opacity: step === 2 ? 1 : 0,
    transform: step === 2 ? `translate3d(0,0%,0)` : `translate3d(0,-85%,0)`,
    ref: passwordFormAnimRef,
  });

  const passwordFormHiddenAnimRef = useSpringRef();
  const passwordFormHiddenAnim = useSpring({
    display: step === 2 ? 'flex' : 'none',
    ref: passwordFormHiddenAnimRef,
  });

  const userNameAnimRef = useSpringRef();
  const userNameAnim = useSpring({
    opacity: step === 2 ? 1 : 0,
    transform: step === 2 ? `translate3d(0,0%,0)` : `translate3d(0,-85%,0)`,
    ref: userNameAnimRef,
  });

  const imageAnimRef = useSpringRef();
  const imageAnim = useSpring({
    size: step === 1 ? 200 : 150,
    opacity: step === 1 ? 0.8 : 0.5,
    ref: imageAnimRef,
  });

  useChain(
    step === 2
      ? [
          imageAnimRef,
          idFormAnimRef,
          idFormHiddenAnimRef,
          passwordFormAnimRef,
          passwordFormHiddenAnimRef,
          userNameAnimRef,
        ]
      : [
          userNameAnimRef,
          passwordFormAnimRef,
          passwordFormHiddenAnimRef,
          idFormHiddenAnimRef,
          idFormAnimRef,
          imageAnimRef,
        ],
    [0, 0.2, 0.4, 0.6, 0.7, 0.8],
  );

  const onSubmit = async () => {
    if (typeof onLogin !== 'function') return;

    const loginSuccess = await onLogin(state);

    if (loginSuccess) {
      setState({
        id: '',
        password: '',
      });
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const moveStep = (stepNumber: number) => {
    if (state.id) {
      setStep(stepNumber);

      setTimeout(() => {
        if (stepNumber === 2 && passwordRef.current) {
          passwordRef.current.focus();
        }
        if (stepNumber === 1 && idRef.current) {
          idRef.current.focus();
        }
      }, 1200);
    }
  };

  const { onEnterDown: onIdEnter } = useKeyboardEvent({ onPressEnter: () => moveStep(2) });
  const { onEnterDown: onPasswdEnter } = useKeyboardEvent({ onPressEnter: onSubmit });

  const renderUserName = useMemo(
    () =>
      state.id && (
        <AnimateBox
          component="div"
          display="flex"
          alignContent="center"
          sx={{ mb: 3, mt: -4 }}
          style={{ ...userNameAnim, ...passwordFormHiddenAnim }}
        >
          <IconButton sx={{ mr: 1, ml: -5 }} onClick={() => moveStep(1)}>
            <ArrowBack color="inherit" sx={{ color: '#ddd' }} />
          </IconButton>
          <Typography variant="h4" color="#eee">
            {state.id}
          </Typography>
          <Divider />
        </AnimateBox>
      ),
    [passwordFormHiddenAnim, userNameAnim, step, state.id],
  );

  const renderIdForm = useMemo(() => {
    return (
      <AnimateBox component="div" display="flex" alignItems="center" style={{ ...idFormAnim, ...idFormHiddenAnim }}>
        <TextField
          variant="filled"
          name="id"
          label="아이디"
          placeholder="아이디를 입력해주세요."
          sx={{ mt: 1, width: 300 }}
          inputRef={idRef}
          InputProps={{ sx: { color: '#fff', fontWeight: 'bold' } }}
          InputLabelProps={{ sx: { color: '#eee', fontWeight: 'bold' } }}
          value={state.id}
          onChange={onChange}
          onKeyDown={onIdEnter}
        />
        <IconButton
          sx={{ ml: 2, backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
          size="medium"
          disabled={!state.id}
          onClick={() => moveStep(2)}
        >
          <Send sx={{ color: '#31518d' }} fontSize="small" />
        </IconButton>
      </AnimateBox>
    );
  }, [idFormHiddenAnim, idFormAnim, state.id, idRef]);

  const renderPasswordForm = useMemo(() => {
    return (
      <AnimateBox
        component="div"
        display="flex"
        alignItems="center"
        style={{ ...passwordFormAnim, ...passwordFormHiddenAnim }}
      >
        <TextField
          inputRef={passwordRef}
          variant="filled"
          name="password"
          label="비밀번호"
          placeholder="비밀번호를 입력해주세요."
          autoFocus
          type="password"
          sx={{ mt: 1, width: 300 }}
          InputProps={{ sx: { color: '#fff', fontWeight: 'bold' } }}
          InputLabelProps={{ sx: { color: '#eee', fontWeight: 'bold' } }}
          value={state.password}
          onChange={onChange}
          onKeyDown={onPasswdEnter}
        />
        <IconButton
          sx={{ ml: 2, backgroundColor: 'rgba(255, 255, 255, 0.3)' }}
          size="medium"
          disabled={!state.password}
          onClick={onSubmit}
        >
          <Send sx={{ color: '#31518d' }} fontSize="small" />
        </IconButton>
      </AnimateBox>
    );
  }, [passwordFormHiddenAnim, passwordFormAnim, state.password]);

  useEffect(() => {
    if (idRef.current) {
      setTimeout(() => idRef?.current?.focus(), 1000);
    }
  }, [idRef]);

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      padding={2}
      sx={{ zIndex: 2, marginTop: '13%' }}
    >
      <Box
        component="div"
        display="flex"
        // flexDirection="column"
        justifyContent="space-around"
        alignItems="center"
        sx={{ width: 500, height: 250, borderRadius: 50, mb: 2 }}
      >
        <AnimateImg
          style={{ width: imageAnim.size, zIndex: 5, opacity: imageAnim.opacity }}
          alt="Logo"
          src={subLogoImage}
        />
        <AnimateImg
          style={{ width: imageAnim.size, zIndex: 5, opacity: imageAnim.opacity }}
          alt="Logo"
          src={logoImage}
        />
      </Box>
      <Box component="div" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {renderUserName}
        {renderIdForm}
        {renderPasswordForm}
      </Box>
    </Box>
  );
}

const AnimateBox = animated(Box);

const AnimateImg = animated.img;

export default LoginBox;
