import { Add, Edit } from '@mui/icons-material';
import { Box, IconButton, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import { IProduct } from '@types';
import { findAllProductAPI } from 'apis/product';
import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import ProductSaveModal from './ProductSaveModal';

type Props = Omit<BaseSelectBoxProps, 'defaultValue'> & {
  isEdit?: boolean;
  containerSx?: SxProps<Theme>;
  site?: string;
  onSelectProduct?(product: IProduct | undefined): void;
  defaultValue?: number;
};

ProductSelecBox.defaultProps = {
  isEdit: false,
  containerSx: undefined,
  site: undefined,
  onSelectProduct: undefined,
  defaultValue: undefined,
};

export default function ProductSelecBox({
  isEdit,
  containerSx,
  site,
  onSelectProduct,
  onChange,
  defaultValue,
  ...others
}: Props) {
  const { data, refetch } = useQuery(['findAllProductBySite', site], () => findAllProductAPI(site || ''));
  const [selectedId, setSelectedId] = useState<number | undefined>(defaultValue);
  const selectedProduct = useMemo(() => _.find(data?.data, (product) => product.id === selectedId), [selectedId, data]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modifyData, setModifyData] = useState<IProduct | undefined>();

  const items = useMemo(
    (): SelectItemProps[] => data?.data?.map((dt) => ({ id: dt.id.toString(), name: dt.name })) ?? [],
    [site, data],
  );

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModifyData(undefined);
  };

  const openModifyModal = () => {
    setModifyData(selectedProduct);
    openModal();
  };

  const handleProductSaveSuccess = (product?: IProduct) => {
    closeModal();
  };

  const handleProductDeleteSuccess = (product?: IProduct) => {
    setSelectedId(undefined);
    closeModal();
  };

  const handleProductChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const selected = _.find(data?.data, (product) => product.id.toString() === value);

    setSelectedId(selected?.id);

    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  useEffect(() => {
    if (typeof onSelectProduct === 'function') {
      onSelectProduct(selectedProduct);
    }
  }, [selectedProduct]);

  useEffect(() => {
    setSelectedId(defaultValue);
    refetch();
  }, [site, defaultValue]);

  return (
    <Box component="div" sx={containerSx} display="flex" alignItems="center">
      <BaseSelectBox
        name="product"
        label="제품"
        showEmpty
        items={items}
        value={selectedProduct?.id.toString() || ''}
        onChange={handleProductChange}
        {...others}
      />
      {isEdit && (
        <>
          <IconButton disabled={!site || !selectedProduct} onClick={openModifyModal}>
            <Edit />
          </IconButton>
          <IconButton disabled={!site} onClick={openModal}>
            <Add />
          </IconButton>
        </>
      )}
      <ProductSaveModal
        data={modifyData}
        site={site}
        open={modalOpen}
        onClose={closeModal}
        onSaveSuccess={handleProductSaveSuccess}
        onDeleteSuccess={handleProductDeleteSuccess}
      />
    </Box>
  );
}
