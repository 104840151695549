import React, { useMemo } from 'react';
import BaseTree, { BaseTreeProps, RenderBaseTree } from 'components/common/BaseTree';
import { SxProps, Theme } from '@mui/material';
import { IEmissionItem, TEmissionItemType } from '@types';
import { flatten, sortEmission } from 'common/lib';
import _ from 'lodash';

type Props = {
  data?: IEmissionItem[];
  containerStyle?: SxProps<Theme>;
  onClickItem?(code: IEmissionItem): void;
  onCheckEmission?(emission: IEmissionItem, checked: boolean): void;
  showAddBtn?: boolean;
  showCheckBtn?: boolean;
  onAddItem?(code: IEmissionItem): void;
};

EmissionItemTree.defaultProps = {
  data: [],
  showAddBtn: true,
  onClickItem: undefined,
  onAddItem: undefined,
  onCheckEmission: undefined,
  showCheckBtn: false,
  containerStyle: {},
};

export default function EmissionItemTree({
  containerStyle,
  showAddBtn,
  onClickItem,
  onAddItem,
  onCheckEmission,
  data,
  ...others
}: Props) {
  const getTypeName = (type: TEmissionItemType) => {
    switch (type) {
      case 'EMISSION_SECTION':
        return '배출부문';
      case 'EMISSION_ITEM':
        return '배출항목';
      case 'EMISSION_FACTOR':
        return '배출계수';
      default:
        return '';
    }
  };

  const createTree = (emission: IEmissionItem): RenderBaseTree => {
    return {
      id: emission.id.toString(),
      name: `${emission.code} | ${emission.name} | ${getTypeName(emission.type)} | ${emission.unit || ''} | ${
        emission.orderNum
      }`,
      children: emission.children.map((sub) => createTree(sub)),
    };
  };

  const onClick = (tree: RenderBaseTree) => {
    if (typeof onClickItem === 'function') {
      const selected = flatDatas.filter((emission) => emission.id.toString() === tree.id);

      if (selected && selected.length > 0) {
        onClickItem(selected[0]);
      }
    }
  };

  const onClickAdd = (parentId: string) => {
    if (typeof onAddItem === 'function') {
      const selected = flatDatas.filter((emission) => emission.id.toString() === parentId);

      if (selected && selected.length > 0) {
        onAddItem(selected[0]);
      }
    }
  };

  const sortedData = useMemo(() => sortEmission(data ?? []), [data]);
  const flatDatas = useMemo(() => flatten(data ?? []) as IEmissionItem[], [data]);

  const handleCheck = (nodeId: string, checked: boolean) => {
    const checkedEmission = _.find(flatDatas, (dt) => dt.id.toString() === nodeId);

    if (typeof onCheckEmission === 'function' && checkedEmission) {
      onCheckEmission(checkedEmission, checked);
    }
  };

  const treeData: RenderBaseTree[] = useMemo(() => {
    return sortedData.map((tree) => createTree(tree));
  }, [sortedData]);

  return (
    <BaseTree
      {...others}
      showAddBtn={showAddBtn}
      data={treeData}
      onClickItem={onClick}
      onClickAdd={onClickAdd}
      onChecked={handleCheck}
      containerStyle={containerStyle}
    />
  );
}
