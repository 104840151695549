import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { IVRTour, TVRTourCreateRequest } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import FileImageCard from 'components/file/FileImageCard';
import FileUploadBox from 'components/file/FileUploadBox';
import SiteSelectBox from 'components/site/SiteSelectBox';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatSiteState } from 'states';

type Props = IBaseModalProps & {
  data?: IVRTour;
  onSave?(request: TVRTourCreateRequest): void;
};

VRTourSaveModal.defaultProps = {
  data: undefined,
  onSave: undefined,
};

export default function VRTourSaveModal({ data, open, onSave, ...others }: Props) {
  const [state, setState] = useState<TVRTourCreateRequest>({});
  const flatSite = useRecoilValue(flatSiteState);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onFileChange = (name: string, files: File[]) => {
    setState((prev) => ({ ...prev, [name]: files.at(0) }));
  };

  const handleSave = () => {
    if (typeof onSave === 'function') {
      onSave(state);
    }
  };

  useEffect(() => {
    setState({
      ...data,
      site: data?.site.siteCode,
    });
  }, [data]);

  useEffect(() => {
    if (!open) {
      setState({});
    }
  }, [open]);

  return (
    <BaseModal open={open} title="VR투어 저장" onConfirm={handleSave} {...others}>
      <Box component="div" sx={{ width: 500, height: 400 }}>
        <TextField
          required
          fullWidth
          variant="standard"
          name="title"
          label="이름"
          sx={{ mt: 2 }}
          onChange={onInputChange}
          defaultValue={data?.title}
        />
        <TextField
          required
          fullWidth
          variant="standard"
          name="url"
          label="URL"
          sx={{ mt: 2 }}
          onChange={onInputChange}
          defaultValue={data?.url}
        />
        <SiteSelectBox
          required
          name="site"
          data={flatSite}
          sx={{ mt: 2 }}
          onChange={onSelectChange}
          defaultValue={data?.site.siteCode}
        />
        <FileUploadBox
          name="file"
          containerSx={{ mt: 2 }}
          singleSelect
          fieldProps={{ variant: 'standard', label: '섬네일 이미지', InputLabelProps: { shrink: true } }}
          onChange={onFileChange}
        />
        <FileImageCard sx={{ width: 100, height: 100, mt: 1 }} data={data?.thumbnailImage} />
      </Box>
    </BaseModal>
  );
}
