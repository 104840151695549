import React, { useEffect, useState } from 'react';
import { SystemLayout } from 'layout/system';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { groupTypeState } from 'states';
import { IGroupType, TGroupTypeCreateRequest, TGroupTypeResponse } from '@types';
import { useMutation, useQuery } from 'react-query';
import useLoading from 'common/hooks/useLoading';
import { createGroupTypeAPI, findAllGroupTypeAPI } from 'apis/grouptype';
import GroupTypeTree from 'components/grouptype/GroupTypeTree';
import GroupTypeForm from 'components/grouptype/GroupTypeForm';
import { sortGroupType } from 'common/lib';

export default function GroupTypePage() {
  // 그룹 선택 이벤트 헨들러
  const handleSelectGroup = (group: IGroupType) => {
    setSelectedGroupType(group);
  };

  // 추가 버튼 클릭 이벤트 헨들러
  const handleAddClick = (parent: string) => {
    setSelectedGroupType({ parent });
  };

  // 저장버튼 클릭 이벤트 헨들러
  const handleSaveClick = (request: TGroupTypeCreateRequest) => {
    createMutation.mutate(request);
  };

  // 저장성공 이벤트 헨들러
  const onSaveSuccess = (data: TGroupTypeResponse) => {
    if (data.success) {
      toast(`${data.data?.code} 저장 성공`, { type: 'success' });
      refetchGroup();
      if (data.data) {
        handleSelectGroup(data.data);
      }
    }
  };

  // 그룹 생성 Mutation
  const createMutation = useMutation(createGroupTypeAPI, {
    onSuccess: onSaveSuccess,
  });

  // groupType
  const { data, refetch: refetchGroup, isFetching } = useQuery(['groupType'], findAllGroupTypeAPI, { enabled: false });

  // 그룹목록 Recoil State
  const [groupTypes, setGroupTypes] = useRecoilState(groupTypeState);

  // 선택된 그룹 정보
  const [selectedGroupType, setSelectedGroupType] = useState<Partial<IGroupType>>();

  const changeLoading = useLoading({ trigger: false });

  useEffect(() => {
    refetchGroup();
  }, []);

  useEffect(() => {
    if (data?.data) {
      setGroupTypes(sortGroupType(data.data));
    }
  }, [data]);

  return (
    <SystemLayout title="그룹유형관리">
      <Box component="div" display="flex" flexDirection="row" flexWrap="wrap">
        <Box component="div" flex={3} p={1}>
          <GroupTypeTree
            containerStyle={{ minHeight: '70vh', minWidth: 200 }}
            data={groupTypes}
            onClickItem={handleSelectGroup}
            onAddItem={handleAddClick}
          />
        </Box>
        <Box component="div" flex={2} p={1}>
          <GroupTypeForm
            containerStyle={{ minHeight: '70vh', maxHeight: '70vh', minWidth: 200, overflowY: 'auto' }}
            data={selectedGroupType}
            onSave={handleSaveClick}
          />
        </Box>
      </Box>
    </SystemLayout>
  );
}
