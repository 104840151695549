import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Switch, SxProps, Theme, Typography } from '@mui/material';
import { IDashboardItem, INodeSystem, IThreeCanvas } from '@types';
import useReactFlow from 'common/hooks/useReactFlow';
import NodeSystemSelectBox from 'components/nodesystem/NodeSystemSelectBox';
import ModelEditCanvas from 'components/threecanvas/ModelEditCanvas';
import ThreeCanvasSelectBox from 'components/threecanvas/ThreeCanvasSelectBox';
import { useRecoilValue } from 'recoil';
import { nodeSystemState, threeCanvasState } from 'states';
import { Fullscreen } from '@mui/icons-material';

type Props = {
  item: IDashboardItem;
  width?: number | string;
  height?: number | string;
  sx?: SxProps<Theme>;
  controllBoxSx?: SxProps<Theme>;
  showFullScreenBtn?: boolean;
};

NodeSystemToggleCard.defaultProps = {
  width: undefined,
  height: undefined,
  sx: undefined,
  controllBoxSx: undefined,
  showFullScreenBtn: true,
};

export default function NodeSystemToggleCard({ item, width, height, sx, controllBoxSx, showFullScreenBtn }: Props) {
  const allNodeSystems = useRecoilValue(nodeSystemState);
  const allCanvases = useRecoilValue(threeCanvasState);

  const [is3D, setIs3D] = useState<boolean>(false);

  const canvasList = useMemo(
    () => allCanvases.filter((canvas) => item.data?.canvases?.includes(canvas.id)),
    [allCanvases, item.data?.canvases],
  );

  const nodeSystemList = useMemo(
    () => allNodeSystems.filter((node) => item.data?.nodes?.includes(node.id)),
    [allNodeSystems, item.data?.nodes],
  );

  const [selectedCanvas, setSelectedCanvas] = useState<IThreeCanvas | undefined>(undefined);

  const [selectedNodeSystem, setSelectedNodeSystem] = useState<INodeSystem | null>(null);

  const { reactFlow } = useReactFlow({
    sx: { height: '100%' },
    cardSx: { width: '100%', height: '100%' },
    nodeSystem: selectedNodeSystem,
  });

  const openFullScreenPage = () => {
    window.open(`/node/fullscreen/${item.id}`, undefined, 'fullscreen=yes');
  };

  useEffect(() => {
    if (nodeSystemList && nodeSystemList.length > 0) {
      setSelectedNodeSystem(nodeSystemList.at(0) || null);
    }
  }, [nodeSystemList]);

  useEffect(() => {
    if (canvasList && canvasList.length > 0) {
      setSelectedCanvas(canvasList.at(0));
    }
  }, [canvasList]);

  const renderSelectBox = useMemo(
    () =>
      is3D ? (
        <ThreeCanvasSelectBox
          data={canvasList}
          sx={{ width: 300, paddingX: 1 }}
          fullWidth={false}
          value={selectedCanvas?.id.toString() || ''}
          onSelectThreeCanvas={(name, selected) => setSelectedCanvas(selected)}
        />
      ) : (
        <NodeSystemSelectBox
          label=""
          data={nodeSystemList}
          sx={{ width: 300, paddingX: 1 }}
          fullWidth={false}
          value={selectedNodeSystem?.id.toString() || ''}
          onChangeNodeSystem={setSelectedNodeSystem}
        />
      ),
    [is3D, nodeSystemList, canvasList, selectedCanvas, selectedNodeSystem],
  );

  const renderCard = useMemo(
    () => (is3D ? <ModelEditCanvas readonly hideControls sx={{ height: '100%' }} data={selectedCanvas} /> : reactFlow),
    [is3D, reactFlow, selectedCanvas],
  );

  return (
    <Box component="div" display="flex" flexDirection="column" sx={{ width, height, ...sx }}>
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={controllBoxSx}
      >
        {showFullScreenBtn && (
          <IconButton onClick={openFullScreenPage}>
            <Fullscreen />
          </IconButton>
        )}

        {renderSelectBox}
        <Typography>2D</Typography>
        <Switch onChange={(e, checked) => setIs3D(checked)} />
        <Typography>3D</Typography>
      </Box>
      {renderCard}
    </Box>
  );
}
