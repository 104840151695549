import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { INode, INodeGroupData, INodeSystem, INodeTagData, TNodeCreateRequest } from '@types';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import BaseSelectBox from 'components/common/BaseSelectBox';
import { NodeSearchFormType } from 'components/reactflow/BaseNodeType';
import NodeTagForm from './NodeTagForm';
import NodeGroupForm from './NodeGroupForm';

type Props = IBaseModalProps & {
  data?: INode | null;
  nodeSystem?: INodeSystem | null;
  onSaveNode?(data: TNodeCreateRequest): void;
  readonly?: boolean;
  requried?: boolean;
  nodeSearchForm?: NodeSearchFormType;
};

NodeSaveModal.defaultProps = {
  data: undefined,
  nodeSystem: undefined,
  onSaveNode: undefined,
  readonly: false,
  requried: true,
  nodeSearchForm: undefined,
};

export default function NodeSaveModal({
  requried,
  readonly,
  open,
  data,
  nodeSystem,
  onSaveNode,
  nodeSearchForm,
  ...others
}: Props) {
  const handleSave = () => {
    if (typeof onSaveNode === 'function' && nodeSystem && state.type) {
      onSaveNode({
        nodeSystem: nodeSystem.id,
        position: { x: 0, y: 0 },
        ...state,
        data: {
          ...state.data,
          tags: tagDatas,
          group: groupData,
        },
      });
    }
  };

  const onChange = (name: string, value: any) => {
    switch (name) {
      case 'tag':
      case 'label':
        setState((prev) => ({ ...prev, data: { ...prev.data, [name]: value } }));
        break;
      default:
        setState((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  // Input Change Event
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  // Select Change Event
  const onChangeSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  // 태그 데이터 변경 이벤트
  const handleChangeTag = (tags: INodeTagData[]) => {
    if (tags) {
      setTagDatas(tags);
    }
  };

  // 그룹 데이터 변경 이벤트
  const handleChangeGroupData = (groupData: INodeGroupData) => {
    setGroupData(groupData);
  };

  const [state, setState] = useState<TNodeCreateRequest>({});
  const [tagDatas, setTagDatas] = useState<INodeTagData[]>([]);
  const [groupData, setGroupData] = useState<Partial<INodeGroupData>>({
    tags: [],
  });

  const selectedType = useMemo(() => {
    switch (state.type) {
      case 'tagNodeDefault':
      case 'tagNodeInput':
      case 'tagNodeOutput':
        return 'tag';

      case 'groupNodeDefault':
      case 'groupNodeInput':
      case 'groupNodeOutput':
        return 'group';
      default:
        return 'tag';
    }
  }, [state.type]);

  // 태그 노드 세팅 Form Rendering
  const renderTagForm = useMemo(
    () =>
      selectedType === 'tag' && (
        <NodeTagForm
          defaultData={data?.data.tags}
          onChange={handleChangeTag}
          readonly={readonly}
          nodeSearchForm={nodeSearchForm}
        />
      ),
    [selectedType, data, readonly, nodeSearchForm],
  );

  // 그룹 노드 세팅 Form Rendering
  const renderGroupForm = useMemo(
    () =>
      selectedType === 'group' && (
        <NodeGroupForm
          defaultData={data?.data.group}
          onChange={handleChangeGroupData}
          readonly={readonly}
          nodeSearchForm={nodeSearchForm}
        />
      ),
    [selectedType, readonly, data, nodeSearchForm],
  );

  // 데이터 수정의 경우 상태값에 데이터 세팅, 생성의 경우 상태값 초기화
  useEffect(() => {
    if (data) {
      setState({ ...data });
      setTagDatas(data.data.tags || []);
      setGroupData(data.data.group || { tags: [] });
    } else {
      setState({});
      setTagDatas([]);
      setGroupData({ tags: [] });
    }
  }, [data]);

  // Modal 이 닫힐 경우 상태값 초기화
  useEffect(() => {
    if (!open) {
      setState({});
      setTagDatas([]);
      setGroupData({ tags: [] });
    }
  }, [open]);

  // 태그 노드 유형 변경 시, 데이터 초기화
  useEffect(() => {
    setTagDatas([]);
  }, [selectedType]);

  useEffect(() => {
    console.log(nodeSearchForm);
  }, [nodeSearchForm]);

  return (
    <BaseModal
      {...others}
      open={open}
      onConfirm={handleSave}
      title={readonly ? '노드 조회' : '노드 저장'}
      disableConfirm={!state.type}
    >
      <Box component="div" sx={{ width: 650, height: 600 }}>
        <TextField
          fullWidth
          name="label"
          label="label"
          variant="standard"
          onChange={onChangeInput}
          InputProps={{ readOnly: readonly }}
          value={state.data?.label}
        />
        {!readonly && (
          <BaseSelectBox
            required={requried}
            fullWidth
            name="type"
            label="type"
            items={[
              { id: 'tagNodeInput', name: 'Tag Input' },
              { id: 'tagNodeDefault', name: 'Tag In/Out' },
              { id: 'tagNodeOutput', name: 'Tag Output' },
              { id: 'groupNodeInput', name: 'Group Input' },
              { id: 'groupNodeDefault', name: 'Group In/Out' },
              { id: 'groupNodeOutput', name: 'Group Output' },
            ]}
            onChange={onChangeSelect}
            defaultValue={data?.type ?? ''}
            value={state.type ?? ''}
          />
        )}
        {renderTagForm}
        {renderGroupForm}
      </Box>
    </BaseModal>
  );
}
