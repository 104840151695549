import { ENDPOINT } from '@const';
import { TNodeEdgeCreateRequest } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const createAllNodeEdgeAPI = async (request: TNodeEdgeCreateRequest) => {
  const result = await axios
    .request({
      ...ENDPOINT.nodeEdge.createAllNodeEdge,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('노드엣지 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};
