import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Card, Grid, IconButton, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { IVRTour } from '@types';
import { getFileURL } from 'common/lib';

export type VRTourCardProps = {
  data?: IVRTour;
  hideControls?: boolean;
  hideTitle?: boolean;
  onEdit?(vrtour: IVRTour): void;
  onDelete?(vrtour: IVRTour): void;
  containerSx?: SxProps<Theme>;
  cardSx?: SxProps<Theme>;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

VRTourCard.defaultProps = {
  data: undefined,
  onEdit: undefined,
  onDelete: undefined,
  containerSx: undefined,
  cardSx: undefined,
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  hideControls: false,
  hideTitle: false,
};

export default function VRTourCard({
  data,
  onEdit,
  onDelete,
  containerSx,
  cardSx,
  xs,
  sm,
  md,
  lg,
  xl,
  hideControls,
  hideTitle,
}: VRTourCardProps) {
  const theme = useTheme();

  const handleClickCard = () => {
    if (data) {
      window.open(data.url, undefined, 'fullscreen=yes');
    }
  };

  const handleEdit = (vrtour: IVRTour | undefined) => {
    if (vrtour && typeof onEdit === 'function') {
      onEdit(vrtour);
    }
  };

  const handleDelete = (vrtour: IVRTour | undefined) => {
    if (vrtour && typeof onDelete === 'function' && window.confirm('VR 투어 정보를 삭제하시겠습니까?')) {
      onDelete(vrtour);
    }
  };

  return (
    <Grid key={data?.id} item xs={xs} sm={sm} md={md} lg={lg} xl={xl} sx={containerSx}>
      <Box component="div" display="flex" justifyContent="flex-end" alignItems="center">
        {!hideTitle && <Typography>{`${data?.title} | ${data?.site.siteCode}`}</Typography>}
        {!hideControls && (
          <>
            <IconButton onClick={() => handleEdit(data)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDelete(data)}>
              <Delete />
            </IconButton>
          </>
        )}
      </Box>

      <Card
        onClick={handleClickCard}
        sx={{
          width: '100%',
          height: 300,
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...cardSx,
        }}
      >
        <Box
          component="div"
          sx={{
            position: 'absolute',
            zIndex: 2,
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundColor: 'rgba(0,0,0, 0.6)',
          }}
        />
        <Box
          component="div"
          sx={{
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundImage: `url(${getFileURL(data?.thumbnailImage)})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.7)' : 'rgba(255,255,255, 0.7)',
            width: '100%',
            zIndex: 3,
            p: 1,
            textAlign: 'center',
          }}
        >
          {data?.title}
        </Typography>
      </Card>
    </Grid>
  );
}
