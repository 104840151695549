import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

type Props = {
  title: string;
  children?: ReactElement | ReactElement[];
};

DataLayout.defaultProps = {
  children: undefined,
};

export function DataLayout({ title, children }: Props) {
  return (
    <Box component="div" p={2}>
      <Box component="div" p={1}>
        <Typography variant="h5" fontWeight="bold">
          {title}
        </Typography>
      </Box>
      <Box component="div" display="flex" flexDirection="column" p={1}>
        {children}
      </Box>
    </Box>
  );
}
