import React, { useEffect, useMemo } from 'react';
import { BaseModal, IBaseModalProps } from 'components/common/BaseModal';
import { Box } from '@mui/material';
import useTagHistoryData from 'common/hooks/useTagHistoryData';
import { TData10FindRequest, TData1ChartResponse } from '@types';
import DataGrid from './DataGrid';

type Props = IBaseModalProps & {
  data?: TData1ChartResponse;
  search?: TData10FindRequest;
};

DataGridModal.defaultProps = {
  data: undefined,
  search: undefined,
};

export default function DataGridModal({ data, search, open, ...others }: Props) {
  const createSearchProps = useMemo(() => {
    if (open) {
      if (data) {
        return undefined;
      }

      return search;
    }

    return undefined;
  }, [open, data, search]);

  const { data: historyData } = useTagHistoryData({ search: createSearchProps });

  const gridData = useMemo(() => {
    if (data) {
      return data;
    }

    if (historyData) {
      return historyData;
    }

    return undefined;
  }, [data, historyData]);

  return (
    <BaseModal open={open} {...others}>
      <Box component="div" sx={{ width: 1000 }}>
        <DataGrid data={gridData} />
      </Box>
    </BaseModal>
  );
}
