import { IAlarmHist, TAlarmHistResponse, TTaskCreateRequest, TTaskResponse } from '@types';
import { deleteAlarmHistAPI } from 'apis/alarmhist';
import AlarmHistSaveModal from 'components/alarmhist/AlarmHistSaveModal';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';
import useLoading from './useLoading';

type Props = {
  createTaskMutation: UseMutationResult<TTaskResponse, unknown, TTaskCreateRequest, unknown>;
  userId?: string;
  onReload?(): void;
};

export default function useAlarmHistModal({ createTaskMutation, userId, onReload }: Props) {
  const reload = () => {
    if (typeof onReload === 'function') {
      onReload();
    }
  };

  const handleOnModalOpen = () => {
    setOpen(true);
  };

  const handleOnModalClose = () => {
    setOpen(false);
    setAlarmHist(undefined);
  };

  const alarmHistDelete = (id: number) => {
    deleteMutation.mutate(id);
  };

  const handleAlarmTaskCreate = (id: number, name: string, desc: string, site: string) => {
    createTaskMutation.mutate({
      alarmHist: id,
      manager: userId,
      taskName: name,
      taskDesc: desc,
      site,
    });
  };

  const onDeleteSuccess = (data: TAlarmHistResponse) => {
    if (data && data.success) {
      toast('알람 이력 삭제 성공', { type: 'success' });
      handleOnModalClose();
      reload();
    }
  };

  const [open, setOpen] = useState<boolean>(false);
  const loading = useLoading({ trigger: false });
  const [alarmHist, setAlarmHist] = useState<IAlarmHist | undefined>();
  const deleteMutation = useMutation(deleteAlarmHistAPI, { onSuccess: onDeleteSuccess });

  useEffect(() => {
    loading({ loading: deleteMutation.isLoading, message: '알람이력 삭제중...' });
  }, [deleteMutation.isLoading]);

  const modal = useMemo(
    () => (
      <AlarmHistSaveModal
        data={alarmHist}
        open={open}
        onReload={reload}
        onCreateAlarmTask={handleAlarmTaskCreate}
        onDeleteAlarmHist={alarmHistDelete}
        onClose={handleOnModalClose}
      />
    ),
    [open, alarmHist, reload],
  );

  return {
    alarmHist,
    setAlarmHist,
    modalOpen: handleOnModalOpen,
    modal,
  };
}
