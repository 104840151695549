import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ISort, IUser, TPageUserRequest, TUserCreateRequest, TUserCreateResponse, TUserSearchField } from '@types';
import UserDataGrid, { IUserGridData } from 'components/user/UserDataGrid';
import { findUsersAPI, registerAPI, userModifyAPI } from 'apis/user';
import UserSaveModal from 'components/user/UserSaveModal';
import { SystemLayout } from 'layout/system';
import UserSearchField from 'components/user/UserSearchField';
import useLoading from 'common/hooks/useLoading';

function UserPage() {
  const [pageInfo, setPageInfo] = useState<TPageUserRequest>({
    page: 0,
    size: 10,
    sort: undefined,
    search: undefined,
  });
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onClickUserRow = (selected: IUserGridData) => {
    const filteredUser = data?.data?.content?.filter((user) => user.userId === selected.userId);

    if (filteredUser && filteredUser.length > 0) {
      setSelectedUser(filteredUser[0]);
      setModalOpen(true);
    }
  };

  const onClickUserCreate = () => {
    setModalOpen(true);
  };

  const userSave = (request: TUserCreateRequest) => {
    if (request.id) {
      userModifyMutation.mutate(request);
    } else {
      userCreateMutation.mutate(request);
    }
  };

  const onChangeRowPerPage = (size: number) => setPageInfo((prev) => ({ ...prev, size, page: 0 }));

  const onChangePage = (page: number) => setPageInfo((prev) => ({ ...prev, page }));

  const onChangeSort = (sort: ISort[]) => setPageInfo((prev) => ({ ...prev, sort }));

  const onChangeSearch = (search: TUserSearchField) => setPageInfo((prev) => ({ ...prev, search }));

  const onCloseModifiyModal = () => {
    setModalOpen(false);
    setSelectedUser(null);
  };

  const onSaveSuccess = (data: TUserCreateResponse) => {
    if (data.success) {
      toast.success(`${data.data?.userId} 유저 저장 완료`, {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
      });

      onCloseModifiyModal();
      setModalOpen(false);
      refetch();
    } else {
      toast.error(data.errMessage, {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
      });
    }
  };

  const userCreateMutation = useMutation(registerAPI, {
    onSuccess: onSaveSuccess,
  });

  const userModifyMutation = useMutation(userModifyAPI, {
    onSuccess: onSaveSuccess,
  });

  const { data, refetch, isFetching } = useQuery(['users', pageInfo], () => findUsersAPI(pageInfo));

  const changeLoading = useLoading({ trigger: false });

  useEffect(() => {
    changeLoading({ loading: userCreateMutation.isLoading, message: '사용자 저장중...' });
  }, [userCreateMutation.isLoading]);

  useEffect(() => {
    changeLoading({ loading: userModifyMutation.isLoading, message: '사용자 저장중...' });
  }, [userModifyMutation.isLoading]);

  useEffect(() => {
    changeLoading({ loading: isFetching, message: '사용자 조회중...' });
  }, [isFetching]);

  return (
    <SystemLayout title="사용자관리">
      <Card sx={{ mb: 1, mt: 1 }}>
        <UserSearchField onChange={onChangeSearch} />
      </Card>
      <UserDataGrid
        data={data}
        page={pageInfo.page}
        rowPerPage={pageInfo.size}
        onClick={onClickUserRow}
        onChangePage={onChangePage}
        onChangeRowPerPage={onChangeRowPerPage}
        onChangeSort={onChangeSort}
      />
      <UserSaveModal open={modalOpen} onClose={onCloseModifiyModal} onSave={userSave} data={selectedUser} />
      <Box component="div" display="flex" p={1} justifyContent="flex-end">
        <Button variant="contained" onClick={onClickUserCreate}>
          <Typography>유저생성</Typography>
        </Button>
      </Box>
    </SystemLayout>
  );
}

export default UserPage;
