import React, { useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { ISite } from '@types';
import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';

type Props = BaseSelectBoxProps & {
  required?: boolean;
  name?: string;
  defaultValue?: string;
  value?: string;
  data?: ISite[];
  useYN?: boolean;
  onChange?(e: SelectChangeEvent): void;
};

SiteSelectBox.defaultProps = {
  required: undefined,
  name: undefined,
  defaultValue: undefined,
  value: undefined,
  useYN: false,
  data: [],
  onChange: undefined,
};

export default function SiteSelectBox({
  required,
  data,
  defaultValue,
  useYN,
  value,
  name,
  onChange,
  ...others
}: Props) {
  const renderSelectItem: SelectItemProps[] = useMemo(
    () =>
      data?.filter((site) => (useYN ? site.useYN : true)).map((site) => ({ id: site.siteCode, name: site.siteName })) ??
      [],
    [data],
  );

  return (
    <BaseSelectBox
      required={required}
      label="사이트"
      items={renderSelectItem}
      defaultValue={defaultValue}
      value={value}
      name={name}
      onChange={onChange}
      {...others}
    />
  );
}
