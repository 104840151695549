import { ENDPOINT } from '@const';
import { TGroupTypeCreateRequest, TGroupTypeListResponse, TGroupTypeResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const findAllGroupTypeAPI = async (): Promise<TGroupTypeListResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.groupType.findGroupType,
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('그룹유형 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const createGroupTypeAPI = async (request: TGroupTypeCreateRequest): Promise<TGroupTypeResponse> => {
  const result = await axios
    .request({ ...ENDPOINT.groupType.createGroupType, ...createAuth(), data: request })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('그룹유형 생성 실패');

  toastErrorMessage(result.data);

  return result.data;
};
