import { Box, Button, Typography } from '@mui/material';
import { IAlarm, ISort, TAlarmCreateRequest, TAlarmPageRequest, TAlarmResponse, TAlarmSearchField } from '@types';
import { createAlarmAPI, deleteAlarmAPI, findAlarmPageAPI, modifyAlarmAPI } from 'apis/alarm';
import useLoading from 'common/hooks/useLoading';
import usePageInfo from 'common/hooks/usePageInfo';
import AlarmDataGrid, { IAlarmGridData } from 'components/alarm/AlarmDataGrid';
import AlarmSaveModal from 'components/alarm/AlarmSaveModal';
import AlarmSearchField from 'components/alarm/AlarmSearchField';
import { SystemLayout } from 'layout/system';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

export default function AlarmPage() {
  const handleAlarmSelect = (alarm: IAlarmGridData) => {
    const filteredAlarm = data?.data?.content?.filter((dt) => dt.id === alarm.id);
    if (filteredAlarm && filteredAlarm.length > 0) {
      setSelectedAlarm({ ...filteredAlarm[0] });
      handleSaveModalOpen();
    }
  };

  const handleCreateAlarm = () => {
    handleSaveModalOpen();
  };

  const handleSaveModalOpen = () => {
    setOpenSaveModel(true);
  };

  const handleSaveModalClose = () => {
    setOpenSaveModel(false);
    setSelectedAlarm(null);
  };

  const handleSaveClick = (request: TAlarmCreateRequest) => {
    if (request.id && request.id > 0) {
      modifyMutation.mutate(request);
    } else {
      createMutation.mutate(request);
    }
  };

  const handleDeleteClick = (alarmId: number) => {
    if (alarmId > 0) {
      deleteMutation.mutate(alarmId);
    }
  };

  const handleSaveSuccess = (data: TAlarmResponse) => {
    if (data && data.success) {
      refetch();
      handleSaveModalClose();
      toast('알람 저장 성공', { type: 'success' });
    }
  };

  const handleDeleteSuccess = (data: TAlarmResponse) => {
    if (data && data.success) {
      refetch();
      handleSaveModalClose();
      toast('알람 삭제 성공', { type: 'error' });
    }
  };
  const [selectedAlarm, setSelectedAlarm] = useState<IAlarm | null>(null);
  const [openSaveModel, setOpenSaveModel] = useState<boolean>(false);

  const { changePage, changeRowsPerPage, changeSearch, changeSort, pageInfo } = usePageInfo<TAlarmSearchField>({
    defaultPage: 0,
    defaultSize: 10,
  });
  const { data, refetch, isFetching } = useQuery(['alarms', pageInfo], () => findAlarmPageAPI(pageInfo));
  const createMutation = useMutation(createAlarmAPI, {
    onSuccess: handleSaveSuccess,
  });
  const modifyMutation = useMutation(modifyAlarmAPI, {
    onSuccess: handleSaveSuccess,
  });
  const deleteMutation = useMutation(deleteAlarmAPI, {
    onSuccess: handleDeleteSuccess,
  });

  const searchLoading = useLoading({ trigger: false });

  useEffect(() => {
    searchLoading({ loading: isFetching, message: '알람 조회중...' });
  }, [isFetching]);

  useEffect(() => {
    searchLoading({ loading: createMutation.isLoading, message: '알람 저장중...' });
  }, [createMutation.isLoading]);

  useEffect(() => {
    searchLoading({ loading: modifyMutation.isLoading, message: '알람 저장중...' });
  }, [modifyMutation.isLoading]);

  useEffect(() => {
    searchLoading({ loading: deleteMutation.isLoading, message: '알람 삭제중...' });
  }, [deleteMutation.isLoading]);

  return (
    <SystemLayout title="알람관리">
      <AlarmSearchField onChange={changeSearch} />
      <AlarmDataGrid
        data={data}
        page={pageInfo.page}
        rowPerPage={pageInfo.size}
        onChangePage={changePage}
        onChangeRowPerPage={changeRowsPerPage}
        onChangeSort={changeSort}
        onClick={handleAlarmSelect}
      />
      <Box component="div" display="flex" justifyContent="flex-end" sx={{ p: 1 }}>
        <Button variant="contained" onClick={handleCreateAlarm}>
          <Typography>알람생성</Typography>
        </Button>
      </Box>
      <AlarmSaveModal
        data={selectedAlarm}
        open={openSaveModel}
        onClose={handleSaveModalClose}
        onSave={handleSaveClick}
        onDeleteAlarm={handleDeleteClick}
      />
    </SystemLayout>
  );
}
