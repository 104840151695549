import React from 'react';
import { createTagAPI, findTagByCodeAPI, modifyTagAPI } from 'apis/tag';
import { useMutation, useQuery } from 'react-query';
import { TTagCreateRequest, TTagCreateResponse } from '@types';
import { toast } from 'react-toastify';
import TagSaveModal from './TagSaveModal';

type Props = {
  open?: boolean;
  onClose?(): void;
  tagCode: string;
};

TagCodeSaveModal.defaultProps = {
  open: undefined,
  onClose: undefined,
};

export default function TagCodeSaveModal({ tagCode, onClose, ...others }: Props) {
  const { data } = useQuery(['findTagByCode', tagCode], () => findTagByCodeAPI([tagCode]));

  const handleSave = (request: TTagCreateRequest) => {
    modifyMutation.mutate(request);
  };

  const onSuccess = (result: TTagCreateResponse) => {
    if (result.success) {
      toast('태그 저장성공', { type: 'success' });

      if (typeof onClose === 'function') {
        onClose();
      }
    }
  };

  const modifyMutation = useMutation(modifyTagAPI, {
    onSuccess,
  });

  return <TagSaveModal data={data?.data?.at(0) || null} onSave={handleSave} onClose={onClose} {...others} />;
}
