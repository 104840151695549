import { Box, SxProps, TextField, TextFieldProps, Theme } from '@mui/material';
import { IGroupFieldData } from '@types';
import { Data1MDuration } from 'common/hooks/useTagRTChartData';
import BaseCheckbox from 'components/common/BaseCheckbox';
import MultiTagData1ChartCard from 'components/data/MultiTagData1ChartCard';
import TagDataCard from 'components/data/TagDataCard';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { DateSearchProps } from 'components/common/DateSearchBox';
import { Data10AggType } from 'common/hooks/useTagChartData10';
import GroupFieldThreeModel from './GroupFieldThreeModel';

type Props = {
  data?: IGroupFieldData;
  showLastData?: boolean;
  showDtt?: boolean;
  showCheckBox?: boolean;
  defaultChecked?: boolean;
  containerSx?: SxProps<Theme>;
  textFieldProp?: TextFieldProps;
  onCheckboxChange?(e: ChangeEvent<HTMLInputElement>): void;
  exceptCodes?: string[];
  data1Duration?: Data1MDuration;
  dateSearch?: DateSearchProps;
  aggFunc?: Data10AggType;
};

GroupFieldDataRenderBox.defaultProps = {
  data: undefined,
  showLastData: false,
  showDtt: true,
  showCheckBox: false,
  defaultChecked: false,
  containerSx: {},
  textFieldProp: undefined,
  onCheckboxChange: undefined,
  exceptCodes: [],
  data1Duration: '1h',
  dateSearch: undefined,
  aggFunc: undefined,
};

export default function GroupFieldDataRenderBox({
  showCheckBox,
  defaultChecked,
  showLastData,
  showDtt,
  data,
  textFieldProp,
  containerSx,
  onCheckboxChange,
  exceptCodes,
  data1Duration,
  dateSearch,
  aggFunc,
}: Props) {
  const [checked, setChecked] = useState<boolean>(defaultChecked || false);

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (typeof onCheckboxChange === 'function') {
      onCheckboxChange(e);
    }

    setChecked(checked);
  };

  const renderTagList = useCallback(() => {
    if (!data) return undefined;

    const tags = data?.data?.split(',');

    return showLastData
      ? tags?.map((tag) => (
          <Box key={tag} component="div" sx={{ position: 'relative' }}>
            <TagDataCard
              sx={{ p: 1 }}
              showTagDesc
              cardVariant="outlined"
              tags={[tag]}
              valueTypoVariant="h5"
              showDtt={showDtt}
              dateProps={dateSearch ?? { type: 'dayLast' }}
              aggFunc={aggFunc}
              showAlert={!dateSearch}
            />
          </Box>
        ))
      : tags?.map((tag) => (
          <MultiTagData1ChartCard
            key={tag}
            showDeleteBtn={false}
            id={`${tag}_${data.createdAt}`}
            duration={data1Duration ?? '1h'}
            tagStr={[data?.data || '']}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            height={120}
            gridSx={{ width: '100%' }}
            chartWidth="100%"
            chartHeight="100%"
            chartPadding={{
              top: 10,
              bottom: 5,
              left: 5,
              right: 5,
            }}
            nodeSearchForm={{ dateSearch, aggFunc }}
          />
        ));
  }, [data, dateSearch, aggFunc]);

  const render = useMemo(() => {
    if (!data?.data) return undefined;

    if (exceptCodes?.includes(data.field.fieldType.code)) return undefined;

    switch (data?.field.fieldType.code) {
      case 'FIELD_NUMBER':
      case 'FIELD_TEXT':
        return (
          <TextField
            InputProps={{ readOnly: true }}
            variant="standard"
            fullWidth
            label={data?.field.name}
            value={data?.data}
            {...textFieldProp}
          />
        );
      case 'FIELD_DATE':
        return (
          <TextField
            InputProps={{ readOnly: true }}
            variant="standard"
            fullWidth
            type="date"
            label={data?.field.name}
            value={data?.data}
            {...textFieldProp}
          />
        );
      case 'FIELD_TAG':
        return showLastData ? (
          <Box component="div" sx={{ position: 'relative' }}>
            <TagDataCard
              sx={{ p: 1 }}
              showTagDesc
              cardVariant="outlined"
              tags={[data.data]}
              alarm={data.alarm}
              valueTypoVariant="h5"
              showDtt={showDtt}
              dateProps={dateSearch ?? { type: 'dayLast' }}
              aggFunc={aggFunc}
              showAlert={!dateSearch}
            />
          </Box>
        ) : (
          <MultiTagData1ChartCard
            showDeleteBtn={false}
            id={`${data.data}_${data.createdAt}`}
            duration={data1Duration ?? '1h'}
            tagStr={[data?.data || '']}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            height={120}
            gridSx={{ width: '100%' }}
            chartWidth="100%"
            chartHeight="100%"
            chartPadding={{
              top: 10,
              bottom: 5,
              left: 5,
              right: 5,
            }}
            nodeSearchForm={{ dateSearch, aggFunc }}
          />
        );
      case 'FIELD_TAG_LIST':
        return renderTagList();
      case 'FIELD_3D':
        return (
          <GroupFieldThreeModel
            containerSx={{ mb: 3 }}
            autoRotate
            data={data}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            height={250}
          />
        );
      default:
        return null;
    }
  }, [showLastData, textFieldProp, data, renderTagList]);

  return (
    <Box component="div" display="flex" alignItems="center" sx={containerSx}>
      {showCheckBox && (
        <BaseCheckbox
          name={data?.field.code || ''}
          defaultChecked={defaultChecked}
          onChange={handleChecked}
          checked={checked}
        />
      )}
      <Box component="div" sx={{ width: '100%' }}>
        {render}
      </Box>
    </Box>
  );
}
