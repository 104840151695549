import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import ThreeModelCard from 'components/threemodel/ThreeModelCard';
import { SystemLayout } from 'layout/system';
import { useMutation, useQuery } from 'react-query';
import { createThreeAPI, deleteThreeAPI, findAllThreeAPI, modifyThreeAPI } from 'apis/threemodel';
import { useRecoilState } from 'recoil';
import { threeModelState } from 'states';
import ThreeModelSaveModal from 'components/threemodel/ThreeModelSaveModal';
import { IThreeModel, TThreemodelCreateRequest, TThreeModelResponse } from '@types';
import { toast } from 'react-toastify';
import BaseTablePagination from 'components/common/BaseTablePagination';
import usePageInfo from 'common/hooks/usePageInfo';

export default function ThreeModelPage() {
  const [threeModel, setThreeModel] = useRecoilState(threeModelState);
  const { data, refetch } = useQuery(['threemodels'], findAllThreeAPI);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<IThreeModel | null>(null);
  const { changePage, pageInfo } = usePageInfo({ defaultPage: 0, defaultSize: 10 });

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedModel(null);
  };

  const handleEdit = (data: IThreeModel) => {
    setSelectedModel(data);
    handleModalOpen();
  };

  const handleSave = (request: TThreemodelCreateRequest) => {
    if (request.id && request.id > 0) {
      modifyMutation.mutate(request);
    } else {
      createMutation.mutate(request);
    }
  };

  const handleDelete = (id: number) => {
    deleteMutation.mutate(id);
  };

  const onSaveSuccess = (result: TThreeModelResponse) => {
    if (result.success) {
      toast('3D 모델 저장 성공', { type: 'success' });
      handleModalClose();
      refetch();
    }
  };

  const onDeleteSuccess = (result: TThreeModelResponse) => {
    if (result.success) {
      toast('3D 모델 삭제 성공', { type: 'success' });
      handleModalClose();
      refetch();
    }
  };

  const renderThreeCards = useMemo(() => {
    return threeModel
      .slice(pageInfo.page * pageInfo.size, (pageInfo.page + 1) * pageInfo.size)
      .map((model) => (
        <ThreeModelCard key={model.id} autoRotate data={model} onEditClick={handleEdit} containerSx={{ mt: 1 }} />
      ));
  }, [pageInfo, threeModel]);

  const createMutation = useMutation(createThreeAPI, {
    onSuccess: onSaveSuccess,
  });

  const modifyMutation = useMutation(modifyThreeAPI, {
    onSuccess: onSaveSuccess,
  });

  const deleteMutation = useMutation(deleteThreeAPI, {
    onSuccess: onDeleteSuccess,
  });

  useEffect(() => {
    if (data?.data) {
      setThreeModel(data.data);
    }
  }, [data]);

  return (
    <SystemLayout title="3D 모델관리">
      <Button fullWidth variant="contained" onClick={handleModalOpen}>
        <Typography>모델추가</Typography>
      </Button>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        {renderThreeCards}
        <Grid item xs={12} sx={{ mt: 1 }}>
          <BaseTablePagination
            onPageChange={changePage}
            page={pageInfo.page}
            rowsPerPageOptions={[pageInfo.size]}
            rowsPerPage={pageInfo.size}
            totalCount={threeModel.length}
          />
        </Grid>
      </Grid>
      <ThreeModelSaveModal
        open={modalOpen}
        onClose={handleModalClose}
        onConfirm={handleSave}
        onDelete={handleDelete}
        data={selectedModel}
      />
    </SystemLayout>
  );
}
