import { Box, Card, Typography, useTheme } from '@mui/material';
import { TNodeType } from '@types';
import TagDataCard from 'components/data/TagDataCard';
import React, { useMemo } from 'react';
import BaseNodeType, { NodeDataProps } from './BaseNodeType';

type Props = {
  id: string;
  type: TNodeType;
  data?: NodeDataProps;
  xPos?: number;
  yPos?: number;
};

TagNodeType.defaultProps = {
  data: {},
  xPos: 0,
  yPos: 0,
};

export default function TagNodeType({ id, data, type, xPos, yPos }: Props) {
  const theme = useTheme();

  // 카드 클릭 이벤트
  const handleCardClick = () => {
    if (typeof data?.onClick === 'function') {
      data.onClick({
        id,
        data,
        type,
        position: { x: xPos || 0, y: yPos || 0 },
      });
    }
  };

  // Card 부분 렌더링
  const renderCard = useMemo(
    () => (
      <Card
        onClick={handleCardClick}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
          minWidth: 250,
          backgroundColor: theme.palette.mode === 'dark' ? 'rgb(10 12 45)' : '#fff',
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          {data?.label}
        </Typography>
        <Box component="div" display="flex" flexDirection="column" sx={{ p: 1, width: '100%' }}>
          {data?.tags?.map((tag) => (
            <TagDataCard
              key={tag.tag}
              cardVariant="outlined"
              title={tag.label}
              sx={{ p: 1 }}
              tags={[tag.tag]}
              valueTypoVariant="h5"
              showDtt={false}
              dateProps={data.search?.dateSearch ?? { type: 'dayLast' }}
              aggFunc={data.search?.aggFunc}
              showAlert={!data.search}
            />
          ))}
        </Box>
      </Card>
    ),
    [data, theme.palette.mode],
  );

  return (
    <BaseNodeType id={id} type={type} data={data} xPos={xPos} yPos={yPos}>
      {renderCard}
    </BaseNodeType>
  );
}
