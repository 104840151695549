import { Cancel, Delete, Edit, Save, TableView } from '@mui/icons-material';
import { Box, Card, Grid, IconButton, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { ChartType, TData1ChartResponse } from '@types';
import { ChartData, ScaleOptionsByType } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { AggUnitType, Data10AggType } from 'common/hooks/useTagChartData10';
import { getDateRange } from 'common/lib';
import BaseCheckbox from 'components/common/BaseCheckbox';
import DateSearchBox, { DateSearchProps } from 'components/common/DateSearchBox';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import AggTypeSelectBox from './AggTypeSelectBox';
import ChartTypeSelectBox from './ChartTypeSelectBox';
import DataGridModal from './DataGridModal';
import DataLineChart from './DataLineChart';
import DataLoadingBox from './DataLoadingBox';

export type Data10ChartCardProps = {
  id: string;
  tags: string[];
  dateProps: DateSearchProps;
  aggType?: Data10AggType;
  isIncrease?: boolean;
  lineColors?: string[];
  bgColors?: string[];
  width?: string | number;
  height?: string | number;
  label?: string;
  chartLabel?: string;
  chartWidth?: number | string;
  chartHeight?: number | string;
  onDelete?(id: string): void;
  onTitleChange?(id: string, title: string): void;
  onDatePropsChange?(id: string, dateProps: DateSearchProps): void;
  onAggTypeChange?(id: string, aggType: Data10AggType): void;
  onIsIncreaseChange?(id: string, isIncrease: boolean): void;
  onChartTypeChange?(id: string, chartType: ChartType | undefined): void;
  onAggUnitTypeChange?(id: string, aggUnitType: AggUnitType): void;
  onAggValChange?(id: string, aggVal: number): void;
  showControls?: boolean;
  showInitLoading?: boolean;
  showGridBtn?: boolean;
  useGridRowData?: boolean;
  chartType?: ChartType;
  aggUnit?: AggUnitType;
  aggVal?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  fill?: boolean;
  dataset?: ChartData<'line' | 'scatter' | 'bar', any>;
  annotation?: AnnotationOptions[];
  gridData?: TData1ChartResponse;
  isFetching?: boolean;
  scales?: ScaleOptionsByType<any>;
  showLoading?: boolean;
  showLegend?: boolean;
};

Data10ChartCard.defaultProps = {
  width: undefined,
  height: 200,
  chartLabel: '',
  aggType: 'mean',
  label: undefined,
  lineColors: undefined,
  bgColors: undefined,
  chartWidth: '100%',
  chartHeight: 120,
  onDelete: undefined,
  onTitleChange: undefined,
  onDatePropsChange: undefined,
  onAggTypeChange: undefined,
  onIsIncreaseChange: undefined,
  onChartTypeChange: undefined,
  onAggUnitTypeChange: undefined,
  onAggValChange: undefined,
  showControls: true,
  showInitLoading: false,
  showGridBtn: true,
  useGridRowData: true,
  isIncrease: false,
  chartType: 'line',
  aggUnit: undefined,
  aggVal: undefined,
  xs: 12,
  sm: 12,
  md: 12,
  lg: 6,
  xl: 6,
  fill: false,
  dataset: [],
  annotation: [],
  gridData: [],
  isFetching: false,
  scales: undefined,
  showLoading: true,
  showLegend: true,
};

export default function Data10ChartCard({
  id,
  width,
  height,
  onDelete,
  onTitleChange,
  onDatePropsChange,
  onAggTypeChange,
  onIsIncreaseChange,
  onChartTypeChange,
  onAggUnitTypeChange,
  onAggValChange,
  label,
  chartLabel,
  chartWidth,
  chartHeight,
  tags,
  dateProps,
  aggType,
  isIncrease,
  showControls,
  showGridBtn,
  useGridRowData,
  chartType,
  aggUnit,
  aggVal,
  xs,
  sm,
  md,
  lg,
  xl,
  dataset,
  annotation,
  gridData,
  isFetching,
  scales,
  showLoading,
  showLegend,
}: Data10ChartCardProps) {
  // 차트 삭제 이벤트
  const handleDelete = () => {
    if (typeof onDelete === 'function') {
      onDelete(id);
    }
  };

  // 차트 변경 모드 ON
  const handleOnTitleEditMode = () => {
    setIsTitleEditMode(true);
  };

  // 차트 변경 모드 OFF
  const handleOffTitleEditMode = () => {
    setIsTitleEditMode(false);
  };

  // 차트 변경 취소
  const handleTitleCancle = () => {
    setTitleState(label);
    setDateState(dateProps);
    setAggTypeState(aggType);
    setIsIncreaseState(isIncrease || false);
    setChartTypeState(chartType);
    setAggUnitState(aggUnit || 'minute');
    setAggValState(aggVal || 15);
    handleOffTitleEditMode();
  };

  // 취합 단위 변경 이벤트
  const handleAggTypeSelectChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setAggTypeState(value as Data10AggType);
  };

  // 적산값처리 Boolean 변경 이벤트
  const handleIsIncreaseChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setIsIncreaseState(checked);
  };

  // 차트 저장 이벤트
  const handleTitleSave = () => {
    if (typeof onTitleChange === 'function' && titleState) {
      onTitleChange(id, titleState);
    }

    if (typeof onDatePropsChange === 'function' && dateState) {
      onDatePropsChange(id, dateState);
    }

    if (typeof onAggTypeChange === 'function' && aggTypeState) {
      onAggTypeChange(id, aggTypeState);
    }

    if (typeof onIsIncreaseChange === 'function') {
      onIsIncreaseChange(id, isIncreaseState);
    }

    if (typeof onChartTypeChange === 'function') {
      onChartTypeChange(id, chartTypeState);
    }

    if (typeof onAggUnitTypeChange === 'function') {
      onAggUnitTypeChange(id, aggUnitState);
    }

    if (typeof onAggValChange === 'function') {
      onAggValChange(id, aggValState);
    }

    handleOffTitleEditMode();
  };

  // 날짜 변경 이벤트
  const handleDateChange = (changedDate: DateSearchProps) => {
    setDateState(changedDate);
  };

  const [isTitleEditMode, setIsTitleEditMode] = useState(false);
  const [openGrid, setOpenGrid] = useState<boolean>(false);
  const [titleState, setTitleState] = useState<string | undefined>(label);
  const [dateState, setDateState] = useState<DateSearchProps | undefined>(dateProps);
  const [aggTypeState, setAggTypeState] = useState<Data10AggType | undefined>(aggType);
  const [isIncreaseState, setIsIncreaseState] = useState<boolean>(false);
  const [chartTypeState, setChartTypeState] = useState<ChartType | undefined>(chartType);
  const [aggUnitState, setAggUnitState] = useState<AggUnitType>(aggUnit || 'minute');
  const [aggValState, setAggValState] = useState<number>(aggVal || 15);

  // Grid Open
  const handleOpenGrid = () => {
    setOpenGrid(true);
  };

  // Grid Close
  const handleCloseGrid = () => {
    setOpenGrid(false);
  };

  // 날짜 텍스트 메모
  const dateText = useMemo(() => {
    const changeDurationName = (durationUnit: string | undefined) => {
      if (durationUnit === 'hour') {
        return '시간';
      }
      if (durationUnit === 'day') {
        return '일';
      }
      if (durationUnit === 'month') {
        return '월';
      }
      if (durationUnit === 'year') {
        return '년';
      }

      return '';
    };

    if (dateProps.type === 'prevDay') {
      return '전일대비 시간별조회';
    }
    if (dateProps.type === 'prevMonth') {
      return '전월대비 일별조회';
    }
    if (dateProps.type === 'prevYear') {
      return '전년대비 월별조회';
    }
    if (dateProps.type === 'currentDay') {
      return '일일조회';
    }
    if (dateProps.type === 'currentMonth') {
      return '금월조회';
    }
    if (dateProps.type === 'currentYear') {
      return '금년조회';
    }

    return `${dateProps.duration} ${changeDurationName(dateProps.durationUnit)}전부터 현재까지`;
  }, [dateProps]);

  // 타이틀명
  const titleText = useMemo(
    () => titleState || `${tags.join(', ')} 차트 (${aggType?.toUpperCase()})`,
    [titleState, tags, aggType],
  );

  useEffect(() => {
    setTitleState(label);
  }, [label]);

  useEffect(() => {
    setDateState(dateProps);
  }, [dateProps]);

  useEffect(() => {
    setAggTypeState(aggType);
  }, [aggType]);

  useEffect(() => {
    setIsIncreaseState(isIncrease || false);
  }, [isIncrease]);

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} sx={{ width }}>
      <Box component="div" display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
        {showControls && (
          <>
            {isTitleEditMode ? (
              <Box component="div">
                <Box component="div">
                  <DateSearchBox {...dateState} onChange={handleDateChange} />
                </Box>
                <Box component="div" display="flex" flexDirection="column" sx={{ m: 0.5 }}>
                  <Box component="div" display="flex" flexDirection="row">
                    <TextField
                      value={titleState}
                      variant="standard"
                      fullWidth
                      placeholder={label}
                      label="차트이름"
                      onChange={(e) => setTitleState(e.target.value)}
                      sx={{ mr: 0.5 }}
                    />
                    {onChartTypeChange && (
                      <ChartTypeSelectBox
                        name="chartType"
                        sx={{ minWidth: 100, mr: 2 }}
                        fullWidth={false}
                        value={chartTypeState}
                        onChange={(e) => setChartTypeState(e.target.value as ChartType | undefined)}
                      />
                    )}
                    {onAggTypeChange && (
                      <AggTypeSelectBox
                        name="aggType"
                        label="데이터유형"
                        defaultValue={aggType || 'mean'}
                        value={aggTypeState}
                        onChange={handleAggTypeSelectChange}
                      />
                    )}
                  </Box>
                  <Box component="div" display="flex" flexDirection="row" alignItems="center">
                    {onIsIncreaseChange && (
                      <BaseCheckbox
                        name="isIncrease"
                        label="적산값 처리"
                        defaultChecked={isIncrease}
                        checked={isIncreaseState}
                        onChange={handleIsIncreaseChange}
                      />
                    )}

                    <IconButton color="success" onClick={handleTitleSave}>
                      <Save />
                    </IconButton>
                    <IconButton color="error" onClick={handleTitleCancle}>
                      <Cancel />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box component="div" display="flex" flexDirection="column">
                <Box component="div">
                  <Typography variant="caption">{dateText}</Typography>
                </Box>
                <Typography fontWeight="bold">{titleText}</Typography>
              </Box>
            )}
            {isTitleEditMode || (
              <Box
                component="div"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
                justifyItems="center"
              >
                {onTitleChange && (
                  <IconButton onClick={handleOnTitleEditMode}>
                    <Edit />
                  </IconButton>
                )}
                {onDelete && (
                  <IconButton onClick={handleDelete}>
                    <Delete />
                  </IconButton>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
      <Card
        sx={{
          height,
          position: 'relative',
        }}
      >
        {showGridBtn && (
          <Box component="div" sx={{ position: 'absolute', top: 5, left: 5 }}>
            <IconButton onClick={handleOpenGrid} size="small">
              <TableView fontSize="small" />
            </IconButton>
          </Box>
        )}
        {showLoading && <DataLoadingBox isLoading={isFetching} />}
        <DataLineChart
          label={chartLabel}
          width={chartWidth}
          height={chartHeight}
          dataset={dataset}
          annotation={annotation}
          showLegend={showLegend ?? (dataset?.datasets.length || 0) > 1}
          stacked={chartType === 'stackedBar'}
          aggUnit={aggUnit}
          scales={scales}
          showX
          showY
        />
      </Card>
      <DataGridModal
        title="그리드 조회"
        open={openGrid}
        onClose={handleCloseGrid}
        data={useGridRowData ? undefined : gridData}
        search={{
          tagTypes: tags.map((tag) => ({ aggFunc: 'mean', tag })),
          isRowData: true,
          ...getDateRange(dateProps),
        }}
      />
    </Grid>
  );
}
