import React, { ChangeEvent } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, SxProps, Theme } from '@mui/material';
import { IRole } from '@types';

type Props = {
  defaultValue?: string;
  value?: string;
  name?: string;
  data?: IRole[];
  showLabel?: boolean;
  sx?: SxProps<Theme>;
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
};

RoleRadioGroup.defaultProps = {
  name: undefined,
  showLabel: false,
  defaultValue: undefined,
  value: undefined,
  onChange: undefined,
  sx: {},
  data: [],
};

export default function RoleRadioGroup({ defaultValue, name, value, data, sx, showLabel, onChange }: Props) {
  return (
    <FormControl>
      {showLabel && <FormLabel>권한</FormLabel>}
      <RadioGroup
        name={name}
        sx={{ display: 'flex', flexDirection: 'row', ...sx }}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
      >
        {data?.map((role) => (
          <FormControlLabel key={role.roleCode} label={role.roleCode} control={<Radio value={role.roleCode} />} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
