import React, { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { SystemLayout } from 'layout/system';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { matterportState } from 'states';
import _ from 'lodash';
import MatterportItemBox from 'components/matterportitem/MatterportItemBox';

export default function MatterportDetailPage() {
  const { matterportIdx } = useParams();

  const matterports = useRecoilValue(matterportState);

  const matterport = useMemo(
    () => _.find(matterports, (data) => data.idx === Number(matterportIdx)),
    [matterports, matterportIdx],
  );

  const renderMatterport = useMemo(() => matterport && <MatterportItemBox data={matterport} />, [matterport]);

  return (
    <SystemLayout title="메타포트 상세관리">
      <Box component="div" sx={{ width: '100%', height: '80vh' }}>
        {renderMatterport}
      </Box>
    </SystemLayout>
  );
}
