import React, { ReactElement, useMemo, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Chip,
  IconButton,
  useTheme,
} from '@mui/material';
import { Add, ArrowDownward, ArrowUpward } from '@mui/icons-material';
import moment from 'moment';
import { TPageUserResponse, ISort } from '@types';
import BaseTablePagination from 'components/common/BaseTablePagination';

interface Column {
  id: keyof IUserGridData;
  label: string;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
  render?: (data: any) => ReactElement;
  sortable?: boolean;
}

export interface IUserGridData {
  id: number;
  userId: string;
  name: string;
  dept: string;
  role: string;
  site: string;
  useYN: string;
  created: string;
  lastLogin: string;
}

interface ButtonRendererProps {
  value: any;
  onClick?(): void;
}

const buttonRenderer = (data: ButtonRendererProps) => <Button onClick={data.onClick}>{data.value}</Button>;

function createData(
  id: number,
  userId: string,
  name: string,
  dept: string,
  role: string,
  site: string,
  useYN: boolean,
  created: string,
  lastLogin: string,
): IUserGridData {
  return { id, userId, name, dept, role, site, useYN: useYN ? 'Y' : 'N', created, lastLogin };
}

const columns: readonly Column[] = [
  { id: 'id', label: 'ID', minWidth: 50, sortable: true },
  { id: 'userId', label: 'UserID', minWidth: 120, render: buttonRenderer, sortable: true },
  { id: 'name', label: 'Name', minWidth: 100, sortable: true },
  { id: 'dept', label: 'Dept', minWidth: 100, sortable: true },
  {
    id: 'role',
    label: 'Role',
    sortable: true,
  },
  {
    id: 'site',
    label: 'Site',
    sortable: true,
    minWidth: 170,
    align: 'center',
  },
  {
    id: 'useYN',
    label: 'UseYN',
    sortable: true,
    minWidth: 100,
    align: 'center',
  },
  { id: 'created', label: 'CreatedAt', align: 'center' },
  { id: 'lastLogin', label: 'Last Login', align: 'center' },
];

type Props = {
  onClick?(data: IUserGridData): void;
  page?: number;
  rowPerPage?: number;
  data?: TPageUserResponse;
  onChangePage?(page: number): void;
  onChangeRowPerPage?(rowPerPage: number): void;
  onChangeSort?(sorts: ISort[]): void;
};

UserDataGrid.defaultProps = {
  data: [],
  onClick: undefined,
  onChangePage: undefined,
  onChangeRowPerPage: undefined,
  onChangeSort: undefined,
  page: 0,
  rowPerPage: 10,
};

export default function UserDataGrid({
  onClick,
  data,
  page,
  rowPerPage,
  onChangePage,
  onChangeRowPerPage,
  onChangeSort,
}: Props) {
  const theme = useTheme();
  const rows = useMemo(
    () =>
      data?.data?.content?.map((user) =>
        createData(
          user.id ?? 0,
          user.userId,
          user.name,
          user.dept ?? '',
          user.role,
          user.site?.siteName ?? '',
          user.useYN,
          user.createdAt ? moment(user.createdAt).format('YYYY-MM-DD HH:mm') : '',
          user.lastLogin || '',
        ),
      ),
    [data],
  );

  const renderCell = (row: any, column: Column) => {
    const value = row[column.id];
    const newValue = column.format && typeof value === 'number' ? column.format(value) : value;

    if (column.render) {
      switch (column.id) {
        case 'id':
          return column.render(newValue);
        case 'userId':
          return column.render({ value: newValue, onClick: () => typeof onClick === 'function' && onClick(row) });
        default:
          return newValue;
      }
    }

    return newValue;
  };

  const renderSortAddButton = (column: Column) => {
    if (!column.sortable) return null;

    const sorted = findSort(column.id);

    if (!sorted) {
      return (
        <IconButton size="small" onClick={() => addSort({ name: column.id, sort: 'ASC' })}>
          <Add />
        </IconButton>
      );
    }

    return null;
  };

  const renderSortChangeButton = (sort: ISort) => {
    if (sort.sort === 'ASC') {
      return (
        <IconButton size="small" onClick={() => changeSort({ ...sort, sort: 'DESC' })}>
          <ArrowUpward />
        </IconButton>
      );
    }

    return (
      <IconButton size="small" onClick={() => changeSort({ ...sort, sort: 'ASC' })}>
        <ArrowDownward />
      </IconButton>
    );
  };

  const addSort = (sort: ISort) => {
    setSorts((prev) => [...prev, sort]);
  };

  const deleteSort = (sort: ISort) => {
    setSorts((prev) => prev.filter((tSort) => tSort.name !== sort.name));
  };

  const changeSort = (sort: ISort) => {
    const newSort = sorts.map((st) => (st.name === sort.name ? sort : st));
    setSorts(newSort);

    if (typeof onChangeSort === 'function') {
      onChangeSort(newSort);
    }
  };

  const findSort = (name: string) => sorts.find((prev) => prev.name === name);

  const [sorts, setSorts] = useState<ISort[]>([]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box component="div" p={1}>
        {sorts.map((sort) => (
          <Chip
            key={sort.name}
            label={sort.name}
            icon={renderSortChangeButton(sort)}
            onDelete={() => deleteSort(sort)}
          />
        ))}
      </Box>
      <TableContainer sx={{ maxHeight: 500, minHeight: 500 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    backgroundColor: theme.palette.mode === 'light' ? '#f7fafc !important' : '#0f1018 !important',
                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                  }}
                >
                  {column.label}
                  {renderSortAddButton(column)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    return (
                      <TableCell key={column.id} align={column.align} sx={{ paddingY: 0 }}>
                        {renderCell(row, column)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <BaseTablePagination
        totalCount={data?.data?.totalElements}
        rowsPerPage={rowPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowPerPage}
      />
    </Paper>
  );
}
