import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { IThreeNode } from '@types';
import GroupFieldDataRenderBox from 'components/group/GroupFieldDataRenderBox';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { animated, easings, useSpring } from 'react-spring';
import { useSetRecoilState } from 'recoil';
import { selectedThreeNodeState } from 'states';

type Props = {
  data: IThreeNode[];
};

export default function ThreeNodePagingBox({ data }: Props) {
  const theme = useTheme();
  // 선택된 Three Node Recoil 상태
  const setSelectedThreeNode = useSetRecoilState(selectedThreeNodeState);
  // 현재 위치 노트
  const [currentPage, setCurrentPage] = useState<number>(0);
  // Paging 노드들
  const pagingNodes = useMemo(() => data.filter((node) => !node.model.isDecoration), [data]);
  // Drawer 오픈 상태
  const [open, setOpen] = useState<boolean>(false);

  // dark <-> white 모드에 따른 Box 배경색상 변경
  const boxColor = useMemo(() => {
    const { mode } = theme.palette;

    if (mode === 'dark') return 'rgba(50, 50, 50, 0.3)';

    return 'rgba(255, 255, 255, 0.5)';
  }, [theme.palette.mode]);

  const anim = useSpring({
    size: open ? 30 : 1,
    config: {
      easing: easings.easeInOutBounce,
    },
  });

  const pageNode = useMemo(() => pagingNodes?.at(currentPage), [pagingNodes, currentPage]);

  const moveNext = () => {
    if (currentPage >= pagingNodes.length - 1) {
      setCurrentPage(0);
    } else {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const movePrev = () => {
    if (currentPage <= 0) {
      setCurrentPage(pagingNodes.length - 1);
    } else {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const renderDrawer = useMemo(
    () =>
      pageNode && (
        <AnimeBox
          component="div"
          height="90%"
          style={{
            width: anim.size.to((size) => `${size}%`),
            minWidth: 25,
          }}
          sx={{
            position: 'absolute',
            backgroundColor: boxColor,
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'row',
            right: 10,
            bottom: 10,
            borderRadius: 2,
          }}
        >
          <Box
            component="div"
            sx={{
              width: 25,
              height: '100%',
              backgroundColor: boxColor,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setOpen((prev) => !prev)}>{open ? <ArrowRight /> : <ArrowLeft />}</IconButton>
          </Box>
          {open && (
            <Box component="div" display="flex" flexDirection="column" flex={15} sx={{ overflowY: 'auto', padding: 1 }}>
              {_.sortBy(pageNode?.group?.groupFieldDatas, (gfd) => gfd.field.orderNum).map((gfd) => (
                <GroupFieldDataRenderBox
                  key={gfd.field.id}
                  exceptCodes={['FIELD_3D']}
                  data1Duration="10m"
                  data={gfd}
                  containerSx={{ width: '100%' }}
                />
              ))}
            </Box>
          )}
        </AnimeBox>
      ),
    [open, pageNode, anim],
  );

  useEffect(() => {
    if (pageNode) {
      setSelectedThreeNode(pageNode);
    }
  }, [pageNode]);

  useEffect(() => {
    setSelectedThreeNode(undefined);
  }, []);

  return (
    <>
      {renderDrawer}
      <Box
        component="div"
        width="100%"
        height={60}
        sx={{
          position: 'relative',
          backgroundColor: boxColor,
          marginTop: -7.5,
          zIndex: 999,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box component="div" display="flex" alignItems="center" sx={{ mt: -1 }}>
          <IconButton onClick={movePrev}>
            <ArrowLeft fontSize="large" />
          </IconButton>
          <Typography fontWeight="bold">{`${currentPage + 1} / ${pagingNodes?.length}`}</Typography>
          <IconButton onClick={moveNext}>
            <ArrowRight fontSize="large" />
          </IconButton>
        </Box>
        <Box component="div" sx={{ mt: -1 }}>
          <Typography fontWeight="bold">{pageNode?.name}</Typography>
        </Box>
      </Box>
    </>
  );
}

const AnimeBox = animated(Box);
