import { ENDPOINT } from '@const';
import { TNodeCreateRequest, TNodeResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

// Node 생성 API
export const createNodeAPI = async (request: TNodeCreateRequest): Promise<TNodeResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.node.createNode,
      ...createAuth(),
      data: { ...request, data: JSON.stringify(request.data) },
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('노드 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

// Node 수정 API
export const modifyNodeAPI = async (request: TNodeCreateRequest): Promise<TNodeResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.node.modifyNode,
      ...createAuth(),
      data: { ...request, data: JSON.stringify(request.data) },
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('노드 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

// Node List 수정 API
export const modifyAllNodeAPI = async (request: TNodeCreateRequest[]) => {
  const result = await axios
    .request({
      ...ENDPOINT.node.modifyAllNode,
      ...createAuth(),
      data: { nodes: request.map((req) => ({ ...req, data: JSON.stringify(req.data) })) },
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('노드 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteNodeAPI = async (id: string): Promise<TNodeResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.node.deleteNode,
      ...createAuth(),
      url: ENDPOINT.node.deleteNode.url.replace('{id}', id),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('노드 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};
