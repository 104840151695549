import { Box, Typography } from '@mui/material';
import { IGroupFieldData, IGroupType, TGroupFieldDataCreateRequest } from '@types';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatGroupTypeState } from 'states';
import GroupFieldDataInput from './GroupFieldDataInput';

type Props = {
  // 어떠한 그룹유형인지 -> 그룹유형 하위 그룹필드 조회에 사용
  typeCode?: string;
  // Box 라벨
  label?: string;
  // 기본 그룹필드데이터 리스트
  data?: IGroupFieldData[];
  // 그룹필드데이터 리스트 변경 이벤트
  onChangeData?(change: TGroupFieldDataCreateRequest[]): void;
};

GroupFieldDataBox.defaultProps = {
  label: undefined,
  typeCode: undefined,
  data: [],
  onChangeData: undefined,
};

export default function GroupFieldDataBox({ label, typeCode, data, onChangeData }: Props) {
  const findGroupType = (code: string | undefined) => {
    const selectedType = _.find(flatGroupTypes, (groupType) => groupType.code === code);
    return selectedType;
  };

  // DTO 데이터를 CreateRequestDTO 데이터로 변환 후 memo
  const convertData: TGroupFieldDataCreateRequest[] = useMemo(
    () => data?.map((dt) => ({ ...dt, field: dt.field.id, alarm: dt.alarm?.id })) || [],
    [data],
  );

  // InputField 데이터 변경 이벤트
  const onChange = (changed: TGroupFieldDataCreateRequest) => {
    setState((prev) =>
      prev.map((fieldData) => (fieldData.field === changed.field ? { ...changed } : { ...fieldData })),
    );
  };

  // fieldId를 통해, FieldData를 찾아주는 함수
  const findFieldData = (fieldId: number | undefined) => {
    const selectedFieldData = _.find(convertData ?? [], (groupFieldData) => groupFieldData.field === fieldId);
    return selectedFieldData;
  };

  const [state, setState] = useState<TGroupFieldDataCreateRequest[]>([]);

  const flatGroupTypes = useRecoilValue(flatGroupTypeState);

  const groupType = useMemo((): IGroupType | undefined => findGroupType(typeCode), [flatGroupTypes, typeCode]);

  /**
   * GRoupFieldData 초기화
   */
  const init = useCallback(() => {
    if (groupType) {
      // 선택된 그룹유형을 통해 필요한 그룹필드 데이터 초기화
      let newState: TGroupFieldDataCreateRequest[] = groupType.fields.map((field) => ({
        id: undefined,
        data: undefined,
        field: field.id,
      }));

      // 이미 그룹에 그룹필드데이터가 존재할 경우, 현재 선택된 그룹유형과 비교하여, 세팅
      if (convertData && convertData.length > 0) {
        newState = newState.map((field) => {
          const findedFiledData = findFieldData(field.field);
          return findedFiledData ? { ...findedFiledData } : { ...field };
        });
      }

      setState(newState);
    }
  }, [groupType, convertData]);

  // Field Input 렌더링 함수
  const renderFields = useMemo(() => {
    const now = moment().format('x');
    return _.sortBy(groupType?.fields, (field) => field.orderNum).map((field) => (
      <GroupFieldDataInput
        key={`${field.id}_${now}`}
        field={field}
        onChange={onChange}
        data={findFieldData(field.id)}
      />
    ));
  }, [groupType, convertData]);

  // 데이터 변경 시, onChange 이벤트 동작
  useEffect(() => {
    if (state && typeof onChangeData === 'function') {
      onChangeData(state);
    }
  }, [state]);

  useEffect(() => {
    init();
  }, [init]);

  // FieldData 가 하나도 존재하지 않을 경우, 비어있는 Box Rendering
  return (groupType?.fields.length ?? 0) > 0 ? (
    <Box component="div" sx={{ mt: 1 }}>
      {label && (
        <Typography color="#666" variant="caption">
          {label}
        </Typography>
      )}
      <Box component="div" sx={{ p: 1 }}>
        {renderFields}
      </Box>
    </Box>
  ) : (
    <Box component="div" />
  );
}
