import React, { useCallback, useState } from 'react';
import { MarkerClusterer } from '@react-google-maps/api';
import useBaseModalControl from 'common/hooks/useBaseModalControl';
import GroupInfoModal from 'components/group/GroupInfoModal';
import { IGroup, ILocation } from '@types';
import useGroupThreeInfo from 'common/hooks/useGroupThreeInfo';
import { Box } from '@mui/material';
import GroupMarker from './GroupMarker';
import Map, { IMapProps } from './Map';
import GroupNavigation from './GroupNavigation';

type Props = IMapProps & {
  groups: number[];
  showGroupModel?: boolean;
  defaultZoom?: number;
};

GroupMap.defaultProps = {
  showGroupModel: false,
  defaultZoom: 18,
};

export default function GroupMap({ groups, showGroupModel, defaultZoom, ...others }: Props) {
  const { onOpen, ...modalControl } = useBaseModalControl();
  const [selectedGroup, setSelectedGroup] = useState<IGroup | undefined>();
  const [selectedLocation, setSelectedLocation] = useState<ILocation | undefined>(undefined);

  const threeInfos = useGroupThreeInfo({ groups });

  const handleClickGroupInfo = useCallback(
    (group: IGroup) => {
      setSelectedGroup(group);
      onOpen();
    },
    [onOpen],
  );

  const createMarkers = useCallback(
    (cluster: any) =>
      groups?.map((gr) => (
        <GroupMarker key={gr} groupId={gr} clusterer={cluster} onClickGroupInfo={handleClickGroupInfo} />
      )),
    [groups, handleClickGroupInfo],
  );

  return (
    <Box component="div" position="relative" sx={{ ...others.containerStyle, overflow: 'hidden' }}>
      <GroupNavigation
        groupIds={groups ?? []}
        onClickGroupsLocation={setSelectedLocation}
        onClickGroup={handleClickGroupInfo}
      />
      <Map
        {...others}
        defaultZoom={defaultZoom}
        mapThrees={showGroupModel ? threeInfos : []}
        moveLocation={selectedLocation}
      >
        <MarkerClusterer maxZoom={15} averageCenter enableRetinaIcons>
          {(cluster) => <>{createMarkers(cluster)}</>}
        </MarkerClusterer>
      </Map>
      <GroupInfoModal group={selectedGroup} {...modalControl} />
    </Box>
  );
}
