import { Box, Card, Typography, useTheme } from '@mui/material';
import { TNodeType } from '@types';
import GroupFieldDataRenderBox from 'components/group/GroupFieldDataRenderBox';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { flatGroupState } from 'states';
import BaseNodeType, { NodeDataProps } from './BaseNodeType';

type Props = {
  id: string;
  type: TNodeType;
  data?: NodeDataProps;
  xPos?: number;
  yPos?: number;
};

GroupNodeType.defaultProps = {
  data: {},
  xPos: 0,
  yPos: 0,
};

export default function GroupNodeType({ id, data, type, xPos, yPos }: Props) {
  const theme = useTheme();

  // group recoil value 조회
  const groups = useRecoilValue(flatGroupState);

  // 선택된 그룹
  const selectedGroup = useMemo(
    () => _.find(groups, (group) => group.groupCode === data?.group?.groupCode),
    [data, groups],
  );

  // GroupField Visible Data
  const gfv = useMemo(() => {
    if (data?.group?.gf) {
      return data.group.gf;
    }
    return {};
  }, [data]);

  // 카드 클릭 이벤트
  const handleCardClick = () => {
    if (typeof data?.onClick === 'function') {
      data.onClick({
        id,
        data,
        type,
        position: { x: xPos || 0, y: yPos || 0 },
      });
    }
  };

  // Card 부분 렌더링
  const renderCard = useMemo(
    () => (
      <Card
        onClick={handleCardClick}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
          minWidth: 250,
          backgroundColor: theme.palette.mode === 'dark' ? 'rgb(10 12 45)' : '#fff',
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          {data?.label}
        </Typography>
        <Box component="div" display="flex" flexDirection="column" sx={{ p: 1, width: '100%' }}>
          {_.sortBy(selectedGroup?.groupFieldDatas, (gfd) => gfd.field.orderNum)
            .filter((gfd) => gfv[gfd.field.code] || false)
            .map((gfd) => (
              <GroupFieldDataRenderBox
                key={gfd.field.id}
                showLastData
                showDtt={false}
                containerSx={{ mt: 1 }}
                data={gfd}
                textFieldProp={{ variant: 'standard' }}
                dateSearch={data?.search?.dateSearch}
                aggFunc={data?.search?.aggFunc}
              />
            ))}
        </Box>
      </Card>
    ),
    [selectedGroup, data, gfv, theme.palette.mode],
  );

  return (
    <BaseNodeType id={id} type={type} data={data} xPos={xPos} yPos={yPos}>
      {renderCard}
    </BaseNodeType>
  );
}
