import { Search } from '@mui/icons-material';
import { Box, IconButton, SxProps, TextField, TextFieldProps, Theme } from '@mui/material';
import { IGroup } from '@types';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatGroupState, groupState } from 'states';
import GroupSelectModal from './GroupSelectModal';

type Props = Omit<TextFieldProps, 'defaultValue' | 'onChange'> & {
  containerSx?: SxProps<Theme>;
  defaultValue?: string;
  onChange?(name?: string, groupCode?: string): void;
  disableSearch?: boolean;
};

GroupSelectInput.defaultProps = {
  containerSx: {},
  defaultValue: undefined,
  onChange: undefined,
  disableSearch: false,
};

export default function GroupSelectInput({
  name,
  containerSx,
  onChange,
  defaultValue,
  disableSearch,
  ...others
}: Props) {
  const flatGroups = useRecoilValue(flatGroupState);
  const groups = useRecoilValue(groupState);

  const [selectedCode, setSelectedCode] = useState<string | undefined>(defaultValue);

  const selectedGroup = useMemo(
    () => _.find(flatGroups, (group) => group.groupCode === selectedCode),
    [flatGroups, selectedCode],
  );

  const selectedTitle = useMemo(
    () => (selectedGroup ? `${selectedGroup?.site.siteCode} | ${selectedGroup?.groupName}` : ''),
    [selectedGroup],
  );

  const [open, setOpen] = useState<boolean>(false);

  const handleSelect = (groups: IGroup[]) => {
    const group = groups.at(0);
    setSelectedCode(group?.groupCode);
    setOpen(false);

    handleChange(group?.groupCode);
  };

  const handleChange = (groupCode: string | undefined) => {
    if (typeof onChange === 'function' && groupCode) {
      onChange(name, groupCode);
    }
  };

  return (
    <Box component="div" display="flex" flexDirection="row" alignItems="center" sx={containerSx}>
      <TextField variant="standard" fullWidth {...others} value={selectedTitle} />
      <IconButton onClick={() => setOpen(true)} disabled={disableSearch}>
        <Search />
      </IconButton>
      <GroupSelectModal
        singleSelect
        open={open}
        data={groups}
        onConfirm={handleSelect}
        onClose={() => setOpen(false)}
      />
    </Box>
  );
}
