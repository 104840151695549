import { ENDPOINT } from '@const';
import { TProductCreateRequest, TProductListResponse, TProductResponse } from '@types';
import axios from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const saveProductAPI = async (request: TProductCreateRequest): Promise<TProductResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.product.saveProduct,
      ...createAuth(),
      data: request,
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('제품 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const findAllProductAPI = async (siteCode?: string): Promise<TProductListResponse> => {
  if (!siteCode) return { data: [], success: true };

  const result = await axios
    .request({
      ...ENDPOINT.product.findAllProduct,
      ...createAuth(),
      url: ENDPOINT.product.findAllProduct.url.replace('{siteCode}', siteCode),
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('제품 조회 실패');

  toastErrorMessage(result.data);

  return result.data;
};

type DeleteProductAPIProps = {
  siteCode: string;
  id: number;
};

export const deleteProductAPI = async (request: DeleteProductAPIProps): Promise<TProductResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.product.deleteProduct,
      ...createAuth(),
      url: ENDPOINT.product.deleteProduct.url
        .replace('{siteCode}', request.siteCode)
        .replace('{id}', request.id.toString()),
    })
    .catch((e) => checkError(e));

  if (!result) throw Error('제품 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};
