import { Data10AggType } from 'common/hooks/useTagChartData10';
import BaseSelectBox, { BaseSelectBoxProps } from 'components/common/BaseSelectBox';
import React from 'react';

export type AggTypeSelectBoxProps = Omit<BaseSelectBoxProps, 'value'> & {
  value?: Data10AggType;
};

AggTypeSelectBox.defaultProps = {
  value: 'mean',
};

export default function AggTypeSelectBox({ value, ...others }: AggTypeSelectBoxProps) {
  return (
    <BaseSelectBox
      label="데이터유형"
      value={value}
      items={[
        { id: 'mean', name: 'MEAN' },
        { id: 'min', name: 'MIN' },
        { id: 'max', name: 'MAX' },
        { id: 'sum', name: 'SUM' },
        { id: 'count', name: 'COUNT' },
      ]}
      {...others}
    />
  );
}
