import React, { useMemo } from 'react';
import {
  FormControl,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material';

export type SelectItemProps = {
  id: string;
  name: string;
};

export type BaseSelectBoxProps = {
  label?: string;
  name?: string;
  required?: boolean;
  fullWidth?: boolean;
  defaultValue?: string;
  variant?: 'standard' | 'outlined';
  value?: string;
  items?: SelectItemProps[];
  onChange?(e: SelectChangeEvent): void;
  sx?: SxProps<Theme>;
  inputProps?: InputBaseComponentProps;
  showEmpty?: boolean;
};

BaseSelectBox.defaultProps = {
  label: undefined,
  name: undefined,
  required: false,
  fullWidth: true,
  variant: 'standard',
  defaultValue: undefined,
  value: undefined,
  items: [],
  onChange: undefined,
  sx: {},
  inputProps: undefined,
  showEmpty: false,
};

export default function BaseSelectBox({
  label,
  items,
  variant,
  required,
  defaultValue,
  fullWidth,
  value,
  name,
  sx,
  inputProps,
  onChange,
  showEmpty,
}: BaseSelectBoxProps) {
  const renderMenuItem = useMemo(
    () =>
      items?.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      )),
    [items],
  );

  return (
    <FormControl sx={sx} required={required} fullWidth={fullWidth} variant={variant}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        required={required}
        defaultValue={defaultValue}
        value={value}
        name={name}
        onChange={onChange}
        inputProps={inputProps ?? {}}
      >
        {showEmpty && (
          <MenuItem key="empty_menu" value="">
            미선택
          </MenuItem>
        )}
        {renderMenuItem}
      </Select>
    </FormControl>
  );
}
