import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { getEdgeCenter, getSmoothStepPath } from 'react-flow-renderer';

const foreignObjectSize = 40;

type ButtonEdgeDataProps = {
  isEditMode?: boolean;
  onDelete?(id: string | undefined): void;
};

type Props = any & {
  data?: ButtonEdgeDataProps;
};

ButtonEdge.defaultProps = {
  data: {},
};

export default function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data = {},
  markerEnd,
}: Props) {
  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const handleDelete = () => {
    if (typeof data?.onDelete === 'function') {
      data.onDelete(id);
    }
  };

  return (
    <>
      <path id={id} style={style} d={edgePath} markerEnd={markerEnd} className="react-flow__edge-path" />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        {data.isEditMode && (
          <IconButton size="small" onClick={handleDelete}>
            <Delete />
          </IconButton>
        )}
      </foreignObject>
    </>
  );
}
