import BaseDataGrid, { TDataGridColumn } from 'components/common/BaseDataGrid';
import React, { useMemo } from 'react';

export type TExcelTagConvertData = {
  index: number;
  tag: string;
  dtt: string;
  value: number;
};

type Props = {
  rows?: TExcelTagConvertData[];
};

ExcelTagDataGrid.defaultProps = {
  rows: [],
};

export default function ExcelTagDataGrid({ rows }: Props) {
  const columns = useMemo(
    (): TDataGridColumn<TExcelTagConvertData>[] => [
      { key: 'index', name: 'Index' },
      { key: 'tag', name: '태그코드' },
      { key: 'dtt', name: '일시' },
      {
        key: 'value',
        name: '값',
      },
    ],
    [],
  );

  return <BaseDataGrid<TExcelTagConvertData> columns={columns} rows={rows} style={{ height: 350 }} />;
}
