import { Add, Refresh, Save, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, SxProps, Theme, Typography } from '@mui/material';
import { ThreeEvent } from '@react-three/fiber';
import { IThreeCanvas, IThreeModel, IThreeNode, TThreeNodeCreateRequest, TThreeNodeListModifyRequest } from '@types';
import BaseCanvas from 'components/three/BaseCanvas';
import ThreeModelSelectDrawer from 'components/threemodel/ThreeModelSelectDrawer';
import ThreeNodeModel from 'components/threenode/ThreeNodeModel';
import ThreeNodeSaveModal from 'components/threenode/ThreeNodeSaveModal';
import _ from 'lodash';
import moment from 'moment';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Euler, Object3D, Vector3 } from 'three';
import ThreeNodePagingBox from './ThreeNodePagingBox';

type Props = {
  data?: IThreeCanvas;
  hideControls?: boolean;
  onAddNode?(node: TThreeNodeCreateRequest): void;
  onDeleteNode?(id: number): void;
  onSaveAll?(nodeList: TThreeNodeListModifyRequest): void;
  onRefresh?(): void;
  readonly?: boolean;
  sx?: SxProps<Theme>;
};

ModelEditCanvas.defaultProps = {
  data: undefined,
  hideControls: false,
  onAddNode: undefined,
  onDeleteNode: undefined,
  onSaveAll: undefined,
  onRefresh: undefined,
  readonly: false,
  sx: {
    height: '72vh',
    mt: 1,
  },
};

export default function ModelEditCanvas({
  data,
  hideControls,
  onAddNode,
  onDeleteNode,
  onSaveAll,
  onRefresh,
  readonly,
  sx,
}: Props) {
  const [state, setState] = useState<IThreeCanvas | undefined>(undefined);
  // 선택된 3D 모델
  const [selectedModel, setSelectedModel] = useState<IThreeModel | null>(null);
  // 선택된 Node ( Transform Controls)
  const [selectedNode, setSelectedNode] = useState<IThreeNode | undefined>(undefined);
  // 선택된 3D Object ( Transform Contorls )
  const [selectedObj, setSelectedObj] = useState<Object3D | undefined>(undefined);
  // 수정된 Node ID List
  const [modifiedList, setModifiedList] = useState<number[]>([]);
  // Model Select Drawer Open State
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  // Save Modal Open State
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // Modal Data
  const [modalNode, setModalNode] = useState<Partial<IThreeNode> | undefined>(undefined);
  // 소품 보이기/숨기기
  const [showDeco, setShowDeco] = useState<boolean>(true);
  // 위치이동 그리드 상세움직임
  const [showDetailGrid, setShowDetailGrid] = useState<boolean>(false);
  // 위치이동 보이기/숨기기
  const [showTransform, setShowTransform] = useState<boolean>(true);
  const [showPlane, setShowPlane] = useState<boolean>(true);

  // Drawer Open
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  // Drawer Close
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // Modal Open
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  // Modal Close
  const handleModalClose = () => {
    setModalOpen(false);
  };

  // 3D Model Select
  const handleModelSelect = (model: IThreeModel | null) => {
    setSelectedModel(model);
  };

  // Add Node
  const handleAddNode = (node: TThreeNodeCreateRequest) => {
    if (typeof onAddNode === 'function') {
      onAddNode(node);
    }
  };

  const handleDeleteSelectedNode = useCallback(() => {
    if (selectedNode) {
      const { id } = selectedNode;
      const { isDecoration } = selectedNode.model;
      if (typeof onDeleteNode === 'function') {
        if (!isDecoration) {
          if (!window.confirm('모델을 삭제하시겠습니까?')) return;
        }

        onDeleteNode(id);
      }

      setSelectedNode(undefined);
      setSelectedObj(undefined);
    }
  }, [selectedNode]);

  const handleDeleteNode = (id: number) => {
    if (typeof onDeleteNode === 'function') {
      if (!window.confirm('모델을 삭제하시겠습니까?')) return;
      onDeleteNode(id);
    }

    handleModalClose();
    setSelectedNode(undefined);
    setSelectedObj(undefined);
  };

  const handleModalSave = (node: TThreeNodeCreateRequest) => {
    handleAddNode(node);
    handleModalClose();
  };

  // Plane Grid 클릭 이벤트 -> 그리드 상의 Position 및 각도
  const handleClickGridPoint = (position: Vector3, rotation: Euler) => {
    if (selectedModel) {
      if (selectedModel.isDecoration) {
        handleModalSave({
          name: `${selectedModel.name}_${moment().format('YYYYMMDDHHmmss')}`,
          canvas: data?.id,
          model: selectedModel.id,
          position,
          rotation: {
            x: rotation.x,
            y: rotation.y,
            z: rotation.z,
          },
        });
      } else {
        setModalNode({
          canvas: data?.id,
          model: selectedModel,
          position,
          rotation: {
            x: rotation.x,
            y: rotation.y,
            z: rotation.z,
          },
        });

        handleModalOpen();
      }
    }
  };

  // 3D 노드 클릭 이벤트
  const handleNodeClick = useCallback(
    (node: IThreeNode, e: ThreeEvent<MouseEvent>) => {
      if (hideControls) return;
      if (selectedModel) return;
      const { eventObject: object } = e;

      const isSameObject = selectedNode?.id === node.id;

      setModifiedList((prev) => _.uniqBy([...prev, node.id], (p) => p));

      setSelectedModel(null);
      setSelectedNode(node);
      setSelectedObj(object);

      if (isSameObject) {
        setSelectedNode(undefined);
        setSelectedObj(undefined);
      } else {
        setSelectedModel(null);
        setSelectedNode(node);
        setSelectedObj(object);
      }
    },
    [hideControls, selectedNode],
  );

  // ESC 입력 이벤트
  const handlePressESC = () => {
    setSelectedObj(undefined);
    setSelectedNode(undefined);
    setSelectedModel(null);
  };

  // Transform 위치 변경 이벤트
  const handleChangePosition = (position: Vector3) => {
    setSelectedNode((prev) => {
      if (prev) {
        return {
          ...prev,
          position,
        };
      }

      return undefined;
    });
  };

  // Transform 각도 변경 이벤트
  const handleChangeRotation = (rotation: Euler) => {
    setSelectedNode((prev) => {
      if (prev) {
        return {
          ...prev,
          rotation: {
            x: rotation.x,
            y: rotation.y,
            z: rotation.z,
          },
        };
      }

      return undefined;
    });
  };

  // 새로고침 버튼 클릭 이벤트
  const handleClickRefresh = () => {
    setState(data);

    if (typeof onRefresh === 'function') {
      onRefresh();
    }
  };

  // 수정사항 전체 저장 버튼 클릭 이벤트
  const handleClickSaveAll = () => {
    if (typeof onSaveAll === 'function') {
      onSaveAll({
        nodes: state?.nodes
          ?.filter((node) => modifiedList.includes(node.id))
          .map((node) => ({
            ...node,
            model: node.model.id,
            group: node.group?.groupCode,
          })),
      });
    }
  };

  const handleNodeLabelClick = (node: IThreeNode) => {
    setModalNode(node);
    handleModalOpen();
  };

  // Drawer 렌더링
  const renderDrawer = useMemo(
    () => <ThreeModelSelectDrawer open={drawerOpen} onSelectModel={handleModelSelect} onClose={handleDrawerClose} />,
    [drawerOpen],
  );

  // Node mesh 렌더링
  const renderNodes = useMemo(
    () =>
      state?.nodes
        ?.filter((node) => (showDeco ? true : !node.model.isDecoration))
        .map((node) => (
          <ThreeNodeModel key={node.id} data={node} onClickLabel={handleNodeLabelClick} onClickMesh={handleNodeClick} />
        )),
    [state?.nodes, handleNodeClick, showDeco],
  );

  // Canvas 렌더링
  const renderCanvas = useMemo(
    () => (
      <BaseCanvas
        showGrid={!hideControls ? showPlane : false}
        showPlane={!hideControls ? showPlane : false}
        showGridPoint={!hideControls}
        gridPointModel={selectedModel || undefined}
        transformGrid={showDetailGrid ? 1 : 20}
        transformSelected={showTransform ? selectedObj : undefined}
        onClickGridPlane={handleClickGridPoint}
        onChangeSelectedPosition={handleChangePosition}
        onChangeSelectedRotation={handleChangeRotation}
        onESCPress={handlePressESC}
        onBackspacePress={handleDeleteSelectedNode}
        editMode={!readonly}
      >
        {renderNodes}
      </BaseCanvas>
    ),
    [renderNodes, selectedModel, selectedObj, handleDeleteSelectedNode, readonly, hideControls, showPlane],
  );

  // Props Data 변경 적용
  useEffect(() => {
    setState(data);
  }, [data]);

  // 선택된 노드의 위치, 각도 변경 적용
  useEffect(() => {
    if (selectedNode) {
      setState((prev) => {
        if (!prev) return undefined;

        const newNodes = prev.nodes?.map((node) => (node.id === selectedNode.id ? { ...selectedNode } : node));

        return {
          ...prev,
          nodes: newNodes,
        };
      });
    }
  }, [selectedNode]);

  return (
    <Suspense fallback={null}>
      <Box component="div" sx={sx}>
        {!hideControls && (
          <Box component="div" display="flex">
            <IconButton onClick={handleDrawerOpen}>
              <Add />
            </IconButton>
            <IconButton onClick={handleClickSaveAll}>
              <Save />
            </IconButton>
            <IconButton onClick={handleClickRefresh}>
              <Refresh />
            </IconButton>
            <Box component="div" display="flex" alignItems="center" sx={{ ml: 1 }}>
              <Typography variant="caption">소품 보기</Typography>
              <IconButton onClick={() => setShowDeco((prev) => !prev)}>
                {showDeco ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Box>
            <Box component="div" display="flex" alignItems="center" sx={{ ml: 1 }}>
              <Typography variant="caption">위치이동 디테일</Typography>
              <IconButton onClick={() => setShowDetailGrid((prev) => !prev)}>
                {showDetailGrid ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Box>
            <Box component="div" display="flex" alignItems="center" sx={{ ml: 1 }}>
              <Typography variant="caption">위치이동 끄기</Typography>
              <IconButton onClick={() => setShowTransform((prev) => !prev)}>
                {showTransform ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Box>
            <Box component="div" display="flex" alignItems="center" sx={{ ml: 1 }}>
              <Typography variant="caption">바닥 보기</Typography>
              <IconButton onClick={() => setShowPlane((prev) => !prev)}>
                {showPlane ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Box>
          </Box>
        )}

        {renderCanvas}
        {readonly && <ThreeNodePagingBox data={state?.nodes ?? []} />}
      </Box>
      {renderDrawer}
      <ThreeNodeSaveModal
        data={modalNode}
        open={modalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalSave}
        onDelete={handleDeleteNode}
        readonly={readonly}
      />
    </Suspense>
  );
}
