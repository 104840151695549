import { SelectChangeEvent } from '@mui/material';
import { INodeSystem } from '@types';
import BaseSelectBox, { BaseSelectBoxProps, SelectItemProps } from 'components/common/BaseSelectBox';
import _ from 'lodash';
import React, { useMemo } from 'react';

type Props = BaseSelectBoxProps & {
  data?: INodeSystem[];
  onChangeNodeSystem?(selected: INodeSystem | null): void;
};

NodeSystemSelectBox.defaultProps = {
  data: [],
  onChangeNodeSystem: undefined,
};

export default function NodeSystemSelectBox({ data, onChangeNodeSystem, ...others }: Props) {
  const items = useMemo(
    (): SelectItemProps[] =>
      data ? data.map((dt) => ({ id: dt.id.toString(), name: `${dt.site} | ${dt.name}` })) : [],
    [data],
  );

  const handleSelect = (e: SelectChangeEvent) => {
    const { value } = e.target;

    const selected = _.find(data, (dt) => dt.id.toString() === value);

    if (typeof onChangeNodeSystem === 'function') {
      onChangeNodeSystem(selected || null);
    }
  };

  return (
    <BaseSelectBox showEmpty onChange={handleSelect} name="nodeSystem" label="계통유형" items={items} {...others} />
  );
}
