import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Grid, IconButton, TextField } from '@mui/material';
import { ICostItem, TCostItemCreateRequest } from '@types';
import moment, { Moment } from 'moment';
import { Delete, Save } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';

type Props = {
  cost: string;
  data?: ICostItem;
  onSave?(request: TCostItemCreateRequest): void;
  onDelete?(id: number): void;
};

CostItemForm.defaultProps = {
  data: undefined,
  onSave: undefined,
  onDelete: undefined,
};

export default function CostItemForm({ cost, data, onSave, onDelete }: Props) {
  const [state, setState] = useState<TCostItemCreateRequest>({});

  const handleSaveClick = () => {
    if (typeof onSave === 'function') {
      onSave(state);
    }

    if (!data) {
      setState({ cost });
    }
  };

  const handleDeleteClick = () => {
    if (typeof onDelete === 'function' && data?.id && window.confirm(`${state.name}를 정말 삭제하시겠습니까?`)) {
      onDelete(data.id);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (name: string, moment: any) => {
    setState((prev) => ({ ...prev, [name]: (moment as Moment).format('YYYY-MM-DD HH:mm:ss') }));
  };

  useEffect(() => {
    if (data) {
      setState({ ...data, applyDate: moment(data.applyDate).format('YYYY-MM-DD HH:mm:ss') });
    } else {
      setState((prev) => ({ cost: prev.cost }));
    }
  }, [data]);

  useEffect(() => {
    setState((prev) => ({ ...prev, cost }));
  }, [cost]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={3}>
        <TextField
          name="name"
          label="이름"
          variant="standard"
          value={state.name ?? ''}
          fullWidth
          required
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <DatePicker
          label="적용일시"
          value={state.applyDate}
          onChange={(moment) => handleDateChange('applyDate', moment)}
          renderInput={(params) => <TextField variant="standard" required fullWidth {...params} />}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <Box component="div" display="flex">
          <IconButton onClick={handleSaveClick}>
            <Save />
          </IconButton>
          <IconButton onClick={handleDeleteClick} disabled={Boolean(!data?.id)}>
            <Delete />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
}
