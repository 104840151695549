import { SystemLayout } from 'layout/system';
import React, { useMemo, useState } from 'react';
import ExcelTagFileUploadBox from 'components/excel/ExcelTagFileUploadBox';
import ExcelTagDataGrid, { TExcelTagConvertData } from 'components/excel/ExcelTagDataGrid';
import { Box, Button, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { createDatasAPI } from 'apis/data';
import useLoading from 'common/hooks/useLoading';
import { createChunk } from 'common/lib';
import { toast } from 'react-toastify';

export default function ExcelTagDataUploadPage() {
  const loading = useLoading({ loading: false, trigger: false });
  const [gridData, setGridData] = useState<TExcelTagConvertData[]>([]);

  // 데이터 생성 API 요청
  const uploadDataList = async (datas: TExcelTagConvertData[]) => {
    const result = await createMutation.mutateAsync({ datas });

    if (!result.success) return 0;

    return result.data ?? 0;
  };

  // 태그 데이터 업로드 이벤트
  const handleUpload = async () => {
    let insertedSize = 0;
    let isSuccess = true;
    const chunkSize = 2000;
    const chunkList = createChunk(gridData, chunkSize);

    loading({ loading: true, message: `업로드 진행중 0/${gridData.length}` });

    // eslint-disable-next-line no-restricted-syntax
    for (const chunk of chunkList) {
      // eslint-disable-next-line no-await-in-loop
      const updatedSize = await uploadDataList(chunk);

      insertedSize += updatedSize;

      if (updatedSize === 0) isSuccess = false;

      loading({
        loading: true,
        message: `업로드 진행중 ${insertedSize.toLocaleString()}/${gridData.length.toLocaleString()}`,
      });
    }

    loading({ loading: false });

    if (isSuccess) {
      toast(`태그 데이터 업로드에 성공했습니다. (${insertedSize.toLocaleString()})`, { type: 'success' });
    } else {
      toast(
        `태그 데이터 업로드에 일부 실패했습니다. (${insertedSize.toLocaleString()} / ${gridData.length.toLocaleString()})`,
        { type: 'error' },
      );
    }
  };

  // 태그 데이터 생셩 Mutation
  const createMutation = useMutation(createDatasAPI);

  // Grid 렌더링
  const renderGrid = useMemo(() => {
    if (gridData.length < 1) return <Box component="div" />;

    let previewData = gridData.slice(0, 50);
    previewData = previewData.concat(gridData.slice(-50));

    return (
      <>
        <Box component="div" sx={{ p: 1 }}>
          <Typography sx={{ mb: -1 }} variant="caption">
            데이터 미리보기(100Row)
          </Typography>
          <ExcelTagDataGrid rows={previewData} />
        </Box>
        <Button sx={{ my: 1 }} variant="contained" fullWidth onClick={handleUpload}>
          <Typography>태그 데이터 업로드</Typography>
        </Button>
      </>
    );
  }, [gridData]);

  return (
    <SystemLayout title="엑셀 태그 데이터 업로드">
      <ExcelTagFileUploadBox onChangeData={setGridData} />
      {renderGrid}
    </SystemLayout>
  );
}
