import React, { useMemo } from 'react';
import { Button, Typography } from '@mui/material';
import { Html } from '@react-three/drei';
import { IThreeNode } from '@types';
import { MaterialMap } from 'components/three/EquipMesh';
import { Euler, Vector3 } from 'three';
import { ThreeEvent } from '@react-three/fiber';
import ThreeModel from '../threemodel/ThreeModel';

export type ThreeNodeModelProps = {
  data: IThreeNode;
  materialMap?: MaterialMap;
  onClickLabel?(data: IThreeNode): void;
  onClickMesh?(node: IThreeNode, e: ThreeEvent<MouseEvent>): void;
};

ThreeNodeModel.defaultProps = {
  materialMap: {},
  onClickLabel: undefined,
  onClickMesh: undefined,
};

export default function ThreeNodeModel({ data, materialMap = {}, onClickLabel, onClickMesh }: ThreeNodeModelProps) {
  const handleClickLabel = () => {
    if (typeof onClickLabel === 'function') {
      onClickLabel(data);
    }
  };

  const handleClickMesh = (e: ThreeEvent<MouseEvent>) => {
    if (typeof onClickMesh === 'function') {
      onClickMesh(data, e);
    }
  };

  const renderLabel = useMemo(() => {
    if (data.model.isDecoration) return undefined;

    const position = {
      ...data.position,
    };

    position.y += 20;
    position.z -= 5;

    return (
      <Html
        sprite
        center
        prepend
        distanceFactor={50}
        position={[position.x, position.y, position.z]}
        zIndexRange={[0, 1000]}
      >
        <Button
          sx={{ width: data.name.length * 40, height: 50 }}
          color="primary"
          size="large"
          variant="contained"
          onClick={handleClickLabel}
        >
          <Typography variant="h4" component="span" fontWeight="bold">
            {data.name}
          </Typography>
        </Button>
      </Html>
    );
  }, [data]);

  return (
    <mesh castShadow receiveShadow>
      <ThreeModel
        data={data.model}
        position={new Vector3(data.position.x, data.position.y, data.position.z)}
        rotation={new Euler(data.rotation.x, data.rotation.y, data.rotation.z)}
        onClick={handleClickMesh}
      />
      {renderLabel}
    </mesh>
  );
}
