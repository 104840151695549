import { Edit } from '@mui/icons-material';
import { Box, Card, Grid, IconButton, RegularBreakpoints, Typography } from '@mui/material';
import { IMatterport } from '@types';
import { getFileURL } from 'common/lib';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  data: IMatterport;
  breakpoints?: RegularBreakpoints;
  onClickEdit?(data: IMatterport): void;
  clickLink?: string;
};

MatterportBox.defaultProps = {
  onClickEdit: undefined,
  clickLink: undefined,

  breakpoints: {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 4,
    xl: 4,
  },
};

export default function MatterportBox({ data, breakpoints, onClickEdit, clickLink }: Props) {
  const navigate = useNavigate();

  const handleEdit = () => {
    if (typeof onClickEdit === 'function') {
      onClickEdit(data);
    }
  };

  const moveTo = () => {
    if (clickLink) {
      navigate(clickLink);
    }
  };

  return (
    <Grid item {...breakpoints} display="flex" flexDirection="column">
      <Box component="div" justifySelf="flex-end" alignSelf="flex-end" display="flex" alignItems="center">
        <Typography>
          {data.name} | {data.site}
        </Typography>
        <IconButton size="small" onClick={handleEdit}>
          <Edit fontSize="small" />
        </IconButton>
      </Box>

      <Card
        onClick={moveTo}
        sx={{
          width: '100%',
          height: 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${getFileURL(data.thumbnailImage)})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <Box
          component="div"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            height: 50,
            alignItems: 'center',
          }}
        >
          <Typography fontWeight="bold" color="#eee">
            {data.name}
          </Typography>
        </Box>
      </Card>
    </Grid>
  );
}
