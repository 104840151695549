import { Grid } from '@mui/material';
import { ChartType } from '@types';
import { DateSearchProps } from 'components/common/DateSearchBox';
import { Data10ChartCardProps } from 'components/data/Data10ChartCard';
import DefaultTagData10Card from 'components/data/DefaultTagData10Card';
import React, { useMemo, useState } from 'react';
import { AggUnitType, Data10AggType } from './useTagChartData10';

type Chart10CardProps = {
  defaultValue?: Data10ChartCardProps[];
};

export const useChart10Card = ({ defaultValue }: Chart10CardProps) => {
  const [chartCards, setChartCards] = useState<Data10ChartCardProps[]>(defaultValue ?? []);

  const addCard = (newCard: Data10ChartCardProps[]) => {
    if (newCard.length > 0) {
      setChartCards((prev) => [...prev, ...newCard]);
    }
  };

  const getCardData = () => {
    return chartCards;
  };

  // Chart Card 삭제
  const handleDeleteChartCard = (id: string) => {
    setChartCards((prev) => prev.filter((p) => p.id !== id));
  };
  // Chart Card Label 변경
  const handleChartCardTitleChange = (id: string, changeTitle: string) => {
    setChartCards((prev) => prev.map((pcard) => (pcard.id === id ? { ...pcard, label: changeTitle } : pcard)));
  };
  // Chart Card 날짜 변경
  const handleChartCardDateChange = (id: string, changeDate: DateSearchProps) => {
    setChartCards((prev) => prev.map((pcard) => (pcard.id === id ? { ...pcard, dateProps: changeDate } : pcard)));
  };
  // Chart AggType 변경
  const handleChartCardAggTypeChange = (id: string, aggType: Data10AggType) => {
    setChartCards((prev) => prev.map((pcard) => (pcard.id === id ? { ...pcard, aggType } : pcard)));
  };
  // Chart IsIncrease 변경
  const handleChartCardIsIncreaseChange = (id: string, isIncrease: boolean) => {
    setChartCards((prev) => prev.map((pcard) => (pcard.id === id ? { ...pcard, isIncrease } : pcard)));
  };
  // 차트유형 변경
  const handleChartCardChartTypeChange = (id: string, chartType: ChartType | undefined) => {
    setChartCards((prev) =>
      prev.map((pcard) => (pcard.id === id ? { ...pcard, chartType: chartType || 'line' } : pcard)),
    );
  };
  // 차트 데이터 취합 단위 변경
  const handleChartCardAggUnitTypeChange = (id: string, aggUnit: AggUnitType) => {
    setChartCards((prev) => prev.map((pcard) => (pcard.id === id ? { ...pcard, aggUnit } : pcard)));
  };
  // 차트 데이터 취합 단위 길이 변경
  const handleChartCardAggValChange = (id: string, aggVal: number) => {
    setChartCards((prev) => prev.map((pcard) => (pcard.id === id ? { ...pcard, aggVal } : pcard)));
  };

  const memoedChartCard = useMemo(
    () => (
      <Grid container spacing={1} sx={{ p: 2 }}>
        {chartCards.map((card) => (
          <DefaultTagData10Card
            {...card}
            key={card.id}
            onDelete={handleDeleteChartCard}
            onTitleChange={handleChartCardTitleChange}
            onDatePropsChange={handleChartCardDateChange}
            onAggTypeChange={handleChartCardAggTypeChange}
            onIsIncreaseChange={handleChartCardIsIncreaseChange}
            onChartTypeChange={handleChartCardChartTypeChange}
            onAggUnitTypeChange={handleChartCardAggUnitTypeChange}
            onAggValChange={handleChartCardAggValChange}
          />
        ))}
      </Grid>
    ),
    [chartCards],
  );

  return { cards: memoedChartCard, addCard, getCardData };
};
