import { TGroupFieldCreateRequest } from '@types';
import GroupFieldSaveModal from 'components/groupfield/GroupFieldSaveModal';
import React, { useCallback, useMemo, useState } from 'react';

type Props = {
  onAddGroupField?(data: TGroupFieldCreateRequest): void;
  onModifyGroupField?(data: TGroupFieldCreateRequest): void;
  onDeleteGroupField?(data: TGroupFieldCreateRequest): void;
};

const useGroupFieldModal = ({ onAddGroupField, onModifyGroupField, onDeleteGroupField }: Props) => {
  const modalOpen = () => setOpen(true);
  const [data, setData] = useState<TGroupFieldCreateRequest | null>(null);

  const modalClose = () => {
    setOpen(false);
    setData({});
  };

  const handleNewGroupFieldOpen = (order?: number) => {
    setData({ orderNum: order || 1 });
    modalOpen();
  };

  const handleModifyGroupFieldOpen = (data: TGroupFieldCreateRequest) => {
    setData(data);
    modalOpen();
  };

  const handleOnSave = useCallback(
    (saveData: TGroupFieldCreateRequest) => {
      if (data?.code) {
        if (typeof onModifyGroupField === 'function') {
          onModifyGroupField(saveData);
        }
      } else if (typeof onAddGroupField === 'function') {
        onAddGroupField(saveData);
      }
    },
    [data, onModifyGroupField, onAddGroupField],
  );

  const [open, setOpen] = useState<boolean>(false);
  const modal = useMemo(
    () => (
      <GroupFieldSaveModal
        onSave={handleOnSave}
        onClose={modalClose}
        onDeleteGroupField={onDeleteGroupField}
        data={data}
        open={open}
      />
    ),
    [handleOnSave, onDeleteGroupField, data, open],
  );

  return {
    open,
    modal,
    data,
    setData,
    modalOpen,
    modalClose,
    handleNewGroupFieldOpen,
    handleModifyGroupFieldOpen,
  };
};

export default useGroupFieldModal;
