import React, { ChangeEvent, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { TProductManageCreateRequest } from '@types';
import { Delete, Save } from '@mui/icons-material';
import { IProductManageData } from './ProductManageGrid';

interface Column {
  id: keyof IProductManageData;
  label: string;
  minWidth?: number;
  align?: 'left' | 'center' | 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'product', label: '제품명' },
  { id: 'year', label: '연도 ' },
  { id: 'month1', label: '1월' },
  { id: 'month2', label: '2월' },
  { id: 'month3', label: '3월' },
  { id: 'month4', label: '4월' },
  { id: 'month5', label: '5월' },
  { id: 'month6', label: '6월' },
  { id: 'month7', label: '7월' },
  { id: 'month8', label: '8월' },
  { id: 'month9', label: '9월' },
  { id: 'month10', label: '10월' },
  { id: 'month11', label: '11월' },
  { id: 'month12', label: '12월' },
];

type Props = {
  row: IProductManageData;
  onSaveRow?(id: number, data: TProductManageCreateRequest): void;
  onDeleteRow?(id: number, data: TProductManageCreateRequest): void;
};

ProductManageGridRow.defaultProps = {
  onSaveRow: undefined,
  onDeleteRow: undefined,
};

export default function ProductManageGridRow({ row, onSaveRow, onDeleteRow }: Props) {
  const [state, setState] = useState<TProductManageCreateRequest>({
    id: row.id,
    site: row.site,
    product: row.product_id,
    year: row.year,
    months: {
      ...row,
    },
  });

  const handleChangeMonthValues = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, months: { ...prev.months, [name]: value } }));
  };

  const handleSave = () => {
    if (typeof onSaveRow === 'function') {
      onSaveRow(row.id, state);
    }
  };

  const handleDelete = () => {
    if (typeof onDeleteRow === 'function' && window.confirm('해당 제품생산량을 삭제하시겠습니까?')) {
      onDeleteRow(row.id, state);
    }
  };

  const renderCell = (row: any, column: Column) => {
    const value = row[column.id];
    const newValue = column.format && typeof value === 'number' ? column.format(value) : value;

    if (column.id === 'month1')
      return (
        <TextField
          name="month1"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month1}
        />
      );
    if (column.id === 'month2')
      return (
        <TextField
          name="month2"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month2}
        />
      );
    if (column.id === 'month3')
      return (
        <TextField
          name="month3"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month3}
        />
      );
    if (column.id === 'month4')
      return (
        <TextField
          name="month4"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month4}
        />
      );
    if (column.id === 'month5')
      return (
        <TextField
          name="month5"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month5}
        />
      );
    if (column.id === 'month6')
      return (
        <TextField
          name="month6"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month6}
        />
      );
    if (column.id === 'month7')
      return (
        <TextField
          name="month7"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month7}
        />
      );
    if (column.id === 'month8')
      return (
        <TextField
          name="month8"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month8}
        />
      );
    if (column.id === 'month9')
      return (
        <TextField
          name="month9"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month9}
        />
      );
    if (column.id === 'month10')
      return (
        <TextField
          name="month10"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month10}
        />
      );
    if (column.id === 'month11')
      return (
        <TextField
          name="month11"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month11}
        />
      );
    if (column.id === 'month12')
      return (
        <TextField
          name="month12"
          variant="standard"
          type="number"
          onChange={handleChangeMonthValues}
          value={state.months?.month12}
        />
      );

    return newValue;
  };

  return (
    <TableRow key={row.id} hover tabIndex={-1}>
      {columns.map((column) => {
        return (
          <TableCell key={column.id} align={column.align} sx={{ paddingY: 1 }}>
            {renderCell(row, column)}
          </TableCell>
        );
      })}
      <TableCell>
        <Box component="div" display="flex">
          <IconButton size="small" onClick={handleSave}>
            <Save fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={handleDelete}>
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}
