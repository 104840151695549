import React, { useState } from 'react';
import { Box, Button, SelectChangeEvent, Typography } from '@mui/material';
import SiteSelectBox from 'components/site/SiteSelectBox';
import { useRecoilValue } from 'recoil';
import { flatSiteState } from 'states';
import { IProduct, TProductCreateRequest } from '@types';
import ProductSelecBox from './ProductSelectBox';

type Props = {
  onSearch?(siteCode: string, productId: number): void;
  onSelectSite?(siteCode?: string): void;
  onSelectProduct?(product?: IProduct): void;
  defaultSite?: string;
  defaultProduct?: number;
  editable?: boolean;
};

ProductSearchForm.defaultProps = {
  onSearch: undefined,
  onSelectProduct: undefined,
  onSelectSite: undefined,
  defaultSite: undefined,
  defaultProduct: undefined,
  editable: true,
};

export default function ProductSearchForm({
  onSearch,
  onSelectSite,
  onSelectProduct,
  defaultSite,
  defaultProduct,
  editable,
}: Props) {
  const flatSite = useRecoilValue(flatSiteState);

  const [state, setState] = useState<TProductCreateRequest>({
    site: defaultSite,
    id: defaultProduct,
  });

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));

    if (typeof onSelectSite === 'function') {
      onSelectSite(value);
    }
  };

  const handleSelectProduct = (product: IProduct | undefined) => {
    setState((prev) => ({ ...prev, ...product }));
    if (typeof onSelectProduct === 'function') {
      onSelectProduct(product);
    }
  };

  const handleSearch = () => {
    if (typeof onSearch === 'function' && state.site && state.id) {
      onSearch(state.site, state.id);
    }
  };

  return (
    <Box component="div">
      <SiteSelectBox name="site" data={flatSite} defaultValue={defaultSite} onChange={handleSelectChange} />
      <ProductSelecBox
        isEdit={editable}
        containerSx={{ marginTop: 1 }}
        site={state.site}
        value={state.id?.toString()}
        defaultValue={defaultProduct}
        onSelectProduct={handleSelectProduct}
      />
      {onSearch && (
        <Button disabled={!state.id} variant="contained" fullWidth sx={{ marginTop: 2 }} onClick={handleSearch}>
          <Typography>조회</Typography>
        </Button>
      )}
    </Box>
  );
}
