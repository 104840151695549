import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { DataLayout } from 'layout/data';
import { useRecoilValue } from 'recoil';
import { flatGroupState } from 'states';
import { useChart10MESCard } from 'common/hooks/useChart10MESCard';
import MesSearchBox from 'components/mes/MesSearchBox';

export default function DataMesPage() {
  const flatGroup = useRecoilValue(flatGroupState);

  const mesGroup = useMemo(
    () =>
      flatGroup.filter(
        (group) => group.groupType.code === 'GROUP_TYPE_MES_PRODUCT' && group.groupDetailType?.code === undefined,
      ),
    [flatGroup],
  );

  const { cards, search: mesDataSearch } = useChart10MESCard({
    mesParentGroupCode: mesGroup.at(0)?.groupCode,
    dateProps: { type: 'currentDay', duration: 3, durationUnit: 'hour' },
  });

  return (
    <DataLayout title="MES 데이터 조회">
      <Box component="div">
        <MesSearchBox
          defaultDate={{ type: 'currentDay' }}
          defaultGroup={mesGroup.at(0)?.groupCode}
          onSearch={(search) => mesDataSearch({ dateProps: search.dateProps, mesParentGroupCode: search.groupCode })}
        />
      </Box>
      {cards}
    </DataLayout>
  );
}
