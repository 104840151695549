import React, { ChangeEvent, MouseEvent, SyntheticEvent, useMemo, useState } from 'react';
import { TreeView, TreeItem, TreeItemProps } from '@mui/lab';
import { Add, ChevronRight, ExpandMore } from '@mui/icons-material';
import { Box, Button, Card, IconButton, SxProps, Theme, Typography } from '@mui/material';
import BaseCheckbox from './BaseCheckbox';

export interface RenderBaseTree {
  id: string;
  name: string;
  children?: readonly RenderBaseTree[];
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  labelText: string;
  showAddBtn?: boolean;
  showCheckBtn?: boolean;
  onClickAdd?(parent: string): void;
  onChecked?(nodeId: string, checked: boolean): void;
};

CustomTreeItem.defaultProps = {
  bgColor: 'transparent',
  showCheckBtn: false,
  showAddBtn: false,
  onClickAdd: undefined,
  onChecked: undefined,
};

function CustomTreeItem(props: StyledTreeItemProps) {
  const { labelText, showAddBtn, showCheckBtn, bgColor, color, nodeId, onClickAdd, onClick, onChecked, ...others } =
    props;

  const onClickName = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (typeof onClick === 'function') {
      const l = e as MouseEvent<HTMLLIElement>;
      onClick(l);
    }
  };

  const onClickAddBtn = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (typeof onClickAdd === 'function') {
      onClickAdd(nodeId);
    }
  };

  const onChangeCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (typeof onChecked === 'function') {
      onChecked(nodeId, checked);
    }

    setChecked(checked);
  };

  const [checked, setChecked] = useState<boolean>(false);

  return (
    <TreeItem
      nodeId={nodeId}
      label={
        <Box
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 0.5,
            backgroundColor: bgColor,
            borderBottom: '#999 0.1px dashed',
          }}
          onClick={onClickName}
        >
          {showCheckBtn && (
            <Box component="div" sx={{ p: 0.5, pr: -1 }}>
              <BaseCheckbox name="chk" checked={checked} defaultChecked={false} onChange={onChangeCheck} />
            </Box>
          )}
          <Typography variant="body2">{labelText}</Typography>
          {showAddBtn && (
            <Box component="div" sx={{ p: 0.5, pl: 2 }}>
              <IconButton size="small" onClick={onClickAddBtn}>
                <Add />
              </IconButton>
            </Box>
          )}
        </Box>
      }
      {...others}
    />
  );
}

export type BaseTreeProps = {
  data?: RenderBaseTree[];
  containerStyle?: SxProps<Theme>;
  onClickItem?(tree: RenderBaseTree): void;
  onClickAdd?(parent: string): void;
  onChecked?(nodeId: string, checked: boolean): void;
  showAddBtn?: boolean;
  showCheckBtn?: boolean;
  bgColor?: string;
  color?: string;
};

BaseTree.defaultProps = {
  data: [],
  containerStyle: {},
  onClickItem: undefined,
  onClickAdd: undefined,
  onChecked: undefined,
  showAddBtn: false,
  showCheckBtn: false,
  bgColor: undefined,
  color: undefined,
};

export default function BaseTree({
  data,
  containerStyle,
  onClickItem,
  onClickAdd,
  onChecked,
  showAddBtn,
  showCheckBtn,
  bgColor,
  color,
}: BaseTreeProps) {
  const handleTreeClick = (selected: RenderBaseTree) => {
    if (typeof onClickItem === 'function') {
      onClickItem(selected);
    }
  };

  const createTree = (tree: RenderBaseTree) => {
    return (
      <CustomTreeItem
        onClick={() => handleTreeClick(tree)}
        onClickAdd={onClickAdd}
        onChecked={onChecked}
        key={tree.id}
        nodeId={tree.id}
        labelText={tree.name}
        showAddBtn={showAddBtn}
        showCheckBtn={showCheckBtn}
        bgColor={bgColor}
        color={color}
      >
        {tree.children?.map((sub) => createTree(sub))}
      </CustomTreeItem>
    );
  };

  const getNodeId = (nodes: readonly RenderBaseTree[]): string[] => {
    return nodes.map((node) => [node.id, ...getNodeId(node.children ?? [])]).flat();
  };

  const nodeIds = useMemo(() => getNodeId(data ?? []), [data]);

  const handleToggle = (e: SyntheticEvent, nodeIds: string[]) => setExpanded(nodeIds);

  const handleExpandClick = () => setExpanded((prev) => (prev.length === 0 ? [...nodeIds] : []));

  const [expanded, setExpanded] = useState<string[]>([]);

  const memoedCard = useMemo(
    () => (
      <Card variant="outlined" sx={{ p: 2, overflow: 'auto', ...containerStyle }}>
        <Box component="div" sx={{ mb: 1 }}>
          <Button onClick={handleExpandClick}>{expanded.length === 0 ? 'Expand all' : 'Collapse all'}</Button>
        </Box>
        <TreeView
          disableSelection
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={expanded}
          onNodeToggle={handleToggle}
        >
          {data?.map((tree) => createTree(tree))}
        </TreeView>
      </Card>
    ),
    [data, expanded, containerStyle],
  );

  return memoedCard;
}
