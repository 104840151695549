import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { findAlarmHistPageAPI } from 'apis/alarmhist';
import { IAlarmHist } from '@types';

type Props = {
  tags?: string[];
};

export default function useTagAlarmState({ tags }: Props) {
  const { data: alarmHistList } = useQuery(
    ['alarmHist', tags],
    async () =>
      Promise.all(
        tags?.map(async (tag) =>
          findAlarmHistPageAPI({
            page: 1,
            size: 1,
            search: { tag },
            sort: [{ name: 'dtt', sort: 'DESC' }],
          }),
        ) ?? [],
      ),
    {
      refetchInterval: 1000 * 60 * 1,
    },
  );

  const latestAlarmHists = useMemo(
    () => alarmHistList?.map((ahl) => ahl.data?.content?.at(-1)).filter((ah): ah is IAlarmHist => !!ah) ?? [],
    [alarmHistList],
  );

  return latestAlarmHists;
}
