import React from 'react';
import { TTagSearchField, TUserSearchField } from '@types';
import BaseSearchField, { SearchFieldProps } from 'components/common/BaseSearchField';

const fields: SearchFieldProps[] = [
  {
    id: 'tagCode',
    name: 'Code',
    type: 'text',
  },
  {
    id: 'tagName',
    name: 'Name',
    type: 'text',
  },
  {
    id: 'tagType',
    name: 'Type',
    type: 'tagType',
  },
  {
    id: 'dataType',
    name: 'DataType',
    type: 'dataType',
  },
  {
    id: 'site',
    name: 'Site',
    type: 'site',
  },
  {
    id: 'tagUnit',
    name: 'Unit',
    type: 'code',
    code: 'TAG_UNIT',
  },
  {
    id: 'tagDetailType',
    name: 'TagDetailType',
    type: 'code',
    code: 'TAG_DETAIL_TYPE',
  },
  {
    id: 'useYN',
    name: 'UseYN',
    type: 'useYN',
  },
];

type Props = {
  onChange?(search: TTagSearchField): void;
};

TagSearchField.defaultProps = {
  onChange: undefined,
};

export default function TagSearchField({ onChange }: Props) {
  return <BaseSearchField<TUserSearchField> field={fields} onCommit={onChange} />;
}
