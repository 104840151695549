import { DesktopDatePicker } from '@mui/x-date-pickers';
import { SxProps, TextField, Theme } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

export type ErrorType = 'invalidDate' | 'maxDate' | 'minDate';

export type ViewType = 'year' | 'month' | 'day';

type Props = {
  name: string;
  onChange?(name: string, changeDate: moment.Moment): void;
  onError?(name: string, reason: ErrorType): void;
  label?: string;
  defaultValue?: moment.Moment;
  value?: moment.Moment;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  readOnly?: boolean;
  views?: ViewType[];
  inputFormat?: string;
  mask?: string;
};

BaseDatePicker.defaultProps = {
  onChange: undefined,
  onError: undefined,
  label: undefined,
  defaultValue: undefined,
  value: moment(),
  sx: {},
  fullWidth: false,
  readOnly: false,
  views: ['year', 'month', 'day'],
  inputFormat: 'YYYY-MM-DD',
  mask: '____-__-__',
};

export default function BaseDatePicker({
  defaultValue,
  name,
  onChange,
  onError,
  label,
  value,
  sx,
  fullWidth,
  readOnly,
  views,
  inputFormat,
  mask,
}: Props) {
  const [state, setState] = useState<moment.Moment | null>(defaultValue || null);
  const handleOnChange = (date: moment.Moment | null, keyboardInputValue: string | undefined) => {
    setState(date);

    if (typeof onChange === 'function' && date) {
      onChange(name, date);
    }
  };

  const handleOnError = (reason: any) => {
    if (typeof onError === 'function') {
      onError(name, reason as ErrorType);
    }
  };

  useEffect(() => {
    if (state === null && value) {
      setState(value);
      handleOnChange(value, undefined);
    }
  }, [value]);

  return (
    <DesktopDatePicker
      label={label}
      inputFormat={inputFormat}
      mask={mask}
      onChange={handleOnChange}
      onError={handleOnError}
      maxDate={moment()}
      value={state}
      views={views}
      readOnly={readOnly}
      renderInput={(params) => (
        <TextField name={name} {...params} variant="standard" sx={{ ...sx }} fullWidth={fullWidth} />
      )}
    />
  );
}
