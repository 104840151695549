import { InfoWindow, Marker, MarkerProps } from '@react-google-maps/api';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { flatGroupState } from 'states';
import { filterGroupFieldData, getAlertText, getLocationFromGroup } from 'common/lib';
import { IGroup, ILocation } from '@types';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { Info, Navigation } from '@mui/icons-material';
import useLoading from 'common/hooks/useLoading';
import useGroupAlarmState from 'common/hooks/useGroupAlarmState';

type Props = {
  groupId: number;
  onClickGroupInfo?(group: IGroup): void;
} & Omit<MarkerProps, 'position'>;

export default function GroupMarker({ groupId, onClickGroupInfo, ...others }: Props) {
  const [activeWindow, setActiveWindow] = useState<boolean>(false);
  const flatGroups = useRecoilValue(flatGroupState);
  const group = useMemo(() => _.find(flatGroups, (gr) => gr.id === groupId), [flatGroups, groupId]);
  const alertState = useGroupAlarmState({ group });

  const location = useMemo(() => getLocationFromGroup(group), [group]);

  const handleClickInfoBtn = useCallback(
    (group?: IGroup) => {
      if (group && typeof onClickGroupInfo === 'function') {
        onClickGroupInfo(group);
      }
    },
    [onClickGroupInfo],
  );

  const loading = useLoading({ trigger: false });

  const handleClickNavigation = useCallback(
    (location?: ILocation) => {
      if (!location) return;

      loading({ loading: true, message: '위치정보를 조회중입니다.' });
      window.navigator.geolocation.getCurrentPosition(
        (data) => {
          const { latitude, longitude } = data.coords;
          const moveURL = `https://www.google.com/maps/dir/${latitude},${longitude}/${location.lat},${location.lng}`;

          loading({ loading: false });

          window.open(moveURL, undefined, 'fullscreen=yes');
        },
        () => {
          loading({ loading: false });
        },
      );
    },
    [loading],
  );

  const renderInfoBox = useMemo(() => {
    return (
      <Box component="div" display="flex" flexDirection="column" alignContent="center" justifyContent="center">
        {alertState.alert !== 'NORMAL' && (
          <Box
            component="div"
            sx={{
              position: 'absolute',
              left: 2,
              top: 2,
            }}
          >
            <Typography
              fontSize={12}
              sx={{
                padding: 0.1,
                color: alertState.alertColor.backgroundColor,
              }}
            >
              {getAlertText(alertState.alert)}
            </Typography>
          </Box>
        )}

        <Box
          component="div"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          alignContent="center"
          sx={{ p: 0, m: 0, minWidth: 100 }}
        >
          <Typography fontSize={15} color="#333" fontWeight={600}>
            {group?.groupName}
          </Typography>
          <IconButton size="small" disabled={!group} onClick={() => handleClickInfoBtn(group)}>
            <Info sx={{ color: '#666' }} />
          </IconButton>
        </Box>
        <Divider color="#999" />
        <Box component="div" display="flex" justifyContent="flex-end" alignContent="center" alignItems="center">
          <Typography fontSize={14} color="#666" fontWeight="bold">
            지도 안내
          </Typography>
          <IconButton size="small" disabled={!group} onClick={() => handleClickNavigation(location)}>
            <Navigation sx={{ color: '#666' }} />
          </IconButton>
        </Box>
      </Box>
    );
  }, [alertState, group, location]);

  const renderMarker = useMemo(() => {
    const renderLocation = { lat: location?.lat ?? 0, lng: location?.lng ?? 0 };

    return (
      <Marker
        {...others}
        position={renderLocation}
        visible={Boolean(location)}
        onLoad={() => {
          setActiveWindow(true);
        }}
        onClick={() => setActiveWindow(true)}
        animation={alertState.alert === 'NORMAL' ? google.maps.Animation.DROP : google.maps.Animation.BOUNCE}
        icon={{
          path: 'M24 23.8q1.55 0 2.675-1.125Q27.8 21.55 27.8 20q0-1.55-1.125-2.675Q25.55 16.2 24 16.2q-1.55 0-2.675 1.125Q20.2 18.45 20.2 20q0 1.55 1.125 2.675Q22.45 23.8 24 23.8Zm0 21.3q-8.45-7.2-12.65-13.35-4.2-6.15-4.2-11.35 0-7.9 5.075-12.575Q17.3 3.15 24 3.15t11.775 4.675Q40.85 12.5 40.85 20.4q0 5.2-4.2 11.35Q32.45 37.9 24 45.1Z',
          fillColor: alertState.alert === 'NORMAL' ? '#333' : alertState.alertColor.backgroundColor,
          fillOpacity: 1,
          strokeOpacity: 0,
          scale: 0.7,
        }}
        noClustererRedraw
      >
        {activeWindow && <InfoWindow onCloseClick={() => setActiveWindow(false)}>{renderInfoBox}</InfoWindow>}
      </Marker>
    );
  }, [location, group, activeWindow, renderInfoBox, alertState, others]);

  return renderMarker;
}
