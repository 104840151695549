import { Box, Card, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { TAlarmHistSearchField } from '@types';
import { findAlarmHistStatusCountAPI } from 'apis/alarmhist';
import { getDateRange } from 'common/lib';
import { DateSearchProps } from 'components/common/DateSearchBox';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

const COLORS = ['#ddd', 'red', 'orange', 'green'];

type Props = {
  sx?: SxProps<Theme>;
  dateSearch?: DateSearchProps;
  search?: TAlarmHistSearchField;
};

AlarmHistStatCard.defaultProps = {
  sx: {},
  dateSearch: undefined,
  search: undefined,
};

export default function AlarmHistStatCard({ dateSearch, search, sx }: Props) {
  const theme = useTheme();

  const colors = useMemo(() => {
    if (theme.palette.mode === 'light') {
      return ['#333', 'red', 'orange', 'green'];
    }

    return COLORS;
  }, [theme]);
  const getDate = () => {
    if (dateSearch) {
      return getDateRange(dateSearch, 'YYYY-MM-DD HH:mm');
    }

    return {};
  };

  const fetch = () => {
    const dates = getDate();
    return findAlarmHistStatusCountAPI({ search: { ...search, ...dates }, page: 0, size: 999999 });
  };

  const { data } = useQuery(['alarmhist_status_count', search, dateSearch], fetch, {
    refetchInterval: 60 * 1000,
  });

  const status = useMemo(() => data?.data, [data]);

  const renderItem = (title: string, count: number, color: string) => {
    return (
      <Box component="div" key={title} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="subtitle1" fontWeight="bold" color={color}>
          {title}
        </Typography>
        <Typography>{`${count.toLocaleString()}건`}</Typography>
      </Box>
    );
  };

  const renderItems = useMemo(() => {
    const element = [];

    if (status) {
      // 미처리 알람 카운트 컴포넌트 추가
      const notProcessed = status.totalCount - status.status.reduce((prev, item) => item.count + prev, 0);
      element.push(renderItem('미처리', notProcessed, colors[0]));

      // 알람 상태별 카운트 컴포넌트 추가
      status.status.forEach((item, index) => {
        element.push(renderItem(item.code.codeName, item.count, colors[index + 1]));
      });
    }

    return element;
  }, [theme, status]);

  return (
    <Card
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        flex={1}
        alignItems="center"
      >
        {renderItems}
      </Box>
    </Card>
  );
}
