/* eslint-disable no-param-reassign */
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, MapControls, MapControlsProps, Sky, TransformControls, useHelper } from '@react-three/drei/native';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { IThreeModel } from '@types';
import ThreeModel from 'components/threemodel/ThreeModel';
import { DirectionalLightHelper, Euler, Object3D, Vector3 } from 'three';
import * as THREE from 'three';
import { useRecoilValue } from 'recoil';
import { selectedThreeNodeState } from 'states';
import CameraControls from 'camera-controls';

CameraControls.install({ THREE });

export type BaseCanvasProps = {
  showGrid?: boolean;
  showPlane?: boolean;
  showGridPoint?: boolean;
  backgroundColor?: string;
  gridPointModel?: IThreeModel;
  onClickGridPlane?(position: Vector3, rotation: Euler): void;
  onChangeSelectedPosition?(position: Vector3): void;
  onChangeSelectedRotation?(rotation: Euler): void;
  onESCPress?(): void;
  onBackspacePress?(): void;
  transformSelected?: Object3D | null;
  transformGrid?: number;
  children?: ReactElement | ReactElement[];
  enablePan?: boolean;
  enableRotate?: boolean;
  enableZoom?: boolean;
  autoRotate?: boolean;
  editMode?: boolean;
};

BaseCanvas.defaultProps = {
  showGrid: undefined,
  showPlane: undefined,
  showGridPoint: undefined,
  gridPointModel: undefined,
  onClickGridPlane: undefined,
  onChangeSelectedPosition: undefined,
  onChangeSelectedRotation: undefined,
  onESCPress: undefined,
  onBackspacePress: undefined,
  backgroundColor: '#111',
  transformSelected: null,
  transformGrid: 20,
  children: undefined,
  enablePan: true,
  enableRotate: true,
  enableZoom: true,
  autoRotate: false,
  editMode: false,
};

type KeyboardMapContorlProps = MapControlsProps;

function CustomCameraContol({ ...others }: KeyboardMapContorlProps) {
  const mapRef = useRef<any>(null);
  const camera = useThree((state) => state.camera);
  const renderer = useThree((state) => state.gl);

  const cameraControls = useMemo(() => {
    if (camera && renderer) return new CameraControls(camera, renderer.domElement);
    return undefined;
  }, [camera, renderer]);

  const selectedNode = useRecoilValue(selectedThreeNodeState);

  useFrame((_, delta) => cameraControls?.update(delta));
  useEffect(() => () => cameraControls?.dispose(), []);
  useEffect(() => {
    if (selectedNode) {
      const { x, y, z } = selectedNode.position;
      const { x: rotateX, y: rotateY, z: rotateZ } = selectedNode.rotation;
      const DEG10 = Math.PI / 10;

      cameraControls?.setLookAt(x + 30, y + 20, z + 30, x, y + 5, z, true);
      cameraControls?.rotateTo(rotateY + DEG10, DEG10 * 3, true);
    }
  }, [selectedNode]);

  useEffect(() => {
    mapRef?.current?.listenToKeyEvents(window);
  }, [mapRef]);

  return <MapControls ref={mapRef} camera={cameraControls?.camera} keyPanSpeed={20} autoRotateSpeed={1} {...others} />;
}

function CustomMapControl({ ...others }: KeyboardMapContorlProps) {
  const mapRef = useRef<any>(null);

  useEffect(() => {
    mapRef?.current?.listenToKeyEvents(window);
  }, [mapRef]);

  return <MapControls ref={mapRef} keyPanSpeed={20} autoRotateSpeed={1} {...others} />;
}

function CustomDirectionLight() {
  const SHADOW_MAP_SIZE = 2048;
  const light = useRef<any>();
  useHelper(light, DirectionalLightHelper);

  useEffect(() => {
    if (light.current) {
      console.log(light.current);
      light.current.shadow.camera.left = -1 * SHADOW_MAP_SIZE;
      light.current.shadow.camera.right = SHADOW_MAP_SIZE;
      light.current.shadow.camera.top = SHADOW_MAP_SIZE;
      light.current.shadow.camera.bottom = -1 * SHADOW_MAP_SIZE;

      light.current.shadow.camera.far = 2000;
      light.current.shadow.camera.near = 100;

      light.current.shadow.width = SHADOW_MAP_SIZE;
      light.current.shadow.height = SHADOW_MAP_SIZE;
    }
  }, [light]);

  return <directionalLight ref={light} position={[0, 300, 300]} castShadow />;
}

export default function BaseCanvas({
  showGrid,
  showPlane,
  showGridPoint,
  gridPointModel,
  onClickGridPlane,
  onChangeSelectedPosition,
  onChangeSelectedRotation,
  onESCPress,
  onBackspacePress,
  backgroundColor,
  transformSelected,
  transformGrid,
  enablePan,
  enableRotate,
  enableZoom,
  autoRotate,
  editMode,
  children,
}: BaseCanvasProps) {
  const [point, setPoint] = useState<Vector3>(new Vector3(0, 0, 0));
  const [focused, setFocused] = useState<boolean>(false);
  const [rotate, setRotate] = useState<number>(0);

  const gridPoint = useMemo(() => {
    const grid = 10;
    const x = Number((point.x / grid).toFixed()) * grid;
    const z = Number((point.z / grid).toFixed()) * grid;
    return new Vector3(x, 1, z);
  }, [point]);

  const gridRotation = useMemo(() => new Euler(0, rotate, 0), [rotate]);

  const handleClickGridPlane = useCallback(() => {
    if (typeof onClickGridPlane === 'function') {
      onClickGridPlane(gridPoint, gridRotation);
    }
  }, [onClickGridPlane, gridPoint, gridRotation]);

  const handleOnKeyDown = (e: KeyboardEvent) => {
    const { key } = e;

    if (key === 'q') {
      setRotate((prev) => prev - Math.PI * 0.1);
    }

    if (key === 'e') {
      setRotate((prev) => prev + Math.PI * 0.1);
    }

    if (key === 'Escape' && typeof onESCPress === 'function') {
      onESCPress();
    }

    if (key === 'Backspace' && typeof onBackspacePress === 'function') {
      onBackspacePress();
    }
  };

  const handleChangeSelectedPosition = useCallback(
    (e: THREE.Event | undefined) => {
      if (e && e.target && typeof onChangeSelectedPosition === 'function') {
        const object = e.target.object as Object3D;
        onChangeSelectedPosition(object.position);
      }
    },
    [onChangeSelectedPosition],
  );

  const handleChangeSelectedRotation = (e: THREE.Event | undefined) => {
    if (e && e.target && typeof onChangeSelectedRotation === 'function') {
      const object = e.target.object as Object3D;
      onChangeSelectedRotation(object.rotation);
    }
  };

  const renderPlane = useMemo(
    () =>
      showPlane && (
        <Box
          receiveShadow
          rotation-x={-1 * (Math.PI / 2)}
          args={[1000, 1000, 1, 2]}
          position={[0, -1, 0]}
          onPointerMove={(e) => setPoint(e.point)}
          onDoubleClick={handleClickGridPlane}
        >
          <meshStandardMaterial attach="material" color="#333" />
        </Box>
      ),
    [showPlane, setPoint, handleClickGridPlane],
  );

  const renderGridPoint = useMemo(() => {
    if (showPlane && showGridPoint) {
      return gridPointModel ? (
        <ThreeModel data={gridPointModel} position={gridPoint} rotation={gridRotation} />
      ) : (
        <mesh castShadow position={gridPoint} scale={3}>
          <sphereGeometry />
        </mesh>
      );
    }

    return undefined;
  }, [gridPointModel, gridPoint, gridRotation, showPlane, showGridPoint]);

  const renderTransformControls = useMemo(
    () =>
      transformSelected && (
        <>
          <TransformControls
            object={transformSelected}
            mode="translate"
            translationSnap={transformGrid || 20}
            onObjectChange={handleChangeSelectedPosition}
          />
          <TransformControls
            object={transformSelected}
            mode="rotate"
            showY
            showX={false}
            showZ={false}
            rotationSnap={Math.PI / 10}
            onObjectChange={handleChangeSelectedRotation}
          />
        </>
      ),
    [transformGrid, transformSelected, handleChangeSelectedPosition],
  );

  useEffect(() => {
    if (focused) window.addEventListener('keydown', handleOnKeyDown);

    return () => window.removeEventListener('keydown', handleOnKeyDown);
  }, [focused, onBackspacePress]);

  return (
    <Canvas
      style={{ width: '100%', height: '100%' }}
      camera={{ position: [0, 100, 50], zoom: 1, far: 45000, fov: 50 }}
      onMouseEnter={() => setFocused(true)}
      onMouseLeave={() => setFocused(false)}
    >
      {showGrid && <gridHelper args={[1000, 50, '#aaa', '#666']} />}
      {renderPlane}
      {renderGridPoint}

      {editMode ? (
        <CustomMapControl
          enablePan={enablePan && !transformSelected}
          enableZoom={enableZoom}
          enableRotate={enableRotate}
          autoRotate={autoRotate}
        />
      ) : (
        <CustomCameraContol />
      )}

      <Sky
        distance={45000}
        inclination={0.6}
        azimuth={0.1}
        mieCoefficient={0.005}
        mieDirectionalG={0.8}
        rayleigh={0.5}
        turbidity={10}
      />
      <ambientLight intensity={0.6} />
      <directionalLight intensity={1} position={[0, 300, 500]} castShadow />
      {/* <CustomDirectionLight /> */}
      {renderTransformControls}
      {backgroundColor && <color attach="background" args={[backgroundColor || '#111']} />}
      {children}
    </Canvas>
  );
}
