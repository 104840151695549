import { Add, Refresh, Save } from '@mui/icons-material';
import { Box, Card, IconButton, SxProps, Theme } from '@mui/material';
import React from 'react';
import ReactFlow, { Controls, DefaultEdgeOptions, FitViewOptions, MiniMap, ReactFlowProps } from 'react-flow-renderer';
import ButtonEdge from './ButtonEdge';
import GroupNodeDefault from './GroupNodeDefault';
import GroupNodeInput from './GroupNodeInput';
import GroupNodeOutput from './GroupNodeOutput';
import TagNodeDefault from './TagNodeDefault';
import TagNodeInput from './TagNodeInput';
import TagNodeOutput from './TagNodeOutput';

const fitViewOptions: FitViewOptions = {
  padding: 0.2,
};

const DEFAULT_EDGE_OPTIONS: DefaultEdgeOptions = {
  type: 'smooth',
  animated: true,
};

type Props = ReactFlowProps & {
  showMinimap?: boolean;
  showControl?: boolean;
  sx?: SxProps<Theme>;
  cardSx?: SxProps<Theme>;
  onClickAdd?(): void;
  onClickSave?(): void;
  onClickRefresh?(): void;
};

ReactFlowCard.defaultProps = {
  showMinimap: false,
  showControl: false,
  cardSx: {},
  sx: {},
  onClickAdd: undefined,
  onClickSave: undefined,
  onClickRefresh: undefined,
};

const nodeTypes = {
  tagNodeInput: TagNodeInput,
  tagNodeDefault: TagNodeDefault,
  tagNodeOutput: TagNodeOutput,
  groupNodeDefault: GroupNodeDefault,
  groupNodeInput: GroupNodeInput,
  groupNodeOutput: GroupNodeOutput,
};
const edgeTypes = { buttonEdge: ButtonEdge };

export default function ReactFlowCard({
  showControl,
  showMinimap,
  sx,
  cardSx,
  onClickAdd,
  onClickSave,
  onClickRefresh,
  ...others
}: Props) {
  return (
    <Box component="div" sx={sx}>
      <Box component="div">
        {onClickAdd && (
          <IconButton onClick={onClickAdd}>
            <Add />
          </IconButton>
        )}
        {onClickRefresh && (
          <IconButton onClick={onClickRefresh}>
            <Refresh />
          </IconButton>
        )}
        {onClickSave && (
          <IconButton onClick={onClickSave}>
            <Save />
          </IconButton>
        )}
      </Box>
      <Card variant="outlined" sx={{ ...cardSx }}>
        <ReactFlow
          fitView
          fitViewOptions={fitViewOptions}
          attributionPosition="bottom-left"
          defaultEdgeOptions={DEFAULT_EDGE_OPTIONS}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          snapToGrid
          snapGrid={[10, 10]}
          {...others}
        >
          {showMinimap && (
            <MiniMap color="#222" nodeStrokeColor="#ccc" nodeColor="#aaa" style={{ backgroundColor: '#222' }} />
          )}
          {showControl && <Controls />}
        </ReactFlow>
      </Card>
    </Box>
  );
}
