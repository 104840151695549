import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormLabel } from '@mui/material';
import moment from 'moment';
import BaseDatePicker from './BaseDatePicker';

export type DateRange = {
  start?: moment.Moment;
  end?: moment.Moment;
};

type Props = {
  label?: string;
  defaultValue?: DateRange;
  onChange?(name: string, value: moment.Moment): void;
};

BaseDateRangePicker.defaultProps = {
  label: undefined,
  onChange: undefined,
  defaultValue: {
    start: moment().add(-1, 'day'),
    end: moment(),
  },
};

export default function BaseDateRangePicker({ onChange, label, defaultValue }: Props) {
  const [state, setState] = useState<DateRange | null>(null);

  const handleDateChange = (name: string, changedDate: moment.Moment) => {
    if (name === 'start') {
      setState((prev) => ({ ...prev, [name]: changedDate.hour(0).minute(0) }));
    } else {
      setState((prev) => ({ ...prev, [name]: changedDate.hour(23).minute(59) }));
    }

    if (typeof onChange === 'function') {
      onChange(name, changedDate);
    }
  };

  useEffect(() => {
    if (state === null) {
      if (defaultValue && defaultValue?.start && defaultValue?.end) {
        setState(defaultValue || null);
        handleDateChange('start', defaultValue.start);
        handleDateChange('end', defaultValue.end);
      }
    }
  }, [defaultValue]);

  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <Box component="div" display="flex">
        <Box component="div" flex={1} sx={{ mr: 2 }}>
          <BaseDatePicker
            fullWidth
            name="start"
            defaultValue={defaultValue?.start}
            value={state?.start}
            onChange={handleDateChange}
          />
        </Box>
        <Box component="div" flex={1}>
          <BaseDatePicker
            fullWidth
            name="end"
            defaultValue={defaultValue?.end}
            value={state?.end}
            onChange={handleDateChange}
          />
        </Box>
      </Box>
    </FormControl>
  );
}
