/* eslint-disable no-param-reassign */
import { API_URL, DEPLOY } from '@const';
import { MpSdk } from '@matterport/webcomponent';
import {
  IAlarm,
  IAlarmHist,
  ICode,
  IData1,
  IEmissionItem,
  IFile,
  IGroup,
  IGroupFieldData,
  IGroupType,
  ILocation,
  IResponse,
  ISite,
  TData10FindRequest,
  TDataSet,
} from '@types';
import { AxiosError, AxiosResponse } from 'axios';
import { DateSearchProps } from 'components/common/DateSearchBox';
import { MaterialMap } from 'components/three/EquipMesh';
import moment from 'moment';
import { CSSProperties } from 'react';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { Group, Mesh, MeshStandardMaterial, TextureLoader } from 'three';

/**
 * API 호출 시, Cookie에서 token을 읽어 header에 반환
 * @returns cookie 토큰값
 */
export const createAuth = () => {
  const cookie = new Cookies();
  const token = cookie.get('token');

  return {
    headers: {
      Authorization: token,
    },
  };
};

export const checkError = (e: AxiosError): AxiosResponse<IResponse<any>> | null => {
  if (!e.response) return null;

  if (e.response.status === 403) {
    return {
      ...e.response,
      data: {
        success: false,
        errCode: '403_ERR',
        errMessage: '다른 곳에서 로그인이 되었거나, 권한이 없습니다. 다시 로그인해주세요.',
      },
    };
  }

  if (e.response.data) {
    const convert = e.response.data as IResponse<any>;

    return {
      ...e.response,
      data: convert,
    };
  }

  return null;
};

export const toastErrorMessage = (data: any) => {
  if (!data.success) {
    if (data.errCode !== 'DEFAULT_EXCEPTION') {
      toast(data.errMessage, { type: 'error', autoClose: 5000 });
    } else {
      console.log(data);
    }
  }
};

/**
 * orderNum으로 코드 정렬
 * @param code
 * @returns
 */
export const sortCode = (code: readonly ICode[]): ICode[] => {
  return [...code]
    .sort((prev, current) => prev.orderNum - current.orderNum)
    .map((c) => ({ ...c, children: sortCode(c.children) }));
};

/**
 * groupType OrderNum 정렬
 * @param groupType
 * @returns
 */
export const sortGroupType = (groupType: readonly IGroupType[]): IGroupType[] => {
  return [...groupType]
    .sort((prev, current) => prev.orderNum - current.orderNum)
    .map((c) => ({ ...c, children: sortGroupType(c.children) }));
};

/**
 * EmissionItem OrderNum 정렬
 * @param emissionItems
 * @returns
 */
export const sortEmission = (emissionItems: readonly IEmissionItem[]): IEmissionItem[] => {
  return [...emissionItems]
    .sort((prev, current) => prev.orderNum - current.orderNum)
    .map((c) => ({ ...c, children: sortEmission(c.children) }));
};

type Flatable = ICode | ISite | IGroup | IGroupType | IEmissionItem;

/**
 *
 * @param site flattable Types
 * @returns flatted array
 */
export const flatten = (site: readonly Flatable[]): Flatable[] => {
  return [...site.map((s) => ({ ...s, children: [] })), ...site.map((s) => flatten(s.children)).flat()];
};

// Get Codes
export const getCode = (codes: ICode[], pCode: string): readonly ICode[] => {
  return sortCode((flatten(codes) as ICode[]).filter((code) => code.parent === pCode));
};

export type GroupFieldTypes =
  | 'FIELD_TAG'
  | 'FIELD_NUMBER'
  | 'FIELD_TEXT'
  | 'FIELD_3D'
  | 'FIELD_GPS'
  | 'FIELD_GROUP'
  | 'ALL';

// FieldType Filtering
export const filterGroupFieldType = (group: IGroup, fieldType: GroupFieldTypes) => {
  return {
    ...group,
    groupFieldDatas: group.groupFieldDatas?.filter((gfd) =>
      fieldType === 'ALL' ? true : gfd.field.fieldType.code === fieldType,
    ),
  };
};

// GroupFieldData Filtering
export const filterGroupFieldData = (group?: IGroup, fieldType?: GroupFieldTypes): IGroupFieldData[] => {
  if (!group || !fieldType) return [];
  return group.groupFieldDatas.filter((gfd) => gfd.field.fieldType.code === fieldType);
};

// 필드코드를 통해 그룹필드데이터 조회
export const filterGFDByGroupFieldCode = (group?: IGroup, fieldCode?: string): IGroupFieldData[] => {
  if (!group || !fieldCode) return [];
  return group.groupFieldDatas.filter((gfd) => gfd.field.code === fieldCode);
};

// 그룹의 Location 데이터 찾기
export const getLocationFromGroup = (group?: IGroup): ILocation | undefined => {
  const gpsData = filterGroupFieldData(group, 'FIELD_GPS').at(0);

  if (!gpsData) return undefined;

  return JSON.parse(gpsData.data ?? '{}') as ILocation;
};

// 그룹타입으로 필터링
export const filterGroupType = (groups: IGroup[], groupTypeCode: string): IGroup[] => {
  return groups.filter((group) => group.groupType.code === groupTypeCode);
};

export const loadMaterial = (
  materialMap: MaterialMap,
  path: string,
  name: string,
  color: string | undefined = undefined,
) => {
  const texturePath = `${path}${name}.jpg`;
  const key = `${texturePath}${color && color}`;

  if (materialMap[key]) {
    return materialMap[key];
  }

  const texture = new TextureLoader().load(texturePath);

  materialMap[key] = new MeshStandardMaterial({
    map: texture,
    color,
  });

  return materialMap[key];
};

// Init FBX Material
export const initMaterial = async (
  fbx: Group,
  materialMap: MaterialMap,
  path: string,
  color: string | undefined = undefined,
) => {
  fbx.traverse((child_: any) => {
    if (child_ instanceof Mesh) {
      const child = child_;
      child.material = loadMaterial(materialMap, path, child.name, color);
    }
  });
};

export const getFileURL = (file: IFile | undefined) => {
  if (!file) return undefined;

  if (DEPLOY === 'SERVER') {
    return `${API_URL}/file/${file.path}${file.filename}`;
  }

  return `${API_URL}${file.url}`;
};

export const getDateRange = (
  dateProps?: DateSearchProps,
  format = 'YYYY-MM-DD HH:mm:ss',
): Omit<TData10FindRequest, 'tagTypes'> => {
  if (dateProps) {
    if (dateProps.type === 'CUSTOM') {
      return {
        start: dateProps.start?.format(format) || moment().format(format),
        end: dateProps.end?.format(format) || moment().format(format),
        aggUnit: dateProps.durationUnit,
        aggVal: dateProps.duration,
      };
    }

    if (dateProps.type === 'day') {
      return {
        start:
          dateProps?.start?.set('hour', 0).set('minute', 0).set('second', 0).format(format) || moment().format(format),
        end:
          dateProps?.start?.set('hour', 23).set('minute', 59).set('second', 59).format(format) ||
          moment().format(format),
        aggUnit: 'minute',
        aggVal: 15,
      };
    }

    if (dateProps.type === 'dayRange') {
      return {
        start:
          dateProps?.start?.set('hour', 0).set('minute', 0).set('second', 0).format(format) || moment().format(format),
        end:
          dateProps?.end?.set('hour', 23).set('minute', 59).set('second', 59).format(format) || moment().format(format),
        aggUnit: 'day',
        aggVal: 1,
      };
    }

    if (dateProps.type === 'month') {
      return {
        start:
          dateProps?.start?.set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).format(format) ||
          moment().format(format),
        end:
          dateProps?.start
            ?.set('date', dateProps.start?.daysInMonth())
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59)
            .format(format) || moment().format(format),
        aggUnit: 'day',
        aggVal: 1,
      };
    }

    if (dateProps.type === 'year') {
      return {
        start:
          dateProps?.start
            ?.set('month', 0)
            .set('date', 1)
            .set('hour', 0)
            .set('minute', 0)
            .set('second', 0)
            .format(format) || moment().format(format),
        end:
          dateProps?.start
            ?.set('month', 11)
            .set('date', 31)
            .set('hour', 23)
            .set('minute', 59)
            .set('second', 59)
            .format(format) || moment().format(format),
        aggUnit: 'month',
        aggVal: 1,
      };
    }

    if (dateProps.type === 'currentDay') {
      return {
        start: moment().set('hour', 0).set('minute', 0).set('second', 0).format(format),
        end: moment().set('hour', 23).set('minute', 59).set('second', 59).format(format),
        aggUnit: 'minute',
        aggVal: 15,
      };
    }

    if (dateProps.type === 'currentMonth') {
      return {
        start: moment().set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).format(format),
        end: moment().set('hour', 23).set('minute', 59).set('second', 59).format(format),
        aggUnit: 'day',
        aggVal: 1,
      };
    }

    if (dateProps.type === 'currentYear') {
      return {
        start: moment().set('month', 0).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).format(format),
        end: moment().set('hour', 23).set('minute', 59).set('second', 59).format(format),
        aggUnit: 'month',
        aggVal: 1,
      };
    }

    return {
      start: dateProps?.start?.set('second', 0).format(format) || moment().format(format),
      end: dateProps?.end?.set('second', 0).format(format) || moment().format(format),
    };
  }

  return {
    start: moment().format(format),
    end: moment().format(format),
  };
};

export const getPrevDateRange = (dateProps?: DateSearchProps, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (dateProps) {
    const defaultDate = moment().format(format);
    if (dateProps.type === 'day') {
      const start = dateProps?.start?.clone().set('hour', 0).set('minute', 0).set('second', 0);
      const end = dateProps?.start?.clone().set('hour', 23).set('minute', 59).set('second', 59);

      start?.add(-1, 'd');
      end?.add(-1, 'd');

      return {
        start: start?.format(format) || defaultDate,
        end: end?.format(format) || defaultDate,
        aggUnit: 'minute',
        aggVal: 15,
      };
    }

    if (dateProps.type === 'month') {
      const start = dateProps?.start?.clone().set('date', 1).set('hour', 0).set('minute', 0).set('second', 0);
      const end = dateProps?.start?.clone().set('hour', 23).set('minute', 59).set('second', 59);

      start?.add(-1, 'month');
      end?.add(-1, 'month');
      end?.set('date', end.daysInMonth());

      return {
        start: start?.format(format) || defaultDate,
        end: end?.format(format) || defaultDate,
        aggUnit: 'day',
        aggVal: 1,
      };
    }

    if (dateProps.type === 'dayRange') {
      const start = dateProps?.start?.clone().set('hour', 0).set('minute', 0).set('second', 0);
      const end = dateProps?.end?.clone().set('hour', 23).set('minute', 59).set('second', 59);

      return {
        start: start?.format(format) || defaultDate,
        end: end?.format(format) || defaultDate,
        aggUnit: 'day',
        aggVal: 1,
      };
    }

    if (dateProps.type === 'year') {
      const start = dateProps?.start
        ?.clone()
        .set('month', 0)
        .set('date', 1)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0);
      const end = dateProps?.start
        ?.clone()
        .set('month', 11)
        .set('date', 31)
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59);

      start?.add(-1, 'year');
      end?.add(-1, 'year');

      return {
        start: start?.format(format) || defaultDate,
        end: end?.format(format) || defaultDate,
        aggUnit: 'month',
        aggVal: 1,
      };
    }

    if (dateProps.type === 'currentDay') {
      return {
        start: moment().add(-1, 'day').set('hour', 0).set('minute', 0).set('second', 0).format(format),
        end: moment().add(-1, 'day').set('hour', 23).set('minute', 59).set('second', 59).format(format),
        aggUnit: 'minute',
        aggVal: 15,
      };
    }

    if (dateProps.type === 'currentMonth') {
      return {
        start: moment().add(-1, 'month').set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).format(format),
        end: moment()
          .add(-1, 'month')
          .set('date', moment().add(-1, 'month').daysInMonth())
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 59)
          .format(format),
        aggUnit: 'day',
        aggVal: 1,
      };
    }

    if (dateProps.type === 'currentYear') {
      return {
        start: moment()
          .add(-1, 'year')
          .set('month', 0)
          .set('date', 1)
          .set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .format(format),
        end: moment()
          .add(-1, 'year')
          .set('month', 11)
          .set('date', 31)
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 59)
          .format(format),
        aggUnit: 'month',
        aggVal: 1,
      };
    }

    return {
      start: dateProps.start?.add(-1, 'day').set('second', 0).format(format) || moment().format(format),
      end: dateProps?.end?.add(-1, 'day').set('second', 0).format(format) || moment().format(format),
    };
  }

  return {
    start: moment().format(format),
    end: moment().format(format),
  };
};

export const createFormData = (request: any): FormData => {
  const frm = new FormData();

  Object.keys(request).forEach((key) => {
    if (request[key]) {
      frm.append(key, request[key] as any);
    }
  });

  return frm;
};

export const getPrevNameByDateProp = (dateProps?: DateSearchProps, prefix = '', postfix = '') => {
  let dateName = '';
  switch (dateProps?.type) {
    case 'currentDay':
    case 'currentDayASC':
    case 'currentDayDESC':
    case 'dayLast':
    case 'day':
      dateName = '전일';
      break;
    case 'currentMonth':
    case 'currentMonthASC':
    case 'currentMonthDESC':
    case 'monthLast':
    case 'month':
      dateName = '전월';
      break;
    case 'currentYear':
    case 'currentYearASC':
    case 'currentYearDESC':
    case 'yearLast':
    case 'year':
      dateName = '전년';
      break;
    default:
      dateName = '';
  }

  return `${prefix}${dateName}${postfix}`;
};

// 알람 상태 코드
export type ALERT_TYPE = 'NODATA' | 'NORMAL' | 'WARNING' | 'ALERT';

// 값을 통해 알람체크
export function checkAlert(alarm?: IAlarm, value?: number, dtt?: moment.Moment): ALERT_TYPE {
  if (dtt) {
    const now = moment();

    if (now.diff(dtt, 'minute') > 15) {
      return 'NODATA';
    }
  }

  if (!dtt) return 'NODATA';

  if (value === undefined) return 'NODATA';

  if (!value || !alarm) return 'NORMAL';

  if (alarm.max && value > alarm.max) return 'ALERT';
  if (alarm.min && value < alarm.min) return 'ALERT';

  if (alarm.alertMin && value < alarm.alertMin) return 'WARNING';
  if (alarm.alertMax && value > alarm.alertMax) return 'WARNING';

  return 'NORMAL';
}

// 데이터를 통해 알람체크
// 본 로직에서는 데이터의 최종값이 현재시간보다 5분이상 지체되었는지 판단하여 NODATA ALERT를 표시함
export function checkAlertWithData(alarm?: IAlarm, data?: IData1): ALERT_TYPE {
  if (!alarm || !data) return 'NODATA';

  const dtt = moment(data.dtt);

  return checkAlert(alarm, data.value, dtt);
}

// 데이터셋을 통해 알람체크
export function checkAlertWithDataset(dataset: TDataSet[]): ALERT_TYPE {
  const alertedDataset = dataset
    .flatMap((ds) => ds.tag.alarms?.map((alarm) => checkAlertWithData(alarm, ds.data.at(-1))))
    .filter((alrt) => alrt !== 'NORMAL' && alrt !== undefined);

  return checkAlertWithAlertList(alertedDataset);
}

export function checkAlertWithAlertList(alerts: (ALERT_TYPE | undefined)[]): ALERT_TYPE {
  if (alerts.length === 0) return 'NORMAL';

  const hasAlert = alerts.filter((alrt) => alrt === 'ALERT');
  const hasWarning = alerts.filter((alrt) => alrt === 'WARNING');
  const hasNodata = alerts.filter((alrt) => alrt === 'NODATA');

  if (hasNodata.length > 0) return 'NODATA';
  if (hasAlert.length > 0) return 'ALERT';
  if (hasWarning.length > 0) return 'WARNING';

  return 'NORMAL';
}

// 알람이력을 통해 알람체크
export function checkAlertWithAlarmHist(alarmHist: IAlarmHist[]): ALERT_TYPE {
  const alertedList = alarmHist.flatMap((ah) => checkAlert(ah.alarm, ah.value));
  return checkAlertWithAlertList(alertedList);
}

// 알람에 따른 색상정보 반환
export function getAlertColor(alertType?: ALERT_TYPE): CSSProperties {
  switch (alertType) {
    case 'ALERT':
      return { backgroundColor: '#CF0A0A' };
    case 'WARNING':
      return { backgroundColor: '#DC5F00' };
    case 'NORMAL':
      return {};
    case 'NODATA':
      return { backgroundColor: '#DC9444' };
    default:
      return {};
  }
}

export function getAlertText(alertType?: ALERT_TYPE): string {
  if (!alertType) return '';

  switch (alertType) {
    case 'ALERT':
      return '알람발생';
    case 'WARNING':
      return '경보발생';
    case 'NODATA':
      return '네트워크오류발생';
    default:
      return '';
  }
}

// Matterport SDK

// Create Matterport SDK Tag Position
export function createTagPosition(pointer?: MpSdk.Pointer.Intersection): MpSdk.Tag.Descriptor | undefined {
  if (pointer) {
    const stemVector = { ...pointer.normal };
    stemVector.x = Math.abs(stemVector.x) < 0.5 ? 0 : stemVector.x * 0.5;
    stemVector.y = Math.abs(stemVector.y) < 0.5 ? 0 : stemVector.y * 0.5;
    stemVector.z = Math.abs(stemVector.z) < 0.5 ? 0 : stemVector.z * 0.5;

    return {
      label: '',
      anchorPosition: pointer.position,
      stemVector,
    };
  }

  return undefined;
}

export function createChunk<T>(arr: T[], chunkSize: number): T[][] {
  const chunkList = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    chunkList.push(chunk);
  }

  return chunkList;
}
