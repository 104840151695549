import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, Card, SelectChangeEvent, SxProps, TextField, Theme, Typography } from '@mui/material';
import { IGroupType, TGroupFieldCreateRequest, TGroupTypeCreateRequest } from '@types';
import GroupFieldList from 'components/groupfield/GroupFieldList';
import useGroupFieldModal from 'common/hooks/useGroupFieldModal';
import BaseCheckbox from 'components/common/BaseCheckbox';

type Props = {
  data?: Partial<IGroupType>;
  onSave?(group: TGroupTypeCreateRequest): void;
  onDelete?(groupCode: string): void;
  containerStyle?: SxProps<Theme>;
};

GroupTypeForm.defaultProps = {
  data: undefined,
  onSave: undefined,
  onDelete: undefined,
  containerStyle: {},
};

export default function GroupTypeForm({ data, onSave, onDelete, containerStyle }: Props) {
  const handleSaveClick = () => {
    if (typeof onSave === 'function') {
      onSave(state);
    }
  };

  const handleDeleteClick = () => {
    if (typeof onDelete === 'function' && data && data.code) {
      if (window.confirm('그룹을 삭제하시겠습니까?')) {
        onDelete(data.code);
      }
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onAddGroupField = (groupfield: TGroupFieldCreateRequest) => {
    setState((prev) => ({
      ...prev,
      fields: [...(prev.fields ?? []), { ...groupfield }],
    }));
    modalClose();
  };

  const onModifyGroupField = (groupfield: TGroupFieldCreateRequest) => {
    setState((prev) => ({
      ...prev,
      fields: [
        ...(prev.fields ?? []).map((field) => (field.code === groupfield.code ? { ...groupfield } : { ...field })),
      ],
    }));

    modalClose();
  };

  const onDeleteGroupField = (groupField: TGroupFieldCreateRequest) => {
    setState((prev) => ({
      ...prev,
      fields: [...(prev.fields ?? []).filter((field) => field.code !== groupField.code)],
    }));
    modalClose();
  };

  const [state, setState] = useState<TGroupTypeCreateRequest>({});

  const {
    modal: GroupFieldModal,
    modalClose,
    handleNewGroupFieldOpen,
    handleModifyGroupFieldOpen,
  } = useGroupFieldModal({
    onAddGroupField,
    onModifyGroupField,
    onDeleteGroupField,
  });

  useEffect(() => {
    setState({
      ...data,
      fields: data?.fields?.map((field) => ({ ...field, fieldType: field.fieldType.code })),
    });
  }, [data]);

  return (
    <Card variant="outlined" sx={{ p: 2, ...containerStyle }}>
      <Box component="div" display="flex" flexDirection="column" justifyContent="space-around">
        <TextField
          fullWidth
          name="parent"
          variant="standard"
          label="부모코드"
          InputLabelProps={{ shrink: true }}
          value={state?.parent ?? ''}
          onChange={onChange}
        />
        <Box component="div" mt={1} />
        <TextField
          fullWidth
          name="code"
          variant="standard"
          label="코드"
          InputLabelProps={{ shrink: true }}
          value={state?.code ?? ''}
          onChange={onChange}
        />
        <Box component="div" mt={1} />
        <TextField
          fullWidth
          name="name"
          variant="standard"
          label="유형명"
          InputLabelProps={{ shrink: true }}
          value={state?.name ?? ''}
          onChange={onChange}
        />
        <Box component="div" mt={1} />
        <TextField
          fullWidth
          variant="standard"
          label="순서"
          name="orderNum"
          InputLabelProps={{ shrink: true }}
          value={state?.orderNum}
          type="number"
          onChange={onChange}
        />
        <Box component="div" mt={1} />
        <BaseCheckbox name="useYN" label="사용여부" checked={state?.useYN} onChange={onChange} />
        <Box component="div" mt={1} />
        <GroupFieldList
          data={state.fields}
          onAddClick={() => handleNewGroupFieldOpen((state.fields?.length || 0) + 1)}
          onModifyClick={handleModifyGroupFieldOpen}
          onOrderChange={onModifyGroupField}
        />
        <Box component="div" display="flex" flexDirection="column">
          <Button sx={{ marginY: 1 }} onClick={handleSaveClick} fullWidth variant="outlined">
            <Typography>저장</Typography>
          </Button>
          {/* <Button sx={{ marginY: 1 }} onClick={handleDeleteClick} fullWidth variant="outlined" color="error">
            <Typography>삭제</Typography>
          </Button> */}
        </Box>
      </Box>
      {GroupFieldModal}
    </Card>
  );
}
