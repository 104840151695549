import { ENDPOINT } from '@const';
import { toast } from 'react-toastify';
import { TCodeCreateRequest, TCodeCreateResponse, TCodeResponse } from '@types';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';
import axios, { AxiosError } from 'axios';

/**
 * 모든 코드 조회 API
 * @returns 조회된 코드
 */
const findAllCodesAPI = async (): Promise<TCodeResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.code.findAllCodes,
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    throw Error('코드 조회 실패');
  }

  toastErrorMessage(result.data);

  return result.data;
};

/**
 * 코드 생성 API
 * @param request 코드 생성 요청 데이터
 * @returns 생성된 코드 정보
 */
const createCodeAPI = async (request: TCodeCreateRequest): Promise<TCodeCreateResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.code.create,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    toast('코드 저장 실패', { type: 'error', autoClose: 5000 });
    throw Error('코드 생성 실패');
  }

  const data = result.data as TCodeCreateResponse;

  toastErrorMessage(data);

  return data;
};

/**
 * 코드 수정 API
 * @param request 코드 수정 요청 데이터
 * @returns 수정된 코드 정보
 */
const modifyCodeAPI = async (request: TCodeCreateRequest): Promise<TCodeCreateResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.code.modify,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) {
    toast('코드 저장 실패', { type: 'error', autoClose: 5000 });
    throw Error('코드 저장 실패');
  }
  toastErrorMessage(result.data);

  const data = result.data as TCodeCreateResponse;

  return data;
};

export { findAllCodesAPI, createCodeAPI, modifyCodeAPI };
