import BaseSelectBox, { BaseSelectBoxProps } from 'components/common/BaseSelectBox';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { costState } from 'states';

type Props = BaseSelectBoxProps;

export default function CostSelectBox({ ...others }: Props) {
  const costs = useRecoilValue(costState);
  const menus = useMemo(
    () => costs.map((cost) => ({ id: cost.code, name: `${cost.name} (${cost.unitCode.codeName})` })),
    [costs],
  );

  return <BaseSelectBox label="비용" {...others} items={menus} />;
}
