import { Add } from '@mui/icons-material';
import { Box, Chip, IconButton, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { roleState } from 'states';
import RoleSelectBox from './RoleSelectBox';

type Props = {
  name?: string;
  label?: string;
  containerSx?: SxProps<Theme>;
  onSelectChange?(name: string | undefined, roles: string[]): void;
  defaultValue?: string[];
};

RoleMultiSelectBox.defaultProps = {
  name: undefined,
  label: undefined,
  containerSx: undefined,
  onSelectChange: undefined,
  defaultValue: undefined,
};

export default function RoleMultiSelectBox({ defaultValue, name, label, onSelectChange, containerSx }: Props) {
  const [state, setState] = useState<string[]>(defaultValue || []);
  const [selectedRole, setSelectedRole] = useState<string | undefined>(undefined);
  const roles = useRecoilValue(roleState);

  const handleChange = useCallback(() => {
    if (typeof onSelectChange === 'function') {
      onSelectChange(name, state);
    }
  }, [state]);

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setSelectedRole(value);
  };

  const handleAddRole = () => {
    if (selectedRole) {
      setState((prev) => _.uniq([...prev, selectedRole]));
      setSelectedRole(undefined);
    }
  };

  const handleDeleteRole = (role: string) => {
    setState((prev) => prev.filter((prole) => prole !== role));
  };

  const renderChips = useMemo(
    () =>
      state.map((role) => (
        <Chip key={role} label={role} onDelete={() => handleDeleteRole(role)} size="small" sx={{ m: 0.5 }} />
      )),
    [state],
  );

  useEffect(() => {
    handleChange();
  }, [handleChange]);

  return (
    <Box component="div" sx={containerSx}>
      <Box component="div" display="flex" flexDirection="row" alignItems="center">
        <RoleSelectBox
          name={name}
          label={label}
          data={roles}
          onChange={handleSelectChange}
          value={selectedRole || ''}
        />
        <IconButton disabled={!selectedRole} onClick={handleAddRole}>
          <Add />
        </IconButton>
      </Box>
      {renderChips}
    </Box>
  );
}
