import React, { useState } from 'react';
import { DataLayout } from 'layout/data';
import NodeSystemSelectBox from 'components/nodesystem/NodeSystemSelectBox';
import { INodeSystem } from '@types';
import { findAllNodeSystemAPI } from 'apis/nodesystem';
import { useQuery } from 'react-query';
import useReactFlow from 'common/hooks/useReactFlow';

export default function DataNodePage() {
  const [selectedNodeSystem, setSelectedNodeSystem] = useState<INodeSystem | null>();
  const { data } = useQuery(['allNodeSystems'], findAllNodeSystemAPI);
  const { reactFlow } = useReactFlow({
    nodeSystem: selectedNodeSystem,
    cardSx: { height: '70vh' },
  });

  return (
    <DataLayout title="그룹 노드 조회">
      <NodeSystemSelectBox data={data?.data} onChangeNodeSystem={setSelectedNodeSystem} sx={{ mb: 1 }} />
      {reactFlow}
    </DataLayout>
  );
}
