import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, Card, SelectChangeEvent, SxProps, TextField, Theme, Typography } from '@mui/material';
import { IGroup, ITag, TGroupCreateRequest, TGroupFieldDataCreateRequest } from '@types';
import { useRecoilValue } from 'recoil';
import { flatGroupTypeState, flatSiteState } from 'states';
import SiteSelectBox from 'components/site/SiteSelectBox';
import BaseCheckbox from 'components/common/BaseCheckbox';
import SynonymInput from 'components/voice/SynonymInput';
import GroupTypeSelectBox from '../grouptype/GroupTypeSelectBox';
import GroupFieldDataBox from './GroupFieldDataBox';

type Props = {
  data?: Partial<IGroup>;
  onSave?(group: TGroupCreateRequest): void;
  onDelete?(groupCode: string): void;
  containerStyle?: SxProps<Theme>;
};

GroupForm.defaultProps = {
  data: undefined,
  onSave: undefined,
  onDelete: undefined,
  containerStyle: {},
};

export default function GroupForm({ data, onSave, onDelete, containerStyle }: Props) {
  const handleSaveClick = () => {
    if (typeof onSave === 'function' && data) {
      const newDatafields = new Set([...groupTypeDatas, ...(state.groupDetailType ? groupDetailTypeDatas : [])]);
      const groupFieldDatas = Array.from(newDatafields);

      onSave({ ...state, groupFieldDatas });
    }
  };

  const handleDeleteClick = () => {
    if (typeof onDelete === 'function' && data && data.groupCode) {
      if (window.confirm('그룹을 삭제하시겠습니까?')) {
        onDelete(data.groupCode);
      }
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setState((prev) => ({ ...prev, [name]: checked }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value === '' ? undefined : value }));
  };

  // 그룹유형 데이터 세팅
  const handleGroupTypeData = (changedFieldData: TGroupFieldDataCreateRequest[]) => {
    setGroupTypeDatas(changedFieldData);
  };

  // 그룹상세유형 데이터 세팅
  const handleGroupDetailTypeData = (changedFieldData: TGroupFieldDataCreateRequest[]) => {
    setGroupDetailTypeDatas(changedFieldData);
  };

  // 동의어 변경
  const handleChangeSynonym = (synonym: string[]) => {
    if (synonym) {
      setState((prev) => ({ ...prev, synonym }));
    }
  };

  const [state, setState] = useState<TGroupCreateRequest>({});
  const [tags, setTags] = useState<ITag[]>([]);
  const [groupTypeDatas, setGroupTypeDatas] = useState<TGroupFieldDataCreateRequest[]>([]);
  const [groupDetailTypeDatas, setGroupDetailTypeDatas] = useState<TGroupFieldDataCreateRequest[]>([]);

  const flatGroupType = useRecoilValue(flatGroupTypeState);
  const flatSites = useRecoilValue(flatSiteState);

  useEffect(() => {
    setState({
      ...data,
      tags: [],
      site: data?.site?.siteCode,
      groupType: data?.groupType?.code,
      groupDetailType: data?.groupDetailType?.code,
      groupFieldDatas: [],
      // groupFieldDatas: data?.groupFieldDatas?.map((fieldData) => ({ ...fieldData, field: fieldData.field.id })),
    });

    setTags(data?.tags ?? []);
  }, [data]);

  useEffect(() => {
    setState((prev) => ({ ...prev, tags: tags.map((tag) => tag.tagCode) }));
  }, [tags]);

  return (
    <Card variant="outlined" sx={{ p: 2, ...containerStyle }}>
      <Box component="div" display="flex" flexDirection="column" justifyContent="space-around">
        <TextField name="id" type="hidden" sx={{ display: 'none' }} value={data?.id ?? 0} />
        <Box component="div" mt={1} />
        <TextField
          fullWidth
          name="parent"
          variant="standard"
          label="부모코드"
          InputLabelProps={{ shrink: true }}
          value={state?.parent ?? ''}
          onChange={onChange}
        />
        <Box component="div" mt={1} />
        <TextField
          fullWidth
          name="groupCode"
          variant="standard"
          label="그룹 코드"
          InputLabelProps={{ shrink: true }}
          value={state?.groupCode ?? ''}
          onChange={onChange}
        />
        <Box component="div" mt={1} />
        <TextField
          fullWidth
          name="groupName"
          variant="standard"
          label="그룹명"
          InputLabelProps={{ shrink: true }}
          value={state?.groupName ?? ''}
          onChange={onChange}
        />
        <Box component="div" mt={1} />
        <SynonymInput defaultValue={state.synonym} onChange={handleChangeSynonym} />
        <Box component="div" mt={1} />
        <SiteSelectBox
          variant="standard"
          useYN
          label="사이트"
          name="site"
          data={flatSites}
          value={state.site ?? ''}
          defaultValue={data?.site?.siteCode ?? ''}
          onChange={onSelect}
        />
        <Box component="div" mt={1} />
        <GroupTypeSelectBox
          variant="standard"
          label="그룹유형"
          name="groupType"
          data={flatGroupType}
          value={state.groupType ?? ''}
          useYN
          defaultValue={data?.groupType?.code ?? ''}
          onChange={onSelect}
        />
        <GroupFieldDataBox
          label="그룹유형필드"
          typeCode={state.groupType}
          data={data?.groupFieldDatas}
          onChangeData={handleGroupTypeData}
        />
        <Box component="div" mt={1} />
        <GroupTypeSelectBox
          variant="standard"
          label="그룹상세유형"
          name="groupDetailType"
          parentId={state.groupType}
          data={flatGroupType}
          useYN
          value={state.groupDetailType ?? ''}
          defaultValue={data?.groupDetailType?.code ?? ''}
          onChange={onSelect}
          showEmpty
        />
        <GroupFieldDataBox
          label="그룹상세유형필드"
          typeCode={state.groupDetailType}
          data={data?.groupFieldDatas}
          onChangeData={handleGroupDetailTypeData}
        />
        <Box component="div" mt={1} />
        <BaseCheckbox
          name="useYN"
          label="사용여부"
          defaultChecked={data?.useYN}
          checked={state.useYN}
          onChange={onChange}
        />
        <Box component="div" display="flex" flexDirection="column">
          <Button sx={{ marginY: 1 }} onClick={handleSaveClick} fullWidth variant="outlined">
            <Typography>저장</Typography>
          </Button>
          <Button sx={{ marginY: 1 }} onClick={handleDeleteClick} fullWidth variant="outlined" color="error">
            <Typography>삭제</Typography>
          </Button>
        </Box>
      </Box>
    </Card>
  );
}
