import { ENDPOINT } from '@const';
import { IResponse, TThreeNodeCreateRequest, TThreeNodeListModifyRequest, TThreeNodeResponse } from '@types';
import axios, { AxiosError } from 'axios';
import { checkError, createAuth, toastErrorMessage } from 'common/lib';

export const createThreeNodeAPI = async (request: TThreeNodeCreateRequest): Promise<TThreeNodeResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.threeNode.createThreeNode,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 캔버스 노드 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const modifyThreeNodeAPI = async (request: TThreeNodeCreateRequest): Promise<TThreeNodeResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.threeNode.modifyThreeNode,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 캔버스 노드 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const modiftThreeNodeListAPI = async (request: TThreeNodeListModifyRequest): Promise<IResponse<any>> => {
  const result = await axios
    .request({
      ...ENDPOINT.threeNode.modifyThreeNodes,
      ...createAuth(),
      data: request,
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 캔버스 노드 저장 실패');

  toastErrorMessage(result.data);

  return result.data;
};

export const deleteThreeNodeAPI = async (id: number): Promise<TThreeNodeResponse> => {
  const result = await axios
    .request({
      ...ENDPOINT.threeNode.deleteThreeNode,
      url: ENDPOINT.threeNode.deleteThreeNode.url.replace('{id}', id.toString()),
      ...createAuth(),
    })
    .catch((e: AxiosError) => checkError(e));

  if (!result) throw Error('3D 캔버스 노드 삭제 실패');

  toastErrorMessage(result.data);

  return result.data;
};
